import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionContext } from '../../App'
import DeviceReader from '../../components/DeviceReader'
import {
    breakdownIc, parseAddress, getState
} from '../../util'
import { STEPS } from "./steps"
import { STATES, STATES_FULLNAME } from '../../constants'
import {
    isAccountExist,
    getAccountDetails,
    update1MIDAccount,
    getProfile
} from '../../services/api'
import {
    setUnverifiedAccount
} from '../../services/local'
import {
    LoadingOutlined
} from '@ant-design/icons'
import {
    Button,
    Card,
    Image,
    message,
    Space
} from 'antd'
import thumbprint from '../../assets/thumbprint.jpg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import arrow_back from '../../assets/arrow_back.svg'
import verify_user from '../../assets/icon-bio-device-160@2x.png'

function AuthorizeRemoteUser(props) {

    const { branch, branchs, company, user } = useContext(SessionContext)
    const [step, setStep] = useState(STEPS.REMOTE_USER_ACTIVATION.label)
    const [subStep, setSubStep] = useState(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
    const { formattedDob, isMale } = breakdownIc(props.remoteUser?.identificationNo)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const COMPONENT_STEPS = {
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: "bolder",
                        }}
                    >
                        Verify new remote user identity
                    </h1>
                    <img src={verify_user} height={160} />
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY]: {
            component: (
                <div>
                    <Card
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px'
                        }}
                    >
                        <h2 style={{ textAlign: 'left', font: 'normal normal 600 24px Raleway' }}>Identity Card Details</h2>
                        <div
                            style={{
                                display: 'flex',
                                width: '100%'
                            }}
                        >
                            <img src={`data:image/png;base64, ${props.remoteUser?.photo}`} height='200px' style={{ marginRight: '10%' }} />
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <Space direction="vertical">
                                    <p style={styles.label}>Name</p>
                                    <p style={styles.label}>NRIC</p>
                                    <p style={styles.label}>Date of Birth</p>
                                    <p style={styles.label}>Gender</p>
                                    <p style={styles.label}>Address</p>
                                </Space>
                            </div>
                            <div style={{ flex: 4, textAlign: 'left' }}>
                                <Space direction='vertical'>
                                    <p style={styles.value}>{props.remoteUser?.name || props.remoteUser?.personName}</p>
                                    <p style={styles.value}>{props.remoteUser?.identificationNo}</p>
                                    <p style={styles.value}>{formattedDob}</p>
                                    <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                    <p style={styles.value}>{parseAddress({ ...props.remoteUser, state: parseInt(props.remoteUser?.state) ? Object.values(STATES).find(s => s.code === props.remoteUser?.state)?.label : props.remoteUser?.state })}</p>
                                </Space>
                            </div>
                        </div>
                    </Card>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between', marginTop: 40
                        }}
                    >
                        <Button
                            shape='round'
                            className='back-button buttons'
                            style={{ backgroundColor: "#F0F2F5" }}
                            onClick={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.REMOTE_USER_ACTIVATION.label,
                                    subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                                })
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            Back
                        </Button>
                        <Button
                            loading={isLoading}
                            size='large'
                            style={{
                                width: 200,
                                background: " #2B61C4 0% 0% no-repeat padding-box",
                                boxShadow: "0px 4px 0px #1E4489",
                                borderRadius: "8px",
                                borderColor: 'transparent'
                            }}
                            onClick={() => {
                                setIsLoading(true)
                                handleRemoteUserVerification()
                            }}
                            type='primary'
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            )
        },
    }

    const handleRemoteUserVerification = () => {

        const {
            address1,
            address2,
            address3,
            dob,
            birthPlace,// birthplace for sagem, birthPlace for dermalog
            birthplace,
            citizenship,
            city,// city for sagem, district for dermalog
            district,
            personName,// personName for sagem, name for dermalog
            name,
            identificationNo,
            postcode,
            race,
            religion,
            state,
        } = props.remoteUser;

        // let remoteUserName = name ? name : personName
        // remoteUserName = String(remoteUserName).replace(/[^\w]/g, "")

        getProfile()
            .then(res => {

                if (res.nric != identificationNo) {

                    message.error('Mismatch NRIC with registered user NRIC. Please ensure the correct mykad inserted.')
                    setIsLoading(false)
                    return

                } else {

                    let stateCode = parseInt(state) ? state : Object.values(STATES).find(s => s.label.replaceAll(" ", "").toLowerCase() == state.replaceAll(" ", "").toLowerCase())?.code

                    isAccountExist(identificationNo)
                        .then(res => {
                            if (!res.status) {
                                setIsLoading(false)
                                message.error(res.message)
                                return
                            }

                            getAccountDetails(identificationNo)
                                .then(res => {

                                    const {
                                        email,
                                        mobileno,
                                        phoneno,
                                        // name
                                    } = res;

                                    // let _name = String(name).replace(/[^\w]/g, "")
                                    
                                    // if (remoteUserName.localeCompare(_name) !== 0) {
                                    //     setIsLoading(false)
                                    //     message.error('The name entered is incorrect. For any further assistance, please call us at 03-7664 8181 or drop us an e-mail at twocar@myeg.com.my')
                                    //     return
                                    // }

                                    update1MIDAccount(
                                        address1,
                                        address2,
                                        address3,
                                        dob,
                                        birthplace ?? birthPlace,
                                        citizenship,
                                        city ?? district,
                                        email,
                                        email,
                                        isMale ? "MALE" : "FEMALE",
                                        mobileno,
                                        personName ?? name,
                                        identificationNo,
                                        true,
                                        phoneno,
                                        postcode,
                                        race,
                                        religion,
                                        stateCode
                                    ).then(res => {

                                        if (!res.status) {
                                            setIsLoading(false)
                                            message.error(res.message)
                                            return
                                        }

                                        history.push('/')
                                        message.success('Thumbprint verification success')
                                        setUnverifiedAccount(false)
                                        setIsLoading(false)


                                    }).catch(err => {
                                        setIsLoading(false)
                                        err && message.error(err?.toString())
                                    })

                                }).catch(err => {
                                    setIsLoading(false)
                                    err && message.error(err?.toString())
                                })

                        }).catch(err => {
                            setIsLoading(false)
                            err && message.error(err?.toString())
                        })

                }
            })
            .catch(err => {
                err && message.error(err)
            })

    }

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null

        // on refresh, remoteUser details will disappear, therefore, go back to INSERT_IC substep
        setSubStep(props.remoteUser.identificationNo ? history.location.state.subStep : STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
    }, [history.location.state])

    const currentStep = COMPONENT_STEPS[subStep]

    return (<>
        <div
            className={
                subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? 'form-background' : ""
            }
            style={{
                height: '50%',
                width: '100%',
                textAlign: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "center" : "left",
                padding: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "20px" : "0px",
                marginBottom: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "10px" : "0px"
            }}
        >
            {
                currentStep && currentStep.component
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                {
                    (
                        subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY
                    ) ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <DeviceReader
                                forKey={'remoteUser'}
                                nextStep={STEPS.REMOTE_USER_ACTIVATION.label}
                                nextSubStep={STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY}
                                onIdentityCardSuccess={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERTING_IC)
                                }}
                                onWaitingThumbprint={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_BEFORE)
                                }}
                                onThumbprintDetected={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINTING)
                                }}
                                onThumbprintFailed={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_FAILED)
                                }}
                                onThumbprintSuccess={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_SUCCESS)
                                }}
                                onTryAgain={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
                                }}
                                onFinish={data => {

                                    let errorMessages = [];

                                    if (!data.name) {
                                        errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.district) {
                                        errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                        errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (errorMessages.length > 0) {
                                        for (let errorMessage of errorMessages) {
                                            message.error(errorMessage);
                                        }
                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.REMOTE_USER_ACTIVATION.label,
                                            subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                                        })
                                        return;
                                    }

                                    const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                    let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                    if (!state) state = getState(data.identificationNo)

                                    props.onChange({
                                        ...data,
                                        state: state?.code,
                                        name: data.name.replace(/\*/g, ''),
                                        district: data.district.replace(/[^a-zA-Z\s]/g, '')
                                    })

                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY)
                                }}
                                onDataChanged={data => { }}
                                onError={data => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
                                }}
                            />
                        </div>
                    ) : null
                }
            </div>
        </div></>)

}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    },

    disclaimer: {
        color: 'grey', fontStyle: 'italic', marginBottom: '15px', width: "60%"
    }
}

export default AuthorizeRemoteUser