// Global site tag (gtag.js) - Google Analytics
const injectGA = () => {
  if (typeof window === "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", process.env.REACT_APP_GTAG);

  // Dynamically inject the GA script
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`;
  document.head.appendChild(script);
};

export default injectGA;
