import {
  Breadcrumb,
  Button,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import { PageHeader } from '@ant-design/pro-components';
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import export_xls from "../../../assets/export_xls_filled.svg";
import moment from "moment";
import {
  getInventoryList,
  getInventoryListCount,
  getVehicleDetails,
  getRolesByUsername,
} from "../../../services/api";
import { SessionContext } from "../../../App";
import { HQ_BRANCH, SEARCH_QUERY_TOOLTIP } from "../../../constants";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function InventoryListingPage({
  isReport,
  isM2mTransaction,
  vehicleProps,
  setVehicleSearchTerm,
  setDisableVehicle,
}) {
  const [searchForm] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [initialDataSource, setInitialDataSource] = useState([]);
  const { company, branch, branchs, user } = useContext(SessionContext);
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchByBranch, setShowSearchByBranch] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const noOfItemsPerPage = 10;

  const columns = [
    {
      title: "No",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNo",
      sorter: (a, b) => a.vehicleNo.localeCompare(b.vehicleNo),
    },
    /* {
			title: "Vehicle Model",
			dataIndex: "model",
		}, */
    {
      title: "Tx Type",
      dataIndex: "transactionType",
      render: (transactionType) => {
        return (
          <p style={{ color: "green", margin: "0px" }}>{transactionType}</p>
        );
      },
    },
    {
      title: "Tx Status",
      dataIndex: "transactionStatus",
      render: (transactionStatus) => {
        return (
          <p style={{ color: "green", margin: "0px" }}>{transactionStatus}</p>
        );
      },
    },
    {
      title: "Thumb In/Out User",
      dataIndex: "thumbInUser",
    },
    {
      title: "Creation Date",
      dataIndex: "transactionDate",
      render: (transactionDate) => {
        let formattedDate = new Date(transactionDate);
        return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
      },
      sorter: (a, b) => {
        let formattedA = moment(new Date(a.transactionDate));
        let formattedB = moment(new Date(b.transactionDate));

        return formattedA.diff(formattedB);
      },
    },
    {
      title: "Approved Date",
      dataIndex: "transactionDate",
      render: (transactionDate) => {
        let formattedDate = new Date(transactionDate);
        return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
      },
      sorter: (a, b) => {
        let formattedA = moment(new Date(a.transactionDate));
        let formattedB = moment(new Date(b.transactionDate));

        return formattedA.diff(formattedB);
      },
    },
    {
      title: "Location",
      dataIndex: "branchId",
      render: (branchId) => {
        let allBranches = branchs ? [...branchs, HQ_BRANCH] : [HQ_BRANCH];
        return allBranches.find((b) => b.id == branchId)?.branchName || "-";
      },
    },
  ];

  function validateAndTrimSearchQuery(searchTerm) {
    if (typeof searchTerm === "undefined" || searchTerm === undefined) {
      searchTerm = ""; // Assign empty string if searchTerm is undefined
    }
    return searchTerm.replace(/\s/g, "");
  }

  const handleSearch = () => {
    setIsLoading(true);
    const { searchBy, searchTerm } = searchForm.getFieldsValue();

    let branchId =
      selectedBranchId !== undefined ? selectedBranchId : branch.id;

    {
      !searchTerm && setDataSource(initialDataSource) && setIsLoading(false);
    }

    let trimmedSearchQuery = validateAndTrimSearchQuery(searchTerm);
    setSearchTerm(trimmedSearchQuery);
    {
      searchBy === "vehicleNumber" &&
        trimmedSearchQuery !== "" &&
        // getVehicleDetails(branchId, company.id, trimmedSearchQuery)
        getVehicleDetails("", company.id, trimmedSearchQuery)
          .then((res) => {
            if (res.message === "Success") {
              res.inventory.key = res.inventory.id;
              setDataSource(Array(res.inventory)); // returned invetory is just an object, but we need an array for datasource of table
              setIsLoading(false);
            } else {
              message.error(res.message);
              setIsLoading(false);
            }
          })
          .catch((err) => {
            err && message.error(err);
          });
    }

    setIsLoading(false);
  };

	useEffect(() => {
		if (Object.keys(branch).length > 0 && Object.keys(company).length) {
			setIsLoading(true);
			// getInventoryListCount(branch.id, company.id)
			// 	.then((result) => {
					// getInventoryList(branch.id, company.coRegNo, result.count, 1)
					getInventoryList(branch.id, company.coRegNo, 0, 1, "ALL")
          .then((res) => {
            const dataWithKey = res.map((item, index) => {
              return {
                key: index + 1,
                ...item,
              };
            });
            setInitialDataSource(dataWithKey);
            setDataSource(dataWithKey);
            setIsLoading(false);
          })
          .catch((err) => {
            err && message.error(err);
          });
				// })
				// .catch((err) => err && message.error(err));
		}
	}, [branch, company]);
  useEffect(() => {
    if (Object.keys(user).length > 0) {
      getRolesByUsername(user.nric)
        .then((res) => {
          if (res.status === true && res.roles.length > 0) {
            res.roles.map((role) => {
              if (isReport && (role.name === "OWNER" || role.name === "ADMIN")) {
                setShowSearch(false);
                setShowSearchByBranch(true);
                searchForm.setFieldsValue({
                  searchBy: "branch",
                });
              } else {
                setShowSearch(true);
                setShowSearchByBranch(false);
                searchForm.setFieldsValue({
                  searchBy: "vehicleNumber",
                });
              }
            });
          }
        })
        .catch((err) => {
          err && message.error(err);
        });
    }
  }, [user]);

  const onChangeSearchBy = () => {
    const { searchBy } = searchForm.getFieldsValue();

    searchBy === "vehicleNumber" ? setShowSearch(true) : setShowSearch(false);
  };

  const onChangeBranch = () => {
    setIsLoading(true);

    const { branchId } = searchForm.getFieldsValue();

    if (branchId === undefined || branchId === null) return;

    setSelectedBranchId(branchId);

    getInventoryListCount(branchId, company.id)
      .then((result) => {
        getInventoryList(branchId, company.coRegNo, result.count, 1)
          .then((res) => {
            const dataWithKey = res.map((item, index) => {
              return {
                key: index + 1,
                ...item,
              };
            });
            setInitialDataSource(dataWithKey);
            setDataSource(dataWithKey);
            setIsLoading(false);
          })
          .catch((err) => {
            err && message.error(err);
          });
      })
      .catch((err) => err && message.error(err));
  };

  useEffect(() => {
    if (branchs) {
      let activeBranches = branchs
        .filter((branch) => branch.is_active)
        .concat(HQ_BRANCH)
        .sort((a, b) => a.branchName.localeCompare(b.branchName));

      if (activeBranches.length > 0) {
        setBranchList(activeBranches);
      }
    }
  }, [branchs]);

  const resetVehicleSelection = () => {
    setSelectedRowKeys([]);
    setVehicleSearchTerm("");
    if(isM2mTransaction) {
        vehicleProps.setFieldsValue({
          ...vehicleProps,
          registrationNum: '',
          chassisNo: '',
          engineNo: '',
        });
      setDisableVehicle(false)
    }
    else {
        vehicleProps.onChange({
            ...vehicleProps.vehicle,
            chassisNo: '',
            engineNo: '',
            vehicleRegistrationNo: '',
        });
        setDisableVehicle(false)
    }
  }

  const setVehicleSelection = (selectedRowKey, selectedRows) => {
    setSelectedRowKeys(selectedRowKey);
    setVehicleSearchTerm(selectedRows[0]?.vehicleNo);

    if(isM2mTransaction) {
      setDisableVehicle(true)
        vehicleProps.setFieldsValue({
          ...vehicleProps,
          registrationNum: selectedRows[0]?.vehicleNo,
          chassisNo: selectedRows[0]?.chassisno ?? selectedRows[0]?.chassisNo,
          engineNo: selectedRows[0]?.engineno ?? selectedRows[0]?.engineNo,
        });
    }
    else {
        vehicleProps.onChange({
            ...vehicleProps.vehicle,
            chassisNo: selectedRows[0]?.chassisno ?? selectedRows[0]?.chassisNo,
            engineNo: selectedRows[0]?.engineno ?? selectedRows[0]?.engineNo,
            vehicleRegistrationNo: selectedRows[0]?.vehicleNo,
        });
    }
  }

  return (
    <>
      <div className="page-content">
        <>
          {isReport && (
            <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
              <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item href="/reporting">Reporting</Breadcrumb.Item>
              <Breadcrumb.Item href="/reporting/inventorylisting">
                Inventory Listing
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </>
        {isReport ? (
          <>
            <PageHeader
              className="site-page-header"
              title={"Inventory Listing"}
            />
            <div className="search-header">Search by</div>
          </>
        ) : (
          <>
            <h2
              style={{
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 24,
              }}
            >
              Inventory Listing
            </h2>
            <div className="search-header section-card-left">Search by</div>
          </>
        )}
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <Form
            layout="inline"
            className="select-search-wrapper"
            style={{ borderRadius: 4, marginBottom: 25, width: "50vw" }}
            form={searchForm}
          >
            <Form.Item
              className="form-item ant-card-left"
              name={"searchBy"}
              style={{ width: "200px" }}
            >
              <Select
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%", maxWidth: 200, height: 40 }}
                onChange={onChangeSearchBy}
              >
                {showSearchByBranch === true && (
                  <Select.Option value={"branch"}>Branch</Select.Option>
                )}

                <Select.Option value={"vehicleNumber"}>
                  Vehicle Number
                </Select.Option>
              </Select>
            </Form.Item>
            {showSearch === true ? (
              <Form.Item className="form-item" name={"searchTerm"}>
                <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                  <Input.Search
                    style={{ maxWidth: "100%" }}
                    size="large"
                    placeholder={"Search"}
                    enterButton
                    value={searchTerm}
                    onSearch={handleSearch}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      searchForm.setFieldsValue({
                        searchTerm: e.target.value.toUpperCase(),
                      });
                    }}
                  />
                </Tooltip>
              </Form.Item>
            ) : (
              <Form.Item
                className="form-item"
                name={"branchId"}
                style={{ width: "200px" }}
              >
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: "100%", maxWidth: 200, height: 40 }}
                  onChange={onChangeBranch}
                >
                  {branchList &&
                    branchList.map((branch) => {
                      return (
                        <Select.Option key={branch.id} value={branch.id}>
                          {branch.branchName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
          </Form>
          {isReport && (
            <ExcelFile
              element={
                <Button
                  shape="round"
                  className="add-new-user-button export-report"
                  type="primary"
                  disabled
                >
                  <Image
                    src={export_xls}
                    preview={false}
                    width={30}
                    height={30}
                    style={{ paddingRight: 10 }}
                  />
                  Export report
                </Button>
              }
              filename="InventoryListing"
            >
              <ExcelSheet data={dataSource} name="Inventory Listing">
                <ExcelColumn label="No" value="id" />
                <ExcelColumn label="Vehicle Number" value="vehicleNo" />
                <ExcelColumn label="Vehicle Model" value="model" />
                <ExcelColumn label="Tx Type" value="transactionType" />
                <ExcelColumn label="Tx Status" value="transactionStatus" />
                <ExcelColumn label="Thumb In/Out User" value="thumbInUser" />
                <ExcelColumn label="Creation Date" value="creationDate" />
                <ExcelColumn
                  label="Approved Date"
                  value={(col) => {
                    let formattedDate = new Date(col.transactionDate);
                    return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
                  }}
                />
                <ExcelColumn
                  label="Location"
                  value={(col) =>
                    [...branchs, HQ_BRANCH].find((b) => b.id == col.branchId)
                      ?.branchName || "-"
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          )}
        </Row>
        {isReport ?
        <Table
            className="report-table"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={{ total: dataSource.length, showSizeChanger: false, pageSize: noOfItemsPerPage }}
            scroll={{
                x: columns.length * 100,
            }}
            onChange={(pagination) => {
                setPage(pagination.current);
            }}/> :
        <Table
            className="report-table"
            dataSource={dataSource}
            columns={columns}
            loading={isLoading}
            pagination={{
                total: dataSource.length,
                showSizeChanger: false,
                pageSize: noOfItemsPerPage,
            }}
            scroll={{
                x: columns.length * 100,
            }}
            onChange={(pagination) => {
                setPage(pagination.current);
            }}
            onRow={(record, rowIndex) => ({
              onClick: () => {
                // setSelectedRowKeys(record.key);
                if(selectedRowKeys.length !== 0 && record.key === selectedRowKeys[0]) {
                    resetVehicleSelection()
                }
                /* else {
                    setSelectedRowKeys([record.key]);
                    setVehicleSearchTerm(record?.vehicleNo);
                    vehicleProps.onChange({
                        ...vehicleProps.vehicle,
                        chassisNo: record?.chassisno,
                        engineNo: record?.engineno,
                        vehicleRegistrationNo: record?.vehicleNo,
                    });
                } */
              }
            })}
            rowSelection={{
                type: "radio",
                selectedRowKeys,
                onChange: (selectedRowKey, selectedRows) => {
                    setVehicleSelection(selectedRowKey, selectedRows)
                },
                /* onSelect: (selectedRowKey) => {
                    console.log("selectedRowKey",selectedRowKey)
                } */
            }}
        />
    }
        

        <Row style={{ display: "flex", justifyContent: "end" }}>
          {/* <Button shape="round" className="add-new-user-button export-report" type="primary">
						<Image src={export_xls} preview={false} width={30} height={30} style={{ paddingRight: 10 }} />
						Export report
					</Button> */}
          {isReport && (
            <ExcelFile
              element={
                <Button
                  shape="round"
                  className="add-new-user-button export-report"
                  type="primary"
                  disabled
                >
                  <Image
                    src={export_xls}
                    preview={false}
                    width={30}
                    height={30}
                    style={{ paddingRight: 10 }}
                  />
                  Export report
                </Button>
              }
              filename="InventoryListing"
            >
              <ExcelSheet data={dataSource} name="Inventory Listing">
                <ExcelColumn label="No" value="id" />
                <ExcelColumn label="Vehicle Number" value="vehicleNo" />
                <ExcelColumn label="Vehicle Model" value="model" />
                <ExcelColumn label="Tx Type" value="transactionType" />
                <ExcelColumn label="Tx Status" value="transactionStatus" />
                <ExcelColumn label="Thumb In/Out User" value="thumbInUser" />
                <ExcelColumn label="Creation Date" value="creationDate" />
                <ExcelColumn
                  label="Approved Date"
                  value={(col) => {
                    let formattedDate = new Date(col.transactionDate);
                    return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
                  }}
                />
                <ExcelColumn
                  label="Location"
                  value={(col) =>
                    [...branchs, HQ_BRANCH].find((b) => b.id == col.branchId)
                      ?.branchName || "-"
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          )}
        </Row>
      </div>
      <style>
        {`
                .site-page-header { 
                    padding-left: 0px;
                    padding-bottom: 15px;
                }
                .search-header { 
                    font: italic normal normal 16px/22px Open Sans;
                    color: white;
                    margin-bottom: 7px;
                }
                .add-new-user-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 127px !important;	
                }
				.add-new-user-button > span { 
					font-size: 16px;
				}
                .export-report { 
                    padding-left: 30px;
                    padding-right: 30px;
                    box-shadow: none;
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { 
                    height: 48px !important;
                }
                .ant-select-single .ant-select-selector {
                    align-items: center;
                    color: #00000080;
                    font-weight: 600;
                }
                .ant-select-arrow { 
                    margin: 0px;
                }
                .ant-select-item-option-content { 
                    color: #2B61C4;
                    font-weight: 600;
                }
                .section-card-left {
                    text-align: left !important;
                    color: black; !important
                }
                .ant-card-left {
                    text-align: left !important;
                }
            `}
      </style>
    </>
  );
}
