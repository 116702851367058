import React, { useCallback, useContext, useEffect, useState } from "react";
import { Card, Row, Button, message, Pagination } from "antd";
import "./index.less";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { breakdownIc } from "../../util";
import propTypes from "prop-types";
import { STEPS } from "./steps";
import { SessionContext } from "../../App";
import { getOutTransferByTransferId } from "../../services/api";
import {
  BUTTON_RETURN_PENDING_TRANSACTION,
  ALERT_AUTHORITY_MESSAGE,
  BUTTON_RESUBMIT,
  BUTTON_JPJ_SUBMISSION,
} from "../../constants";
import {
  getCompanyId,
  getUserRole,
  getPermissions,
} from "../../services/local";
import { validateRolePermission } from "../../components/Utils";
import {
  VerificationCard,
  CompanyCardComponent,
} from "../../components/component/Layout/CardComponent";
import { VehicleDetailsTableComponent } from "../../components/component/Layout/TableComponent";
import {
  TextButton,
  JPJSubmissionButton,
} from "../../components/component/UI/Button";
import { InfoAlert } from "../../components/component/UI/Alert";
import { outTransferInquiry } from "./function";

function JPJSubmission(props) {
  const history = useHistory();
  const params = useParams();
  const { user } = useContext(SessionContext);

  // const [transferId, setTransferId] = useState(null)
  const [currentSubStep, setCurrentSubStep] = useState(
    STEPS.JPJ_SUBMISSION.subSteps.REVIEW
  );

  const [isJPJLoading, setIsJPJLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(true);
  const transferId = history.location.state.transferId ?? 0;
  const bulkTransferId = history.location.state.bulkTransferId ?? 0;
  const [isDisable, setIsDisabled] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState([]);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState("");
  const [sellerCompanyData, setSellerCompanyData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [buyerData, setBuyerData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    getUserRole().then((res) => {
      setLoggedInUserRole(res);
    });
    getPermissions().then(async (permissions) => {
      setPermissions(permissions);
    });
  }, []);

  const isUntilJpjSubmission = validateRolePermission(
    loggedInUserRole,
    "M2M",
    true,
    permissions
  );

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };

  const dataSource = vehicleDetails ? vehicleDetails : [];

  /* Pagination */
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [isFooterEditing, setIsFooterEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPageSize = 10;
  const totalPages = Math.ceil(dataSource.length / maxPageSize);
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const paginatedData = dataSource.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, dataSource.length)
  );
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };
  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsHeaderEditing(false);
    setIsFooterEditing(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const delayTimer = () => {
    setTimeout(() => {
      // setIsJPJLoading(true);
    }, 3000); // 3-second delay
  };

  /* getInBulkTransactionDetails */
  useEffect(async () => {
    if (bulkTransferId !== 0) {
      // await delayTimer();
      history.push(`/transfer/out/payment/${bulkTransferId}`, {
        isBulk: true,
      });
    }
  }, [bulkTransferId]);

  useEffect(async () => {
    if (transferId !== 0) {
      await delayTimer();
      await getTransactionDetail();
    }
  }, [transferId]);

  const getTransactionDetail = () => {
    getOutTransferByTransferId(transferId, "OUT", getCompanyId()).then(
      (res) => {
        try {
          if (res.status === 4) {
            message.warning("Redirect to printslip");
            history.push(`/transfer/out/printslip/${transferId}`, {
              isBulk: false,
            });
          } else if (res.status === 3) {
            history.push(`/transfer/out/payment/${transferId}`, {
              isBulk: false,
            });
          }
          setTransactionDetail(res);
          if (res.status === 6) setVerificationSuccess(false);
          setCurrentVehicle(res.carRegistrationNo);
          const sellerCompany = {
            id: 0,
            coName: res.sellerInfo.name,
            coRegNo: res.sellerInfo.identificationNo,
            email: res.sellerInfo.email,
            contactNo: res.sellerInfo.phoneNo,
            ucdCode: "",
          };
          setSellerCompanyData(sellerCompany);

          const buyer = {
            id: 0,
            name: res.buyerInfo.name,
            identificationNo: res.buyerInfo.identificationNo,
            email: res.buyerInfo.email,
            phoneNo: res.buyerInfo.phoneNo,
            ucdCode: "",
          };

          setBuyerData(buyer);

          const vehicle = {
            carRegistrationNo: res.carRegistrationNo,
            chassisNo: res.chassisNo,
            engineNo: res.engineNo,
            vehicleTypeNewId: res.vehicleTypeNewId,
            failCode: res.status === 6 || res.status === 1 ? res.failCode : "",
            branchId: res.vehicleBranchId,
          };
          const dataWithKey = {
            key: 1,
            ...vehicle,
          };
          setVehicleDetails([dataWithKey]);
          setTotalSuccess(1);
        } catch (err) {
        } finally {
          setIsJPJLoading(false);
        }
      }
    );
  };

  const onResubmit = async () => {
    setIsDisabled(true);
    await outTransferInquiry(user.nric, transferId)
      .then((res) => {
        if (res !== null) {
          setVerificationSuccess(true);
        } else {
          setVerificationSuccess(false);
        }
        getTransactionDetail();
      })
      .catch((ex) => {})
      .finally(() => {
        setIsDisabled(false);
      });
  };

  const onBackButton = () => {
    props.isFromAdminPage
      ? history.push("/adminSearchVehicleInfo")
      : props.isReport
      ? history.push("/reporting/transferreport/")
      : history.push(`/reporting/transferreport/Out`, { tabType: "1" });
  };

  /* Pagination */
  const CustomPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    isEditing,
    setIsEditing,
  }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              // onBlur={handleInputBlur}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const showingVehicleCount = () => {
    return (
      <span>
        Showing{" "}
        <b>
          {startItem} - {endItem}
        </b>{" "}
        of <b>{dataSource.length} vehicles</b>.&nbsp;&nbsp;&nbsp;&nbsp;All JPJ
        verification are saved in{" "}
        <Button style={{ padding: 0 }} type="link" onClick={onBackButton}>
          <span style={{ textDecoration: "underline" }}>
            pending submission
          </span>
          .
        </Button>
      </span>
    );
  };

  const PaginationWrapperHeader = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isHeaderEditing}
            setIsEditing={setIsHeaderEditing}
          />
        </Row>
      </>
    );
  };

  const PaginationWrapperFooter = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isFooterEditing}
            setIsEditing={setIsFooterEditing}
          />
        </Row>
      </>
    );
  };

  const VehicleDetailPaginationTable = () => {
    return (
      <Card
        style={{
          flex: 3,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <>
          <Pagination
            current={currentPage}
            pageSize={1}
            total={totalPages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            showQuickJumper={false}
            showSizeChanger={false}
            style={{ marginBottom: "16px" }}
          />
          {PaginationWrapperHeader()}
          {VehicleDetailsTableComponent(paginatedData)}
          {PaginationWrapperFooter()}
        </>
      </Card>
    );
  };
  /* Pagination */

  useEffect(() => {
    if (!history.location.state) return null;
    if (!history.location.state.subStep) return null;
    setCurrentSubStep(history.location.state.subStep);
  }, [history.location.state]);

  const LoadingCard = () => {
    return (
      <Card>
        <p style={{ marginTop: "10px", fontSize: "2em" }}>
          <strong>JPJ Verification</strong>
        </p>
        <p style={{ marginTop: "60px", marginBottom: "60px" }}>
          Checking in progress. Retrieving data...
        </p>
      </Card>
    );
  };

  return (
    <>
      {isJPJLoading ? (
        <LoadingCard />
      ) : (
        <>
          {/* {isUntilJpjSubmission && InfoAlert(ALERT_AUTHORITY_MESSAGE)} */}

          {!verificationSuccess && (
            <VerificationCard
              isSuccess={false}
              totalSuccess={totalSuccess}
              totalFailed={totalFailed}
              isBulk={bulkTransferId !== 0}
              isPayment={true}
              vehicleNo={currentVehicle}
              onBackButtonClick={onBackButton}
              isUpdateJpjFailed={false}
            />
          )}
          <br />
          {VehicleDetailPaginationTable()}
          <br />
          {CompanyCardComponent(sellerCompanyData, buyerData, false)}
          <br />
          <br />
          {/* {isUntilJpjSubmission && InfoAlert(ALERT_AUTHORITY_MESSAGE)} */}

          {TextButton(BUTTON_RETURN_PENDING_TRANSACTION, onBackButton)}
          {JPJSubmissionButton(
            onResubmit,
            isDisable,
            verificationSuccess ? BUTTON_JPJ_SUBMISSION : BUTTON_RESUBMIT
          )}
        </>
      )}
      {
        // currentStep.component
      }
      <style>
        {`
        .ant-pagination {
          display: none;
        }
        .custom-pagination {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .custom-wrap-row {
          display: flex;
          justify-content: space-between;
        }
      `}
      </style>
    </>
  );
}

JPJSubmission.propTypes = {
  seller: propTypes.object.isRequired,
  buyer: propTypes.object.isRequired,
  vehicle: propTypes.object.isRequired,
  transactionType: propTypes.number.isRequired,
  onApproverNRICChange: propTypes.func.isRequired,
  approverNric: propTypes.string,
};

const styles = {
  label: {
    font: "italic normal normal 16px Open Sans",
    color: "#333333",

    marginBottom: 0,
  },
  value: {
    font: "normal normal 600 16px Open Sans",
    color: "#333333",
  },
};

export default JPJSubmission;
