import React from "react";
import {
  Form,
  Input,
  Button,
  Image,
  Select,
  Space,
  message,
  Row,
  Col,
} from "antd";
import arrow_back from "../../assets/arrow_back.svg";
import { STEPS } from "../../pages/CreateOutTransferPageV2/steps";
import { getDistrictList } from "../../services/api";
import { STATES, OUT_TRANSACTION_TYPES_IDS } from "../../constants";

function formatNumber(number) {
  const str = number.toString();
  return `${str.slice(0, 6)}-${str.slice(6, 8)}-${str.slice(8)}`;
}

export default function AuthorizeBuyerComponent({
  parseAddress,
  removeTrailings,
  history,
  props,
  isShowCity,
  cityLoading,
  cityList,
  isButtonDisabled,
  styles,
  formattedDob,
  form,
  districts,
  correspondentDistricts,
  buyerCity,
  isAddressDifferent,
  setDistrictLoading,
  setCorrespondentDistricts,
  isMale,
}) {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <div
            style={{
              padding: 24,
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: 4,
              width: "100%",
              marginBottom: 24,
              height: "400px",
              // display: "flex",
              flexDirection: "column",
              wordWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                <div style={{ display: "flex", marginTop: 20 }}>
                  <img
                    alt=""
                    src={`data:image/png;base64, ${props.buyer.photo}`}
                    height="200px"
                    style={{ marginRight: "10%" }}
                  />
                </div>
              </Col>
              {props.buyer.name && (
                <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                  <div style={{ marginTop: 20, paddingLeft: 20 }}>
                    <div>
                      <>
                        <p
                          style={{
                            font: "normal normal 600 24px Open Sans",
                            marginBottom: 24,
                          }}
                        >
                          <b>{props.buyer.name}</b> (
                          {formatNumber(props.buyer.identificationNo)})
                        </p>
                        <p style={styles.value}>{formattedDob}</p>
                        <p style={styles.value}>{isMale ? "Male" : "Female"}</p>
                        <p style={styles.value}>
                          {parseAddress({
                            ...props.buyer,
                            state: Object.values(STATES).find(
                              (s) => s.code === props.buyer?.state
                            )?.label,
                            district: "",
                          })}
                        </p>
                      </>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 32,
            }}
          >
            <Button
              className="back-button buttons"
              shape="round"
              onClick={() => {
                props.onChange({});

                history.push(history.location.pathname, {
                  step: STEPS.TRANSACTION_TYPE.label,
                  subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE,
                  tabType: props.tabType,
                });
              }}
            >
              <Image src={arrow_back} preview={false} />
              Back
            </Button>
          </div>
        </Col>
        <Col span={14}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "80%",
            }}
          >
            <Form
              form={form}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              layout={"vertical"}
              initialValues={{
                correspondentMobileNo: props.buyer.mobileNo,
                correspondentEmail: props.buyer.email,
              }}
              onFinish={(values) => {
                if (values.correspondentAddress1) {
                  values.correspondentAddress1 = removeTrailings(
                    values.correspondentAddress1
                  );
                }

                if (values.correspondentAddress2) {
                  values.correspondentAddress2 = removeTrailings(
                    values.correspondentAddress2
                  );
                }

                if (values.correspondentAddress3) {
                  values.correspondentAddress3 = removeTrailings(
                    values.correspondentAddress3
                  );
                }

                if (values.district) {
                  districts
                    .filter(
                      (district) => district.districtCode === values.district
                    )
                    .map((district) => {
                      values.district = district.districtName;
                    });
                }

                if (values.correspondentDistrict) {
                  correspondentDistricts
                    .filter(
                      (district) =>
                        district.districtCode === values.correspondentDistrict
                    )
                    .map((district) => {
                      values.correspondentDistrict = district.districtName;
                    });
                }

                const buyer = {
                  address1: props.buyer?.address1,
                  address2: props.buyer?.address2,
                  address3: props.buyer?.address3,
                  correspondentAddress1: values.correspondentAddress1 ?? "",
                  correspondentAddress2: values.correspondentAddress2 ?? "",
                  correspondentAddress3: values.correspondentAddress3 ?? "",
                  correspondentPostcode: values.correspondentPostcode ?? "",
                  correspondentState: values.correspondentState ?? "",
                  correspondentDistrict: values.correspondentDistrict ?? "",
                  correspondentEmail: values.correspondentEmail,
                  correspondentMobileNo: values.correspondentMobileNo,
                  dateOfBirth: props.buyer?.dateOfBirth,
                  city: values.cityName ? values.cityName : buyerCity,
                  mykadCity: props.buyer.mykadCity ?? props.buyer?.city,
                  district: values.district,
                  email: props.buyer?.email,
                  gender: props.buyer?.gender,
                  identificationNo: props.buyer?.identificationNo?.replace(
                    /[\s*]/g,
                    ""
                  ),
                  name: props.buyer?.name,
                  phoneNo: props.buyer?.phoneNo,
                  mobileNo: props.buyer?.mobileNo,
                  postcode: props.buyer?.postcode,
                  state: props.buyer?.state,
                  photo: props.buyer?.photo,
                  buyerType: props.buyer?.buyerType,
                  isAddressDifferent: isAddressDifferent,
                  citizenship: props.buyer?.citizenship,
                };

                props.onChange(buyer);
                switch (props.transactionType) {
                  case OUT_TRANSACTION_TYPES_IDS.I2C:
                    history.push(history.location.pathname, {
                      ...history.location.state,
                      step: STEPS.AUTHORIZE_SELLER.label,
                      subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                    });
                    break;
                  default:
                    history.push(history.location.pathname, {
                      ...history.location.state,
                      step: STEPS.VEHICLE_INFORMATION.label,
                      subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO,
                    });
                    break;
                }
              }}
            >
              <div
                style={{
                  padding: "24px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "4px",
                  //   width: "100%",
                  minHeight: "400px", // Set your desired minimum height here
                  height: "auto", // Allow the div to expand as needed
                  display: "flex",
                  flexDirection: "column",
                  //   maxWidth: "100%",
                }}
              >
                <h2 style={{ fontWeight: "bold", marginTop: 10 }}>
                  Additional Buyer information
                </h2>
                <div
                  style={{
                    font: "normal normal 16px Open Sans",
                  }}
                >
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    *
                  </span>{" "}
                  indicates required field
                </div>
                <Row gutter={[10, 22]} style={{ marginTop: "25px" }}>
                  <Col span={12}>
                    <Form.Item
                      name="correspondentMobileNo"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp("^\\d{9,11}$"),
                          message:
                            "Accepts only numbers (length must be between 9 - 11)",
                        },
                      ]}
                      label={
                        <>
                          <p style={styles.formLabel}>Mobile Number</p>&nbsp;
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            *
                          </span>
                        </>
                      }
                    >
                      <Input
                        className="textbox-form-style"
                        // style={{ width: 500 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="correspondentEmail"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            "^(?=.{6,50}$)[a-zA-Z0-9~!$%^.&*_\\-=+}|{\\‘?#/`]*@[a-zA-Z0-9_\\-\\.]*$"
                          ),
                          message: (
                            <li>
                              <ol>{"Must be between 6 - 50 characters"}</ol>
                              <ol>{"Must contain @"}</ol>
                              <ol>
                                {
                                  "Allow for (a-z), (A-Z), (0-9) ~!$%^.&*_-=+}|{‘?#/`"
                                }
                              </ol>
                            </li>
                          ),
                        },
                      ]}
                      label={
                        <>
                          <p style={styles.formLabel}>Email</p>&nbsp;
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            *
                          </span>
                        </>
                      }
                    >
                      <Input
                        className="textbox-form-style"
                        // style={{ width: 500 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  {isShowCity && (
                    <Col span={12}>
                      <Form.Item
                        name="cityName"
                        rules={[
                          {
                            required: true,
                            message: `Please choose city from the dropdown`,
                          },
                        ]}
                        label={
                          <>
                            <p style={styles.formLabel}>City</p>&nbsp;
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              *
                            </span>
                          </>
                        }
                      >
                        <Select
                          className="custom-select"
                          showSearch
                          style={{ height: 40 }}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              .toUpperCase()
                              .indexOf(input.toUpperCase()) >= 0
                          }
                          loading={cityLoading}
                        >
                          {cityList.map((d) => {
                            return (
                              <Select.Option
                                key={d.cityCode}
                                value={d.cityName}
                              >
                                {d.cityName}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 32,
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  className="rectangle-button to-the-right"
                  disabled={isButtonDisabled}
                >
                  Continue
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <style>
        {`
              .ant-form-item-label
                > label.ant-form-item-required:not(
                  .ant-form-item-required-mark-optional
                )::before {
                display: none !important;
              }
          `}
      </style>
    </>
  );
}
