import React, { useContext } from "react";
import { Card } from "antd";
import { SessionContext } from "../../App";

import "./index.css";

const translation = {
    en: {
        mainTitle: "Interested to get early access to TwoCar?",
        mainSubtitle: "Email us at ",
        cardHeader: "Required details and documents",
        list1: {
            title: (
                <>
                    Please provide <span>company details</span> in your email:
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Company name</li>
                        <li>Company registration number</li>
                        <li>
                            Contact number (we may contact you via this number
                            for verification purposes)
                        </li>
                    </ul>
                </>
            ),
        },
        list2: (
            <>
                2 photos of your{" "}
                <span>showroom with the signboard visible.</span>.
            </>
        ),
        list3: (
            <>
                A copy of the company director/owner's <span>MyKad</span> (front
                and back).
            </>
        ),
        list4: (
            <>
                A copy of the <span>Business License</span> (Majlis Perbandaran
                Tempatan), <span>Membership Certificate</span> of Malaysian /
                Regional Car or Motorcycle Association, if any.
            </>
        ),
        list5: {
            title: (
                <>
                    If your company is an <span>Enterprise</span>: sole
                    proprietership, partnerships, and limited-liability
                    partnerships (LLP).
                </>
            ),
            subTitle: (
                <>
                    <p>Additional documents for Enterprise companies:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Certificate of Registration(Form D) / Form E</li>
                        <li>Business Information</li>
                        <li>Latest Business Owner Information</li>
                    </ul>
                </>
            ),
        },
        list6: {
            title: (
                <>
                    If your company is an <span>Sdn Bhd/Bhd</span>:
                </>
            ),
            subTitle: (
                <>
                    <p>Additional documents for Sdn Bhd/Bhd companies:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>SSM Company Profile (ROC)</li>
                        <li>Certificate of Incorporation (Section 17)</li>
                        <li>
                            Application for Company Registration (Section 14)
                        </li>
                        <li>
                            Notification of Appointment of Company Secretary
                            (Sections 58 & 236 [2])
                        </li>

                        <li>Register of Members (Sections 51 / 58) if any</li>
                        <li>SSM Change of Company Name (Section 28) if any</li>
                    </ul>
                </>
            ),
        },
        list7: {
            title: (
                <>
                    If your company located in <span>Sabah/Sarawak</span>:
                </>
            ),
            subTitle: (
                <>
                    <p>Additional documents for Sabah/Sarawak companies:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Business Names Ordinance (Chapter 64)</li>
                        <li>Form 1 (Sections 5, 23, and 24(2))</li>
                        <li>Business Names Registration Extract</li>
                    </ul>
                </>
            ),
        },
        list8: (
            <>
                Additional or supporting <span>documents</span> if any.
            </>
        ),
    },
    bm: {
        mainTitle: "Berminat untuk mendapatkan akses awal kepada TwoCar?",
        mainSubtitle: "E-mel kami di ",
        cardHeader: "Butiran dan dokumen yang diperlukan",
        list1: {
            title: (
                <>
                    Sila berikan <span>butiran syarikat</span> dalam e-mel anda:
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Nama syarikat</li>
                        <li>Nombor pendaftaran syarikat</li>
                        <li>
                            Nombor untuk dihubungi (kami mungkin menghubungi
                            anda melalui nombor ini untuk tujuan pengesahan)
                        </li>
                    </ul>
                </>
            ),
        },
        list2: (
            <>
                2 gambar{" "}
                <span>
                    ruang pameran syarikat dengan papan tanda yang jelas
                </span>
                .
            </>
        ),
        list3: (
            <>
                Salinan <span>MyKad</span> pengarah/pemilik syarikat (depan and
                belakang).
            </>
        ),
        list4: (
            <>
                Salinan <span>Lesen Perniagaaan</span> (Majlis Perbandaran
                Tempatan), <span>Sijil Keahlian Persatuan</span> Kereta atau
                Motorsikal Malaysia / Serantau, jika ada
            </>
        ),
        list5: {
            title: (
                <>
                    Jika syarikat anda adalah <span>Enterprise</span>: pemilikan
                    tunggal, perkongsian dan perkongsian liabiliti terhad (LLP).
                </>
            ),
            subTitle: (
                <>
                    <p>Dokumen tambahan untuk syarikat Enterprise:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Sijil pendaftaran (Borang D) / Borang E</li>
                        <li>Maklumat Perniagaan</li>
                        <li>Maklumat Pemilik Perniagaan Terkini</li>
                    </ul>
                </>
            ),
        },
        list6: {
            title: (
                <>
                    Jika syarikat anda adalah <span>Sdn Bhd/Bhd</span>:
                </>
            ),
            subTitle: (
                <>
                    <p>Dokumen tambahan untuk syarikat Sdn Bhd/Bhd:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Profil Syarikat SSM (ROC)</li>
                        <li>Sijil Pemerbadanan (seksyen 17)</li>
                        <li>Permohonan Pendaftaran Syarikat (Seksyen 14)</li>
                        <li>
                            Notifikasi Pelantikan Setiausaha Syarikat(Seksyen 58
                            & 236 [2])
                        </li>

                        <li>Daftar Ahli (Seksyen 51 / 58) jika ada</li>
                        <li>
                            Pertukaran Nama Syarikat SSM (Seksyen 28) jika ada
                        </li>
                    </ul>
                </>
            ),
        },
        list7: {
            title: (
                <>
                    Jika syarikat anda terletak di <span>Sabah/Sarawak</span>:
                </>
            ),
            subTitle: (
                <>
                    <p>Dokumen tambahan untuk syarikat Sabah/Sarawak:</p>
                </>
            ),
            lists: (
                <>
                    <ul>
                        <li>Ordinan Nama-Nama Perniagaan (Bab 64)</li>
                        <li>Borang 1(Seksyen 5, 23, dan 24(2))</li>
                        <li>Ekstrak Pendaftaran Nama-Nama Perniagaan</li>
                    </ul>
                </>
            ),
        },
        list8: (
            <>
                <span>Dokumen</span> tambahan atau sokongan, jika ada.
            </>
        ),
    },
};

export default function Reginterest() {
    const { language } = useContext(SessionContext);

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <p className="landing-title">{translation[language]?.mainTitle}</p>
            <p className="landing-subtitle">
                {translation[language]?.mainSubtitle}

                <a href="mailto:twocar@myeg.com.my">twocar@myeg.com.my</a>
            </p>
            <Card
                style={{
                    minWidth: "75vw",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "8px",
                }}
            >
                <div className="landing-lists">
                    <p
                        style={{
                            fontSize:
                                window.innerWidth <= 768 ? "20px" : "24px",
                            paddingBottom:
                                window.innerWidth <= 768 ? "8px" : "16px",
                        }}
                    >
                        {translation[language]?.cardHeader}
                    </p>
                    <ul>
                        <li>
                            {translation[language]?.list1?.title}
                            <div>
                                <Card
                                    style={{
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "8px",
                                        background: "#EDDEFF",
                                        textAlign: "left",
                                        marginTop: "12px",
                                        maxWidth: "fit-content",
                                        paddingBottom: "12px",
                                    }}
                                    className="my-custom-card"
                                >
                                    <div>
                                        <div>
                                            {
                                                translation[language]?.list1
                                                    ?.lists
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </li>
                        <li>{translation[language]?.list2}</li>
                        <li>{translation[language]?.list3}</li>
                        <li>{translation[language]?.list4}</li>
                        <li>
                            {translation[language]?.list5?.title}
                            <div>
                                <Card
                                    style={{
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "8px",
                                        background: "#DEEDFF",
                                        textAlign: "left",
                                        marginTop: "12px",
                                        maxWidth: "fit-content",
                                        paddingBottom: "12px",
                                    }}
                                    className="my-custom-card"
                                >
                                    <div>
                                        {translation[language]?.list5?.subTitle}

                                        <div>
                                            {
                                                translation[language]?.list5
                                                    ?.lists
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </li>
                        <li>
                            {translation[language]?.list6?.title}
                            <div>
                                <Card
                                    style={{
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "8px",
                                        background: "#EDDEFF",
                                        textAlign: "left",
                                        marginTop: "12px",
                                        maxWidth: "fit-content",
                                        paddingBottom: "12px",
                                    }}
                                    className="my-custom-card"
                                >
                                    <div>
                                        {translation[language]?.list6?.subTitle}

                                        <div>
                                            {
                                                translation[language]?.list6
                                                    ?.lists
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </li>
                        <li>
                            {translation[language]?.list7?.title}
                            <div>
                                <Card
                                    style={{
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "8px",
                                        background: "#DEEDFF",
                                        textAlign: "left",
                                        marginTop: "12px",
                                        maxWidth: "fit-content",
                                        paddingBottom: "12px",
                                    }}
                                    className="my-custom-card"
                                >
                                    <div>
                                        {translation[language]?.list7?.subTitle}

                                        <div>
                                            {
                                                translation[language]?.list7
                                                    ?.lists
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </li>
                        <li>{translation[language]?.list8}</li>
                    </ul>
                </div>
            </Card>
        </div>
    );
}
