export const STEPS = {
    AUTHORIZE_DEALER: {
        label: 'AUTHORIZE_DEALER',
        subSteps: {
            INSERT_IC: 'D_INSERT_IC',
            INSERTING_IC: 'D_INSERTING_IC',
            THUMBPRINT_BEFORE: 'D_THUMBPRINT_BEFORE',
            THUBMPRINTING: 'D_THUBMPRINTING',
            THUMBPRINT_FAILED: 'D_THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'D_THUMBPRINT_SUCCESS',
            SUMMARY: 'D_SUMMARY',
            AUTHORIZE_FAILED: 'AUTHORIZE_FAILED'
        }
    },
    OWNERSHIP_TYPE: {
        label: 'OWNERSHIP_TYPE',
        subSteps: {
            OWNERSHIP_TYPE: 'OWNERSHIP_TYPE',
        }
    },
    VEHICLE_INFORMATION: {
        label: 'VEHICLE_INFORMATION',
        subSteps: {
            VEHICLE_INFO: 'VEHICLE_INFO',
            UPDATE_VEHICLE_INFO: 'UPDATE_VEHICLE_INFO'
        }
    },
    AUTHORIZE_SELLER: {
        label: 'AUTHORIZE_SELLER',
        subSteps: {
            INSERT_IC: 'S_INSERT_IC',
            INSERTING_IC: 'S_INSERTING_IC',
            THUMBPRINT_BEFORE: 'S_THUMBPRINT_BEFORE',
            THUBMPRINTING: 'S_THUBMPRINTING',
            THUMBPRINT_FAILED: 'S_THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'S_THUMBPRINT_SUCCESS',
            SUMMARY: 'S_SUMMARY'
        }
    },
    JPJ_SUBMISSION: {
        label: 'JPJ_SUBMISSION',
        subSteps: {
            REVIEW: 'REVIEW',
            JPJ_CHECKING: 'JPJ_CHECKING',
            JPJ_FAILED: 'JPJ_FAILED',
            JPJ_SUCCESS: 'JPJ_SUCCESS',
        }
    },
    PAYMENT: {
        label: 'PAYMENT'
    },
    PRINT_SLIP: {
        label: 'PRINT_SLIP',
    }
}


export type Dealer = {
    address1?: string,
    address2?: string,
    address3?: string,
    district?: string,
    state: string,
    postcode: string,
    dob: string,
    correspondentEmail?: string,
    gender: string,
    correspondentAddress?: string,
    correspondentMobileNo?: string,
    name: string,
    nric: string,
    phoneno?: string,
    username?: string,
    photo?: string,
    mobileno?: string,
}


export type Buyer = {
    address1?: string,
    address2?: string,
    address3?: string,
    correspondentAddress1?: string,
    correspondentAddress2?: string,
    correspondentAddress3?: string,
    correspondentPostcode?: string,
    correspondentState?: string,
    correspondentEmail?: string,
    correspondentMobileNo?: string,
    dateOfBirth?: string,
    dob?: string,
    district?: string,
    email?: string,
    gender?: "",
    identificationNo: string,
    name: string,
    phoneNo?: string,
    postcode?: string,
    state: string,
    photo?: string,
}

export type Vehicle = {
    carMake: string,
    carModel: string,
    chassisNo: string,
    engineNo: string,
    manufactureYear: string,
    ownerIdCat: string,
    vehicleRegistrationNo: string,
    vehicleType: string,
    isWhiteListVehicle: false, //30.3.2022 daniel.kwok hardcoded defaults
    lkmoption: 1, //30.3.2022 daniel.kwok hardcoded defaults
}
export type Seller = {
    address1?: string,
    address2?: string,
    address3?: string,
    correspondentAddress1?: string,
    correspondentAddress2?: string,
    correspondentAddress3?: string,
    correspondentDistrict: string,
    correspondentPostcode?: string,
    correspondentState?: string,
    correspondentEmail?: string,
    correspondentMobileNo?: null | string,
    dateOfBirth: string,
    district: string,
    city: string,
    email?: string,
    gender: string,
    identificationNo: string,
    name: string,
    phoneNo?: string,
    postcode: string,
    repIc?: string,
    repIc2?: string,
    repName?: string,
    sellerType: number,
    state: string,
    photo?: string,
    citizenship: null | string,
}

export type AdminUser = {
    nric_no: string,
    login_name: string,
    name: string,
}

export type HistoryState = {
    dealer?: Dealer,
    vehicle?: Vehicle,
    seller?: Seller,
    step?: string,
    subStep?: string,
    isReport: boolean,
    fromPendingPage: boolean,
    oldIsReport: boolean,
}

export enum Modes {
    create = 'create',
    edit = 'edit',
    payment = 'payment',
    printslip = 'printslip',
    vehicleinfo = 'vehicleinfo'
}

export type Params = {
    mode: Modes,
    id: string,
    transferId: string,
}

export enum ReceiptType {
    vehicleDealerCopy = '1',
    confirmationSlip = '2',
    dealerCopy = '3',
}