export const STEPS = {
    AUTHORIZE_ADMIN_COMPANY_USER: {
        label: 'AUTHORIZE_ADMIN_COMPANY_USER',
        subSteps: {
            EDIT_STAFF: 'EDIT_STAFF',
            ADD_STAFF: 'ADD_STAFF'
        }
    },
    ADMIN_COMPANY_USER_VIEW: {
        label: 'ADMIN_COMPANY_USER_VIEW',
        // subSteps: {
        //     INSERT_IC: 'INSERT_IC'
        // }
    },
}