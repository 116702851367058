import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, Col, Row, Button, message, Pagination } from "antd";
import "./index.less";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  PrinterOutlined,
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { breakdownIc } from "../../util";
import {
  getOutTransferAmountByTransferId,
  getOutTransferByTransferId,
  getPrepaidBalance,
  getPrepaidReceiptOUT,
  getPrepaidReceiptV2OUT,
  getOutBulkTransactionDetails,
} from "../../services/api";
import { BUTTON_RETURN_PENDING_TRANSACTION } from "../../constants";
import { CheckCircleOutlined } from "@ant-design/icons";
import { SessionContext } from "../../App";
import { useShortcut } from "../../components/Utils";
import {
  getCompanyId,
  getUserRole,
  getPermissions,
} from "../../services/local";
import { validateRolePermission } from "../../components/Utils";
import {
  VerificationCard,
  CompanyCardComponent,
  TransactionAmountCard,
} from "../../components/component/Layout/CardComponent";
import {
  VehicleSelectedTableComponent,
  VehicleDetailsTableComponent,
} from "../../components/component/Layout/TableComponent";
import { TextButton, BackButton } from "../../components/component/UI/Button";

export default function PrintSlip(props) {
  const { isReport } = props;

  const history = useHistory();
  const params = useParams();
  const { company, user, branchs, branch } = useContext(SessionContext);
  const { state: locationState } = useLocation();

  const [isPrintLoading, setIsPrintLoading] = useState(false);
  const [transfer, setTransfer] = useState(null);

  const [isNewPrice, setIsNewPrice] = useState(false);
  const [isPrint2Loading, setIsPrint2Loading] = useState(false);
  const [isPrint3Loading, setIsPrint3Loading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const transferId = Number(
    history.location.state.isBulk
      ? 0
      : params.transferId ?? history.location.state.transferId
  );
  const [bulkTransferId, setBulkTransferId] = useState(
    Number(history.location.state.isBulk ? params.transferId : 0)
  );
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [permissions, setPermissions] = useState([]);
  //payment
  //Approval

  const [balanceAmount, setBalanceAmount] = useState(0);

  const [isJPJLoading, setIsJPJLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(true);
  const [updateJpjFailed, setUpdateJpjFailed] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState("");
  const [sellerCompanyData, setSellerCompanyData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [buyerData, setBuyerData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [transactionAmount, setTransactionAmount] = useState({
    id: 0,
    name: "",
    jpjAmount: 0,
    eserviceAmount: 0,
    fisAmount: 0,
    fisEserviceAmount: 0,
    sstAmount: 0,
    totalAmount: 0,
    processingAmount: 0,
    subTotalAmount: 0,
  });

  useEffect(() => {
    getUserRole().then((res) => {
      setLoggedInUserRole(res);
    });
    getPermissions().then(async (permissions) => {
      setPermissions(permissions);
    });
  }, []);
  {
    useShortcut(setIsModalVisible);
  }

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };

  const dataSource = vehicleDetails ? vehicleDetails : [];
  const isBulk = bulkTransferId && bulkTransferId !== 0;

  /* Pagination */
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [isFooterEditing, setIsFooterEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPageSize = 10;
  const totalPages = Math.ceil(dataSource.length / maxPageSize);
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const paginatedData = dataSource.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, dataSource.length)
  );
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };
  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsHeaderEditing(false);
    setIsFooterEditing(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  /* Pagination */

  const vehicleDetailList = paginatedData.map((item, index) => {
    return {
      no: index + 1,
      vehicleNo: item.carRegistrationNo,
      ...item,
    };
  });

  const isFromReport = locationState?.oldIsReport;
  const isFromAdminPage = history?.location?.state?.fromAdminPage;

  useEffect(async () => {
    const res = await getPrepaidBalance(company.coRegNo);
    res.balanceAmount && setBalanceAmount(res.balanceAmount);
  }, []);

  /* getInBulkTransactionDetails */
  useEffect(() => {
    if (bulkTransferId !== 0) {
      getBulkTransactionDetail();
    }
  }, [bulkTransferId]);

  const getBulkTransactionDetail = () => {
    setIsJPJLoading(true);
    getOutBulkTransactionDetails(bulkTransferId, "BULKOUT").then((res) => {
      try {
        const sellerCompany = {
          id: 0,
          coName: res.seller.name,
          coRegNo: res.seller.identificationNo,
          email: res.seller.email ?? "",
          contactNo: res.seller.phoneNo ?? "",
          ucdCode: "",
        };
        setSellerCompanyData(sellerCompany);

        const buyer = {
          id: 0,
          name: res.buyer.name,
          identificationNo: res.buyer.identificationNo,
          email: res.buyer.email,
          phoneNo: res.buyer.phoneNo,
          ucdCode: "",
        };
        setBuyerData(buyer);

        const dataWithKey = res.vehicleDetailList.map((item, index) => {
          return {
            key: index + 1,
            ...item,
          };
        });
        setVehicleDetails(dataWithKey);
        setTransactionAmount(res.transactionAmount);
        setTotalSuccess(res.totalSuccess);
        const totalFail = res.vehicleDetailList.length - res.totalSuccess;
        setTotalFailed(totalFail ?? 0);
        setIsTransactionSuccess(true);
      } catch (err) {
      } finally {
      }
    });

    setIsJPJLoading(false);
  };

  useEffect(() => {
    if (transferId !== 0) {
      getTransactionDetail();
    }
  }, [transferId]);

  const getTransactionDetail = () => {
    getOutTransferByTransferId(transferId, "OUT", getCompanyId()).then(
      (res) => {
        try {
          if (res?.vehicleTypeNewId !== 0) {
            setIsNewPrice(true);
          }
          /* if (res.status === 4) {
            message.warning("Redirect to printslip");
            history.push(`/transfer/out/printslip/${transferId}`, {
              isBulk: false,
            });
          } */
          if (res.status === 6) setVerificationSuccess(false);
          if (res.status === 7) setUpdateJpjFailed(true);
          if (res.status === 4) setIsTransactionSuccess(true);
          setCurrentVehicle(res.carRegistrationNo);
          const sellerCompany = {
            id: 0,
            coName: res.sellerInfo?.name ?? company.coName ?? "",
            coRegNo: res.sellerInfo?.identificationNo ?? company.coRegNo ?? "",
            email: res.sellerInfo?.email ?? company.email ?? "",
            contactNo: res.sellerInfo?.phoneNo ?? company.contactNo ?? "",
            ucdCode: "",
          };
          setSellerCompanyData(sellerCompany);

          const buyer = {
            id: 0,
            name: res.buyerInfo?.name ?? "",
            identificationNo: res.buyerInfo?.identificationNo ?? "",
            email: res.buyerInfo?.email ?? "",
            phoneNo: res.buyerInfo?.phoneNo ?? "",
            ucdCode: "",
          };

          setBuyerData(buyer);
          const failCode = res.failCode === "" ? "-" : res.failCode;

          const vehicle = {
            carRegistrationNo: res.carRegistrationNo,
            chassisNo: res.chassisNo,
            engineNo: res.engineNo,
            vehicleTypeNewId: res.vehicleTypeNewId,
            failCode: res.status === 6 || res.status === 7 ? failCode : "",
            branchId: res.vehicleBranchId,
          };
          const dataWithKey = {
            key: 1,
            ...vehicle,
          };
          setVehicleDetails([dataWithKey]);
          setTotalSuccess(1);

          getOutTransferAmountByTransferId(transferId)
            .then((res) => {
              if (res.id === undefined) throw res.message;

              const amount = {
                id: res.id,
                name: res.name,
                jpjAmount: res.jpjAmount,
                eserviceAmount: res.eserviceAmount,
                fisAmount: res.fisAmount,
                fisEserviceAmount: res.fisEserviceAmount,
                sstAmount: res.sstAmount,
                totalAmount: res.totalAmount,
                processingAmount: res.processingAmount,
                subTotalAmount: res.processingAmount + res.eserviceAmount,
              };
              setTransactionAmount(amount);
            })
            .catch((err) => err && message.error(err))
            .finally(() => {});
        } catch (err) {
          message.error(err);
        } finally {
          setIsJPJLoading(false);
        }
      }
    );
  };

  const LoadingCard = () => {
    return (
      <Card>
        <LoadingOutlined />
      </Card>
    );
  };

  const onResetData = () => {};
  const onBackButton = () => {
    isFromAdminPage
      ? history.push("/adminSearchVehicleInfo")
      : isFromReport
      ? history.push("/reporting/transferreport/")
      : history.push(`/reporting/transferreport/Out`, { tabType: "2" });
  };

  /* Pagination */
  const CustomPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    isEditing,
    setIsEditing,
  }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              // onBlur={handleInputBlur}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const showingVehicleCount = () => {
    return (
      <span>
        Showing{" "}
        <b>
          {startItem} - {endItem}
        </b>{" "}
        of <b>{dataSource.length} vehicles</b>.&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    );
  };

  const PaginationWrapperHeader = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isHeaderEditing}
            setIsEditing={setIsHeaderEditing}
          />
        </Row>
      </>
    );
  };

  const PaginationWrapperFooter = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isFooterEditing}
            setIsEditing={setIsFooterEditing}
          />
        </Row>
      </>
    );
  };

  const VehicleDetailPaginationTable = () => {
    return (
      <Card
        style={{
          flex: 3,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <>
          <Pagination
            current={currentPage}
            pageSize={1}
            total={totalPages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            showQuickJumper={false}
            showSizeChanger={false}
            style={{ marginBottom: "16px" }}
          />
          {PaginationWrapperHeader()}
          {/* {VehicleDetailsTableComponent(paginatedData)} */}
          {isTransactionSuccess
            ? VehicleSelectedTableComponent(
                vehicleDetailList,
                branchs,
                false,
                true,
                branch.id === 0
              )
            : VehicleDetailsTableComponent(paginatedData)}
          {PaginationWrapperFooter()}
        </>
      </Card>
    );
  };
  /* Pagination */

  const ReceiptType = {
    vehicleDealerCopy: "1",
    confirmationSlip: "2",
    dealerCopy: "3",
  };

  function onPrintSlip(type, setPrintLoading) {
    if (
      type !== null &&
      type !== "undefined" &&
      (ReceiptType.confirmationSlip === type ||
        ReceiptType.dealerCopy === type ||
        ReceiptType.vehicleDealerCopy === type)
    ) {
      setPrintLoading(true);
      getPrepaidReceiptV2OUT(
        company.id,
        transferId,
        vehicleDetails[0].carRegistrationNo,
        type
      ).finally(() => setPrintLoading(false));
    } else {
      setIsPrintLoading(true);
      getPrepaidReceiptOUT(
        company.id,
        transferId,
        vehicleDetails[0].carRegistrationNo
      ).finally(() => setIsPrintLoading(false));
    }
  }

  const { formattedDob: buyerFormattedDob, isMale: buyerIsMale } = breakdownIc(
    transfer?.buyerInfo?.identificationNo
  );
  const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } =
    breakdownIc(transfer?.sellerInfo?.identificationNo);

  const PrintReportButton = () => {
    return !isNewPrice ? (
      <Button
        className="back-button"
        icon={<PrinterOutlined />}
        shape="round"
        style={{
          flex: 1,
          border: "1px solid #2B61C4",
          color: "#2B61C4",
        }}
        loading={isPrintLoading}
        onClick={onPrintSlip}
      >
        Print Slip
      </Button>
    ) : (
      <Col>
        <Row>
          <Button
            className="back-button"
            icon={<PrinterOutlined />}
            shape={"round"}
            style={{
              flex: 1,
            }}
            loading={isPrintLoading}
            onClick={() =>
              onPrintSlip(ReceiptType.vehicleDealerCopy, setIsPrintLoading)
            }
          >
            Vehicle Dealer Copy
          </Button>
        </Row>
        <br />
        <Row>
          <Button
            className="back-button"
            icon={<PrinterOutlined />}
            shape={"round"}
            style={{
              flex: 1,
            }}
            loading={isPrint2Loading}
            onClick={() =>
              onPrintSlip(ReceiptType.confirmationSlip, setIsPrint2Loading)
            }
          >
            Confirmation Slip
          </Button>
        </Row>
        <br />
        <Row>
          <Button
            className="back-button"
            icon={<PrinterOutlined />}
            shape={"round"}
            style={{
              flex: 1,
            }}
            loading={isPrint3Loading}
            onClick={() =>
              onPrintSlip(ReceiptType.dealerCopy, setIsPrint3Loading)
            }
          >
            Dealer Copy
          </Button>
        </Row>
      </Col>
    );
  };

  const verificationPaymentCard = () => {
    return (
      <div style={{ width: "100%" }}>
        <Card
          style={{
            height: "50%",
            textAlign: "left",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "4px",
            // marginBottom: 16,
          }}
        >
          <Row gutter={16}>
            <Col span={2}>
              <CheckCircleOutlined
                style={{
                  color: "#40a05f",
                  fontSize: 70,
                  marginRight: 20,
                  paddingTop: isNewPrice ? "30px" : "10px",
                }}
              />
            </Col>
            <Col span={16}>
              <div
                style={{
                  color: "#40a05f",
                  font: "normal normal bold 16px/24px Open Sans",
                  padding: isNewPrice ? "35px 0px 15px" : "15px 0px 15px",
                }}
              >
                Payment of RM {Number(transactionAmount.totalAmount).toFixed(2)}{" "}
                is successful.
              </div>
              <div>
                Your remaining account balance is{" "}
                <strong>RM {Number(balanceAmount).toFixed(2)}</strong>.
              </div>
            </Col>
            <Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {transferId && transferId !== 0 ? PrintReportButton() : null}
            </Col>
          </Row>
        </Card>
      </div>
    );
  };

  return (
    <>
      {isJPJLoading ? (
        <LoadingCard />
      ) : (
        <>
          {isTransactionSuccess ? (
            verificationPaymentCard()
          ) : (
            <VerificationCard
              isSuccess={verificationSuccess}
              totalSuccess={totalSuccess}
              totalFailed={totalFailed}
              isBulk={false}
              isPayment={true}
              vehicleNo={currentVehicle}
              onBackButtonClick={onBackButton}
              isUpdateJpjFailed={updateJpjFailed}
            />
          )}
          <br />
          {VehicleDetailPaginationTable()}
          <br />
          {CompanyCardComponent(sellerCompanyData, buyerData, false)}
          <br />
          {transactionAmount.totalAmount !== 0 &&
            transactionAmount.totalAmount !== null &&
            TransactionAmountCard(
              transactionAmount ? transactionAmount : [],
              totalSuccess,
              currentVehicle,
              isBulk
            )}
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isFromAdminPage || isFromReport
              ? BackButton(onBackButton)
              : TextButton(BUTTON_RETURN_PENDING_TRANSACTION, onBackButton)}
          </div>
        </>
      )}

      <style>
        {`
                .ant-pagination {
                  display: none;
                }
                .custom-pagination {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                }
                .custom-wrap-row {
                  display: flex;
                  justify-content: space-between;
                }
              `}
      </style>
    </>
  );
}

const styles = {
  label: {
    font: "italic normal normal 16px Open Sans",
    color: "#333333",
    marginBottom: 8,
  },
  value: {
    font: "normal normal 600 16px Open Sans",
    color: "#333333",
  },
};

// export default PrintSlip;
