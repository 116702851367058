import React, { useState } from "react";
import { Input, Button, Form, Card, message } from "antd";
import { useHistory } from "react-router-dom";
import {
    check2FA,
    checkOtpStatus,
    // login,
    // check1midAccount,
    loginNric,
} from "../../services/api";
import {
    setToken,
    setUnverifiedAccount,
    setIsAuthorizeToAccessUserManagement,
    getAuthorizedNric,
    removeAuthorizedNric,
    setAuthUid,
    setCipheredPassword,
} from "../../services/local";
import { APP_CONST, iv } from "../../constants";
import { HideEyeIcon, ShowEyeIcon } from "../../components/CustomIcons";
import CryptoJS from "crypto-js";
// import AES from 'crypto-js/aes';

export default function LoginPage() {
    console.log("0.0.482");
    const [username, setUsername] = useState("");
    const [nric, setNric] = useState("");
    const [password, setPassword] = useState("");
    const [submittedusernamepassword, setSubmittedusernamepassword] =
        useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginDisabled, setIsLoginDisabled] = useState(false);
    const [error, setError] = useState("");

    const currentUsernamePassword = nric + password;

    if (
        isLoginDisabled === true &&
        currentUsernamePassword !== submittedusernamepassword
    ) {
        setIsLoginDisabled(false);
    }

    const onSubmit = () => {
        if (nric.trim() === "" && password.trim() === "") {
            setError("The Mykad number and Password cannot be empty");
            return;
        }
        if (nric.trim() === "") {
            setError("Mykad number cannot be empty");
            return;
        }
        if (password.trim() === "") {
            setError("Password cannot be empty");
            return;
        }

        setError("");

        const _usernamepassword = nric + password;
        setSubmittedusernamepassword(_usernamepassword);

        let passwordCiphertext = CryptoJS.AES.encrypt(password, iv).toString();

        setIsLoading(true);
        // login(username, password)
        loginNric(nric, passwordCiphertext)
            .then(async (res) => {
                if (res.status !== "00") throw res;

                await setToken(res.access_token);
                await setUnverifiedAccount(res.unverifiedAccount);
                await setIsAuthorizeToAccessUserManagement(true); //set to true to disable otp
                await setAuthUid(res.uid);
                await setCipheredPassword(passwordCiphertext);

                let authorizedNric = getAuthorizedNric();

                if (authorizedNric) {
                    await removeAuthorizedNric();
                }

                if (APP_CONST.USE_OTP === true) {
                    await checkOtpStatus().then((res) => {
                        if (res.status === true) {
                            window.location.pathname = "/postlogin";
                        } else {
                            history.push("/otp");
                            window.location.reload();
                        }
                    });
                } else {
                    await check2FA().then((res) => {
                        if (res.status === true) {
                            window.location.pathname = "/postlogin";
                        } else {
                            history.push("/2fa");
                            window.location.reload();
                        }
                    });
                }
            })
            .catch((err) => {
                err && message.error(err.error_description || err.message);
                setIsLoginDisabled(true);
            })
            .finally(() => setIsLoading(false));
    };

    const history = useHistory();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Card
                style={{
                    width: 300,
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "8px",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h2 style={{ font: "normal normal 600 32px/37px Raleway" }}>
                        Login
                    </h2>
                </div>
                <Form layout={"vertical"}>
                    {/* <Form.Item
                        label='Username (1MID)'
                        name='username'
                        style={{ font: 'italic normal normal 16px/22px Open Sans', color: '#333333' }}
                    >
                        <Input
                            value={username}
                            placeholder={'Enter username'}
                            onChange={e => setUsername(e.target.value.trim())}
                            onPressEnter={onSubmit}
                            style={{

                                border: '1px solid #2B61C4',
                                borderRadius: '4px',
                                font: 'normal normal 600 15px/20px Open Sans'
                            }}
                        />
                    </Form.Item> */}
                    <Form.Item
                        label="Mykad number"
                        name="nric"
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        style={{
                            font: "italic normal normal 16px/22px Open Sans",
                            color: "#333333",
                        }}
                    >
                        <Input
                            value={nric}
                            placeholder={"Enter mykad number"}
                            onChange={(e) => setNric(e.target.value.trim())}
                            maxLength={12}
                            onPressEnter={onSubmit}
                            style={{
                                border: "1px solid #2B61C4",
                                borderRadius: "4px",
                                font: "normal normal 600 15px/20px Open Sans",
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        placeholder={"Password"}
                        name="password"
                        style={{
                            font: "italic normal normal 16px/22px Open Sans",
                            color: "#333333",
                        }}
                    >
                        <Input.Password
                            iconRender={(visible) =>
                                visible ? ShowEyeIcon() : HideEyeIcon()
                            }
                            value={password}
                            placeholder={"Enter password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onPressEnter={onSubmit}
                            style={{
                                border: "1px solid #2B61C4",
                                borderRadius: "4px",
                                font: "normal normal 600 15px/20px Open Sans",
                            }}
                        />
                    </Form.Item>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{ marginBottom: "8px" }}
                    >
                        <Button
                            loading={isLoading}
                            type="primary"
                            onClick={onSubmit}
                        >
                            Login
                        </Button>
                    </Form.Item>
                    <Button
                        id={"forgot-password-link"}
                        style={{
                            width: "100%",
                            background: "white",
                            border: "none",
                            color: "#2B61C4",
                            textTransform: "uppercase",
                        }}
                        type="secondary"
                        onClick={() => history.push("/forgot-password")}
                    >
                        Forgot password
                    </Button>
                </Form>
            </Card>
        </div>
    );
}
