import { Pagination } from "antd";

export const CustomPaginationComponent = (
  setCurrentPage,
  currentPage,
  totalPages,
  setSelectAll
) => {
  return (
    <Pagination
      current={currentPage}
      pageSize={1}
      total={totalPages}
      onChange={(page) => {
        setCurrentPage(page);
        setSelectAll((prev) => ({ ...prev, [page]: false }));
      }}
      showSizeChanger={false}
      style={{ marginBottom: "16px" }}
    />
  );
};