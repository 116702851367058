import React, { useEffect, useState } from 'react'
import {
    Card,
    Typography,
    message,
    Skeleton,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import './index.css'
import { activateAccount } from '../../services/api'

const STATUSES = {
    ACTIVATING: 'ACTIVATING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
}


export default function ActivateAccountPage() {

    const history = useHistory()
    const params = useParams()

    const [status, setStatus] = useState(null)

    /**
     * if dev != undefined, tokenStatus === verified
     * This is for debugging purposes
     */
    const { token, dev } = params

    useEffect(async () => {

        setStatus(STATUSES.ACTIVATING)
        activateAccount(token)
            .then(res => {
                if (res.status !== true) throw res.message
                message.success(res.message)
                setStatus(STATUSES.SUCCESS)
            })
            .catch(err => {
                setStatus(STATUSES.FAILED)
                err && message.error(err.toString())
            })
    }, [])

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography.Title
                style={{
                    color: 'white'
                }}
            >
                Twocar
            </Typography.Title>

            <Card
                style={{
                    width: 450,
                }}
            >
                {
                    status === STATUSES.TOKEN_INVALID ? (
                        <>
                            <h2>Invalid activate account link</h2>
                            <a
                                id='back-link'
                                onClick={() => history.push('/login')}
                            >
                                Back to login
                            </a>
                        </>
                    ) : status === STATUSES.ACTIVATING ? (
                        <>
                            <h2>Activating account</h2>
                            <Skeleton active />
                        </>
                    ) : status === STATUSES.SUCCESS ? (
                        <>
                            <h2 style={{ color: 'light-green' }}>Activation success</h2>
                            <a
                                id='back-link'
                                onClick={() => history.push('/login')}
                            >
                                Click here to login
                            </a>
                        </>
                    ) : status === STATUSES.FAILED ? (
                        <>
                            <h2 style={{ color: 'light-red' }}>Activation failed</h2>
                            <a
                                id='back-link'
                                onClick={() => history.push('/login')}
                            >
                                Back to login
                            </a>
                        </>
                    ) : null
                }
            </Card>
        </div>
    )
}
