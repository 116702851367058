import React, { useContext, useEffect, useState } from "react";
import {
    Form,
    Input,
    Button,
    Table,
    message,
    Select,
    Switch,
    Modal,
    Row,
    Col,
    Image,
    Breadcrumb,
    Card
} from "antd";
import { PageHeader } from '@ant-design/pro-components';
import { useHistory, useLocation } from "react-router-dom";
import { HQ_BRANCH } from "../../constants";
import "./index.less";
import {
    deleteBranchuser,
    activateOrDeactivateBranchUser,
    getCompanyOfLoggedInUser,
    getCompanyDetails
} from "../../services/api";
import { SessionContext } from "../../App";
import { STEPS } from "../BranchUserFormPage/steps";
import arrow_back from "../../assets/arrow_back.svg";
import { getUserRole } from "../../services/local"

const AdminCompanyPage = () => {

    const { users: _users, company, branch } = useContext(SessionContext)

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [searchForm] = Form.useForm()
    const [dataSource, setDataSource] = useState([])
    const [showTable, setShowTable] = useState()
    const [isFirstLoad, setIsFirstLoad] = useState(true)

    useEffect(async () => {

        if (company.id) {

            await getUserRole().then(async (role) => {

                if (role != 'MYEG_ADMIN') history.goBack()
            })

        }
    }, [company])

    const columns = [
        {
            title: "Name",
            dataIndex: "coName",
            sorter: (a, b) => a.coName.localeCompare(b.coName)
        },
        {
            title: "Registration Number",
            dataIndex: "coRegNo",
            ellipsis: true,
            width: "50%",
            sorter: (a, b) => a.coRegNo.localeCompare(b.coRegNo)
        },
        {
            title: "Action",
            render: (rowData) => {
                return (
                    <Button
                        style={{
                            border: "1px solid #2B61C4",
                            padding: "12px 24px",
                            borderRadius: "4px",
                            color: "#2B61C4",
                            display: "flex",
                            alignItems: "center",
                            font: "normal normal 600 14px/19px Open Sans",
                        }}
                        onClick={() => {
                            history.push(`/adminCompany/view`, {
                                companyInfo: rowData
                                // step: STEPS.AUTHORIZE_BRANCH_USER.label,
                                // subStep:
                                //     STEPS.AUTHORIZE_BRANCH_USER.subSteps.FORM,
                            })
                        }}
                    >
                        VIEW{" "}
                    </Button>
                );
            },
        },
        {
            title: "Activation",
            fixed: "right",
            // sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
            // render: (rowData) => {
            //     return (
            //         <>
            //             <Switch
            //                 checked={rowData.is_active}
            //                 onChange={(e) => {
            //                     const is_active = e;

            //                     setIsLoading(true);
            //                     activateOrDeactivateBranchUser(
            //                         is_active,
            //                         rowData.id
            //                     )
            //                         .then((res) => {
            //                             if (res.status !== true)
            //                                 throw res.message;
            //                             const newBranchUsers = users.map(
            //                                 (b) => {
            //                                     if (b.id === rowData.id) {
            //                                         b.is_active = is_active;
            //                                     }
            //                                     return b;
            //                                 }
            //                             );
            //                             message.success(res.message);
            //                             setUsers(newBranchUsers);
            //                         })
            //                         .catch((err) => {
            //                             message.error(err.toString());
            //                         })
            //                         .finally(() => setIsLoading(false));
            //                 }}
            //             />
            //         </>
            //     );
            // },
        }
    ];

    const onSearch = () => {

        setIsLoading(true)

        const { searchBy, searchTerm } = searchForm.getFieldsValue();

        if (!searchTerm || searchTerm.trim().length === 0) {

            message.error('Please enter a search term')
            return
        }

        setIsFirstLoad(false)

        let resultCompany = []
        let companyDetails = []

        if (searchBy === 'rocNo') {

            getCompanyDetails(searchTerm.trim().toUpperCase())
                .then(res => {

                    if (res.status != '00') throw res.message

                    companyDetails.push(res.companyDetails)
                    companyDetails[0]['users'] = res.users

                    resultCompany.push(companyDetails[0])
                    message.success(res.message)

                })
                .catch(err => {
                    err && message.error(err)
                })
                .finally(() => {

                    if (Object.keys(resultCompany).length > 0) {
                        setShowTable(true)
                    } else {
                        setShowTable(false)
                    }

                    setDataSource(resultCompany)
                    setIsLoading(false)

                })

        } else if ('companyId') {

        }
    };

    // useEffect(() => {

    //     if (dataSource) {
    //         if (Object.keys(dataSource).length > 0) {
    //             setShowTable(true)
    //         } else {
    //             setShowTable(false)
    //         }
    //     } else {
    //         setShowTable(false)
    //     }

    // }, [dataSource])

    return (
        <>
            <div className="page-content">
                <Breadcrumb separator=">" style={{ marginTop: "60px" }}>
                    <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/usermanagement">
                        Company Information
                    </Breadcrumb.Item>
                </Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    style={{ paddingLeft: "0px" }}
                    title="Company Information"
                />
                <Row>
                    <Col span={24}>
                        <div
                            style={{
                                font: "italic normal normal 16px/22px Open Sans",
                                color: "white",
                                marginBottom: "7px",
                            }}
                        >
                            Search by
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "25px",
                            }}
                        >
                            <Form
                                layout="inline"
                                className="select-search-wrapper"
                                style={{ minWidth: "65vw", borderRadius: 4 }}
                                initialValues={{
                                    searchBy: "rocNo",
                                }}
                                form={searchForm}
                            >
                                <Form.Item
                                    className="form-item"
                                    name={"searchBy"}
                                >
                                    <Select
                                        optionFilterProp="children"
                                        // filterOption={(input, option) =>
                                        //     option.children
                                        //         .toLowerCase()
                                        //         .indexOf(input.toLowerCase()) >=
                                        //     0
                                        // }
                                        style={{ width: "100%", maxWidth: 200 }}
                                    >
                                        <Select.Option value={"rocNo"}>
                                            ROC Number
                                        </Select.Option>
                                        {/* <Select.Option value={"companyId"}>
                                            Company Id
                                        </Select.Option> */}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    className="form-item"
                                    name={"searchTerm"}
                                >
                                    <Input.Search
                                        style={{ maxWidth: "100%" }}
                                        size="large"
                                        placeholder={"Search"}
                                        enterButton
                                        onSearch={onSearch}
                                        onChange={e => {
                                            searchForm.setFieldsValue({
                                                searchTerm: e.target.value.toUpperCase()
                                            })
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
                {showTable === true &&
                    <Table
                        className="report-table"
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        loading={isLoading}
                        scroll={{
                            x: columns.length * 150,
                        }}
                        // rowSelection={{
                        //     type: "checkbox",
                        //     onChange: (selectedRowKeys) => {
                        //         setSelectedIds(selectedRowKeys);
                        //     },
                        // }}
                        // pagination={{
                        //     showSizeChanger: false,
                        //     showQuickJumper: false,
                        // }}
                        footer={() => { }}
                    />
                }

                {
                    showTable === false && searchForm.getFieldValue('searchTerm') != undefined &&
                    <Card
                        style={{
                            height: '50%',
                            width: '100%',
                            boxShadow: '0px 3px 6px #00000029',
                            marginBottom: '20px'
                        }}
                    >

                        <div>
                            <h1
                                style={{
                                    fontSize: 25,
                                    fontWeight: 'bolder'
                                }}
                            >
                                No company
                            </h1>
                            <p style={{
                                fontStyle: 'italic'
                            }}>Sorry, no company matching with <strong>{searchForm.getFieldValue('searchTerm')}</strong></p>
                        </div>

                    </Card>

                }

                {
                    isFirstLoad === true &&

                    <Card
                        style={{
                            height: '50%',
                            width: '100%',
                            boxShadow: '0px 3px 6px #00000029',
                            marginBottom: '20px'
                        }}
                    >
                        <div>
                            <h1
                                style={{
                                    fontSize: 25,
                                    fontWeight: 'bolder'
                                }}
                            >
                                How to start
                            </h1>
                            <p style={{
                                fontStyle: 'italic'
                            }}>To begin, search for the company registration number</p>
                        </div>
                    </Card>
                }

                {/* {
                    showTable === false &&
                    <Card
                        style={{
                            height: '50%',
                            width: '100%',
                            boxShadow: '0px 3px 6px #00000029',
                            marginBottom: '20px'
                        }}
                    >

                        {
                            isFirstLoad === true ?

                                <div>
                                    <h1
                                        style={{
                                            fontSize: 25,
                                            fontWeight: 'bolder'
                                        }}
                                    >
                                        How to start
                                    </h1>
                                    <p style={{
                                        fontStyle: 'italic'
                                    }}>To begin, search for the company registration number</p>
                                </div>

                                :

                                <div>
                                    <h1
                                        style={{
                                            fontSize: 25,
                                            fontWeight: 'bolder'
                                        }}
                                    >
                                        No company
                                    </h1>
                                    <p style={{
                                        fontStyle: 'italic'
                                    }}>Sorry, no company matching with <strong>{searchForm.getFieldValue('searchTerm')}</strong></p>
                                </div>

                        }


                    </Card>
                } */}

                <Button
                    className="back-button buttons"
                    shape="round"
                    onClick={() => {
                        history.push('/')
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>
            <style>
                {`                                
                .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
            `}
            </style>
        </>
    );
}

export default AdminCompanyPage
