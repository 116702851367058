export const STEPS = {
  REMOTE_USER_ACTIVATION: {
    label: "REMOTE_USER_ACTIVATION",
    subSteps: {
      INSERT_IC: "INSERT_IC",
      INSERTING_IC: "INSERTING_IC",
      THUMBPRINT_BEFORE: "THUMBPRINT_BEFORE",
      THUMBPRINTING: "THUMBPRINTING",
      THUMBPRINT_FAILED: "THUMBPRINT_FAILED",
      THUMBPRINT_SUCCESS: "THUMBPRINT_SUCCESS",
      SUMMARY: "SUMMARY",
      AUTHORIZE_FAILED: "AUTHORIZE_FAILED",
    },
  },
};
