import { message, Space, Steps, Card } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { STEPS } from './steps'
import { SessionContext } from '../../App'
import AuthorizeDealer from './authorizeDealer'
import VehicleInformation from './vehicleInformation'
import AuthorizeBuyer from './authorizeBuyer'
import JPJSubmission from './jpjSubmission'
import Payment from './payment'
import { getAuthorizedNric, getCachedStates, removeCachedStates, setAuthorizedNric, getSagemUrlForKey, removeSagemUrlForKey,
    getCompanyId, 
} from '../../services/local'
import { getOutTransferByTransferId, isAccountExist, getAccountDetails } from '../../services/api'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import AuthorizeSeller from './authorizeSeller'
import { STATES, OUT_TRANSACTION_TYPES_IDS, STATES_FULLNAME } from '../../constants'
import TransactionType from './transactionType'
import PrintSlip from './printSlip'
import moment from 'moment'
import {
    getState
} from '../../util'
import {skipAuth, decodeForSagem, decryptUrlVerify} from '../../components/Utils'

export default function CreateI2ITransferPage() {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const [partyA, setPartyA] = useState('Owner') // Party A a.k.a seller
    const [partyB, setPartyB] = useState('Buyer') // Party B a.k.a buyer
    const { user } = useContext(SessionContext)
    const [dealer, setDealer] = useState({
        "address1": "",
        "address2": "",
        "addres3": "",
        "district": "",
        "state": "",
        "postcode": "",
        "dob": "",
        "correspondentEmail": "",
        "gender": "",
        "correspondentMobileNo": null,
        "name": "",
        "nric": "",
        "phoneno": "",
        "username": ""
    })
    const [vehicle, setVehicle] = useState(
        {
            "carMake": "",
            "carModel": "",
            "chassisNo": "",
            "engineNo": "",
            "manufactureYear": "",
            "ownerIdCat": "",
            "vehicleRegistrationNo": "",
            "vehicleType": "",
            "isWhiteListVehicle": false,    // 28.12.2021 daniel.kwok hardcoded defaults
            "lkmoption": 1,                 // 28.12.2021 daniel.kwok hardcoded defaults

        }
    )
    const [seller, setSeller] = useState({
        "address1": "",
        "address2": "",
        "address3": "",
        "correspondentAddress": "",
        "correspondentEmail": "",
        "correspondentMobileNo": "",
        "dateOfBirth": "",
        "district": "",
        "email": "",
        "gender": "",
        "identificationNo": "",
        "name": "",
        "phoneNo": "",
        "postcode": "",
        "repIc": "",
        "repIc2": "",
        "repName": "",
        "state": "",
        "dob": "",
        "citizenship": "",
    })
    const [buyer, setBuyer] = useState({
        "address1": "",
        "address2": "",
        "address3": "",
        "dateOfBirth": "",
        "district": "",
        "email": "",
        "gender": "",
        "identificationNo": "",
        "name": "",
        "phoneNo": "",
        "postcode": "",
        "state": "",
        "citizenship": "",
    })
    const [approverNric, setApproverNric] = useState(null)
    const [transactionType, setTransactionType] = useState(OUT_TRANSACTION_TYPES_IDS.I2I)
    window.historyProps = history
    const [isUnknownTransfer, setIsUnknownTransfer] = useState(false)
    const tabType = history.location.state?.tabType ?? "1"

    useEffect(() => {

        const urlParams = new URLSearchParams(location.search);
        // When sagem redirect back, prevent it from re-executing below logic
        // so that it goes to sagem data receiver logic
        if (urlParams.get('forKey') == null) {

            if (location?.state?.fromAdminPage === true) {

                if (params.mode == 'vehicleinfo') {

                    getOutTransferByTransferId(location?.state?.transferId, "I2I", getCompanyId()).then((res) => {

                        if (res.id === undefined) throw res.message

                        setTransactionType(res.transactionType);

                        if (params.mode === 'vehicleinfo') {

                            let vt = {
                                "carMake": res?.make,
                                "carModel": res?.model,
                                "chassisNo": res?.chassisNo,
                                "engineNo": res?.engineNo,
                                "manufactureYear": res?.manufactureYear,
                                "ownerIdCat": res?.ownerCatagory,
                                "vehicleRegistrationNo": res?.carRegistrationNo,
                                "vehicleType": res?.vehicleTypeId,
                                "isWhiteListVehicle": false,
                                "lkmoption": 1,
                                // "tacNumber": res?.tacNumber,
                            }

                            history.push(history.location.pathname, {
                                ...history.location.state,
                                vehicle: vt,
                                step: STEPS.VEHICLE_INFORMATION.label,
                                subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                            })

                        }
                    }).catch(err => {
                        err && message.error(err)
                        setIsUnknownTransfer(true)
                    })

                } else {

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.PRINT_SLIP.label,
                    })

                }

            }
            // Check if transaction exist or not
            else if (params.transferId) {

                const _transferId = Number(params.transferId)

                getOutTransferByTransferId(_transferId, "I2I", getCompanyId()).then((res) => {


                    if (res.id === undefined) throw res.message

                    setTransactionType(res.transactionType);

                    if (params.mode === 'vehicleinfo') {

                        let vt = {
                            "carMake": res?.make,
                            "carModel": res?.model,
                            "chassisNo": res?.chassisNo,
                            "engineNo": res?.engineNo,
                            "manufactureYear": res?.manufactureYear,
                            "ownerIdCat": res?.ownerCatagory,
                            "vehicleRegistrationNo": res?.carRegistrationNo,
                            "vehicleType": res?.vehicleTypeId,
                            "isWhiteListVehicle": false,
                            "lkmoption": 1,
                            // "tacNumber": res?.tacNumber,
                        }

                        history.push(history.location.pathname, {
                            ...history.location.state,
                            vehicle: vt,
                            step: STEPS.VEHICLE_INFORMATION.label,
                            subStep: STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO
                        })

                        return

                    }
                    // Below pushToNextStep is set to false, for the sake of to change party label.
                    // The pushing will be done in the switch statement instead.
                    getPartyLabel(res.transactionType, false)
                    if (res.status === 10) {
                        switch (res.transactionType) {
                            case 1:
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC,
                                    transactionType: res.transactionType,
                                    transferId: res.id,
                                })
                                break;
                            case 2:
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC,
                                    transactionType: res.transactionType,
                                    transferId: res.id,
                                    // tacNumber: res.tacNumber
                                })
                                break;
                            case 3:
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.B_ROC_SEARCH_PAGE,
                                    transactionType: res.transactionType,
                                    transferId: res.id,
                                })
                                break;
                            case 4:
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.B_ROC_SEARCH_PAGE,
                                    transactionType: res.transactionType,
                                    transferId: res.id,
                                })
                                break;
                            default:
                            // code block
                        }
                    }
                    // else if (res.status === 9) {
                    //     history.push(history.location.pathname, {
                    //         ...history.location.state,
                    //         step: STEPS.AUTHORIZE_SELLER.label,
                    //         subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                    //         transactionType: res.transactionType,
                    //         transferId: res.id,
                    //     })
                    // }
                    else {

                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.JPJ_SUBMISSION.label,
                            subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                            transactionType: res.transactionType,
                            buyer: res.buyerInfo,
                            transferId: res.id,
                        })

                    }
                }).catch(err => {
                    err && message.error(err)
                    setIsUnknownTransfer(true)
                })
            }
            else {

                /**
         * Check if user is already authorized for this session.
         * If no, need re-authorize
         * Else, redirect to next step
         */
                const authorizedNric = getAuthorizedNric()

                if (authorizedNric === user?.nric) {
                    if (
                        params.mode !== 'payment' && params.mode !== 'printslip' && params.mode !== 'continue'
                    ) {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: history.location.state?.step || STEPS.TRANSACTION_TYPE.label,
                            subStep: history.location.state?.subStep || STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                    } else if (params.mode === 'continue') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_BUYER.label,
                            subStep: STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC
                        })
                    }
                    else if (params.mode === 'payment') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.PAYMENT.label,
                        })
                    } else if (params.mode === 'printslip') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.PRINT_SLIP.label,
                        })
                    }
                } else {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_DEALER.label,
                        subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                    })
                }
            }
        }
    }, [])

    /**
     * 23/2/2022 daniel.kwok
     * Receiving and transforming data from Sagem
     */
    useEffect(async () => {
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get('forKey') != null) {

            if (getSagemUrlForKey() !== urlParams.get('forKey')) {

                removeSagemUrlForKey()

                history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.TRANSACTION_TYPE.label,
                    subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                })

                return

            }

            let values;
            let decodedValues;
            const decodedForKey = decodeURIComponent(urlParams.get('forKey'))
            const cached = JSON.parse(getCachedStates())
            const isSkipAuth = skipAuth(false, null, null, urlParams, user?.nric)
            if (isSkipAuth) {
                values = isSkipAuth
            }
            else {
                if (urlParams.get('tk') != null && urlParams.get('dt') != null) {
                    decodedValues = await decodeForSagem(
                        decodeURIComponent(urlParams.get('tk')), 
                        decodeURIComponent(urlParams.get('dt')),
                        urlParams.get('forKey'),
                        history,
                        STEPS,
                        "I2I")
                    if (decodedValues === undefined) {
                        message.error("Please try again.")
                        removeSagemUrlForKey()
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                        return
                    }
                }
                else {
                    message.error("Please try again.")
                    removeSagemUrlForKey()
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.TRANSACTION_TYPE.label,
                        subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                    })
                    return
                }

                removeCachedStates()

                // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
                values = JSON.parse(decodedValues)

                const decryptResponse = await decryptUrlVerify(values,
                    urlParams.get('tk'),
                    decodedForKey,
                    getCompanyId(),
                    "SAGEM",
                    user.nric.replace(/[\s*]/g, ""));
                if(decryptResponse === false) {
                    if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                        history.push(history.location.pathname, {
                            step: STEPS.AUTHORIZE_DEALER.label,
                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                        })
                        return
                    }
                    removeSagemUrlForKey()
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.TRANSACTION_TYPE.label,
                        subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                    })
                    return
                }
            }

            if (values['ThumbSucess'] === 'true') {

                let errorMessages = [];

                if (!values['Name']) {
                    errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + values['Name'] + "'. Please contact the eTukar Admin for more information.");
                }

                // if (!values['City']) {
                //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + values['City'] + "'. Please contact the eTukar Admin for more information.");
                // }

                if (!values['Ic No.'] || !/^[+]?\d+([.]\d+)?[*]?$/.test(values['Ic No.'])) {
                    errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + values['Ic No.'] + "'. Please contact the eTukar Admin for more information.");
                }

                if (errorMessages.length > 0) {
                    for (let errorMessage of errorMessages) {
                        message.error(errorMessage);
                    }
                    if (decodedForKey == 'dealer') {
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.AUTHORIZE_DEALER.label,
                            subStep: STEPS.AUTHORIZE_DEALER.subSteps.INSERT_IC
                        })
                    } else if (decodedForKey == 'seller') {
                        history.push(history.location.pathname, {
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                    } else if (decodedForKey == 'buyer') {
                        history.push(history.location.pathname, {
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                        })
                    }

                    return;
                }

                // To put the cached location state into location state
                history.push(history.location.pathname, {
                    ...history.location.state,
                    ...cached,
                })

                if (cached?.transactionType) {
                    setTransactionType(cached?.transactionType)
                }

                let stateKey, state, formatted, dob, formattedState

                let mobileNo
                let _email

                switch (decodedForKey) {
                    case 'dealer':
                        if (user.nric.replace(/\D/g, '') !== values['Ic No.'].replace(/\D/g, '')) {
                            history.push(history.location.pathname, {
                                step: STEPS.AUTHORIZE_DEALER.label,
                                subStep: STEPS.AUTHORIZE_DEALER.subSteps.AUTHORIZE_FAILED
                            })
                            return
                        }

                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        formatted = {
                            address1: values['Address 1'].replace(/\0.*$/, ""),
                            address2: values['Address 2'].replace(/\0.*$/, ""),
                            address3: values['Address 3'].replace(/\0.*$/, ""),
                            district: values['City'].replace(/[^a-zA-Z\s]/g, ''),
                            state: state?.code,
                            postcode: values['PostCode'],
                            dob: dob,
                            correspondentEmail: '',
                            gender: values['Gender'] === 'Male' ? 'L' : 'P',
                            correspondentMobileNo: '',
                            "correspondentAddress": "",
                            name: values['Name'].replace(/\*/g, ''),
                            nric: values['Ic No.'],
                            phoneno: '',
                            username: '',
                            photo: values['Photo'],
                        }

                        setDealer(formatted)
                        setAuthorizedNric(values['Ic No.'])

                        history.push(history.location.pathname, {
                            ...history.location.state,
                            dealer: formatted,
                            ...cached,
                            step: STEPS.TRANSACTION_TYPE.label,
                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE,
                        })

                        break
                    case 'seller':
                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        isAccountExist(values['Ic No.'].replace(/[\s*]/g, ""))
                            .then(res => {

                                if (!res.status) {
                                    return {
                                        status: false
                                    }
                                } else {
                                    return getAccountDetails(values['Ic No.'].replace(/[\s*]/g, ""))
                                }

                            })
                            .then(res => {

                                if (res.status == false) {
                                    mobileNo = ''
                                    _email = ''
                                } else {

                                    const { email, mobileno } = res

                                    mobileNo = mobileno
                                    _email = email

                                }

                            })
                            .catch(err => {
                                err && message.error(err)
                            }).finally(() => {

                                formatted = {
                                    address1: values['Address 1'].replace(/\0.*$/, ""),
                                    address2: values['Address 2'].replace(/\0.*$/, ""),
                                    address3: values['Address 3'].replace(/\0.*$/, ""),
                                    correspondentAddress: '',
                                    correspondentEmail: '',
                                    correspondentMobileNo: '',
                                    dateOfBirth: dob,
                                    district: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    city: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    email: _email,
                                    mobileNo: mobileNo,
                                    gender: values['Gender'] === 'Male' ? 'L' : 'P',
                                    identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                    name: values['Name'].replace(/\*/g, ''),
                                    phoneNo: '',
                                    postcode: values['PostCode'],
                                    "repIc": "",
                                    "repIc2": "",
                                    "repName": "",
                                    state: state?.code,
                                    dob: dob,
                                    photo: values['Photo'],
                                    citizenship: values['Warganegara']
                                }

                                setSeller(formatted)
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    seller: formatted,
                                    ...cached,
                                    step: STEPS.AUTHORIZE_SELLER.label,
                                    subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
                                })
                            })

                        break
                    case 'buyer':
                        formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                        state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                        if (!state) state = getState(values['Ic No.'])

                        if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

                            dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

                        } else {

                            let ic = values['Ic No.']

                            let Year = ic.substring(0, 2)
                            let Month = ic.substring(2, 4)
                            let Day = ic.substring(4, 6)

                            let cutoff = (new Date()).getFullYear() - 2000

                            let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

                            if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
                            } else {
                                dob = ''
                            }
                        }

                        if (dob == 'Invalid date') dob = ''

                        isAccountExist(values['Ic No.'].replace(/[\s*]/g, ""))
                            .then(res => {

                                if (!res.status) {
                                    return {
                                        status: false
                                    }
                                } else {
                                    return getAccountDetails(values['Ic No.'].replace(/[\s*]/g, ""))
                                }

                            })
                            .then(res => {

                                if (res.status == false) {
                                    mobileNo = ''
                                    _email = ''
                                } else {

                                    const { email, mobileno } = res

                                    mobileNo = mobileno
                                    _email = email

                                }

                            })
                            .catch(err => {
                                err && message.error(err)
                            }).finally(() => {

                                formatted = {
                                    address1: values['Address 1'].replace(/\0.*$/, ""),
                                    address2: values['Address 2'].replace(/\0.*$/, ""),
                                    address3: values['Address 3'].replace(/\0.*$/, ""),
                                    dateOfBirth: dob,
                                    city: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    district: values['City'].replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                    email: _email,
                                    mobileNo: mobileNo,
                                    gender: values['Gender'] === 'Male' ? 'L' : 'P',
                                    identificationNo: values['Ic No.'].replace(/[\s*]/g, ""),
                                    name: values['Name'].replace(/\*/g, ''),
                                    phoneNo: '',
                                    postcode: values['PostCode'],
                                    state: state?.code,
                                    dob: dob,
                                    photo: values['Photo'],
                                    citizenship: values['Warganegara']
                                }

                                setBuyer(formatted)
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    buyer: formatted,
                                    ...cached,
                                    step: STEPS.AUTHORIZE_BUYER.label,
                                    subStep: STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY,
                                })
                            })


                        break
                    default:
                        break
                }

            } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {
                message.error('Thumbprint is not sucess')

                if (decodedForKey == 'dealer') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_DEALER.label,
                        subStep: STEPS.AUTHORIZE_DEALER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                } else if (decodedForKey == 'seller') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_SELLER.label,
                        subStep: STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                } else if (decodedForKey == 'buyer') {
                    history.push(history.location.pathname, {
                        step: STEPS.AUTHORIZE_BUYER.label,
                        subStep: STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED
                    })
                    return
                }
            }

        }

        getPartyLabel(history.location?.state?.transactionType, false)
    }, [])

    /**
     * Dynamically set the label for partyA and partyB
     * @param {number} transactionType - Transaction type.
     * @param {boolean} pushToNextStep - To push to next step or not. If redirected back from sagem, or mode is either payment or printslip, set this params to false.
     */
    const getPartyLabel = (transactionType, pushToNextStep) => {
        switch (transactionType) {
            case 1:
                setPartyA('Owner')
                setPartyB('Buyer')
                if (pushToNextStep) {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        // If party A is individual, redirect to Authorize Seller since we need his details
                        step: STEPS.AUTHORIZE_SELLER.label,
                        subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                        transactionType,
                    })
                }
                break;
            case 2:
                setPartyA('Company')
                setPartyB('Individual')
                if (pushToNextStep) {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        // If party A is company, no need to get it's details, hence redirect to vehicle info
                        step: STEPS.VEHICLE_INFORMATION.label,
                        subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO,
                        transactionType
                    })
                }
                break;
            case 3:
                setPartyA('Company')
                setPartyB('Company')
                if (pushToNextStep) {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.VEHICLE_INFORMATION.label,
                        subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO,
                        transactionType
                    })
                }
                break;
            case 4:
                setPartyA('Individual')
                setPartyB('Company')
                if (pushToNextStep) {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.AUTHORIZE_SELLER.label,
                        subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                        transactionType
                    })
                }
                break;
            default:
                break;
        }
    }

    // To navigate between components (a.k.a form steps), we use history.push by
    // pushing to the current path, along with the steps and desired substeps inside the history state.
    // Below is an example to navigate to Authorize Seller (Owner Authentication) step, 
    // and pointing to the Summary substep:
    /* 
    history.push(history.location.pathname, {
        ...locationState,
        step: STEPS.AUTHORIZE_SELLER.label,
        subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
    })
    */
    // The ...locationState (history.location.state) was used to carry data from one step to the next
    const COMPONENTS_WITH_SELLER = [
        {
            title: 'Owner Authentication',
            label: STEPS.AUTHORIZE_SELLER.label,
            component: <AuthorizeSeller
                seller={history.location.state?.seller || seller}
                transactionType={transactionType}
                onChange={_seller => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        seller: _seller
                    })
                    setSeller(_seller)
                }}
            />
        },
        {
            title: 'Vehicle Information',
            label: STEPS.VEHICLE_INFORMATION.label,
            url: '/transfer/i2i/vehicleinfo/',
            component: <VehicleInformation
                dealer={history.location.state && history.location.state.dealer || dealer}
                seller={history.location.state?.seller || seller}
                vehicle={history.location.state && history.location.state.vehicle || vehicle}
                transactionType={history.location?.state?.transactionType ?? transactionType}
                onChange={_vehicle => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        vehicle: _vehicle
                    })

                    setSeller(seller)
                    setVehicle(_vehicle)
                }}
            />
        },

        {
            title: 'Buyer Authentication',
            label: STEPS.AUTHORIZE_BUYER.label,
            component: <AuthorizeBuyer
                buyer={history.location.state?.buyer || buyer}
                transactionType={transactionType}
                onChange={_buyer => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        buyer: _buyer
                    })
                    setBuyer(_buyer)
                }}
            />
        },
        {
            title: 'JPJ Submission',
            label: STEPS.JPJ_SUBMISSION.label,
            component: <JPJSubmission
                // The data for this buyer props will be either from locationState or buyer variable.
                // If thumbscan is using SAGEM, every time we do history.push, index.js will reload, 
                // and all variable from useState will be reset to initial state. 
                // Hence we need to take previous step data from locationState.
                // But if using DERMALOG, no need to get buyer from locationState, because buyer data was 
                // already set into buyer variable.
                buyer={history.location.state?.buyer || buyer}
                vehicle={history.location.state?.vehicle || vehicle}
                seller={history.location.state?.seller || seller}
                onApproverNRICChange={approverNric => {
                    setApproverNric(approverNric)
                }}
                transactionType={history.location?.state?.transactionType ?? transactionType}
                approverNric={approverNric}
                isReport={history.location.state?.isReport ?? false}
            />
        },
        /**
         * This step is a little special.
         * Payment page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Payment',
            label: STEPS.PAYMENT.label,
            url: '/transfer/i2i/payment/',
            component: <Payment
                approverNric={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        {
            title: 'Print Slip',
            label: STEPS.PRINT_SLIP.label,
            url: '/transfer/i2i/printslip/',
            component: <PrintSlip isReport={history.location.state?.isReport ?? false} />
        }
    ]

    // If the company is seller (C2I or C2C), then there's no need to get seller's details.
    // Hence that's why there's no <AuthorizeSeller /> component below. We just need to send
    // vehicle's details and buyer's details to backend.
    const COMPONENTS_WITHOUT_SELLER = [
        {
            title: 'Vehicle Information',
            label: STEPS.VEHICLE_INFORMATION.label,
            component: <VehicleInformation
                dealer={history.location.state && history.location.state.dealer || dealer}
                vehicle={history.location.state && history.location.state.vehicle || vehicle}
                seller={history.location.state?.seller || seller}
                transactionType={history.location?.state?.transactionType ?? transactionType}
                onChange={_vehicle => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        vehicle: _vehicle
                    })

                    setSeller(seller)
                    setVehicle(_vehicle)
                }}
            />
        },
        {
            title: 'Buyer Authentication',
            label: STEPS.AUTHORIZE_BUYER.label,
            component: <AuthorizeBuyer
                buyer={history.location.state?.buyer || buyer}
                transactionType={transactionType}
                onChange={_buyer => {
                    history.push(history.location.pathname, {
                        ...history.location.state,
                        buyer: _buyer
                    })
                    setBuyer(_buyer)
                }}
            />
        },
        {
            title: 'JPJ Submission',
            label: STEPS.JPJ_SUBMISSION.label,
            component: <JPJSubmission
                buyer={history.location.state?.buyer || buyer}
                vehicle={history.location.state?.vehicle || vehicle}
                seller={history.location.state?.seller || seller}
                onApproverNRICChange={approverNric => {
                    setApproverNric(approverNric)
                }}
                transactionType={history.location?.state?.transactionType ?? transactionType}
                approverNric={approverNric}
                isReport={history.location.state?.isReport ?? false}
            />
        },
        /**
         * This step is a little special.
         * Payment page is a separate page, managed by location url instead of location state
         * This assumes that all transactions that's in the payment stage is already created
         */
        {
            title: 'Payment',
            label: STEPS.PAYMENT.label,
            url: '/transfer/i2i/payment/',
            component: <Payment
                approverNric={approverNric}
                vehicle={vehicle}
                seller={seller}
                dealer={dealer}
                isReport={history.location.state?.isReport ?? false}
                tabType={tabType}
            />
        },
        {
            title: 'Print Slip',
            label: STEPS.PRINT_SLIP.label,
            url: '/transfer/i2i/printslip/',
            component: <PrintSlip isReport={history.location.state?.isReport ?? false} />
        }
    ]

    let COMPONENTS = []
    switch (transactionType) {
        case 2:
            COMPONENTS = COMPONENTS_WITHOUT_SELLER
            break;
        case 3:
            COMPONENTS = COMPONENTS_WITHOUT_SELLER
            break;
        default:
            COMPONENTS = COMPONENTS_WITH_SELLER
            break
    }
    const component = COMPONENTS.find(step => {
        if (params.mode === 'create') {
            return step.label === (history.location.state?.step)
        } else if (params.mode === 'payment') {
            return step.label === STEPS.PAYMENT.label
        } else if (params.mode === 'continue') {
            return step.label === STEPS.AUTHORIZE_BUYER.label
        }
        else if (params.mode === 'printslip') {
            return step.label === STEPS.PRINT_SLIP.label
        }
        else if (params.mode === 'vehicleinfo') {
            return step.label === STEPS.VEHICLE_INFORMATION.label
        }
    })

    const index = component && COMPONENTS.findIndex(step => step.label === component.label) || 0

    return (
        <div style={{
            // minHeight: '100vh'
            margin: '40px 80px'
        }}>
            <div style={{ textAlign: 'center' }}>
                <Space direction='horizontal'>
                    <h2 className='transaction-title-i2i'>i2i</h2>
                    <h2 style={{ color: '#FFFFFF' }}>-</h2>
                    <h2 className='transaction-type-i2i'>{partyA}</h2>
                    <h2 className='transaction-additional-i2i'>to</h2>
                    <h2 className='transaction-type-i2i'>{partyB}</h2>
                </Space>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {
                    (
                        location?.state?.fromAdminPage != true
                        && history.location.state?.step !== STEPS.AUTHORIZE_DEALER.label
                        && history.location.state?.step !== STEPS.TRANSACTION_TYPE.label

                    ) ? (

                        <div style={{
                            marginBottom: 40, marginTop: 20
                        }} >
                            <Steps
                                labelPlacement='vertical'
                                style={{ width: '60vw' }}
                                size="default"
                                current={index}
                            >
                                {
                                    COMPONENTS.map(step => {
                                        return <Steps.Step
                                            key={step.title} title={<span style={{ color: 'white' }}>{step.title}</span>}
                                        />
                                    })
                                }
                            </Steps>
                        </div>
                    ) : null
                }

                {
                    history.location.state?.step === STEPS.AUTHORIZE_DEALER.label ? (
                        <AuthorizeDealer
                            onAuthorizedSuccess={() => {
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.TRANSACTION_TYPE.label,
                                    subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                })
                            }} />
                    ) : history.location.state?.step === STEPS.TRANSACTION_TYPE.label ? (
                        <TransactionType
                            onChange={transactionType => {
                                setTransactionType(transactionType)
                                getPartyLabel(transactionType, true)
                            }}
                        />
                    ) : (
                        component?.component
                    )
                }
            </div>

            {isUnknownTransfer && 
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <a href='/'>Go back to home</a>
            </Card>}
        </div >
    )
}
