import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Select,
  DatePicker,
  Checkbox,
  message,
  Image,
  Modal,
  Space,
  Tooltip,
  Spin,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
// import { SessionContext } from "../../App";
import { useForm } from "antd/lib/form/Form";
import "./index.less";
// import { useHistory, useParams } from 'react-router-dom'
import {
  OUT_TRANSACTION_TYPES_IDS,
  SEARCH_QUERY_TOOLTIP,
} from "../../constants";
import arrow_back from "../../assets/arrow_back.svg";
import propTypes from "prop-types";
import moment from "moment";
import { STEPS } from "./steps";
import {
  getOutVehicleTypes,
  getInVehicleTypes,
  outUpdateVehicleDetails,
  inUpdateVehicleDetails,
  getVehicleDetails,
  saveOutSeller,
  getDistrictList,
  isAccountExist,
  create1MIDAccount,
  saveOutBuyer,
  checkOutBulkTransactionDetails,
} from "../../services/api";
import { links } from "../../config";
import { disabledYear, breakdownIc } from "../../util";
import { SessionContext } from "../../App";
import InventoryListingPage from "../../components/Inventory/InventoryV1";
import { saveSellerBuyerFunc } from "./function";
import { PropsFromChildren } from ".";
// import { VehicleResponse, CompanyResponse, CompanyData } from "./types";
import InventoryListingV2Page from "../../components/Inventory/InventoryV2";
import { CompanyCardComponent } from "../../components/component/Layout/CardComponent";
import {
  VehicleUpdateTableComponent,
  VehicleSelectedTableComponent,
} from "../../components/component/Layout/TableComponent";
import { TermAndConditionFormItem } from "../../components/component/Layout/FormComponent";
import {
  UpdateButton,
  BackButton,
  JPJSubmissionButton,
  SaveAndContinueButton,
} from "../../components/component/UI/Button";
import { TermAndConditionCheckbox } from "../../components/component/UI/Checkbox";
import {
  saveBulkM2mBuyer,
  saveBulkM2mProceed,
  saveM2mBuyer,
  saveM2mProceed,
  outBulkTransferInquiry,
  outTransferInquiry,
} from "./function";
import { BUTTON_JPJ_SUBMISSION } from "../../constants";
import { useShortcut } from "../../components/Utils";

function VehicleInformation(props) {
  const [saveSellerLoading, setSaveSellerLoading] = useState(false);
  const params = useParams();

  const { company, branchs, branch, user } = useContext(SessionContext);
  const [isVehicleSearched, setIsVehicleSearched] = useState(false);
  const [vehicleDataList, setVehicleDataList] = useState([]);
  const history = useHistory();
  const [vehicleInfoForm] = useForm();
  const [sellerCompanyData, setSellerCompanyData] = useState([]);
  const [buyerData, setBuyerData] = useState([]);
  const [bulkTransferId, setBulkTransferId] = useState(0);
  const [transferId, setTransferId] = useState(0);
  const [bulkDetails, setBulkDetails] = useState({
    currentVehicle: "",
    totalVehicle: 0,
    remainingVehicle: 0,
    inquirySuccess: 0,
    inquiryFailed: 0,
  });
  const [isProceedProcess, setIsProceedProcess] = useState(false);
  const [isBulkCompleted, setIsBulkCompleted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isOneVehicle = vehicleDataList.length === 1;
  const [isDisable, setIsDisabled] = useState(false);

  const intervalId = useRef(null);

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };
  const isFromAdminPage = history?.location?.state?.fromAdminPage;

  const isVehicleEdit =
    history.location.state?.subStep ===
    STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO;

  const editVehicleDetail = [
    {
      vehicleNo: props.vehicle?.vehicleRegistrationNo,
      chassisNo: props.vehicle?.chassisNo,
      engineNo: props.vehicle?.engineNo,
    },
  ];

  useEffect(() => {
    const sellerCompany = {
      id: company.id,
      coName: company.coName,
      coRegNo: company.coRegNo,
      email: company.email,
      contactNo: company.contactNo,
      ucdCode: company.ucdCode,
    };
    setSellerCompanyData(sellerCompany);
  }, [props.company]);

  useEffect(() => {
    const buyer = {
      name: props.buyer?.name,
      identificationNo: props.buyer?.identificationNo,
      email: props.buyer?.email,
    };
    setBuyerData(buyer);
  }, [props.buyer]);

  /* checkBulkTransaction */
  useEffect(() => {
    if (bulkTransferId !== 0 && isModalVisible && !isBulkCompleted) {
      intervalId.current = setInterval(() => {
        checkOutBulkTransactionDetails(bulkTransferId).then((res) => {
          if (res.status) {
            setBulkDetails(res);
          } else {
            message.error(res.message);
            clearInterval(intervalId.current);
            // setBulkTransferId(0);
          }

          if (res.remainingVehicle === 0) {
            message.success("Process completed.");
            clearInterval(intervalId.current);
            setIsBulkCompleted(true);
          }
        });
      }, 3000);
    }

    return () => clearInterval(intervalId.current);
  }, [bulkTransferId, isModalVisible]);

  const onUpdateVehicle = () => {
    outUpdateVehicleDetails(
      vehicleInfoForm.getFieldValue("chassisNo")
        ? vehicleInfoForm.getFieldValue("chassisNo").trim().toUpperCase()
        : editVehicleDetail[0].chassisNo,
      company.coRegNo,
      vehicleInfoForm.getFieldValue("engineNo")
        ? vehicleInfoForm.getFieldValue("engineNo").trim().toUpperCase()
        : editVehicleDetail[0].engineNo,
      params.transferId ?? history?.location?.state?.transferId
    )
      .then((res) => {
        if (res.updateStatus != "00") {
          if (res.updateMSG) throw res.updateMSG;

          throw res.message;
        }

        message.success(res.updateMSG);

        backToPreviousScreen();
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => {});
  };

  const backToPreviousScreen = () => {
    if (isFromAdminPage) {
      history.push("/adminSearchVehicleInfo");
      return;
    }

    if (isVehicleEdit) {
      history.push(`/reporting/transferreport/Out`, { tabType: "1" });
      return;
    }

    if (isVehicleSearched) {
      onBulkModalClear();
      setIsVehicleSearched(false);
    } else {
      history.push(history.location.pathname, {
        ...history.location.state,
        step: STEPS.AUTHORIZE_BUYER.label,
        subStep: STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY,
        tabType: props.tabType,
      });
      /* if (props.isBulk) {
        // history.push("/reporting/transferreport/m2m/bulk");
        history.push("/reporting/transferreport/Out");
      } else if (props.isFromAdminPage) {
        history.push("/adminSearchVehicleInfo");
      } else if (props.isReport) {
        history.push("/reporting/transferreport/");
      } else {
        history.push("/reporting/transferreport/" + props.pendingType);
      } */
    }
  };

  const onJpjSubmitButtonClick = () => {
    if (bulkTransferId !== 0) {
      setIsModalVisible(true);
    }
  };

  const onResetData = () => {
    /* TODO */
  };

  const onBulkModalClear = () => {
    setBulkTransferId(0);
    setIsModalVisible(false);
    setIsBulkCompleted(false);
  };

  const submissionModal = () => {
    return (
      <>
        <Modal
          visible={isModalVisible}
          // closable={isBulkCompleted}
          footer={false}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          centered // This prop will center the modal
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <p style={{ fontSize: "2.5em" }}>
              <b>
                {isBulkCompleted ? (
                  <>{bulkDetails.totalVehicle} transactions processed</>
                ) : (
                  <>
                    {bulkDetails.totalVehicle != 0
                      ? bulkDetails.totalVehicle
                      : vehicleDataList.length}{" "}
                    transactions will submit for inquiry. This may take a
                    minute.
                  </>
                )}
              </b>
            </p>
            {isBulkCompleted ? (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#def2d6",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquirySuccess}</strong>
                      </span>
                      <br />
                      <span style={{ fontSize: "1.5em" }}>Successful</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#ffefef",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquiryFailed}</strong>
                      </span>
                      <br /> <span style={{ fontSize: "1.5em" }}>Failed</span>
                    </div>
                  </Col>
                </Row>
                <br />
                <p style={{ fontSize: "1.5em" }}>
                  Retry the {bulkDetails.inquiryFailed} failed transactions from
                  the <br />
                  transaction list.
                </p>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spin size="large" />
              </div>
            )}
            <br />
            <Button
              type="primary"
              disabled={!isBulkCompleted}
              onClick={() => {
                // props.next({ bulkTransferId: bulkTransferId });
                history.push(history.location.pathname, {
                  ...history.location.state,
                  step: STEPS.JPJ_SUBMISSION.label,
                  subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                  // transferId : transferId
                  bulkTransferId: bulkTransferId,
                });
              }}
            >
              {bulkDetails.inquirySuccess === 0 ? "OK" : "Payment"}
            </Button>
          </div>
          {/* )} */}
        </Modal>
      </>
    );
  };

  const getVehicleMapped = () => {
    return vehicleDataList.map((item, index) => {
      return {
        vehicleRegistrationNo: item.vehicleNo,
        engineNo: item.engineNo,
        chassisNo: item.chassisNo,
        branchId: item.branchId,
        manufactureYear: "",
        ownerIdCat: "",
        isWhiteListVehicle: false,
        vehicleType: "",
        lkmoption: 1,
        tnc: true,
      };
    });
  };

  const onFormFinal = async () => {
    let saveBuyerResponse = null;
    if (isBulkCompleted) {
      return;
    }
    if (isOneVehicle) {
      let currentTransferId = transferId;
      if (transferId === 0) {
        setIsDisabled(true);
        saveBuyerResponse = await saveSellerBuyerFunc(
          branch,
          user,
          company,
          props,
          getVehicleMapped()[0],
          setIsDisabled,
          false
        ).then((resultId) => {
          if (resultId === null) {
            setIsDisabled(false);
          } else {
            setTransferId(resultId);
            currentTransferId = resultId;
          }
          return resultId;
        });
      }

      if (saveBuyerResponse === null) return;

      await outTransferInquiry(user.nric, currentTransferId);
      setIsBulkCompleted(true);
      setIsDisabled(false);
      history.push(history.location.pathname, {
        ...history.location.state,
        step: STEPS.JPJ_SUBMISSION.label,
        subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
        transferId: currentTransferId,
      });
    } else {
      if (isProceedProcess) {
        return;
      }

      let currentBulkId = bulkTransferId;
      if (bulkTransferId === 0) {
        setIsDisabled(true);
        saveBuyerResponse = await saveSellerBuyerFunc(
          branch,
          user,
          company,
          props,
          getVehicleMapped(),
          setIsDisabled,
          true
        ).then((resultId) => {
          if (resultId === null) {
            setIsDisabled(false);
            throw "";
          } else {
            setBulkTransferId(resultId);
            currentBulkId = resultId;
          }
          return resultId;
        });
      }

      if (saveBuyerResponse === null) return;

      const response = await outBulkTransferInquiry(
        setBulkTransferId,
        currentBulkId,
        [],
        company.id,
        onResetData,
        true
      );

      if (response) {
        setIsProceedProcess(true);
        setIsModalVisible(true);
        setIsDisabled(false);
      }
    }
  };

  const onSaveAndContinue = async () => {
    if (isOneVehicle) {
      let currentTransferId = transferId;
      if (transferId === 0) {
        setIsDisabled(true);
        await saveSellerBuyerFunc(
          branch,
          user,
          company,
          props,
          getVehicleMapped()[0],
          setIsDisabled,
          false
        ).then((resultId) => {
          if (resultId === null) {
            setIsDisabled(false);
          } else {
            setTransferId(resultId);
            currentTransferId = resultId;
          }
          return resultId;
        });
        if (currentTransferId !== 0) redirectToPendingList();
      }
    } else {
      let currentBulkId = bulkTransferId;
      if (bulkTransferId === 0) {
        setIsDisabled(true);
        await saveSellerBuyerFunc(
          branch,
          user,
          company,
          props,
          getVehicleMapped(),
          setIsDisabled,
          true
        ).then((resultId) => {
          if (resultId === null) {
            setIsDisabled(false);
            throw "";
          } else {
            setBulkTransferId(resultId);
            currentBulkId = resultId;
          }
          return resultId;
        });
        if (currentBulkId !== 0) redirectToPendingList();
      }
    }
  };

  const redirectToPendingList = () => {
    message.loading("Redirect to pending list...");

    setTimeout(() => {
      history.push(`/reporting/transferreport/Out`, { tabType: "1" });
    }, 1 * 1000);
  };

  return (
    <>
      {history.location.state?.subStep === undefined ? (
        <></>
      ) : isVehicleEdit ? (
        /* Vehicle Edit */
        <>
          {VehicleUpdateTableComponent(editVehicleDetail, vehicleInfoForm)}
          <div className="section-bottom-button">
            {BackButton(backToPreviousScreen)}
            {UpdateButton(onUpdateVehicle)}
          </div>
        </>
      ) : !isVehicleSearched ? (
        /* Inventory Listing */
        <>
          <InventoryListingV2Page
            props={props}
            setIsVehicleSearched={setIsVehicleSearched}
            setVehicleSelectedData={setVehicleDataList}
          />
          <br />
          {BackButton(backToPreviousScreen)}
        </>
      ) : (
        /* JPJ Submission Confirmation */
        <>
          <Form onFinish={onFormFinal}>
            {CompanyCardComponent(sellerCompanyData, buyerData, false)}
            {VehicleSelectedTableComponent(
              vehicleDataList,
              branchs,
              true,
              false,
              branch.id === 0
            )}
            {/* Term and Condition */}
            {TermAndConditionFormItem(TermAndConditionCheckbox)}
            <br />

            <Row justify={"space-between"}>
              <Col style={{ display: "flex", alignItems: "center" }}>
                {BackButton(backToPreviousScreen)}&nbsp;
                {SaveAndContinueButton(
                  onSaveAndContinue,
                  transferId !== 0 || bulkTransferId !== 0 || isDisable,
                  isDisable
                )}
              </Col>
              <Col>
                <Form.Item>
                  {JPJSubmissionButton(
                    onJpjSubmitButtonClick,
                    isDisable,
                    BUTTON_JPJ_SUBMISSION
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {submissionModal()}
    </>
  );
}

VehicleInformation.propTypes = {
  vehicle: propTypes.object,
  dealer: propTypes.object,
  onChange: propTypes.func,
  transactionType: propTypes.number,
};

export default VehicleInformation;
