// @ts-nocheck
import {
    CheckCircleOutlined, CloseCircleOutlined, PrinterOutlined
} from '@ant-design/icons'
import {
    Button, Card,
    Col, message,
    Modal, Row, Skeleton,
    Space,
    Image,
} from 'antd'
import propTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { SessionContext } from '../../App'
import {
    STATES,
    TRANSACTION_RESPONSE_CODES,
    TRANSFER_PAYMENT_STATUSES,
} from '../../constants'
import {
    getInTransferAmountByTransferId,
    getInTransferByTransferId, getPrepaidBalance, getPrepaidReceiptIN, inUpdateVehicleDetailsToJPJ, processPaymentIn,
    getPrepaidReceiptV2IN,
} from '../../services/api'
import {
    breakdownIc,
    getDistrictName,
    parseAddress, parseCorrespondenceAddress, parseTime
} from '../../util'
import './index.less'
import { Params, ReceiptType } from './types'

import {
    GetInTransferAmountRequest,
    GetInTransferDetailRequest,
    GetInTransferDetailResponse,
    GetInTransferAmountResponse,
    InProcessPaymentRequest,
    InUpdateVehicleDetailsToJPJRequest,
    InGetPrepareReceiptRequest,
} from '../../services/api/types'
import failedIcon from "../../assets/icon-failed.svg";
import { getCompanyId } from '../../services/local'

function PrintSlip(props) {

    const { isReport, isStatusFail } = props

    const history = useHistory()
    const { state: locationState } = useLocation<Record<string, any>>()
    const params = useParams<Params>()
    const { company, branch } = useContext(SessionContext)

    const [isLoading, setIsLoading] = useState(false)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)
    const [isPrintSlipLoading, setIsPrintSlipLoading] = useState(false)
    const [isPrintSlip2Loading, setIsPrintSlip2Loading] = useState(false)
    const [isPrintSlip3Loading, setIsPrintSlip3Loading] = useState(false)
    const [transferDetails, setTransferDetails] = useState<GetInTransferDetailResponse | undefined>()

    const [transferAmount, setTransferAmount] = useState<GetInTransferAmountResponse | undefined>()
    const [isM2M, setIsM2M] = useState(false)
    const [balance, setBalance] = useState(0)
    const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } = breakdownIc(transferDetails?.sellerInfo?.identificationNo)
    const [transferId, setTransferId] = useState('')
    const [districtName, setDistrictName] = useState('')
    const [correspondenceDistrictName, setCorrespondenceDistrictName] = useState('')
    const [isNewPrice, setIsNewPrice] = useState(false)

    useEffect(() => {

        if (params.transferId) setTransferId(params.transferId)

    }, [params.transferId])

    useEffect(() => {

        if (transferId) getAndSetTransfer()

    }, [transferId])

    useEffect(() => {

        if (locationState && locationState?.isM2M === true) setIsM2M(true)

        if (locationState && !transferId && locationState?.fromAdminPage === true) setTransferId(locationState?.transferId)

    }, [transferId, locationState])

    const getAndSetTransfer = () => {

        setIsLoading(true)

        getPrepaidBalance(company.coRegNo)
            .then(res => {
                setBalance(res.balanceAmount)
            })
            .catch(err => err && message.error(err.toString()))

        const getInTransferAmountRequest: GetInTransferAmountRequest = {
            transferId
        }

        getInTransferAmountByTransferId(getInTransferAmountRequest)
            .then(res => {
                if (res.id === undefined && !isStatusFail) throw res.message

                setTransferAmount(res)
            })
            .catch(err => err && message.error(err))

        const getInTransferDetailRequest: GetInTransferDetailRequest = {
            transferId, moduleType : "IN"
        }

        getInTransferByTransferId(getInTransferDetailRequest)
            .then(res => {

                if (res.id === undefined) throw res.message

                setTransferDetails(res)
                getDistrictName(
                    res?.sellerInfo?.state,
                    res?.sellerInfo?.district
                ).then((res) => {
                    setDistrictName(res)
                })

                getDistrictName(
                    res?.sellerInfo?.correspondentState,
                    res?.sellerInfo?.correspondentDistrict
                ).then((res) => {
                    setCorrespondenceDistrictName(res)
                })

                if (res?.vehicleTypeNewId !== 0) {
                    setIsNewPrice(true)
                }
            })
            .catch(err => err && message.error(err))
            .finally(() => setIsLoading(false))
    }

    function onPay() {
        Modal.confirm({
            title: 'Are you sure to proceed to payment?',
            onOk: () => {
                message.loading('Proceeding payment')
                setIsPaymentLoading(true)
                const inProcessPaymentRequest: InProcessPaymentRequest = {
                    dealerCompanyROC: company.coRegNo,
                    transferId: (transferDetails?.id || "").toString()
                }
                processPaymentIn(inProcessPaymentRequest)
                    .then(res => {
                        if (res.status !== "true") throw res.message
                        message.success(`Payment success`)

                        const inUpdateVehicleDetailsToJPJRequest: InUpdateVehicleDetailsToJPJRequest = {
                            approverNRIC: props.approverNRIC,
                            transferId: transferDetails?.id || 0,
                            branchId: branch?.id || 0
                        }
                        return inUpdateVehicleDetailsToJPJ(inUpdateVehicleDetailsToJPJRequest)
                    })
                    .then(res => {
                        if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG

                        message.success(`Successfully updated to JPJ`)

                        getAndSetTransfer()
                    })
                    .catch(err => err && message.error(err))
                    .finally(() => setIsPaymentLoading(false))
            }
        })
    }

    function onPrintSlip(type, setPrintSlipLoading) {
        if (type) {
            setPrintSlipLoading(true)
            const inV2GetPrepareReceiptRequest: InV2GetPrepareReceiptRequest = {
                companyId: company.id,
                transferId: (transferDetails?.id || "").toString(),
                vehNo: transferDetails?.carRegistrationNo || "",
                type: type
            }
            getPrepaidReceiptV2IN(inV2GetPrepareReceiptRequest)
                .finally(() => setPrintSlipLoading(false))
        }
        else {
            setIsPrintSlipLoading(true)
            const inGetPrepareReceiptRequest: InGetPrepareReceiptRequest = {
                companyId: company.id,
                transferId: (transferDetails?.id || "").toString(),
                vehNo: transferDetails?.carRegistrationNo || "",
            }
            getPrepaidReceiptIN(inGetPrepareReceiptRequest)
                .finally(() => setIsPrintSlipLoading(false))
        }
    }

    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && !transferDetails
    ) {
        return (
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <Button
                    type='link'
                    onClick={() => history.push('/')}
                >
                    Go back to home
                </Button>
            </Card>
        )
    }

    return (

        <div
            style={{
                width: '100%',
            }}
        >
            {
                transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CloseCircleOutlined
                                    style={{
                                        color: 'red',
                                        fontSize: 70,
                                        marginRight: 30,
                                    }}
                                />
                                <div style={{ flex: 4 }}>
                                    <h2  >This transfer had yet to be paid.</h2>
                                    <Button
                                        shape={'round'}
                                        type='primary'
                                        style={{
                                            flex: 1,
                                        }}
                                        onClick={() => {
                                            window.location.pathname = `/transfer/in/payment/${params.transferId}`
                                        }}
                                    >
                                        Click here to pay
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CheckCircleOutlined
                                    style={{
                                        color: '#40e327a8',
                                        fontSize: 70,
                                        marginRight: 50,
                                    }}
                                />
                                <div style={{ flex: 4 }}>
                                    <h2 style={{ color: '#40e327a8' }} >Payment Successful</h2>
                                    {
                                        locationState?.fromAdminPage != true &&
                                        < p > Your remaining balance is {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'MYR',
                                        }).format(balance || 0) || `Unavailable`}</p>
                                    }
                                </div>
                                { !isNewPrice ? <Button
                                    className='back-button'
                                    icon={<PrinterOutlined />}
                                    shape={'round'}
                                    style={{
                                        flex: 1
                                    }}
                                    loading={isPrintSlipLoading}
                                    onClick={() => onPrintSlip()}
                                >
                                    Print Slip
                                </Button> : 
                                <Col>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrintSlipLoading}
                                        onClick={() => onPrintSlip(ReceiptType.vehicleDealerCopy, setIsPrintSlipLoading)}
                                    >
                                        Vehicle Dealer Copy
                                    </Button>
                                    </Row>
                                    <br/>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrintSlip2Loading}
                                        onClick={() => onPrintSlip(ReceiptType.confirmationSlip, setIsPrintSlip2Loading)}
                                    >
                                        Confirmation Slip
                                    </Button>
                                    </Row>
                                    <br/>
                                    <Row>
                                    <Button
                                        className='back-button'
                                        icon={<PrinterOutlined />}
                                        shape={'round'}
                                        style={{
                                            flex: 1
                                        }}
                                        loading={isPrintSlip3Loading}
                                        onClick={() => onPrintSlip(ReceiptType.dealerCopy, setIsPrintSlip3Loading)}
                                    >
                                        Dealer Copy
                                    </Button>
                                    </Row>
                                </Col>
                                }
                            </div>
                        </Card>
                    </div >
                ) : transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',

                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CloseCircleOutlined
                                    style={{
                                        color: 'red',
                                        fontSize: 70,
                                        marginRight: 50,
                                    }}
                                />
                                <div style={{ flex: 4 }}>
                                    <h2 style={{ color: 'red', display: 'inline' }} >Payment Failed. </h2>
                                    <h2 onClick={onPay} style={{ color: 'red', display: 'inline', textDecoration: 'underline', cursor: 'pointer' }} >Try again?</h2>
                                    <p>{transferDetails.message || `Payment failed`}</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : null
            }
            {
                locationState?.fromAdminPage === true && locationState?.jpjErrorMsg &&

                <div>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: locationState?.jpjErrorMsg }}></p>
                            </Space>
                        </div>
                    </Card >
                </div>

            }
            {!isStatusFail && 
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                    }}
                >
                    <h2>Payment Details</h2>

                    {
                        isLoading ? (
                            <Skeleton active />
                        ) : (

                            <Row gutter={16}>
                                <Col className="gutter-row" span={6}>
                                    <p style={styles.label}>JPJ Ownership Transfer Fee </p>
                                    <p style={styles.value}>RM {transferAmount?.jpjAmount.toFixed(2)}</p>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <p style={styles.label}>MYEG Service Charge</p>
                                    <p style={styles.value}>RM {transferAmount?.eserviceAmount}</p>
                                </Col>
                                <Col className="gutter-row" span={6}>
                                    <p style={styles.label}>SST 8%</p>
                                    <p style={styles.value}>RM {transferAmount?.sstAmount.toFixed(2)}</p>
                                </Col>
                                {transferAmount?.processingAmount && <Col className="gutter-row" span={6}>
                                    <p style={styles.label}>Processing Fee</p>
                                    <p style={styles.value}>RM {transferAmount?.processingAmount.toFixed(2)}</p>
                                </Col>}
                                <Col className="gutter-row" span={6}>
                                    <p style={styles.label}>Total Payment</p>
                                    <p style={styles.value}>RM {transferAmount?.totalAmount.toFixed(2)}</p>
                                </Col>
                            </Row>
                        )
                    }
                </Card>
            </div>
            }
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                    }}
                >
                    <h2>Vehicle Information</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{transferDetails?.carRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p>{transferDetails?.make}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p>{transferDetails?.model}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{transferDetails?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{transferDetails?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                    }}
                >
                    <h2>JPJ Enquiry Status</h2>
                    {
                        isLoading ? (
                            <Skeleton active />
                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Blacklisted</span>
                                            <p>No</p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Ownership Claim</span>
                                            <p>No</p>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div className='detail-wrapper'>
                                            <span>Condition Code</span>
                                            <p>No</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Valid as at</p>
                                        <p style={styles.value}> {parseTime(transferDetails?.updatedDate)}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 10,
                }}
            >

                <>
                    <Card
                        style={{
                            width: '50%',
                            textAlign: 'left',
                            marginTop: 10,
                        }}
                    >

                        <h2>{!isM2M ? `Owner's` : `Dealer's`} Details</h2>
                        {
                            isLoading ? (
                                <Skeleton active />

                            ) : (
                                <>
                                    <p style={styles.label}>{!isM2M ? `Name` : `Company Name`}</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.name}</p>
                                    <p style={styles.label}>{!isM2M ? `NRIC` : `Company ROC number`}</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.identificationNo}</p>
                                    {!isM2M ? (
                                        <>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{sellerFormattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{sellerIsMale ? 'Male' : 'Female'}</p>
                                        </>
                                    ) : null}
                                    <p style={styles.label}>{isM2M && `Company `}Address</p>
                                    <p style={styles.value}>{
                                        parseAddress({
                                            ...transferDetails?.sellerInfo,
                                            state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo?.state)?.label,
                                            districtName: districtName,
                                        })}
                                    </p>
                                    {/* <p style={styles.label}>Current Address</p>
                                    <p style={styles.value}>{
                                        parseCorrespondenceAddress({
                                            ...transferDetails?.sellerInfo,
                                            correspondentState: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo?.correspondentState)?.label,
                                            districtName: correspondenceDistrictName,
                                        })}
                                    </p> */}
                                    <p style={styles.label}>{isM2M && `Company `}Email</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.email}</p>
                                    <p style={styles.label}>{!isM2M ? `Mobile ` : `Company Phone `} Number</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.phoneNo}</p>
                                </>
                            )
                        }
                    </Card>
                    <Card
                        style={{
                            width: '50%',
                            textAlign: 'left',
                            marginTop: 10,
                        }}
                    >
                        <h2>{!isM2M ? `Dealer's` : `Buyer's`} Details</h2>
                        {
                            isLoading ? (
                                <Skeleton active />

                            ) : (
                                <>
                                    <p style={styles.label}>Company Name</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.name}</p>
                                    <p style={styles.label}>Company ROC number</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.identificationNo}</p>
                                    <p style={styles.label}>Company Address</p>
                                    <p style={styles.value}>{parseAddress({ ...transferDetails?.buyerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.buyerInfo?.state)?.label, districtName: transferDetails?.buyerInfo?.districtName })}</p>
                                    <p style={styles.label}>Company e-mail</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.correspondentEmail}</p>
                                    <p style={styles.label}>Company Phone Number</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.correspondentMobileNo}</p>
                                    <p style={styles.label}>Dealer Representative</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.repName}</p>
                                    <p style={styles.label}>Representative's NRIC</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.repIc}</p>
                                    <p style={styles.label}>Representative's Mobile Number</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.phoneNo}</p>
                                </>
                            )
                        }
                    </Card>
                </>
            </div>
            {
                locationState?.fromAdminPage == true
                &&
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 20
                    }}
                >
                    <Button
                        className='back-button buttons'
                        shape='round'
                        onClick={() => {
                            history.push('/adminSearchVehicleInfo')
                        }}
                    >
                        Back
                    </Button>
                    {
                        transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS && !isNewPrice &&

                        <Button
                            icon={<PrinterOutlined />}
                            shape={'round'}
                            // onClick={onPrintSlip}
                            onClick={() => onPrintSlip()}
                            loading={isPrintSlipLoading}
                            className="back-button buttons"
                        >
                            Print Slip
                        </Button>
                    }

                </div>
            }
            {
                locationState?.fromAdminPage != true && transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/In')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>
                        {isReport == true && !isNewPrice && <Button
                            icon={<PrinterOutlined />}
                            shape={'round'}
                            // onClick={onPrintSlip}
                            onClick={() => onPrintSlip()}
                            loading={isPrintSlipLoading}
                            className="back-button buttons"
                        >
                            Print Slip
                        </Button>}
                    </div>
                ) : transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-right',
                            marginTop: 20
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        history.push('/reporting/transferreport/In')
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>
                        <Button
                            type='primary'
                            shape={'round'}
                            onClick={onPay}
                        >
                            Try again
                        </Button>
                    </div>
                ) : null
            }
        </div >
    )
}

PrintSlip.propTypes = {
    seller: propTypes.object,
    dealer: propTypes.object,
    vehicle: propTypes.object,
    approverNric: propTypes.string,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0, fontFamily: 'Open Sans', fontSize: '16px'
    },
    value: {
        fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '16px'
    },
}

export default PrintSlip