import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Button,
    Modal,
    Image,
    Space,
    message,
    Skeleton
} from 'antd'
import './index.less'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    LoadingOutlined
} from '@ant-design/icons'
import {
    breakdownIc,
    getDistrictName,
    parseAddress,
    parseCorrespondenceAddress,
} from '../../util'
import propTypes from 'prop-types'
import {
    STEPS
} from './steps'
import { SessionContext } from '../../App'

import {
    saveOutBuyer,
    saveOutSeller,
    saveOutSellerBuyerInquiry,
    getOutTransferByTransferId,
    isAccountExist,
    create1MIDAccount,
    getDistrictList
} from '../../services/api'
import {
    STATES,
    TRANSACTION_OUT_RESPONSE_CODES,
    TRANSACTION_RESPONSE_CODES,
    OUT_TRANSACTION_TYPES_IDS,
    OUT_TRANSACTION_STATUSES
} from '../../constants'
import failedIcon from "../../assets/icon-failed.svg";
import successIcon from "../../assets/icon-success.svg";
import moment from 'moment'
import {
    getCompanyId,
    getUserRole,
    getPermissions,
} from '../../services/local';
import {
    processErrorMessage,
    validateRolePermission,
} from '../../components/Utils'

function JPJSubmission(props) {
    const { isReport } = props

    const history = useHistory()
    const params = useParams()
    const { user, company, branch } = useContext(SessionContext)
    const { state: locationState } = useLocation()

    const [transferId, setTransferId] = useState(null)
    const [approverNRIC, setApproverNRIC] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [currentSubStep, setCurrentSubStep] = useState(STEPS.JPJ_SUBMISSION.subSteps.REVIEW)
    const [statusMSG, setStatusMSG] = useState('')
    const [districtName, setDistrictName] = useState('')
    const [transactionStatus, setTransactionStatus] = useState(null)

    const { formattedDob: sellerFormattedDob, isMale: isSellerMale } = breakdownIc(props.seller.identificationNo)
    const { formattedDob: buyerFormattedDob, isMale: isBuyerMale } = breakdownIc(props.buyer.identificationNo)

    const [sellerDistricts, setSellerDistricts] = useState([])
    const [sellerCorrespondenceDistricts, setSellerCorrespondenceDistricts] = useState([])
    const [buyerDistricts, setBuyerDistricts] = useState([])
    const [buyerCorrespondenceDistricts, setBuyerCorrespondenceDistricts] = useState([])
    const [loggedInUserRole, setLoggedInUserRole] = useState('')
	const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        getUserRole()
            .then(res => {
                setLoggedInUserRole(res)
            })
        getPermissions().then(async (permissions) => {
          setPermissions(permissions);
        });
    }, [])

    useEffect(() => {

        if (props?.seller?.state) {
            setIsLoading(true)
            getDistrictList(props?.seller?.state)
                .then(res => {
                    setSellerDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.seller?.correspondentState) {
            setIsLoading(true)
            getDistrictList(props?.seller?.correspondentState)
                .then(res => {
                    setSellerCorrespondenceDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.buyer?.state) {
            setIsLoading(true)
            getDistrictList(props?.buyer?.state)
                .then(res => {
                    setBuyerDistricts(res)
                }).finally(() => setIsLoading(false))
        }

        if (props?.buyer?.correspondentState) {
            setIsLoading(true)
            getDistrictList(props?.buyer?.correspondentState)
                .then(res => {
                    setBuyerCorrespondenceDistricts(res)
                }).finally(() => setIsLoading(false))
        }

    }, [props])

    const COMPONENT_STEPS = {
        [STEPS.JPJ_SUBMISSION.subSteps.REVIEW]: {
            component: (
                <div style={{ width: '100%', }}>
                    <div style={{ width: '100%' }}>
                        <Col span={24}>
                            <Card
                                style={{
                                    textAlign: 'left',
                                    marginBottom: 10,
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: 4
                                }}
                            >
                                <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{props.vehicle.vehicleRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{props.vehicle.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{props.vehicle.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{props.vehicle.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{props.vehicle.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{props.vehicle.chassisNo}</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            gap: 10,
                        }}
                    >
                        {

                            <Card
                                style={{
                                    flex: 3,
                                    textAlign: 'left',
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: 4
                                }}
                            >
                                {
                                    /**
                                     * 11/3/2022 daniel.kwok
                                     * Show individual information if it's i2i or i2c
                                     * Show company information if it's d2i or d2c
                                     */

                                    isLoading ? (
                                        <Skeleton active />
                                    ) : (
                                        props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C
                                        ||
                                        props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2I
                                    ) ? (
                                        <>

                                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Seller MyKad Details</h2>
                                            <p style={styles.label}>Name</p>
                                            <p style={styles.value}>{props.seller.name}</p>
                                            <p style={styles.label}>NRIC</p>
                                            <p style={styles.value}>{props.seller.identificationNo}</p>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{sellerFormattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                            <p style={styles.label}>Address</p>
                                            <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label })}</p>
                                        </>
                                    ) : (
                                        <>
                                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                                            <p style={styles.label}>Company name</p>
                                            <p style={styles.value}>{company.coName}</p>
                                            <p style={styles.label}>Company ROC number</p>
                                            <p style={styles.value}>{company.coRegNo}</p>
                                            <p style={styles.label}>Company Address</p>
                                            <p style={styles.value}>{parseAddress({ ...company?.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                                            <p style={styles.label}>Company e-mail</p>
                                            <p style={styles.value}>{company.email}</p>
                                            <p style={styles.label}>Company Phone Number</p>
                                            <p style={styles.value}>{company.contactNo}</p>
                                            <p style={styles.label}>Dealer Representative</p>
                                            <p style={styles.value}>{user.name}</p>
                                            <p style={styles.label}>Dealer Representative's NRIC</p>
                                            <p style={styles.value}>{user.nric}</p>
                                            <p style={styles.label}>Dealer Representative's Mobile Number</p>
                                            <p style={styles.value}>{user.mobileno}</p>
                                        </>
                                    )

                                } </Card>

                        }

                        <Card
                            style={{
                                flex: 3,
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: 4
                            }}
                        >
                            <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Buyer's Details</h2>

                            {
                                isLoading ? (
                                    <Skeleton active />
                                ) : (
                                    <>
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.value}>{props.buyer.name}</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.value}>{props.buyer.identificationNo}</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.value}>{buyerFormattedDob}</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                        <p style={styles.label}>Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.districtName })}</p>
                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>
                                            {props.buyer.correspondentAddress1
                                                ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: props?.buyer?.correspondentDistrict })
                                                : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.district })
                                            }
                                        </p> */}
                                        <p style={styles.label}>Email</p>
                                        <p style={styles.value}>{props.buyer.correspondentEmail}</p>
                                        <p style={styles.label}>Mobile Number</p>
                                        <p style={styles.value}>{props.buyer.correspondentMobileNo}</p>
                                    </>
                                )
                            }

                        </Card>
                    </div>
                    <div
                        style={{ marginTop: 20 }}
                    >

                        <Row
                            style={{
                                width: '100%', justifyContent: 'space-between'
                            }}
                        >
                            <Col>
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        if (locationState?.oldIsReport) {
                                            if (locationState?.fromPendingPage === true) {
                                                history.push('/reporting/transferreport/Out')
                                            } else {
                                                history.push('/reporting/transferreport')
                                            }
                                        } else {
                                            if (history.location.state.transferId) {
                                                history.push('/')
                                            } else if (params.transferId == null) {
                                                history.push(history.location.pathname, {
                                                    ...locationState,
                                                    step: STEPS.VEHICLE_INFORMATION.label,
                                                    subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO,
                                                    tabType: props.tabType
                                                })
                                            } else {
                                                history.push('/')
                                            }
                                        }
                                    }}
                                >
                                    {history.location.state.transferId ? 'Back' : 'Back'}
                                </Button>
                            </Col>
                            <Col>
                                {/* <Button
                                    icon={<SaveOutlined />}
                                    style={{
                                        width: 100,
                                        marginRight: 10
                                    }}
                                    shape='round'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Confirm save as draft ?',
                                            onOk: () => onSave()
                                        })
                                    }}
                                >
                                    Save
                                </Button> */}
                                {isReport == false && 
                                    <Button
                                        className='rectangle-button'
                                        onClick={() => {
                                            // setIsModalVisible(true)
                                            onJPJSubmit()

                                        }}
                                        type='primary'
                                    >
                                        JPJ Submission
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING]: {
            component: (
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                    }}
                >
                    <div>
                        <h1
                            style={{
                                fontSize: 25,
                                fontWeight: 'bolder'
                            }}
                        >
                            JPJ Verification
                        </h1>
                        <p>Checking in process. Retrieving data...</p>
                        <LoadingOutlined />
                    </div>
                </Card>
            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: statusMSG }}></p>
                            </Space>
                        </div>
                    </Card>

                    <div style={{ width: '100%', }}>
                        <div style={{ width: '100%' }}>
                            <Col span={24}>
                                <Card
                                    style={{
                                        textAlign: 'left',
                                        marginBottom: 10,
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: 4
                                    }}
                                >
                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Vehicle Number</p>
                                            <p style={styles.value}>{props.vehicle.vehicleRegistrationNo}</p>
                                        </Col>
                                        {/* <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Make</p>
                                            <p style={styles.value}>{props.vehicle.carMake}</p>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Model</p>
                                            <p style={styles.value}>{props.vehicle.carModel}</p>
                                        </Col> */}
                                    {/* </Row>
                                    <Row gutter={16}> */}
                                        {/* <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Manufacture Year</p>
                                            <p style={styles.value}>{props.vehicle.manufactureYear}</p>
                                        </Col> */}
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Engine Number</p>
                                            <p style={styles.value}>{props.vehicle.engineNo}</p>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Chassis Number</p>
                                            <p style={styles.value}>{props.vehicle.chassisNo}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: 10,
                            }}
                        >



                            {

                                <Card
                                    style={{
                                        flex: 3,
                                        textAlign: 'left',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: 4
                                    }}
                                >
                                    {

                                        /**
                                         * 11/3/2022 daniel.kwok
                                         * Show individual information if it's i2i or i2c
                                         * Show company information if it's d2i or d2c
                                         */

                                        isLoading ? (
                                            <Skeleton active />
                                        ) :

                                            (
                                                props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C
                                                ||
                                                props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2I
                                            ) ? (
                                                <>

                                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Seller MyKad Details</h2>
                                                    <p style={styles.label}>Name</p>
                                                    <p style={styles.value}>{props.seller.name}</p>
                                                    <p style={styles.label}>NRIC</p>
                                                    <p style={styles.value}>{props.seller.identificationNo}</p>
                                                    <p style={styles.label}>Date of Birth</p>
                                                    <p style={styles.value}>{sellerFormattedDob}</p>
                                                    <p style={styles.label}>Gender</p>
                                                    <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                                    <p style={styles.label}>Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label })}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                                                    <p style={styles.label}>Company name</p>
                                                    <p style={styles.value}>{company.coName}</p>
                                                    <p style={styles.label}>Company ROC number</p>
                                                    <p style={styles.value}>{company.coRegNo}</p>
                                                    <p style={styles.label}>Company Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...company?.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                                                    <p style={styles.label}>Company e-mail</p>
                                                    <p style={styles.value}>{company.email}</p>
                                                    <p style={styles.label}>Company Phone Number</p>
                                                    <p style={styles.value}>{company.contactNo}</p>
                                                    <p style={styles.label}>Dealer Representative</p>
                                                    <p style={styles.value}>{user.name}</p>
                                                    <p style={styles.label}>Dealer Representative's NRIC</p>
                                                    <p style={styles.value}>{user.nric}</p>
                                                    <p style={styles.label}>Dealer Representative's Mobile Number</p>
                                                    <p style={styles.value}>{user.mobileno}</p>
                                                </>
                                            )
                                    } </Card>

                            }

                            <Card
                                style={{
                                    flex: 3,
                                    textAlign: 'left',
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: 4
                                }}
                            >
                                <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Buyer's Details</h2>

                                {
                                    isLoading ? (
                                        <Skeleton active />
                                    ) : (
                                        <>
                                            <p style={styles.label}>Name</p>
                                            <p style={styles.value}>{props.buyer.name}</p>
                                            <p style={styles.label}>NRIC</p>
                                            <p style={styles.value}>{props.buyer.identificationNo}</p>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{buyerFormattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                            <p style={styles.label}>Address</p>
                                            <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.district })}</p>
                                            {/* <p style={styles.label}>Current Address</p>
                                            <p style={styles.value}>
                                                {props.buyer.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: props?.buyer?.correspondentDistrict })
                                                    : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.district })
                                                }
                                            </p> */}
                                            <p style={styles.label}>Email</p>
                                            <p style={styles.value}>{props.buyer.correspondentEmail}</p>
                                            <p style={styles.label}>Mobile Number</p>
                                            <p style={styles.value}>{props.buyer.correspondentMobileNo}</p>
                                        </>
                                    )
                                }

                            </Card>
                        </div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px',
                                marginBottom: 16,
                                marginTop: 16
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image src={failedIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                <Space direction='vertical' size={0}>
                                    <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ verification failed</h2>
                                    <p style={{ font: 'normal normal normal 16px Open Sans', margin: 0 }} dangerouslySetInnerHTML={{ __html: statusMSG }}></p>
                                </Space>
                            </div>
                        </Card>
                        <div
                            style={{ marginTop: 20 }}
                        >

                            <Row
                                style={{
                                    width: '100%', justifyContent: 'space-between'
                                }}
                            >
                                <Col>
                                    <Button
                                        className='back-button buttons'
                                        shape='round'
                                        onClick={() => {
                                            if (locationState?.oldIsReport) {
                                                if (locationState?.fromPendingPage === true) {
                                                    history.push('/reporting/transferreport/Out')
                                                } else {
                                                    history.push('/reporting/transferreport')
                                                }
                                            } else {
                                                history.push('/')
                                            }
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col>
                                    {/* <Button
                                    icon={<SaveOutlined />}
                                    style={{
                                        width: 100,
                                        marginRight: 10
                                    }}
                                    shape='round'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Confirm save as draft ?',
                                            onOk: () => onSave()
                                        })
                                    }}
                                >
                                    Save
                                </Button> */}
                                    {isReport == false &&
                                        <Button
                                            className='rectangle-button'
                                            onClick={() => {
                                                onJPJSubmit()
                                            }}
                                            type='primary'
                                        >
                                            Resubmit
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>


                </div>

            )
        },
        [STEPS.JPJ_SUBMISSION.subSteps.JPJ_SUCCESS]: {
            component: (
                <div style={{ width: '100%', }}>
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                            <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                            </div>
                        </div>
                    </Card>

                    <div style={{ width: '100%', }}>
                        <div style={{ width: '100%' }}>
                            <Col span={24}>
                                <Card
                                    style={{
                                        textAlign: 'left',
                                        marginBottom: 10,
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: 4
                                    }}
                                >
                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Vehicle Information</h2>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Vehicle Number</p>
                                            <p style={styles.value}>{props.vehicle.vehicleRegistrationNo}</p>
                                        </Col>
                                        {/* <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Make</p>
                                            <p style={styles.value}>{props.vehicle.carMake}</p>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Model</p>
                                            <p style={styles.value}>{props.vehicle.carModel}</p>
                                        </Col> */}
                                    {/* </Row>
                                    <Row gutter={16}> */}
                                        {/* <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Manufacture Year</p>
                                            <p style={styles.value}>{props.vehicle.manufactureYear}</p>
                                        </Col> */}
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Engine Number</p>
                                            <p style={styles.value}>{props.vehicle.engineNo}</p>
                                        </Col>
                                        <Col className="gutter-row" span={8}>
                                            <p style={styles.label}>Chassis Number</p>
                                            <p style={styles.value}>{props.vehicle.chassisNo}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: 10,
                            }}
                        >



                            {

                                <Card
                                    style={{
                                        flex: 3,
                                        textAlign: 'left',
                                        boxShadow: '0px 3px 6px #00000029',
                                        borderRadius: 4
                                    }}
                                >
                                    {

                                        /**
                                         * 11/3/2022 daniel.kwok
                                         * Show individual information if it's i2i or i2c
                                         * Show company information if it's d2i or d2c
                                         */

                                        isLoading ? (
                                            <Skeleton active />
                                        ) :

                                            (
                                                props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2C
                                                ||
                                                props?.transactionType === OUT_TRANSACTION_TYPES_IDS.I2I
                                            ) ? (
                                                <>

                                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Seller MyKad Details</h2>
                                                    <p style={styles.label}>Name</p>
                                                    <p style={styles.value}>{props.seller.name}</p>
                                                    <p style={styles.label}>NRIC</p>
                                                    <p style={styles.value}>{props.seller.identificationNo}</p>
                                                    <p style={styles.label}>Date of Birth</p>
                                                    <p style={styles.value}>{sellerFormattedDob}</p>
                                                    <p style={styles.label}>Gender</p>
                                                    <p style={styles.value}>{isSellerMale ? 'Male' : 'Female'}</p>
                                                    <p style={styles.label}>Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller.state)?.label })}</p>
                                                </>
                                            ) : (
                                                <>
                                                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Dealer's Details</h2>
                                                    <p style={styles.label}>Company name</p>
                                                    <p style={styles.value}>{company.coName}</p>
                                                    <p style={styles.label}>Company ROC number</p>
                                                    <p style={styles.value}>{company.coRegNo}</p>
                                                    <p style={styles.label}>Company Address</p>
                                                    <p style={styles.value}>{parseAddress({ ...company?.companyAddress, state: Object.values(STATES).find(s => s.code === company?.companyAddress?.stateCode)?.label })}</p>
                                                    <p style={styles.label}>Company e-mail</p>
                                                    <p style={styles.value}>{company.email}</p>
                                                    <p style={styles.label}>Company Phone Number</p>
                                                    <p style={styles.value}>{company.contactNo}</p>
                                                    <p style={styles.label}>Dealer Representative</p>
                                                    <p style={styles.value}>{user.name}</p>
                                                    <p style={styles.label}>Dealer Representative's NRIC</p>
                                                    <p style={styles.value}>{user.nric}</p>
                                                    <p style={styles.label}>Dealer Representative's Mobile Number</p>
                                                    <p style={styles.value}>{user.mobileno}</p>
                                                </>
                                            )
                                    } </Card>

                            }

                            <Card
                                style={{
                                    flex: 3,
                                    textAlign: 'left',
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: 4
                                }}
                            >
                                <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 24 }}>Buyer's Details</h2>

                                {
                                    isLoading ? (
                                        <Skeleton active />
                                    ) : (
                                        <>
                                            <p style={styles.label}>Name</p>
                                            <p style={styles.value}>{props.buyer.name}</p>
                                            <p style={styles.label}>NRIC</p>
                                            <p style={styles.value}>{props.buyer.identificationNo}</p>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{buyerFormattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{isBuyerMale ? 'Male' : 'Female'}</p>
                                            <p style={styles.label}>Address</p>
                                            <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.district })}</p>
                                            {/* <p style={styles.label}>Current Address</p>
                                            <p style={styles.value}>
                                                {props.buyer.correspondentAddress1
                                                    ? parseCorrespondenceAddress({ ...props.buyer, correspondentState: Object.values(STATES).find(s => s.code === props.buyer.correspondentState)?.label, districtName: props?.buyer?.correspondentDistrict })
                                                    : parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer.state)?.label, districtName: props?.buyer?.district })
                                                }
                                            </p> */}
                                            <p style={styles.label}>Email</p>
                                            <p style={styles.value}>{props.buyer.correspondentEmail}</p>
                                            <p style={styles.label}>Mobile Number</p>
                                            <p style={styles.value}>{props.buyer.correspondentMobileNo}</p>
                                        </>
                                    )
                                }

                            </Card>
                        </div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px',
                                marginBottom: 16,
                                marginTop: 16
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                    <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                                </div>
                            </div>
                        </Card>
                        <div
                            style={{ marginTop: 20 }}
                        >

                            <Row
                                style={{
                                    width: '100%', justifyContent: 'space-between'
                                }}
                            >
                                <Col>
                                    <Button
                                        className='back-button buttons'
                                        shape='round'
                                        onClick={() => {
                                            if (locationState?.oldIsReport) {
                                                if (locationState?.fromPendingPage === true) {
                                                    history.push('/reporting/transferreport/Out')
                                                } else {
                                                    history.push('/reporting/transferreport')
                                                }
                                            } else {
                                                history.push('/')
                                            }
                                        }}
                                    >
                                        Back
                                    </Button>
                                </Col>
                                <Col>
                                    {/* <Button
                                    icon={<SaveOutlined />}
                                    style={{
                                        width: 100,
                                        marginRight: 10
                                    }}
                                    shape='round'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Confirm save as draft ?',
                                            onOk: () => onSave()
                                        })
                                    }}
                                >
                                    Save
                                </Button> */}
                                {
                                    <Button
                                        className='rectangle-button'
                                        onClick={() => {
                                            // setIsModalVisible(true)
                                            history.push('/transfer/out/create', {
                                                ...history.location.state,
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })

                                        }}
                                        type='primary'
                                    >
                                        OK
                                    </Button>
                                }
                                </Col>
                            </Row>
                        </div>
                    </div>


                </div>

            )
        },
    }

    const currentStep = COMPONENT_STEPS[currentSubStep]

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null
        setCurrentSubStep(history.location.state.subStep)
    }, [history.location.state])

    useEffect(async () => {

        /**
         * 1/3/2022 daniel.kwok
         * Checks if this is a previously saved transfer.
         * If yes, transferId would be in history state.
         * Only need to call saveOutSellerBuyerInquiry if that's the case
         */
        const _transferId = params.transferId
        if (_transferId) {
            setIsLoading(true)
            setTransferId(_transferId)

            const res = await getOutTransferByTransferId(_transferId, "OUT", getCompanyId())
            if(res.status === 4) {
                message.warning("Redirect to printslip")
                history.push(`/transfer/out/printslip/${_transferId}`)
            }
            setTransactionStatus(res.status)
            setIsLoading(false)
            const state = {
                step: STEPS.JPJ_SUBMISSION.label,
                subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                buyer: res.buyerInfo,
                vehicle: {
                    vehicleRegistrationNo: res.carRegistrationNo,
                    chassisNo: res.chassisNo,
                    engineNo: res.engineNo,
                    vehicleTypeId: res.vehicleTypeId,
                    // carMake: res.make,
                    // carModel: res.model,
                    // manufactureYear: res.manufactureYear,
                },
            }

            history.push(history.location.pathname, {
                ...history.location.state,
                ...state,
            })
        } else {
            if (props.buyer.correspondentState && props.buyer.correspondentDistrict)
                getDistrictName(props.buyer.correspondentState, props.buyer.correspondentDistrict).then((res) => {
                    setDistrictName(res)
                })
        }

    }, [])

    function onJPJSubmit() {

        history.push(history.location.pathname, {
            ...history.location.state,
            step: STEPS.JPJ_SUBMISSION.label,
            subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_CHECKING,
        })

        setIsLoading(true)

        const isM2m = props.transactionType === OUT_TRANSACTION_TYPES_IDS.M2M

        if (isM2m) {

            /**
         * 14/2/2022 daniel.kwok
         * From venky
         * Need to send only vehicle information if transaction type is “Company to Individual” or “Company to Company”
         */
            let seller = {
                address1: "",
                address2: "",
                address3: "",
                correspondentAddress: "",
                correspondentEmail: "",
                correspondentMobileNo: "",
                dateOfBirth: "",
                district: "",
                email: "",
                gender: "",
                identificationNo: "",
                name: "",
                phoneNo: "",
                postcode: "",
                repIc: "",
                repIc2: "",
                repName: "",
                state: ""
            }

            let buyerDistrictCode

            if (props?.buyer?.district) {
                buyerDistricts
                    .filter(district => {

                        return district?.districtName.toUpperCase() === props.buyer?.district.toUpperCase()

                    })
                    .map(district => {
                        buyerDistrictCode = district.districtCode
                    })
            }

            let buyerCorrespondentDistrictCode

            if (props?.buyer?.correspondentDistrict) {
                buyerCorrespondenceDistricts
                    .filter(district => {

                        return district?.districtName.toUpperCase() === props.buyer?.correspondentDistrict.toUpperCase()

                    })
                    .map(district => {
                        buyerCorrespondentDistrictCode = district.districtCode
                    })
            }

            let buyer = {
                address1: props?.buyer?.address1,
                address2: props?.buyer?.address2,
                address3: props?.buyer?.address3,
                correspondentAddress1: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress1 : props?.buyer?.address1,
                correspondentAddress2: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress2 : props?.buyer?.address2,
                correspondentAddress3: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentAddress3 : props?.buyer?.address3,
                correspondentDistrict: props?.buyer?.isAddressDifferent === true ? buyerCorrespondentDistrictCode : buyerDistrictCode,
                correspondentEmail: props?.buyer?.correspondentEmail,
                correspondentMobileNo: props?.buyer?.correspondentMobileNo,
                correspondentPostcode: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentPostcode : props?.buyer?.postcode,
                correspondentState: props?.buyer?.isAddressDifferent === true ? props?.buyer?.correspondentState : props?.buyer?.state,
                dateOfBirth: props?.buyer?.dateOfBirth,
                district: buyerDistrictCode,
                email: props?.buyer?.email,
                gender: props?.buyer?.gender,
                identificationNo: props?.buyer?.identificationNo,
                name: props?.buyer?.name,
                phoneNo: props?.buyer?.phoneNo,
                mobileNo: props?.buyer?.mobileNo,
                postcode: props?.buyer?.postcode,
                state: props?.buyer?.state,
                buyerType: props?.buyer?.buyerType,
                citizenship: props?.buyer?.citizenship?.replace(/\0.*$/, "").toUpperCase()
            }

            let sellerDistrictCode

            if (props?.seller?.district) {
                sellerDistricts
                    .filter(district => {

                        return district?.districtName.toUpperCase() === props.seller?.district.toUpperCase()

                    })
                    .map(district => {
                        sellerDistrictCode = district.districtCode
                    })
            }

            let sellerCorrespondentDistrictCode

            if (props?.seller?.correspondentDistrict) {
                sellerCorrespondenceDistricts
                    .filter(district => {

                        return district?.districtName.toUpperCase() === props.seller?.correspondentDistrict.toUpperCase()

                    })
                    .map(district => {
                        sellerCorrespondentDistrictCode = district.districtCode
                    })
            }

            seller = {
                address1: props?.seller?.address1,
                address2: props?.seller?.address2,
                address3: props?.seller?.address3,
                correspondentAddress1: props?.seller?.correspondentAddress1,
                correspondentAddress2: props?.seller?.correspondentAddress2,
                correspondentAddress3: props?.seller?.correspondentAddress3,
                correspondentDistrict: sellerCorrespondentDistrictCode,
                correspondentEmail: props?.seller?.correspondentEmail,
                correspondentMobileNo: props?.seller?.correspondentMobileNo,
                correspondentPostcode: props?.seller?.correspondentPostcode,
                correspondentState: props?.seller?.correspondentState,
                dateOfBirth: props?.seller?.dateOfBirth,
                district: sellerDistrictCode,
                email: props?.seller?.email,
                gender: props?.seller?.gender,
                identificationNo: props?.seller?.identificationNo,
                name: props?.seller?.name,
                phoneNo: props?.seller?.phoneNo,
                postcode: props?.seller?.postcode,
                state: props?.seller?.state,
            }



            if (Object.keys(seller).length === 0) {
                message.error('Seller info is empty.')
                return
            }

            isAccountExist(seller?.identificationNo)
                .then(res => {

                    if (res.status === false) {

                        const { formattedDob, isMale } = breakdownIc(seller?.identificationNo)
                        let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

                        return create1MIDAccount(
                            seller?.correspondentAddress1,
                            seller?.correspondentAddress2,
                            seller?.correspondentAddress3,
                            localFormattedDob,
                            '',
                            '',
                            seller?.correspondentDistrict,
                            seller?.correspondentEmail,
                            seller?.correspondentEmail,
                            isMale ? "MALE" : "FEMALE",
                            seller?.correspondentMobileNo,
                            seller?.name,
                            seller?.identificationNo,
                            false,
                            seller?.correspondentMobileNo,
                            seller?.correspondentPostcode,
                            '',
                            '',
                            seller?.correspondentState
                        )
                    } else if (res.status === true) {

                        return {
                            status: true
                        }

                    } else {
                        throw res.message
                    }

                }).then(res => {

                    if (!res.status) {

                        let msg = res.message

                        if ('Sorry, an account already exists with email address - ' === res.message) {
                            msg += seller?.correspondentEmail
                        }

                        throw msg
                    }

                    return saveOutSeller(
                        branch.id,
                        user.username,
                        company.coRegNo,
                        true,
                        seller,
                        props.transactionType,
                        props.vehicle,
                    )

                }).then(res => {

                    if (res.saveSellerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveSellerMSG ?? res.message

                    setTransferId(res.transferId)
                    transferId = res.transferId

                    return isAccountExist(props.buyer?.identificationNo)

                }).then(res => {

                    if (res.status === false) {

                        const { formattedDob, isMale } = breakdownIc(props.buyer?.identificationNo)
                        let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

                        return create1MIDAccount(
                            buyer?.correspondentAddress1,
                            buyer?.correspondentAddress2,
                            buyer?.correspondentAddress3,
                            localFormattedDob,
                            '',
                            '',
                            buyer?.correspondentDistrict,
                            buyer?.correspondentEmail,
                            buyer?.correspondentEmail,
                            isMale ? "MALE" : "FEMALE",
                            buyer?.correspondentMobileNo,
                            buyer?.name,
                            buyer?.identificationNo,
                            false,
                            buyer?.correspondentMobileNo,
                            buyer?.correspondentPostcode,
                            '',
                            '',
                            buyer?.correspondentState
                        )

                    } else if (res.status === true) {

                        return {
                            status: true
                        }

                    } else {
                        throw res.message
                    }

                }).then(res => {

                    if (!res.status) {

                        let msg = res.message

                        if ('Sorry, an account already exists with email address - ' === res.message) {
                            msg += props.buyer?.correspondentEmail
                        }

                        throw msg
                    }

                    let buyerDistrictCode

                    if (props?.buyer?.district) {
                        buyerDistricts
                            .filter(district => {

                                return district?.districtName.toUpperCase() === props.buyer?.district.toUpperCase()

                            })
                            .map(district => {
                                buyerDistrictCode = district.districtCode
                            })
                    }

                    let buyerCorrespondentDistrictCode

                    if (props?.buyer?.correspondentDistrict) {
                        buyerCorrespondenceDistricts
                            .filter(district => {

                                return district?.districtName.toUpperCase() === props.buyer?.correspondentDistrict.toUpperCase()

                            })
                            .map(district => {
                                buyerCorrespondentDistrictCode = district.districtCode
                            })
                    }

                    let buyer = {
                        ...props.buyer,
                        district: buyerDistrictCode,
                        correspondentDistrict: buyerCorrespondentDistrictCode,
                    }

                    return saveOutBuyer(
                        buyer,
                        user.username,
                        company.coRegNo,
                        // null,
                        transferId
                    )

                }).then(res => {

                    if (res.saveBuyerStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.saveBuyerMSG

                    setStatusMSG(res.statusMSG)

                    history.push(`/transfer/out/payment/${res.transferId}`)

                }).catch(err => {

                    if (err) {

                        err && message.error(err?.toString())

                        setStatusMSG(err?.toString())

                    }

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.JPJ_SUBMISSION.label,
                        subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
                    })

                }).finally(() => setIsLoading(false))



        } else {

            let trfId = history.location.state.transferId ? history.location.state.transferId : transferId

            if (trfId) {


                saveOutSellerBuyerInquiry(user.nric, trfId).then(res => {

                    if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) throw res.statusMSG

                    setStatusMSG(res.statusMSG)

                    // if(!validateRolePermission(loggedInUserRole, "OUT", true, permissions)) {
                        history.push(`/transfer/out/payment/${res.transferId}`)
                    // }
                    // else {
                    //     history.push(history.location.pathname, {
                    //         ...history.location.state,
                    //         step: STEPS.JPJ_SUBMISSION.label,
                    //         subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_SUCCESS,
                    //     })
                    // }

                }).catch(err => {

                    if (err) {

                        // err && message.error(err?.toString())
                        processErrorMessage(err)

                        setStatusMSG(err?.toString())

                    }

                    history.push(history.location.pathname, {
                        ...history.location.state,
                        step: STEPS.JPJ_SUBMISSION.label,
                        subStep: STEPS.JPJ_SUBMISSION.subSteps.JPJ_FAILED,
                    })

                }).finally(() => setIsLoading(false))

            }
        }
    }

    return (
        <>
            {
                currentStep.component
            }
        </>

    )
}

JPJSubmission.propTypes = {
    seller: propTypes.object.isRequired,
    buyer: propTypes.object.isRequired,
    vehicle: propTypes.object.isRequired,
    transactionType: propTypes.number.isRequired,
    onApproverNRICChange: propTypes.func.isRequired,
    approverNric: propTypes.string,
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',

        marginBottom: 0
    },
    value: {
        font: 'normal normal 600 16px Open Sans',
        color: '#333333'
    }
}

export default JPJSubmission