import React, { useState, useContext, useEffect } from 'react'
import {
    Col,
    Button,
    Card,
    Image,
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'
import propTypes from 'prop-types'
import {
    OUT_TRANSACTION_TYPES,
    OUT_TRANSACTION_TYPES_IDS,
    TRANSFER_TYPES
} from '../../constants'
import { REPORT_TYPE_LABELS } from '../ReportingPage/TransferReportPage'
import ssm_disabled from '../../assets/illus-ssm-disabled.png'
import arrow_back from '../../assets/arrow_back.svg'
import iconPending from '../../assets/icon-pending-list-60.svg'
import { getPermissions } from '../../services/local'
import { SessionContext } from "../../App";
import { LoadingSpinner } from "../../components/component/UI/Custom"

function TransactionType(props) {
    const history = useHistory()
    const showM2mBulk = false; //RELEASE_CHANGE
	const { company } = useContext(SessionContext);
	const [permissions, setPermissions] = useState([]);

	useEffect(async () => {

		if (company.id) {
			await getPermissions().then(async (permissions) => {
				setPermissions(permissions);
			});
		}
	}, [company]);

    return (

        <Card
            style={{
                height: '50%',
                width: '100%',
                background: 'transparent',
                border: '0px',
            }}
        >
            <div style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline'
            }}>
                <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#FFFFFF', }}>Start a new ownership transfer</h2>

                {/* <Button
                    size="large"
                    className="i2i-pending-button"
                    shape='round'
                    style={{
                        background: ' #FFFFFF 0% 0% no-repeat padding-box',
                        BorderColor: 'transparent',
                        font: 'normal normal 600 16px Raleway',
                        color: '#2B61C4',
                    }}
                    onClick={() => history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.OUT}`)}
                ><Image src={iconPending} preview={false} width={20} />OUT PENDING LIST</Button> */}

            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',

                    }}
                >
                    {!(permissions.outTransaction || permissions.m2mTransaction)? <LoadingSpinner /> : <></>}
                    {
                        TRANSFER_TYPES.map(tt => {
                            if(!permissions.outTransaction && tt.txn_type === OUT_TRANSACTION_TYPES_IDS.D2I) {
                                return;
                            }
                            if (!permissions.m2mTransaction && tt.txn_type === OUT_TRANSACTION_TYPES_IDS.M2M) {
                                return;
                            }
                            return (
                                <Col key={tt.id} xs={12} md={8}>
                                    <Card
                                        className={'ant-card-hover'}
                                        key={tt.label}
                                        onClick={() => {
                                            props.onChange({
                                                transactionType: tt.txn_type,
                                                buyerType: tt.txn_type == 2 && tt.id
                                            })
                                        }}
                                        style={styles.ownershipTypeCard}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                height: 150
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center', // Align items in a row
                                                    height: 150
                                                }}
                                            >
                                                <div style={{ flex: 1, paddingRight: 16 }}> {/* Add paddingRight for padding */}
                                                    <img alt='' src={tt.image} style={{ maxHeight: 100, filter: '' }} />
                                                    {/* <img alt='' src={tt.image} style={{ maxHeight: 100, filter: tt.disabled ? `grayscale(100%)` : '' }} /> */}
                                                </div>

                                                <div style={{ flex: 2 }}>
                                                    <h1 style={{
                                                        font: 'normal normal 600 24px Raleway',
                                                        textAlign: 'left',
                                                        color: '#16467D'
                                                    }}>{tt.label}</h1>
                                                    <h2 style={{
                                                        font: 'normal normal 400 20px Raleway',
                                                        textAlign: 'left',
                                                        color: '#16467D'
                                                    }}>{tt.desc}</h2> {/* Display the description */}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    {/* <Card
                                        className={type.id === 3 || type.id === OUT_TRANSACTION_TYPES_IDS.M2M ? null : 'ant-card-hover'}
                                        key={type.label}
                                        onClick={() => {
                                            if (type.id == 3 || type.id ===  OUT_TRANSACTION_TYPES_IDS.M2M) return null
                                            props.onChange(type.id)

                                        }}
                                        style={
                                            type.id == 3 || type.id ===  OUT_TRANSACTION_TYPES_IDS.M2M 
                                            ? styles.ownershipTypeCardDisabled
                                            : styles.ownershipTypeCard
                                        }
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                height: 150
                                            }}
                                        >
                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <img alt='' src={type.id === 3 ? ssm_disabled : type.image} style={{ maxHeight: 100, mixBlendMode: type.id === 3 ? 'multiply' : '' }} />
                                            </div>

                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <h1
                                                    style={{
                                                        font: 'normal normal 600 24px Raleway',
                                                        color: '#16467D'
                                                    }}
                                                >{type.label}</h1>
                                            </div>
                                        </div>
                                    </Card> */}
                                </Col>
                            )
                        })
                    }
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 16,
                    }}
                >
                    <hr style={{ width: '100%', borderTop: '1px solid #D8D8D8' }} />
                    <span
                        style={{
                            position: 'relative',
                            top: '-25px',
                            background: '#D8D8D8',
                            padding: '4px 12px',
                            borderRadius: '20px',
                            fontWeight: 'bold',
                            color: '#575757',
                        }}
                    >
                        OR
                    </span>
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column', // Added to stack items vertically
                    }}
                >
                    <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#333333', }}>Continue pending transfers</h2>

                    {/* <div style={{ width: '100%', maxWidth: 400 }}>  */}
                    {/* Adjust the max width as needed */}
                        {/* <Card
                            className={'ant-card-hover'}
                            onClick={() => {
                                history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.OUT}`)
                            }}
                            style={styles.ownershipTypeCard}
                        >
                            <div

                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 150
                                }}
                            >

                                <div
                                    style={{ flex: 3 }}
                                >
                                    <img alt='' src={iconPending} style={{ maxHeight: 100, filter: '' }} />

                                </div>

                                <div
                                    style={{ flex: 3 }}
                                >
                                    <h1
                                        style={{
                                            font: 'normal normal 600 24px Raleway',
                                            textAlign: 'left',
                                            color: '#16467D'
                                        }}
                                    >OUT Pending List</h1>

                                </div>
                            </div>
                        </Card> */}
                    {/* </div> */}

                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <Col key={1} xs={12} md={8}>
                            <Card
                            className={'ant-card-hover'}
                            onClick={() => {
                                history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.OUT}`)
                            }}
                            style={styles.ownershipTypeCard}
                            >
                                <div

                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        height: 150
                                    }}
                                >

                                    <div
                                        style={{ flex: 1, paddingRight: 16 }}
                                    >
                                        <img alt='' src={iconPending} style={{ maxHeight: 100, filter: '' }} />

                                    </div>

                                    <div
                                        style={{ flex: 3 }}
                                    >
                                        <h1
                                            style={{
                                                font: 'normal normal 600 24px Raleway',
                                                textAlign: 'left',
                                                color: '#16467D'
                                            }}
                                        >OUT Pending List</h1>

                                    </div>
                                </div>
                            </Card>
                    </Col>
                    {/* temporary hide bulk transactions for prod */}
                    {showM2mBulk && <Col key={2} xs={12} md={8}> 
                        <Card
                                className={'ant-card-hover'}
                                onClick={() => {
                                    history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.M2M}/bulk`)
                                }}
                                style={styles.ownershipTypeCard}
                            >
                                <div

                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        height: 150
                                    }}
                                >

                                    <div
                                        style={{ flex: 1, paddingRight: 16 }}
                                    >
                                        <img alt='' src={iconPending} style={{ maxHeight: 100, filter: '' }} />

                                    </div>

                                    <div
                                        style={{ flex: 3 }}
                                    >
                                        <h1
                                            style={{
                                                font: 'normal normal 600 24px Raleway',
                                                textAlign: 'left',
                                                color: '#16467D'
                                            }}
                                        >Bulk Transactions</h1>

                                    </div>
                                </div>
                            </Card>
                    </Col>}
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 32
                    }}
                >
                    <Button
                        className='back-button buttons'
                        shape='round'
                        onClick={() => {
                            history.push('/')
                        }}
                    >
                        <Image src={arrow_back} preview={false} />
                        Back
                    </Button>
                    {/* <Button
                        style={{
                            width: 200
                        }}
                        disabled={!transactionType}
                        onClick={() => {
                            props.onChange(transactionType)
                        }}
                        type='primary'
                    >
                        Continue
                    </Button> */}
                </div>
            </div>
        </Card >
    )
}

TransactionType.propTypes = {
    transactionType: propTypes.number, // 10/2/2022 must be optional
    onChange: propTypes.func.isRequired,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: 8
    },
    ownershipTypeCardDisabled: {
        background: '#DDDDDD 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        borderColor: 'transparent',
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 10px rgba(9, 8, 8, 0.393)`,
        transition: `box-shadow .1s`,

    }
}

export default TransactionType