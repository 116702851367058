import { Row, Col } from "antd";

export const PaginationShowingSpan = (startItem, endItem, count) => {
  return (
    <span>
      Showing{" "}
      <b>
        {startItem} - {endItem}
      </b>{" "}
      of <b>{count} transactions</b>.
    </span>
  );
};

export const ApprovalStatusSpan = ({ isSuccess, inquiryCount }) => {
  const backgroundColor = isSuccess ? "#def2d6" : "#ffefef";
  return (
    <div
      style={{
        backgroundColor,
        padding: "20px",
        textAlign: "center",
      }}
    >
      <span style={{ fontSize: "2.5em" }}>
        <strong>{inquiryCount}</strong>
      </span>
      <br />
      <span style={{ fontSize: "1.5em" }}>
        {isSuccess ? "Successful" : "Failed"}
      </span>
    </div>
  );
};

export const ApprovalStatusSpanWithCustom = ({
  isSuccess,
  inquiryCount,
  message,
}) => {
  const backgroundColor = isSuccess ? "#def2d6" : "#ffefef";
  const color = isSuccess ? "#40e327a8" : "#E53B34";
  return (
    <div
      style={{
        backgroundColor,
        padding: "20px",
      }}
    >
      <Row>
        <Col span={12} 
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-start",
            color: color
          }}>
          <span style={{ fontSize: "1.5em", textAlign: "left", paddingRight: "5px" }}>
            <strong>{inquiryCount}</strong>{" "}
          </span>
          <span style={{ fontSize: "1.5em", textAlign: "left" }}>
            {isSuccess ? "successful" : "failed"}
          </span>
        </Col>
        <Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>{message}</div>
        </Col>
      </Row>
    </div>
  );
};
