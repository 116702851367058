import { LoadingOutlined } from "@ant-design/icons";

export const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "25vh",
      }}
    >
      <LoadingOutlined style={{ fontSize: 50 }} />
    </div>
  );
};
