import Icon from '@ant-design/icons';

// Convert svg to jsx using this website if needed: https://svg2jsx.com/

const ShowEyeSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
    >
        <defs>
            <clipPath id="b">
                <path d="M0 0H28V28H0z"></path>
            </clipPath>
        </defs>
        <g clipPath="url(#b)">
            <path fill="none" d="M0 0H28V28H0z"></path>
            <g fill="#0e69dc">
                <path
                    d="M684.889 2189.9a12.037 12.037 0 01-4.874-1.1 18.8 18.8 0 01-3.919-2.395 23.15 23.15 0 01-3.6-3.475.5.5 0 010-.621 23.135 23.135 0 013.6-3.475 18.808 18.808 0 013.92-2.395 11.323 11.323 0 019.747 0 18.805 18.805 0 013.92 2.395 23.115 23.115 0 013.6 3.475.5.5 0 010 .621 23.146 23.146 0 01-3.6 3.475 18.8 18.8 0 01-3.92 2.395 12.037 12.037 0 01-4.874 1.1zm-11.347-7.283a23.471 23.471 0 003.188 3.012c1.915 1.492 4.885 3.271 8.159 3.271s6.259-1.787 8.178-3.285a23.406 23.406 0 003.169-3 23.475 23.475 0 00-3.187-3.012c-1.915-1.492-4.885-3.271-8.159-3.271s-6.258 1.787-8.178 3.285a23.419 23.419 0 00-3.17 3.005z"
                    transform="translate(2 7) translate(-672.889 -2175.839)"
                ></path>
                <path
                    d="M4.174-.5A4.674 4.674 0 11-.5 4.174 4.679 4.679 0 014.174-.5zm0 8.348A3.674 3.674 0 10.5 4.174a3.678 3.678 0 003.674 3.674z"
                    transform="translate(2 7) translate(7.826 2.608)"
                ></path>
                <path
                    d="M684.476 2185.013a2.59 2.59 0 01-2.587-2.587.5.5 0 011 0 1.587 1.587 0 101.587-1.587.5.5 0 010-1 2.587 2.587 0 110 5.174z"
                    transform="translate(2 7) translate(-672.476 -2175.644)"
                ></path>
            </g>
        </g>
    </svg>
)

const HideEyeSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
    >
        <defs>
            <clipPath id="a">
                <path fill="none" d="M0 0H28V28H0z"></path>
            </clipPath>
            <clipPath id="c">
                <path d="M0 0H28V28H0z"></path>
            </clipPath>
        </defs>
        <g clipPath="url(#c)">
            <g fill="none" clipPath="url(#a)">
                <path d="M0 0H28V28H0z"></path>
                <g
                    stroke="#0e69dc"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    transform="translate(6489 -5028)"
                >
                    <path d="M-6467 5038a26.764 26.764 0 014 3.7s-5.1 6.5-11.5 6.5a10.474 10.474 0 01-3-.5M-6482 5045.5a26.764 26.764 0 01-4-3.7s5.1-6.5 11.5-6.5a10.074 10.074 0 012.5.3M-6478.5 5041.7a4.012 4.012 0 014-4M-6470.5 5041.7a4.012 4.012 0 01-4 4"></path>
                    <path
                        d="M19.6 0L0 19.5"
                        transform="translate(-6484.299 5032)"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
)

function WhiteChevronSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
        >
            <defs>
                <clipPath id="a">
                    <path fill="none" d="M0 0H28V28H0z"></path>
                </clipPath>
                <clipPath id="c">
                    <path d="M0 0H28V28H0z"></path>
                </clipPath>
            </defs>
            <g clipPath="url(#c)">
                <g clipPath="url(#a)">
                    <path fill="none" d="M0 0H28V28H0z" opacity="0.75"></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M14 0L7 7 0 0"
                        transform="translate(7 11)"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

function WhiteProfileSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
      >
        <defs>
          <clipPath id="b">
            <path d="M0 0H28V28H0z"></path>
          </clipPath>
        </defs>
        <g clipPath="url(#b)">
          <path
            fill="#fff"
            d="M10 .563a10 10 0 1010 10 10 10 0 00-10-10zm0 3.871a3.548 3.548 0 11-3.548 3.548A3.548 3.548 0 0110 4.433zM10 18.3a7.727 7.727 0 01-5.907-2.75 4.5 4.5 0 013.972-2.411.987.987 0 01.286.044 5.339 5.339 0 001.649.283 5.319 5.319 0 001.649-.278.987.987 0 01.286-.044 4.5 4.5 0 013.972 2.411A7.727 7.727 0 0110 18.3z"
            transform="translate(4 3.438)"
          ></path>
          <path fill="none" d="M0 0H28V28H0z"></path>
        </g>
      </svg>
    );
  }

function BlueProfileSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="44"
        viewBox="0 0 44 44"
      >
        <defs>
          <clipPath id="b">
            <path d="M0 0H44V44H0z"></path>
          </clipPath>
        </defs>
        <g clipPath="url(#b)">
          <path
            fill="#2b61c4"
            d="M19.2.563a19.2 19.2 0 1019.2 19.2A19.2 19.2 0 0019.2.563zm0 7.432a6.813 6.813 0 11-6.813 6.813A6.813 6.813 0 0119.2 7.995zm0 26.633a14.836 14.836 0 01-11.342-5.28 8.632 8.632 0 017.626-4.63 1.894 1.894 0 01.55.085 10.251 10.251 0 003.166.534 10.212 10.212 0 003.166-.534 1.894 1.894 0 01.55-.085 8.632 8.632 0 017.626 4.63A14.836 14.836 0 0119.2 34.627z"
            transform="translate(3 2.438)"
          ></path>
          <path fill="none" d="M0 0H44V44H0z"></path>
        </g>
      </svg>
    );
  }

export const ShowEyeIcon = (props) => (
    <Icon component={ShowEyeSvg} {...props} />
)

export const HideEyeIcon = (props) => (
    <Icon component={HideEyeSvg} {...props} />
)

export const WhiteChevronIcon = (props) => (
    <Icon component={WhiteChevronSvg} {...props} />
)

export const WhiteProfileIcon = (props) => (
    <Icon component={WhiteProfileSvg} {...props} />
)

export const BlueProfileIcon = (props) => (
    <Icon component={BlueProfileSvg} {...props} />
)
