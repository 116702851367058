import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    message
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'

import thumbprint from '../../assets/thumbprint.jpg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import {
    LoadingOutlined,
} from '@ant-design/icons'
import {
    STEPS,
} from './steps'
import {
    breakdownIc,
    parseAddress,
    getState
} from '../../util'
import {
    isAccountExist
} from '../../services/api'
import DeviceReader from '../../components/DeviceReader'
import propTypes from 'prop-types'
import { STATES, STATES_FULLNAME } from '../../constants'

function AuthorizeSeller(props) {
    const history = useHistory()

    const { formattedDob, isMale } = breakdownIc(props.seller.identificationNo)

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Seller Identity
                    </h1>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Seller Identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Seller Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUBMPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Seller Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Seller Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY]: {
            component: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                    }}
                >
                    <h2 style={{ fontWeight: 'bold' }}>MyKad Details</h2>
                    <div
                        style={{ textAlign: 'left' }}
                    >
                        <p style={styles.label}>Owner's Name</p>
                        <p style={styles.value}>{props.seller.name}</p>
                        <p style={styles.label}>NRIC</p>
                        <p style={styles.value}>{props.seller.identificationNo}</p>
                        <p style={styles.label}>Date of Birth</p>
                        <p style={styles.value}>{formattedDob}</p>
                        <p style={styles.label}>Gender</p>
                        <p style={styles.value}>{isMale ? 'Male' : 'Female'}</p>
                        <p style={styles.label}>Address</p>
                        <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label })}</p>

                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button
                            shape='round'
                            onClick={() => {
                                setStep(STEPS.AUTHORIZE_BUYER.label)
                                setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                            }}
                        >
                            Prev step
                        </Button>
                        <Button
                            style={{
                                width: 200
                            }}
                            onClick={() => {
                                props.onChange({
                                    ...props.seller,
                                })

                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.VEHICLE_INFORMATION.label,
                                    subStep: STEPS.VEHICLE_INFORMATION.subSteps.REVIEW
                                })
                            }}
                            type='primary'
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            )
        },
    }

    const [step, setStep] = useState(STEPS.AUTHORIZE_SELLER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null
        setSubStep(history.location.state.subStep)
    }, [])

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                {
                    currentStep && currentStep.component
                }

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {
                        subStep !== STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <DeviceReader
                                    forKey='seller'
                                    nextStep={STEPS.VEHICLE_INFORMATION.label}
                                    nextSubStep={STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO}
                                    onIdentityCardSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERTING_IC)
                                    }}
                                    onWaitingThumbprint={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_BEFORE)
                                    }}
                                    onThumbprintDetected={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUBMPRINTING)
                                    }}
                                    onThumbprintFailed={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED)
                                    }}
                                    onThumbprintSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_SUCCESS)
                                    }}
                                    onTryAgain={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)
                                    }}
                                    onFinish={data => {

                                        let errorMessages = [];

                                        if (!data.name) {
                                            errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        // if (!data.district) {
                                        //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                        // }

                                        if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                            errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (errorMessages.length > 0) {
                                            for (let errorMessage of errorMessages) {
                                                message.error(errorMessage);
                                            }
                                            setStep(STEPS.AUTHORIZE_BUYER.label)
                                            setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                                            return;
                                        }
                                        data.identificationNo = data.identificationNo.replace(/[\s*]/g, "");

                                        const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                        let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                        if (!state) state = getState(data.identificationNo)

                                        props.onChange({
                                            ...props.seller,
                                            ...data,
                                            state: state?.code || "",
                                            address1: data.address1.replace(/\0.*$/, ""),
                                            address2: data.address2.replace(/\0.*$/, ""),
                                            address3: data.address3.replace(/\0.*$/, ""),
                                            name: data.name.replace(/\*/g, ''),
                                            district: data.district.replace(/[^a-zA-Z\s]/g, '')
                                        })

                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY)
                                    }}
                                    onDataChanged={data => { }}
                                    onError={data => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 20,
                                    }}
                                >
                                    <Button
                                        shape='round'
                                        onClick={() => {
                                            history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.AUTHORIZE_BUYER.label,
                                                subStep: STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY
                                            })
                                        }}
                                    >
                                        Prev step
                                    </Button>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </Card>
        </>
    )
}

AuthorizeSeller.propTypes = {
    seller: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    }
}

export default AuthorizeSeller