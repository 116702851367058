import {
    Button,
    Card, Col, Image
} from 'antd'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { OWNERSHIP_TYPES } from '../../constants'
import { REPORT_TYPE_LABELS } from '../ReportingPage/TransferReportPage'
import './index.less'
import { HistoryState, STEPS, Vehicle } from './types'
import iconPending from '../../assets/icon-pending-list-60.svg'
import arrow_back from '../../assets/arrow_back.svg'

type OwnershipTypeProps = {
    onChange: Function,
    vehicle: Vehicle,
}

function OwnershipType(props: OwnershipTypeProps) {
    const history = useHistory()
    const { state: locationState = {} } = useLocation<HistoryState>()

    return (
        <Card
            style={{
                height: '50%',
                width: '100%',
                maxWidth: 1200,
                background: 'transparent', border: "0px"
            }}
        >

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }}
            >
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center', /* Change 'space-between' to 'center' */
                    alignItems: 'baseline'
                }}>
                    <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#FFFFFF', }}>Start a new ownership transfer</h2>
                    {/* <Button
      size="large"
      className="i2i-pending-button"
      shape='round'
      style={{
          background: ' #FFFFFF 0% 0% no-repeat padding-box',
          font: 'normal normal 600 16px Raleway',
          color: '#2B61C4',
      }}
      onClick={() => history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.IN}`)}
  ><Image src={iconPending} preview={false} width={20} />IN PENDING LIST</Button> */}
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',

                    }}
                >
                    {
                        OWNERSHIP_TYPES
                            .filter(ot => ot.id !== 9 /**23/3/2022 daniel.kwok filter out member to member. */)
                            .map(ot => {

                                return (
                                    <Col key={ot.id} xs={12} md={8}>
                                        <Card
                                            className={!ot.disabled ? 'ant-card-hover' : ''}
                                            key={ot.label}
                                            onClick={() => {
                                                if (!ot.disabled) {
                                                    if (ot.overridingUrl) {
                                                        window.open(ot.overridingUrl, '_blank')?.focus();
                                                    } else {
                                                        const vehicle = {
                                                            ...props.vehicle,
                                                            ownerIdCat: ot.id,
                                                        }
                                                        props.onChange(vehicle)

                                                        history.push(history.location.pathname, {
                                                            ...locationState,
                                                            vehicle: vehicle,
                                                            step: STEPS.AUTHORIZE_SELLER.label,
                                                            subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC,
                                                        })
                                                    }
                                                }
                                            }}
                                            style={
                                                ot.disabled
                                                    ? styles.ownershipTypeCardDisabled
                                                    : styles.ownershipTypeCard
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center', // Align items in a row
                                                    height: 150
                                                }}
                                            >
                                                <div style={{ flex: 1, paddingRight: 16 }}> {/* Add paddingRight for padding */}
                                                    <img alt='' src={ot.image} style={{ maxHeight: 100, filter: ot.disabled ? `grayscale(100%)` : '' }} />
                                                </div>

                                                <div style={{ flex: 2 }}>
                                                    <h1 style={{
                                                        font: 'normal normal 600 24px Raleway',
                                                        textAlign: 'left',
                                                        color: '#16467D'
                                                    }}>{ot.label}</h1>
                                                    <h2 style={{
                                                        font: 'normal normal 400 20px Raleway',
                                                        textAlign: 'left',
                                                        color: '#16467D'
                                                    }}>{ot.desc}</h2> {/* Display the description */}
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })
                    }

                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 16,
                    }}
                >
                    <hr style={{ width: '100%', borderTop: '1px solid #D8D8D8' }} />
                    <span
                        style={{
                            position: 'relative',
                            top: '-25px',
                            background: '#D8D8D8',
                            padding: '4px 12px',
                            borderRadius: '20px',
                            fontWeight: 'bold',
                            color: '#575757',
                        }}
                    >
                        OR
                    </span>
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column', // Added to stack items vertically
                    }}
                >
                    <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#333333', }}>Continue pending transfers</h2>
                    <div style={{ width: '100%', maxWidth: 400 }}> {/* Adjust the max width as needed */}
                        <Card
                            className={'ant-card-hover'}
                            onClick={() => {
                                history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.IN}`)
                            }}
                            style={styles.ownershipTypeCard}
                        >
                            <div

                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    height: 150
                                }}
                            >

                                <div
                                    style={{ flex: 3 }}
                                >
                                    <img alt='' src={iconPending} style={{ maxHeight: 100, filter: '' }} />

                                </div>

                                <div style={{ flex: 3 }}>
                                    <h1 style={{
                                        font: 'normal normal 600 24px Raleway',
                                        textAlign: 'left',
                                        color: '#16467D'
                                    }}>
                                        <span style={{ color: '#4BFF00' }}>IN</span> Pending List
                                    </h1>
                                </div>

                            </div>
                        </Card>
                    </div>
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 32
                    }}
                >
                    <Button
                        className='back-button buttons'
                        shape='round'
                        onClick={() => {
                            history.push('/')
                        }}
                    >
                        <Image src={arrow_back} preview={false} />
                        Back
                    </Button>
                    {/* <Button
                        style={{
                            width: 200
                        }}
                        disabled={!transactionType}
                        onClick={() => {
                           
                        }}
                        type='primary'
                    >
                        Continue
                    </Button> */}
                </div>
            </div>
        </Card >
    )
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
        background: ' #FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: '8px',
    },
    ownershipTypeCardDisabled: {
        background: '#DDDDDD 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        borderColor: 'transparent',
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 10px rgba(9, 8, 8, 0.393)`,
        transition: `box - shadow .1s`,

    }
}

export default OwnershipType