import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Skeleton, Button, Card, message, Row, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PropsFromChildren } from ".";
import { SessionContext } from "../../App";
import { BUTTON_RETURN_PENDING_TRANSACTION } from "../../constants";
import {
  getInTransferByTransferId,
  getInBulkTransactionDetails,
  getInTransferAmountByTransferId,
} from "../../services/api";
import {
  GetInTransferDetailRequest,
  GetInTransferAmountRequest,
} from "../../services/api/types";
import { CompanyData, vehicleDetail, TransactionAmount } from "./types";
import {
  VerificationCard,
  CompanyCardComponent,
  TransactionAmountCard,
  PaymentApprovalSuccessCard,
} from "../../components/component/Layout/CardComponent";
import {
  VehicleDetailsTableComponent,
  VehicleSelectedTableComponent,
} from "../../components/component/Layout/TableComponent";
import { TextButton } from "../../components/component/UI/Button";

type SellerApprovalProps = {
  transferId: number;
  bulkTransferId: number;
  isApprover: boolean;
  next: (data?: PropsFromChildren) => void;
};

export default function SellerApproval(props: SellerApprovalProps) {
  const { company, branchs, branch } = useContext(SessionContext);
  const history = useHistory();
  const [isJPJLoading, setIsJPJLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(true);
  const [transferId, setTransferId] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState("");
  const [sellerCompanyData, setSellerCompanyData] = useState<CompanyData>({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [buyerCompanyData, setBuyerCompanyData] = useState<CompanyData>({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState<vehicleDetail[]>([]);
  const [transactionAmount, setTransactionAmount] = useState<TransactionAmount>(
    {
      id: 0,
      name: "",
      jpjAmount: 0,
      eserviceAmount: 0,
      fisAmount: 0,
      fisEserviceAmount: 0,
      sstAmount: 0,
      totalAmount: 0,
      processingAmount: 0,
      subTotalAmount: 0,
    }
  );

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };

  const dataSource = vehicleDetails ? vehicleDetails : [];

  /* Pagination */
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [isFooterEditing, setIsFooterEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPageSize = 10;
  const totalPages = Math.ceil(dataSource.length / maxPageSize);
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const paginatedData = dataSource.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, dataSource.length)
  );
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };
  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsHeaderEditing(false);
    setIsFooterEditing(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  /* Pagination */

  const vehicleDetailList = paginatedData.map((item, index) => {
    return {
      no: index + 1,
      vehicleNo: item.carRegistrationNo,
      ...item,
    };
  });

  /* getInBulkTransactionDetails */
  useEffect(() => {
    if (props.bulkTransferId !== 0) {
      setIsJPJLoading(true);
      getInBulkTransactionDetails(props.bulkTransferId, "BULKM2M").then(
        (res) => {
          try {
            const sellerCompany: CompanyData = {
              id: 0,
              coName: res.seller.name,
              coRegNo: res.seller.identificationNo,
              email: res.seller.email ?? "",
              contactNo: res.seller.phoneNo ?? "",
              ucdCode: "",
            };
            setSellerCompanyData(sellerCompany);

            const buyerCompany: CompanyData = {
              id: 0,
              coName: res.buyer.name,
              coRegNo: res.buyer.identificationNo,
              email: res.buyer.email ?? "",
              contactNo: res.buyer.phoneNo ?? "",
              ucdCode: "",
            };
            setBuyerCompanyData(buyerCompany);

            const dataWithKey = res.vehicleDetailList.map((item, index) => {
              return {
                key: index + 1,
                ...item,
              };
            });
            setVehicleDetails(dataWithKey);
            setTransactionAmount(res.transactionAmount);
            setTotalSuccess(res.totalSuccess);
            setCurrentVehicle(res.vehicleDetailList[0]?.carRegistrationNo)
          } catch (err) {
          } finally {
          }
        }
      );

      setIsJPJLoading(false);
    }
  }, [props.bulkTransferId]);

  useEffect(() => {
    if (props.transferId !== 0) {
      setIsJPJLoading(true);
      setTransferId(props.transferId);
      const transferId = props.transferId;
      const getInTransferDetailRequest: GetInTransferDetailRequest = {
        transferId,
        uId: "",
        companyId: 0,
        moduleType: "M2M",
      };
      getInTransferByTransferId(getInTransferDetailRequest).then((res) => {
        try {
          setCurrentVehicle(res.carRegistrationNo);
          const sellerCompany: CompanyData = {
            id: 0,
            coName: res.sellerInfo.name,
            coRegNo: res.sellerInfo.identificationNo,
            email: res.sellerInfo.email,
            contactNo: res.sellerInfo.phoneNo,
            ucdCode: "",
          };
          setSellerCompanyData(sellerCompany);

          const buyerCompany: CompanyData = {
            id: 0,
            coName: res.buyerInfo.name,
            coRegNo: res.buyerInfo.identificationNo,
            email: res.buyerInfo.email,
            contactNo: res.buyerInfo.phoneNo,
            ucdCode: "",
          };

          setBuyerCompanyData(buyerCompany);

          const vehicle: vehicleDetail = {
            carRegistrationNo: res.carRegistrationNo,
            chassisNo: res.chassisNo,
            engineNo: res.engineNo,
            vehicleTypeNewId: res.vehicleTypeNewId,
            failCode: res.failCode,
            branchId: res.vehicleBranchId,
            id: 0,
            sellerInquirySuccess: new Date(),
            status: 0
          };
          const dataWithKey = {
            key: 1,
            ...vehicle,
          };
          setVehicleDetails([dataWithKey]);
          setTotalSuccess(1);

          const getInTransferAmountRequest: GetInTransferAmountRequest = {
            transferId,
          };

          getInTransferAmountByTransferId(getInTransferAmountRequest)
            .then((res) => {
              if (res.id === undefined) throw res.message;

              const amount: TransactionAmount = {
                id: res.id,
                name: res.name,
                jpjAmount: res.jpjAmount,
                eserviceAmount: res.eserviceAmount,
                fisAmount: res.fisAmount,
                fisEserviceAmount: res.fisEserviceAmount,
                sstAmount: res.sstAmount,
                totalAmount: res.totalAmount,
                processingAmount: res.processingAmount,
                subTotalAmount: res.processingAmount + res.eserviceAmount,
              };
              setTransactionAmount(amount);
            })
            .catch((err) => err && message.error(err))
            .finally(() => {});
        } catch (err) {
        } finally {
        }
      });

      setIsJPJLoading(false);
    }
  }, [props.transferId]);

  const onBackButton = () => {
    // history.push("/transfer/out/create");
    history.push(`/reporting/transferreport/Out`, { tabType: "3" });
  };

  /* Pagination */
  const CustomPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    isEditing,
    setIsEditing,
  }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const showingVehicleCount = () => {
    return (
      <span>
        Showing{" "}
        <b>
          {startItem} - {endItem}
        </b>{" "}
        of <b>{dataSource.length} vehicles</b>.
      </span>
    );
  };

  const PaginationWrapperHeader = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isHeaderEditing}
            setIsEditing={setIsHeaderEditing}
          />
        </Row>
      </>
    );
  };

  const PaginationWrapperFooter = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isFooterEditing}
            setIsEditing={setIsFooterEditing}
          />
        </Row>
      </>
    );
  };


  const VehicleDetailPaginationTable = () => {
    return (
      <Card
        style={{
          flex: 3,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <>
          <Pagination
            current={currentPage}
            pageSize={1}
            total={totalPages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            showQuickJumper={false}
            showSizeChanger={false}
            style={{ marginBottom: "16px" }}
          />
          {PaginationWrapperHeader()}
          {VehicleSelectedTableComponent(
            vehicleDetailList,
            branchs,
            false,
            true,
            branch.id === 0
          )}
          {PaginationWrapperFooter()}
        </>
      </Card>
    );
  };
  /* Pagination */

  return (
    <>
      {isJPJLoading ? (
        <Skeleton />
      ) : (
        <>
          {/* {VerificationCard(verificationSuccess)} */}
          <PaymentApprovalSuccessCard
            isBulk={props.bulkTransferId !== 0}
            totalVehicle={totalSuccess}
            vehicleNo={currentVehicle}
          />
          <br />
          {VehicleDetailPaginationTable()}
          <br />
          {CompanyCardComponent(sellerCompanyData, buyerCompanyData, true)}
          <br />
          {TransactionAmountCard(
            transactionAmount ? transactionAmount : [],
            totalSuccess,
            currentVehicle,
            props.bulkTransferId !== 0
          )}
          <br />
          {TextButton(BUTTON_RETURN_PENDING_TRANSACTION, onBackButton)}
        </>
      )}

      <style>
        {`
          .ant-pagination {
            display: none;
          }
          .custom-pagination {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .custom-wrap-row {
            display: flex;
            justify-content: space-between;
          }
        `}
      </style>
    </>
  );
}
