
/**
 * This is a constant file meant to keep all router state names
 * AUTHORIZE_DEALAR     //step
 * D_INSERT_IC          //subStep
 */
export const STEPS = {
    AUTHORIZE_DEALER: {
        label: 'AUTHORIZE_DEALER',
        subSteps: {
            INSERT_IC: 'D_INSERT_IC',
            INSERTING_IC: 'D_INSERTING_IC',
            THUMBPRINT_BEFORE: 'D_THUMBPRINT_BEFORE',
            THUBMPRINTING: 'D_THUBMPRINTING',
            THUMBPRINT_FAILED: 'D_THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'D_THUMBPRINT_SUCCESS',
            SUMMARY: 'D_SUMMARY',
            AUTHORIZE_FAILED: 'AUTHORIZE_FAILED'
        }
    },
    TRANSACTION_TYPE: {
        label: 'TRANSACTION_TYPE',
        subSteps: {
            TRANSACTION_TYPE: 'TRANSACTION_TYPE',
        }
    },
    VEHICLE_INFORMATION: {
        label: 'VEHICLE_INFORMATION',
        subSteps: {
            VEHICLE_INFO: 'VEHICLE_INFO',
            UPDATE_VEHICLE_INFO: 'UPDATE_VEHICLE_INFO',
        }
    },
    AUTHORIZE_BUYER: {
        label: 'AUTHORIZE_BUYER',
        subSteps: {
            B_ROC_SEARCH_PAGE:'B_ROC_SEARCH_PAGE',
            INSERT_IC: 'B_INSERT_IC',
            INSERTING_IC: 'B_INSERTING_IC',
            THUMBPRINT_BEFORE: 'B_THUMBPRINT_BEFORE',
            THUBMPRINTING: 'B_THUBMPRINTING',
            THUMBPRINT_FAILED: 'B_THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'B_THUMBPRINT_SUCCESS',
            SUMMARY: 'B_SUMMARY'
        }
    },
    AUTHORIZE_SELLER: {
        label: 'AUTHORIZE_SELLER',
        subSteps: {
            S_ROC_SEARCH_PAGE:'S_ROC_SEARCH_PAGE',
            INSERT_IC: 'S_INSERT_IC',
            INSERTING_IC: 'S_INSERTING_IC',
            THUMBPRINT_BEFORE: 'S_THUMBPRINT_BEFORE',
            THUBMPRINTING: 'S_THUBMPRINTING',
            THUMBPRINT_FAILED: 'S_THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'S_THUMBPRINT_SUCCESS',
            SUMMARY: 'S_SUMMARY'
        }
    },
    JPJ_SUBMISSION: {
        label: 'JPJ_SUBMISSION',
        subSteps: {
            REVIEW: 'REVIEW',
            JPJ_CHECKING: 'JPJ_CHECKING',
            JPJ_FAILED: 'JPJ_FAILED',
            JPJ_SUCCESS: 'JPJ_SUCCESS',
        }
    },
    PAYMENT: {
        label: 'PAYMENT'
    },
    PRINT_SLIP: {
        label: 'PRINT_SLIP'
    },
}