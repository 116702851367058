import React,
{
    useContext,
    useEffect,
    useState
} from 'react'
import {
    ReadOutlined,
    DownloadOutlined,
    EditOutlined,
    PrinterOutlined,
    SearchOutlined,
    RedoOutlined,
} from '@ant-design/icons'
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    message,
    Row,
    Select,
    Table,
    Tag,
    Tooltip
} from 'antd'
import { PageHeader } from '@ant-design/pro-components';
import moment from 'moment'
import {
    useHistory,
    useParams
} from 'react-router-dom'
import { SessionContext } from '../../App'
import {
    APT_TRANSACTION_STATUSES_LABELS,
    APT_TRANSACTION_STATUSES_COLORS,
    APT_TRANSACTION_STATUSES,
    IN_TRANSACTION_STATUSES,
    IN_TRANSACTION_STATUSES_COLORS,
    IN_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_STATUSES,
    OUT_TRANSACTION_STATUSES_COLORS,
    OUT_TRANSACTION_STATUSES_LABELS,
    OUT_TRANSACTION_TYPES,
    OUT_TRANSACTION_TYPES_IDS,
    TRANSACTION_RESPONSE_CODES,
    TRANSACTION_OUT_RESPONSE_CODES,
    SEARCH_TYPES,
    NRIC_TYPES,
    SEARCH_QUERY_TOOLTIP,
    M2M_TRANSACTION_STATUSES
} from '../../constants'
import {
    getInTxnForAdmin,
    getM2mTxnForAdmin,
    getOutTxnForAdmin,
    getI2iTxnForAdmin,
    getReceiptAPT,
    InTransferInquiryWithJPJ,
    i2iSaveOutSellerBuyerInquiry,
    getInTxnForAdminPost,
    getI2iTxnForAdminPost,
    getOutTxnForAdminPost,
    getAptTxnForAdminPost,
    revertStatus,
    getM2mTxnForAdminPost,
    m2mProceed,
    saveOutSellerBuyerInquiry,
    recoverExpiredTransaction,
    getV2ReceiptAPT,
} from '../../services/api'
import { parseTime } from '../../util'
import './index.less'
import arrow_back from '../../assets/arrow_back.svg'
import export_xls from '../../assets/export_xls.svg'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const REPORT_TYPE_LABELS = {
    ALL: 'All',
    IN: 'IN',
    APT: 'APT',
    OUT: 'OUT',
    I2I: 'I2I',
    M2M: 'M2M'
}

const AdminSearchVehicleInfoPage = () => {

    const history = useHistory()
    const { company, branch, user } = useContext(SessionContext)
    const [isLoading, setIsLoading] = useState(false)
    const [reportType, setReportType] = useState(REPORT_TYPE_LABELS.IN)
    const [transfers, setTransfers] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [searchType, setSearchType] = useState(SEARCH_TYPES.VEHICLE_NUMBER.key)
    const [nricType, setNricType] = useState(NRIC_TYPES.SELLER.key)
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, showSizeChanger: false, showQuickJumper: false });
    const [sortBy, setSortBy] = useState('1D')
    const [cachedSearchData, setCachedSearchData] = useState({})
    const [doRefreshData, setDoRefreshData] = useState(false)
    const [doRefreshDataForRecover, setDoRefreshDataForRecover] = useState(false)

    const [searchForm] = Form.useForm();

    const ReceiptType = {
        vehicleDealerCopy : '1',
        confirmationSlip : '2',
        dealerCopy : '3',
    }

    useEffect(async () => {

        if (doRefreshData === true) {

            setDoRefreshData(false)
            setIsLoading(true);

            try {

                const res = await report.api(
                    cachedSearchData.currentPage,
                    cachedSearchData.search,
                    cachedSearchData.searchParam,
                    cachedSearchData.status
                );

                if (res) {

                    setTransfers(res);
                } else {
                    setTransfers([]);
                    setPagination(prevPagination => ({
                        ...prevPagination,
                        total: 0,
                    }));
                }

            } catch (err) {
                message.error(err.toString());
                setPagination(prevPagination => ({
                    ...prevPagination,
                    total: 0,
                }));
            } finally {
                setIsLoading(false);
            }

        }
        else if (doRefreshDataForRecover === true) {
            setDoRefreshDataForRecover(false)
            setIsLoading(true);

            try {

                const res = await report.api(
                    1,
                    cachedSearchData.search,
                    cachedSearchData.searchParam,
                    cachedSearchData.status
                );

                if (res) {

                    setTransfers(res);
                } else {
                    setTransfers([]);
                    setPagination(prevPagination => ({
                        ...prevPagination,
                        total: 0,
                    }));
                }

            } catch (err) {
                message.error(err.toString());
                setPagination(prevPagination => ({
                    ...prevPagination,
                    total: 0,
                }));
            } finally {
                setIsLoading(false);
            }

        }

    }, [cachedSearchData, doRefreshData, doRefreshDataForRecover]);

    useEffect(() => {
        getData(pagination.current);
    }, [sortBy])

    useEffect(() => {
        setTransfers([])
        setPagination(prevPagination => ({
            ...prevPagination,
            total: 0,
        }));
    }, [reportType])

    const handleTableChange = (pagination_, filters, sorter) => {

        const sortMap = {
            'createdDate-ascend': '1A',
            'createdDate-descend': '1D',
            'carRegistrationNo-ascend': '2A',
            'carRegistrationNo-descend': '2D',
        };

        const sortKey = sorter.field + '-' + sorter.order;
        const newSortBy = sortMap[sortKey] || null;
        // if (newSortBy) setSortBy(newSortBy);
        setSortBy(newSortBy);

        const isUserClickPagination = pagination_.current !== pagination.current;
        setPagination(pagination_);

        if (Object.keys(branch).length > 0 && isUserClickPagination) {
            getData(pagination_.current);
        }
    };

    const getData = async (currentPage, reportType) => {
        const reportModule = reportType ? REPORT_TYPES[reportType] : report 
        const {
            searchType,
            searchTerm,
            nricType,
            createdDate,
            transactionStatus
        } = searchForm.getFieldsValue();

        let search = searchType
        let searchParam
        let status = transactionStatus

        if (search === SEARCH_TYPES.NRIC.key) search = nricType
        if (search === SEARCH_TYPES.VEHICLE_NUMBER.key
            || search === SEARCH_TYPES.ROC_NUMBER.key
            || search === NRIC_TYPES.BUYER.key
            || search === NRIC_TYPES.SELLER.key) {

            if (!searchTerm.trim()) {
                // setPagination(prevPagination => ({
                //     ...prevPagination,
                //     total: 0,
                // }));
                // return
                search = ''
            }

            searchParam = searchTerm

        }

        if (search === SEARCH_TYPES.TRANSACTION_DATE.key) {

            if (!createdDate) {
                // setPagination(prevPagination => ({
                //     ...prevPagination,
                //     total: 0,
                // }));
                // return
                search = ''
            }

            searchParam = moment(createdDate).format('YYYY-MM-DD')
        }

        setIsLoading(true);

        try {

            const res = await reportModule.api(
                currentPage,
                search,
                searchParam,
                status
            );

            if (res) {

                setTransfers(res);
            } else {
                setTransfers([]);
                setPagination(prevPagination => ({
                    ...prevPagination,
                    total: 0,
                }));
            }

        } catch (err) {
            message.error(err.toString());
            setPagination(prevPagination => ({
                ...prevPagination,
                total: 0,
            }));
        } finally {
            setIsLoading(false);
        }
    };

    const filterSearchBy = () => {

        getData(1);
        searchForm.setFieldsValue({
            searchTerm: ''
        })

    };

    useEffect(() => {

        const datas = transfers.map(t => {
            return {
                key: t.id,
                ...t,
            }
        })

        setDataSource(datas)

    }, [transfers])

    useEffect(() => {
        setColumns(prevColumns => {
            if (reportType === 'I2I' || reportType === 'OUT') {
                const updatedColumns = [
                    ...prevColumns.filter(column => column.dataIndex !== 'dayRemaining').slice(0, 7),  // Columns before index 7
                    {
                        title: 'Day Remaining',
                        dataIndex: 'dayRemaining',
                        // sorter: (a, b) => a.dayRemaining - b.dayRemaining,
                        render: dayRemaining => dayRemaining > 1 ? `${dayRemaining} days` : dayRemaining > 0 ? `${dayRemaining} day` : "Expired"
                    },
                    ...prevColumns.filter(column => column.dataIndex !== 'dayRemaining').slice(7),  // Columns after index 7
                ];
                return updatedColumns;
            } else {
                const filteredColumns = prevColumns.filter(column => column.dataIndex !== 'dayRemaining');
                return filteredColumns;
            }
        });
    }, [reportType]);

    const InputSearchBy = (props) => {
        const { style } = props
        return (
            <Col span={12}>
                <div
                    style={{
                        font: "italic normal normal 16px/22px Open Sans",
                        color: "white",
                        marginBottom: "7px",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    Search
                </div>
                <Form
                    className='select-search-wrapper'
                    layout="inline"
                    initialValues={{
                        searchType: SEARCH_TYPES.VEHICLE_NUMBER.key,
                        nricType: NRIC_TYPES.SELLER.key,
                        transactionType: REPORT_TYPE_LABELS.IN,
                        searchTerm: "",
                    }}
                    form={searchForm}
                    onFinish={filterSearchBy}
                    style={style}
                >

                    <Form.Item
                        className="form-item"
                        name="transactionStatus"
                        initialValue={'All'}
                    >
                        <Select
                            style={{ width: "100%", maxWidth: 200 }}
                        >
                            <Select.Option key={'All'} value={'All'}>{'All Status'}</Select.Option>
                            {
                                reportType === REPORT_TYPE_LABELS.M2M &&
                                Object.keys(M2M_TRANSACTION_STATUSES).map((key) => {
                                    return (
                                        <Select.Option key={key} value={M2M_TRANSACTION_STATUSES[key]}>
                                            {key}
                                        </Select.Option>
                                    );
                                })
                            }
                            {
                                reportType === REPORT_TYPE_LABELS.IN &&
                                Object.keys(IN_TRANSACTION_STATUSES).map((key) => {
                                    return (
                                        <Select.Option key={key} value={IN_TRANSACTION_STATUSES[key]}>
                                            {key}
                                        </Select.Option>
                                    );
                                })
                            }


                            {
                                reportType === REPORT_TYPE_LABELS.APT &&
                                Object.keys(APT_TRANSACTION_STATUSES).map((key) => {
                                    return (

                                        <Select.Option key={key} value={APT_TRANSACTION_STATUSES[key]}>
                                            {key}
                                        </Select.Option>
                                    )
                                })
                            }

                            {
                                (reportType === REPORT_TYPE_LABELS.OUT ||
                                    reportType === REPORT_TYPE_LABELS.I2I ) && (
                                    Object.keys(OUT_TRANSACTION_STATUSES).map((key) => {
                                        return (<Select.Option key={key} value={OUT_TRANSACTION_STATUSES[key]}>
                                            {key}
                                        </Select.Option>)
                                    })
                                )
                            }

                        </Select>
                    </Form.Item>

                    <Form.Item className="form-item" name={"searchType"} style={{ width: "160px" }}>
                        <Select
                            value={searchType}
                            onChange={value => {
                                searchForm.resetFields([
                                    'nricType',
                                    'createdDate',
                                    'searchTerm'
                                ]);
                                setSearchType(value)
                            }
                            }
                        >
                            {Object.values(SEARCH_TYPES).map(searchType => {
                                // Check if reportType is 'IN' and the searchType is ROC_NUMBER
                                // if (reportType === REPORT_TYPE_LABELS.IN && searchType.key === 'rocnumber') {
                                //     return null; // Skip rendering this option
                                // }

                                return (
                                    <Select.Option key={searchType.key}>
                                        {searchType.label}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>



                    {
                        searchType === SEARCH_TYPES.NRIC.key &&

                        <Form.Item className="form-item" name={"nricType"} style={{ width: "120px" }}>
                            <Select
                                style={{ width: "100%", maxWidth: 200 }}
                                value={nricType}
                                onChange={value => setNricType(value)}
                            >
                                {Object.values(NRIC_TYPES).map(nricType => (
                                    <Select.Option key={nricType.key}>
                                        {nricType.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                    {
                        searchType === SEARCH_TYPES.TRANSACTION_DATE.key &&

                        <Form.Item name={"createdDate"} className='date-wrapper' style={{ width: "200px" }}>
                            <DatePicker onChange={filterSearchBy} format={'YYYY-MM-DD'} />
                        </Form.Item>

                    }

                    {
                        searchType !== SEARCH_TYPES.TRANSACTION_DATE.key && searchType!== SEARCH_TYPES.VEHICLE_NUMBER.key &&

                        <Form.Item className="date-wrapper" name={"searchTerm"} style={{ width: "200px" }}>
                            <Input.Search
                                allowClear
                                style={{ maxWidth: "100%" }}
                                placeholder={"Search"}
                                enterButton
                                onSearch={filterSearchBy}
                                onChange={e => {
                                    searchForm.setFieldsValue({
                                        searchTerm: e.target.value.toUpperCase()
                                    })
                                }}
                            />
                        </Form.Item>

                    }

                    {
                        searchType === SEARCH_TYPES.VEHICLE_NUMBER.key &&

                        <Form.Item className="date-wrapper" name={"searchTerm"} style={{ width: "200px" }}>
                            <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
                                <Input.Search
                                    allowClear
                                    style={{ maxWidth: "100%" }}
                                    placeholder={"Search"}
                                    enterButton
                                    onSearch={filterSearchBy}
                                    onChange={e => {
                                        searchForm.setFieldsValue({
                                            searchTerm: e.target.value.toUpperCase()
                                        })
                                    }}
                                />
                            </Tooltip>
                        </Form.Item>
                    }

                </Form>
            </Col>
        );
    }

    const TransactionTypeDropdown = () => {
        return (
            <Col span={12}>
                <Form layout="vertical">
                    <Form.Item style={{width: "100%"}} label="Transaction Type" className="dropdown-wrapper">
                        <Select
                            value={reportType}
                            onChange={async (search) => {
                                searchForm.resetFields([
                                    'transactionStatus',
                                    'searchType',
                                    'nricType',
                                    'createdDate',
                                    'searchTerm'
                                ]);
                                setReportType(search);
                                setSearchType(SEARCH_TYPES.VEHICLE_NUMBER.key)
                                if(search === REPORT_TYPE_LABELS.APT) {
                                    setSortBy("1A")
                                }
                                getData(1, search)
                            }}
                        >
                            {Object.keys(REPORT_TYPES).map((key) => {
                                // Use a conditional return to skip the option if the key is 'OUT'
                                // if (key !== REPORT_TYPE_LABELS.M2M && key !== REPORT_TYPE_LABELS.OUT && key !== REPORT_TYPE_LABELS.ALL) {
                                    return <Select.Option key={key}>{REPORT_TYPES[key].label.toLocaleUpperCase()}</Select.Option>;
                                // }
                                // return null; // Do not render anything for 'OUT'
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Col>
        );
    }

    function validateAndTrimSearchQuery(searchTerm) {
		if (typeof searchTerm === 'undefined' || searchTerm === undefined) {
			searchTerm = ""; // Assign empty string if searchTerm is undefined
		}
        return searchTerm.replace(/\s/g, "");
	}

    const onRecoverExpiredTransaction = async (trfId, type) => {
        await recoverExpiredTransaction(trfId, type)
        .then((res)=> {
            if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) {
                throw(res.statusMSG)
            }
        })
        .catch((err)=>{
            err && message.error(err.toString())
        })
        .finally(()=>{
            setDoRefreshDataForRecover(true)
            return
        })
    }

    const onInquiryWithJpj = async (trfId, type) => {
        setIsLoading(true)
        if(type === "IN") {
            await InTransferInquiryWithJPJ(trfId)
            .then((res) => {

                if (res.inquiryStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) {
                    if (res.inquiryMSG == 'UNKNOW ERROR') {
                        throw("Unable to process JPJ submission")
                    } else {
                        throw(res.inquiryMSG)
                    }
                }
                else {
                    message.success(res.inquiryMSG)
                }

            }).catch((err) => {
                err && message.error(err.toString())
            }).finally(() => {
                setDoRefreshData(true)
                return
            })
        }
        else if (type === "M2M") {
            const saveBuyerPayload = {
                transferId: trfId,
                uId: "",
                moduleType: "",
            }

            await m2mProceed(saveBuyerPayload)
            .then((res) => {
                if (res.inquiryStatus !== '00') {
                    throw(res.inquiryMSG)
                }
                else {
                    message.success(res.inquiryMSG)
                }
            }).catch((err) => {
                err && message.error(err.toString())
            }).finally(() => {
                setDoRefreshData(true)
                return
            })
        }
        else if (type === "OUT") {
            await saveOutSellerBuyerInquiry(user.nric, trfId).then(async res => {
    
                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                else {
                    message.success(res.statusMSG)
                }
            }).catch(err => {
                message.error(err.toString())
            }).finally(() => {
                setDoRefreshData(true)
                return
            })
        }
        else if (type === "I2I") {
            await i2iSaveOutSellerBuyerInquiry(trfId).then(async res => {
    
                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                else {
                    message.success(res.statusMSG)
                }
            }).catch(err => {
                message.error(err.toString())
            }).finally(() => {
                setDoRefreshData(true)
                return
            })
        }
        setIsLoading(false)
    }

    const REPORT_TYPES = {
        // [REPORT_TYPE_LABELS.ALL]: {
        //     label: REPORT_TYPE_LABELS.ALL
        // },
        [REPORT_TYPE_LABELS.IN]: {
            label: REPORT_TYPE_LABELS.IN,
            api: async (currentPage, search, searchParam, status) => {

                try {
                    if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                        searchParam = validateAndTrimSearchQuery(searchParam);
                    }
                    let res = await getInTxnForAdminPost(
                        '',
                        '',
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {

                        setCachedSearchData({
                            currentPage: currentPage,
                            search: search,
                            searchParam: searchParam,
                            status: status
                        })

                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));

                        let results = res.data
                            .map(async (res) => {

                                res.transactionTypeModule = "IN"
                                return res
                            })

                        results = await Promise.all(results)

                        return results;

                    }

                    return [];

                } catch (err) {
                    throw err;
                }

            },
        },
        [REPORT_TYPE_LABELS.OUT]: {
            label: REPORT_TYPE_LABELS.OUT,
            api: async (currentPage, search, searchParam, status) => {

                try {
                    if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                        searchParam = validateAndTrimSearchQuery(searchParam);
                    }
                    let res = await getOutTxnForAdminPost(
                        '',
                        '',
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {

                        setCachedSearchData({
                            currentPage: currentPage,
                            search: search,
                            searchParam: searchParam,
                            status: status
                        })

                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));

                        let results = res.data
                            .map(async (res) => {

                                res.transactionTypeModule = "OUT"
                                return res
                            })

                        results = await Promise.all(results)

                        return results;

                    }

                    return [];

                } catch (err) {
                    throw err;
                }

            },
        },
        [REPORT_TYPE_LABELS.I2I]: {
            label: REPORT_TYPE_LABELS.I2I,
            api: async (currentPage, search, searchParam, status) => {

                try {
                    if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                        searchParam = validateAndTrimSearchQuery(searchParam);
                    }
                    let res = await getI2iTxnForAdminPost(
                        '',
                        '',
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {

                        setCachedSearchData({
                            currentPage: currentPage,
                            search: search,
                            searchParam: searchParam,
                            status: status
                        })

                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));

                        let results = res.data
                            .map(async (res) => {

                                res.transactionTypeModule = "I2I"
                                return res
                            })

                        results = await Promise.all(results)

                        return results;
                    }

                    return []

                } catch (err) {
                    throw err;
                }

            },
        },
        [REPORT_TYPE_LABELS.M2M]: {
            label: REPORT_TYPE_LABELS.M2M,
            api: async (currentPage, search, searchParam, status) => {

                try {
                    if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                        searchParam = validateAndTrimSearchQuery(searchParam);
                    }
                    let res = await getM2mTxnForAdminPost(
                        '',
                        '',
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {

                        setCachedSearchData({
                            currentPage: currentPage,
                            search: search,
                            searchParam: searchParam,
                            status: status
                        })

                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));

                        let results = res.data
                            .map(async (res) => {

                                res.transactionTypeModule = "M2M"
                                return res
                            })

                        results = await Promise.all(results)

                        return results;

                    }

                    return [];

                } catch (err) {
                    throw err;
                }

            },
        },
        [REPORT_TYPE_LABELS.APT]: {
            label: REPORT_TYPE_LABELS.APT,
            api: async (currentPage, search, searchParam, status) => {

                try {
                    if (search === SEARCH_TYPES.VEHICLE_NUMBER.key) {
                        searchParam = validateAndTrimSearchQuery(searchParam);
                    }
                    let res = await getAptTxnForAdminPost(
                        '',
                        '',
                        pagination.pageSize,
                        currentPage,
                        search,
                        searchParam,
                        sortBy,
                        status
                    );

                    if (Array.isArray(res.data)) {

                        setCachedSearchData({
                            currentPage: currentPage,
                            search: search,
                            searchParam: searchParam,
                            status: status
                        })

                        setPagination((prevPagination) => ({
                            ...prevPagination,
                            total: res.count,
                            current: res.currentPage,
                        }));

                        let results = res.data
                            .map(async (res) => {

                                res.transactionTypeModule = "APT"
                                return res
                            })

                        results = await Promise.all(results)

                        return results;
                    }

                    return []


                } catch (err) {
                    throw err;
                }

            },
        }
    }

    const report = REPORT_TYPES[reportType]

    const [columns, setColumns] = useState([
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            render: createdDate => parseTime(moment(createdDate).valueOf()),
            sorter: (a, b) => moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf(),
            // defaultSortOrder: 'descend'
        },
        {
            title: 'Vehicle Number',
            dataIndex: 'carRegistrationNo',
            sorter: (a, b) => a.carRegistrationNo.localeCompare(b.carRegistrationNo),
        },
        {
            title: 'Engine Number',
            dataIndex: 'engineNo',
            // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
            render: (engineNo) => {
                return engineNo ? engineNo : "-"
            }
        },
        {
            title: 'Chasis Number',
            dataIndex: 'chassisNo',
            // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
            render: (chassisNo) => {
                return chassisNo ? chassisNo : "-"
            }
        },
        {
            title: 'Seller Name',
            dataIndex: 'sellerName',
            // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
            render: (sellerName, rowData) => {
                /* if (rowData.transactionTypeModule == "OUT") {
                    return company.coName
                } */
                return sellerName ? sellerName : "-"
            }
        },
        /* {
            title: 'Seller IC Number',
            dataIndex: 'sellerNRIC',
            // sorter: (a, b) => a.sellerNRIC.localeCompare(b.sellerNRIC),
            render: (sellerNRIC) => {
                return sellerNRIC ? sellerNRIC : "-"
            }
        }, */
        {
            title: 'Buyer Name',
            dataIndex: 'buyerName',
            // sorter: (a, b) => a.buyerName.localeCompare(b.buyerName),
            render: (buyerName, rowData) => {
                /* if (rowData.transactionTypeModule == "OUT") {
                    return company.coName
                } */
                return buyerName ? buyerName : "-"
            }
        },
        /* {
            title: 'Buyer IC Number',
            dataIndex: 'buyerNRIC',
            // sorter: (a, b) => a.buyerNRIC.localeCompare(b.buyerNRIC),
            render: (buyerNRIC) => {
                return buyerNRIC ? buyerNRIC : "-"
            }
        }, */
        {
            title: 'Status',
            dataIndex: 'status',
            width: 200,
            render: (status, rowData) => {

                let label, color

                switch (rowData.transactionTypeModule) {
                    case "IN":
                        if (rowData.isM2mTransaction && rowData.failCode === "" && rowData.status === 2) {
                            label = "Pending M2M Approval"
                            color = "blue"
                        }
                        else {
                            label = IN_TRANSACTION_STATUSES_LABELS[status] || `Unknown status`
                            color = IN_TRANSACTION_STATUSES_COLORS[status]
                        }
                        break
                    case "M2M":
                        if (rowData.isM2mTransaction && rowData.failCode === "" && rowData.status === 2) {
                            label = "Pending M2M Approval"
                            color = "blue"
                        }
                        else {
                            label = IN_TRANSACTION_STATUSES_LABELS[status] || `Unknown status`
                            color = IN_TRANSACTION_STATUSES_COLORS[status]
                        }
                        break
                    case "APT":
                        label = APT_TRANSACTION_STATUSES_LABELS[rowData.aptStatus] || `Unknown status`
                        color = APT_TRANSACTION_STATUSES_COLORS[rowData.aptStatus]
                        break
                    default:
                        label = OUT_TRANSACTION_STATUSES_LABELS[status] || `Unknown status`
                        color = OUT_TRANSACTION_STATUSES_COLORS[status]
                }

                return <Tag color={color}>{label}</Tag>
            },
            // sorter: (a, b) => a.status - b.status,
        },
        {
            title: 'JPJ Response Message',
            dataIndex: 'failCode',
            width: 300,
            // sorter: (a, b) => a.failCode.localeCompare(b.failCode),
            render: (text) => <span dangerouslySetInnerHTML={{ __html: text }}></span>,
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (rowData) => {

                /**
                 * Two buttons in this "Action" tab: VIEW, AUDIT TRAIL LOG
                 * 
                 * FOR AUDIT TRAIL LOG:
                 * ---- REGARDING SERVICE ID ----
                 * * IN Module -> Etms
                 * * OUT Module, M2M transactions -> Etms
                 * * REST OF OUT Module -> stms
                 * * i2i Module -> stms
                 * ---- END REGARDING SERVICE ID ----
                 * 
                 */
                let url = ''

                const hideEditIn = [
                    IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS
                ]

                let hideEditOut = []

                Object.values(OUT_TRANSACTION_STATUSES).filter(value => {
                    // Include values 1, 3, 4, 6, 7, 8
                    if ([
                        OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS,
                        OUT_TRANSACTION_STATUSES.PENDING_PAYMENT,
                        OUT_TRANSACTION_STATUSES.SUCCESS,
                        // OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
                        // OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
                        OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL
                    ].includes(value)) {
                        hideEditOut.push(value);
                    }
                })

                if (rowData.transactionType
                    && rowData.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I
                    || rowData.transactionType == OUT_TRANSACTION_TYPES_IDS.D2C) {
                    hideEditOut = hideEditOut.filter(status =>
                        // status !== OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED &&
                        status !== OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
                    )
                }

                switch (rowData.transactionTypeModule) {
                    case "IN":
                        if(rowData.isM2mTransaction && rowData.status === 2) {
                            return ( <>
                            <Button
                                style={{
                                    border: "1px solid #2B61C4",
                                    color: "#2B61C4",
                                    borderRadius: "4px",
                                    marginBottom: 10,
                                    marginRight: 10,
                                }}
                                onClick={async () => {
                                    history.push("/transfer/m2m", {
                                        rowData: rowData,
                                        type: REPORT_TYPE_LABELS.M2M,
                                        isReport: true,
                                        isBulk: false,
                                        fromAdminPage: true,
                                        // isJpjSubmitted: rowData.failCode !== "",
                                        isJpjSubmitted: true,
                                    });
                                }}
                                >
                                    <>
                                        <ReadOutlined />
                                        <span className='img-btn-text'>VIEW</span>
                                    </>
                            </Button>
                            <Button
                                style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                onClick={() => {
                                    history.push("/transfer/m2m", {
                                        rowData: rowData,
                                        type: REPORT_TYPE_LABELS.OUT,
                                        isVehicleEdit: true,
                                        fromAdminPage: true,
                                    });
                                }}
                            >
                                <>
                                    <EditOutlined />
                                    <span className='img-btn-text'>EDIT</span>
                                </>
                            </Button>

                            {
                                rowData.status === 2 && rowData.failCode !== "" &&
                                <Button
                                    style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                    onClick={() => {
                                        onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                    }}
                                >
                                    <SearchOutlined />
                                    INQUIRY
                                </Button>
                            }
                            </>)
                        }

                        url += '/transfer/in'

                        return (
                            <div>
                                <Button
                                    style={{
                                        border: "1px solid #2B61C4",
                                        color: "#2B61C4",
                                        borderRadius: "4px",
                                        marginBottom: 10,
                                        marginRight: 10
                                    }}
                                    onClick={() => {

                                        url += '/printslip'

                                        history.push(url, {
                                            isM2M: rowData.isM2mTransaction ?? false,
                                            isReport: false,
                                            oldIsReport: true,
                                            fromPendingPage: false,
                                            fromAdminPage: true,
                                            transferId: rowData.id,
                                            jpjErrorMsg: rowData.failCode,
                                            isStatusFail: rowData.status === 2,
                                        });
                                    }}
                                >
                                    <ReadOutlined />
                                    VIEW
                                </Button>

                                {
                                    hideEditIn.includes(rowData.status) == false
                                    &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {

                                            url += '/vehicleinfo'

                                            history.push(url, {
                                                fromAdminPage: true,
                                                transferId: rowData.id,
                                            })
                                        }}
                                    >
                                        <ReadOutlined />
                                        EDIT
                                    </Button>
                                }

                                {
                                    rowData.status === 2 && rowData.failCode !== "" &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <SearchOutlined />
                                        INQUIRY
                                    </Button>
                                }

                                {
                                    rowData.status === 2 &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <SearchOutlined />
                                        REVERT
                                    </Button>
                                }

                            </div>
                        );

                    case "M2M":
                        if(rowData.isM2mTransaction && rowData.status === 2) {
                            return ( <>
                            <Button
                                style={{
                                    border: "1px solid #2B61C4",
                                    color: "#2B61C4",
                                    borderRadius: "4px",
                                    marginBottom: 10,
                                    marginRight: 10,
                                }}
                                onClick={async () => {
                                    history.push("/transfer/m2m", {
                                        rowData: rowData,
                                        type: REPORT_TYPE_LABELS.M2M,
                                        isReport: true,
                                        isBulk: false,
                                        fromAdminPage: true,
                                        // isJpjSubmitted: rowData.failCode !== "",
                                        isJpjSubmitted: true,
                                    });
                                }}
                                >
                                    <>
                                        <ReadOutlined />
                                        <span className='img-btn-text'>VIEW</span>
                                    </>
                            </Button>
                            <Button
                                style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                onClick={() => {
                                    history.push("/transfer/m2m", {
                                        rowData: rowData,
                                        type: REPORT_TYPE_LABELS.OUT,
                                        isVehicleEdit: true,
                                        fromAdminPage: true,
                                    });
                                }}
                            >
                                <>
                                    <EditOutlined />
                                    <span className='img-btn-text'>EDIT</span>
                                </>
                            </Button>
                            {
                                rowData.failCode !== "" &&
                                <Button
                                    style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                    onClick={() => {
                                        onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                    }}
                                >
                                    <SearchOutlined />
                                    INQUIRY
                                </Button>
                            }
                            </>)
                        }
                        else {
                            url += '/transfer/in'
    
                            return (
                                <div>
                                    <Button
                                        style={{
                                            border: "1px solid #2B61C4",
                                            color: "#2B61C4",
                                            borderRadius: "4px",
                                            marginBottom: 10,
                                            marginRight: 10
                                        }}
                                        onClick={() => {
    
                                            url += '/printslip'
    
                                            history.push(url, {
                                                isM2M: rowData.isM2mTransaction ?? false,
                                                isReport: false,
                                                oldIsReport: true,
                                                fromPendingPage: false,
                                                fromAdminPage: true,
                                                transferId: rowData.id,
                                                jpjErrorMsg: rowData.failCode
                                            });
                                        }}
                                    >
                                        <ReadOutlined />
                                        VIEW
                                    </Button>
    
                                    {
                                        hideEditIn.includes(rowData.status) == false
                                        &&
                                        <Button
                                            style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                            onClick={() => {
    
                                                url += '/vehicleinfo'
    
                                                history.push(url, {
                                                    fromAdminPage: true,
                                                    transferId: rowData.id,
                                                })
                                            }}
                                        >
                                            <ReadOutlined />
                                            EDIT
                                        </Button>
                                    }
    
                                </div>
                            );
                        }

                    case "OUT":

                        url += `/transfer/out`

                        return (
                            <div>
                                {
                                    (rowData.status !== OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining > 0)
                                            ||
                                            (rowData.status === OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining > 0)
                                            ||
                                            (rowData.status === OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining == 0)
                                            ?(
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={async () => {

                                            url += '/printslip'

                                            history.push(url, {
                                                isReport: false, // if there is a pendingType, which means this button is being pressed from Pending List page
                                                oldIsReport: true,
                                                fromPendingPage: false,
                                                fromAdminPage: true,
                                                transferId: rowData.id,
                                                jpjErrorMsg: rowData.failCode
                                            });
                                        }}
                                    >
                                        <ReadOutlined />
                                        VIEW
                                    </Button>
                                    ):(<></>)
                                }

                                {
                                    rowData.dayRemaining > 0
                                    &&
                                    hideEditOut.includes(rowData.status) == false
                                    &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {

                                            url += '/vehicleinfo'

                                            history.push(url, {
                                                fromAdminPage: true,
                                                transferId: rowData.id,
                                            })

                                        }}
                                    >
                                        <ReadOutlined />
                                        EDIT
                                    </Button>

                                }
                                {
                                    (rowData.status === 6 || rowData.status === 7 || rowData.status === 2) && rowData.dayRemaining !== 0 ?
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <SearchOutlined />
                                        INQUIRY
                                    </Button> : <></>
                                }

                                {
                                    rowData.status !== 4 && rowData.dayRemaining === 0 ?
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onRecoverExpiredTransaction(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <RedoOutlined />
                                        RECOVER
                                    </Button> : <></>
                                }

                                {
                                    rowData.dayRemaining > 0
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.SUCCESS
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                    &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            setIsLoading(true);
                                            revertStatus('', rowData.id)
                                                .then(res => {
                                                    if (res.statusCode === "00") {
                                                        message.success(res.statusMSG);
                                                        setDoRefreshData(true);
                                                    }
                                                    else if (res.statusCode === "01") {
                                                        message.error(res.statusMSG);
                                                        setDoRefreshData(true);
                                                    }
                                                })
                                                .catch(err => {
                                                    err && message.error(err.toString());
                                                })
                                                .finally(() => {
                                                    setIsLoading(false);
                                                });
                                        }}
                                    >
                                        <ReadOutlined />
                                        REVERT
                                    </Button>

                                }

                            </div>
                        )

                    case "I2I":

                        url += '/transfer/i2i'

                        return (
                            <div>
                                {
                                    (rowData.status !== OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining > 0)
                                        ||
                                        (rowData.status === OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining > 0)
                                        ||
                                        (rowData.status === OUT_TRANSACTION_STATUSES.SUCCESS && rowData.dayRemaining == 0)
                                        ?
                                        (
                                            <Button
                                                style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                                onClick={async () => {
                                                    url += '/printslip'
                                                    history.push(url, {
                                                        isReport: false,
                                                        oldIsReport: true,
                                                        fromPendingPage: false,
                                                        fromAdminPage: true,
                                                        transferId: rowData.id,
                                                        jpjErrorMsg: rowData.failCode
                                                    });
                                                }}
                                            >
                                                <ReadOutlined />
                                                VIEW
                                            </Button>
                                        ) : (<></>)
                                }

                                {
                                    rowData.dayRemaining > 0
                                    &&
                                    hideEditOut.includes(rowData.status) == false
                                    &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            url += '/vehicleinfo'

                                            history.push(url, {
                                                fromAdminPage: true,
                                                transferId: rowData.id,
                                            })
                                        }}
                                    >
                                        <ReadOutlined />
                                        EDIT
                                    </Button>
                                }
                                {
                                    (rowData.status === 6 || rowData.status === 7 || rowData.status === 2) && rowData.dayRemaining !== 0 ?
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onInquiryWithJpj(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <SearchOutlined />
                                        INQUIRY
                                    </Button> : <></>
                                }

                                {
                                    rowData.status !== 4 && rowData.dayRemaining === 0 ?
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            onRecoverExpiredTransaction(rowData.id, rowData.transactionTypeModule)
                                        }}
                                    >
                                        <RedoOutlined />
                                        RECOVER
                                    </Button> : <></>
                                }

                                {
                                    rowData.dayRemaining > 0
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.SUCCESS
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_SELLER_INFO
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP
                                    &&
                                    rowData.status !== OUT_TRANSACTION_STATUSES.PENDING_FOR_APPROVAL_SUBMISSION
                                    &&
                                    <Button
                                        style={{ border: "1px solid #2B61C4", color: "#2B61C4", borderRadius: "4px", marginBottom: 10, marginRight: 10 }}
                                        onClick={() => {
                                            setIsLoading(true);
                                            revertStatus('', rowData.id)
                                                .then(res => {
                                                    if (res.statusCode === "00") {
                                                        message.success(res.statusMSG);
                                                        setDoRefreshData(true);
                                                    }
                                                    else if (res.statusCode === "01") {
                                                        message.error(res.statusMSG);
                                                        setDoRefreshData(true);
                                                    }
                                                })
                                                .catch(err => {
                                                    err && message.error(err.toString());
                                                })
                                                .finally(() => {
                                                    setIsLoading(false);
                                                });
                                        }}
                                    >
                                        <ReadOutlined />
                                        REVERT
                                    </Button>

                                }
                            </div>
                        )
                    case "APT":

                        if (rowData.aptStatus === APT_TRANSACTION_STATUSES.SUCCESS) {
                            return (
                                rowData.isNewAptPrice ?
                                <div>
                                    <Tooltip title="Vehicle Dealer Copy">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.vehicleDealerCopy)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Vehicle Dealer Copy
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Confirmation Slip">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.confirmationSlip)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Confirmation Slip
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Dealer Copy">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getV2ReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo, ReceiptType.dealerCopy)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            Dealer Copy
                                        </Button>
                                    </Tooltip>
                                </div>
                                :
                                <div>
                                    <Tooltip title="APT Receipt">
                                        <Button
                                            style={{
                                                border: "1px solid #2B61C4",
                                                color: "#2B61C4",
                                                borderRadius: "4px",
                                                marginBottom: 10,
                                                marginRight: 10
                                            }}
                                            onClick={() => {
                                                getReceiptAPT(company.id, rowData.id, rowData.carRegistrationNo)
                                            }}
                                        >
                                            <PrinterOutlined />
                                            APT Receipt
                                        </Button>
                                    </Tooltip>
                                </div>
                            );
                        }
                        break;
                    default:
                        return (<></>);
                }
            }
        }
    ]);

    return (
        <>
            <div className="page-content">
                <>
                    <PageHeader
                        style={{ paddingLeft: "0px", marginTop: "40px" }}
                        className="site-page-header"
                        title={"Search Vehicle Information"}
                    />
                    <Row>
                        <Col span={12}><TransactionTypeDropdown /></Col>
                        <InputSearchBy style={{ display: "flex", justifyContent: "flex-end", marginBottom: "24px" }} />
                    </Row>
                </>
                <Table
                    className="report-table"
                    size="small"
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    scroll={{
                        x: columns.length * 150,
                    }}
                    onChange={handleTableChange}
                    pagination={pagination}
                />
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                    }}
                >
                    <Button
                        className="back-button buttons"
                        shape="round"
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <Image src={arrow_back} preview={false} />
                        Back
                    </Button>
                </div>
            </div>
            <style>
                {`
                 .ant-select  {
                    max-width: 100% !important;
                }
                
                .ant-select > .ant-select-selector {
                    height: 48px !important;
                    display: flex !important;
                    align-items: center !important;
                }
                .footer-button {
                    border: 1px solid #2B61C4;
                    color: #2B61C4;
                    margin-right: 20px;
                    margin-left: 0px;
                }
            `}
            </style>
        </>
    );
}

export default AdminSearchVehicleInfoPage