import React, { useContext, useState, useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { Button, Col, Form, Input, message, Row, Spin } from "antd";

import {
    checkPhoneNumberStaff,
    sendSmsOtp,
    verifySmsOtp,
    updatePhoneNumber,
} from "../../../../services/api";
import { getDecryptedVerifyOtpResponse } from "../../../../util/index";

import { SessionContext } from "../../../../App";

import "./index.css"; // Import your custom CSS file

export default function UpdateMobileNoComponent({ postMobileUpdate }) {
    const [changeNoForm] = useForm();
    const { user } = useContext(SessionContext);

    const [countdown, setCountdown] = useState(0);
    const [sendCodeDisabled, setSendCodeDisabled] = useState(true);
    const [inputCodeDisabled, setInputCodeDisabled] = useState(true);
    const [sendingCode, setSendingCode] = useState(false);
    const [isUpdatingPhoneNo, setIsUpdatingPhoneNo] = useState(false);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            setSendCodeDisabled(true);
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else {
            setSendCodeDisabled(false);
            changeNoForm.resetFields(["authCode"]);
            setSendCodeDisabled(false);
            setInputCodeDisabled(true);
        }

        return () => clearInterval(timer);
    }, [countdown]);

    const mobileNoValues = Form.useWatch([], changeNoForm);

    useEffect(() => {
        changeNoForm
            .validateFields(["newPhoneNo"], {
                validateOnly: true,
            })
            .then(
                (value) =>
                    value && value?.newPhoneNo && setSendCodeDisabled(false)
            )
            .catch(() => setSendCodeDisabled(true));
    }, [changeNoForm, mobileNoValues]);

    const handleSendCode = async () => {
        setSendingCode(true);

        const phone = changeNoForm.getFieldValue("newPhoneNo");
        try {
            const checkNoResponse = await checkPhoneNumberStaff({
                phone,
                nric: user?.nric,
            });
            if (
                checkNoResponse &&
                checkNoResponse?.message &&
                checkNoResponse?.isUpdateRequired == false
            ) {
                const sendOtpResponse = await sendSmsOtp(user?.nric, 1, phone);
                if (sendOtpResponse && sendOtpResponse?.status) {
                    setSendCodeDisabled(true);
                    setInputCodeDisabled(false);
                    setCountdown(120);
                    message.success(sendOtpResponse?.message);
                } else {
                    throw new Error(sendOtpResponse?.message);
                }
            } else {
                throw new Error(checkNoResponse?.message);
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            setSendingCode(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} min ${remainingSeconds
            .toString()
            .padStart(2, "0")} sec`;
    };

    const changePhoneNo = async () => {
        setIsUpdatingPhoneNo(true);
        try {
            const smsOtp = changeNoForm.getFieldValue("authCode");

            //Check OTP valid
            const checkNoResponse = await verifySmsOtp(smsOtp);
            const decryptedResponse = getDecryptedVerifyOtpResponse(
                checkNoResponse.encryptedString
            );

            if (decryptedResponse.status == "false")
                throw new Error(decryptedResponse?.message);

            //Proceed to update phone number
            const newPhoneNo = changeNoForm.getFieldValue("newPhoneNo");
            const updateResponse = await updatePhoneNumber(
                user?.nric,
                newPhoneNo
            );

            if (updateResponse && updateResponse?.status) {
                message.success(updateResponse?.message);
                setTimeout(async () => {
                    await postMobileUpdate();
                    setIsUpdatingPhoneNo(false);
                }, 2000);
            } else {
                throw new Error(updateResponse?.message);
            }
        } catch (error) {
            message.error(error?.message);
            setIsUpdatingPhoneNo(false);
        }
    };

    return (
        <>
            <h2
                style={{
                    fontWeight: "bold",
                    textAlign: "center",
                }}
            >
                Change Phone Number
            </h2>
            <Form
                layout="vertical"
                style={{ padding: "0px 20px 0px 20px" }}
                width={200}
                requiredMark={false}
                form={changeNoForm}
                onFinish={() => {
                    changePhoneNo();
                }}
                className="custom-update-mobile-no-form"
            >
                {/* Hidden dummy fields for hiding auto populate*/}
                <div style={{ display: "none" }}>
                    <input type="text" autoComplete="username" />
                    <input type="password" autoComplete="current-password" />
                </div>
                <Form.Item
                    label="New phone number"
                    style={{ color: "grey", fontStyle: "italic" }}
                    name="newPhoneNo"
                    rules={[
                        {
                            required: true,
                            pattern: /^(01)[0-46-9][0-9]{7,8}$/,
                            message: "Please enter a valid phone number",
                        },
                        {
                            required: true,
                            validator: (_, value) => {
                                if (value === String(user?.mobileno)) {
                                    return Promise.reject(
                                        "New phone number cannot be the same as existing mobile number"
                                    );
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input type={"tel"} className="form-input-field" />
                </Form.Item>

                <Form.Item
                    label="Enter the code received from your new phone number"
                    style={{ color: "grey", fontStyle: "italic" }}
                    name="authCode"
                    rules={[
                        {
                            required: true,
                            message: "Please enter your OTP",
                        },
                    ]}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={16}>
                            <Input.Password
                                className="form-input-field"
                                placeholder={"000 000"}
                                visibilityToggle
                                disabled={inputCodeDisabled}
                            />
                        </Col>
                        <Col span={8}>
                            {sendingCode ? (
                                <Spin
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingTop: "10px",
                                    }}
                                />
                            ) : (
                                <Button
                                    id={"show-resend-otp"}
                                    style={{
                                        width: "100%",
                                        background: "white",
                                        border: "none",
                                        color: sendCodeDisabled
                                            ? "#949494"
                                            : "#2B61C4",
                                        textTransform: "uppercase",
                                        cursor: sendCodeDisabled
                                            ? "not-allowed"
                                            : "pointer",
                                    }}
                                    type="secondary"
                                    onClick={() => {
                                        handleSendCode();
                                    }}
                                    disabled={sendCodeDisabled}
                                >
                                    SEND CODE
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form.Item>
                {countdown > 0 && (
                    <p
                        style={{
                            color: "grey",
                            fontStyle: "italic",
                            marginTop: "1em",
                        }}
                    >
                        Code will expire in {formatTime(countdown)}
                    </p>
                )}
                <Form.Item>
                    <Button
                        className="add-new-user-button custom-disabled-button"
                        type="primary"
                        style={{ width: "100%" }}
                        htmlType="submit"
                        loading={isUpdatingPhoneNo}
                        disabled={
                            changeNoForm.getFieldValue("authCode")
                                ? false
                                : true
                        }
                    >
                        Change phone number
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
