import React from "react";
import { Card, Image, Row, Col, Button, message } from "antd";
import successIcon from "../../../../assets/icon-success.svg";
import failedIcon from "../../../../assets/icon-failed.svg";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { LinkButton } from "../../UI/Button";
import { ApprovalStatusSpanWithCustom } from "../../UI/Span";

const styles = {
  label: {
    font: "italic normal normal 16px Open Sans",
    color: "#333333",

    marginBottom: 0,
  },
  value: {
    font: "normal normal 600 16px Open Sans",
    color: "#333333",
  },
};

export const CompanyCardComponent = (sellerCompany, buyer, isBuyerCompany) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      {
        <Card
          style={{
            flex: 3,
            textAlign: "left",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: 4,
          }}
        >
          <>
            <h2
              style={{
                font: "normal normal 600 24px Raleway",
                marginBottom: 24,
              }}
            >
              Dealer
            </h2>
            <p style={styles.value}>
              <b>{sellerCompany.coName ? sellerCompany.coName : "-"}</b>(
              {sellerCompany.coRegNo ? sellerCompany.coRegNo : "-"})
            </p>
            <p style={styles.value}>
              {sellerCompany.contactNo ? sellerCompany.contactNo : "-"}
            </p>
            <p style={styles.value}>
              {sellerCompany.email ? sellerCompany.email : "-"}
            </p>
          </>
        </Card>
      }

      <Card
        style={{
          flex: 3,
          textAlign: "left",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <h2
          style={{ font: "normal normal 600 24px Raleway", marginBottom: 24 }}
        >
          Buyer
        </h2>
        {isBuyerCompany ? (
          <>
            <p style={styles.value}>
              <b>{buyer.coName ? buyer.coName : "-"}</b>(
              {buyer.coRegNo ? buyer.coRegNo : "-"})
            </p>
            <p style={styles.value}>
              {buyer.contactNo ? buyer.contactNo : "-"}
            </p>
            <p style={styles.value}>{buyer.email ? buyer.email : "-"}</p>
          </>
        ) : (
          <>
            <p style={styles.value}>
              <b>{buyer.name ? buyer.name : "-"}</b>
            </p>
            <p style={styles.value}>
              {buyer.identificationNo ? buyer.identificationNo : "-"}
            </p>
            <p style={styles.value}>{buyer.email ? buyer.email : "-"}</p>
          </>
        )}
      </Card>
    </div>
  );
};

//TODO - pending refactor
const successStatus = (isBulk, vehicleNo, totalVehicle, isPayment) => {
  return (
    <>
      <CheckCircleOutlined
        style={{
          color: "#40a05f",
          fontSize: 70,
          marginRight: 20,
        }}
      />
      <div
        style={{
          color: "#40a05f",
          font: "normal normal bold 16px/24px Open Sans",
        }}
      >
        {isBulk ? "All " + totalVehicle + " vehicles" : vehicleNo}
      </div>
      <div
        style={{
          color: "#40a05f",
          font: "normal normal 16px/24px Open Sans",
        }}
      >
        &nbsp;has passed the JPJ verification.
      </div>{" "}
      <div
        style={{
          font: "normal normal 16px/24px Open Sans",
        }}
      >
        &nbsp;You may now proceed with the {isPayment ? "payment" : "approval"}.
      </div>
    </>
  );
};

const failedStatus = (
  isBulk,
  vehicleNo,
  totalVehicle,
  onBackButtonClick,
  isUpdateJpjFailed
) => {
  return (
    <>
      <CloseCircleOutlined
        style={{
          color: "#E53B34",
          fontSize: 70,
          marginRight: 20,
        }}
      />
      <div
        style={{
          color: "#E53B34",
          font: "normal normal bold 16px/24px Open Sans",
        }}
      >
        {isBulk ? "All " + totalVehicle + " vehicles" : vehicleNo}
      </div>
      <div
        style={{
          color: "#E53B34",
          font: "normal normal 16px/24px Open Sans",
        }}
      >
        &nbsp;has failed the{" "}
        {isUpdateJpjFailed ? "JPJ Submission" : "JPJ verification"}.
      </div>{" "}
      {isBulk && (
        <div
          style={{
            font: "normal normal 16px/24px Open Sans",
          }}
        >
          &nbsp;You can retry in
          {pendingSubmissioButton(onBackButtonClick)}
        </div>
      )}
    </>
  );
};

const pendingSubmissioButton = (onBackButtonClick) => {
  return (
    <Button type="link" style={{ padding: 2 }} onClick={onBackButtonClick}>
      <span
        style={{
          textDecoration: "underline",
          font: "normal normal 16px/24px Open Sans",
        }}
      >
        pending submission
      </span>
    </Button>
  );
};

const pendingSubmission = (onBackButtonClick) => {
  return (
    <div
      style={{
        font: "normal normal 16px/24px Open Sans",
      }}
    >
      You can retry in {pendingSubmissioButton(onBackButtonClick)}
    </div>
  );
};

export const VerificationCard = ({
  isSuccess, //single txn
  totalSuccess, //bulk
  totalFailed, //bulk
  isBulk, //indicator if bulk or single txn
  isPayment, //payment or approval
  vehicleNo,
  onBackButtonClick,
  isUpdateJpjFailed,
}) => {
  const isAllSuccess = totalFailed === 0;
  const isAllFailed = totalSuccess === 0;
  const totalVehicle = totalSuccess + totalFailed;
  return (
    <div style={{ width: "100%" }}>
      <Card
        style={{
          height: "50%",
          textAlign: "left",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          // marginBottom: 16,
        }}
      >
        {isBulk && !isAllSuccess && !isAllFailed && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <p>
                  <b>{totalVehicle} vehicles</b> processed for JPJ submission
                </p>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <ApprovalStatusSpanWithCustom
                  isSuccess={false}
                  inquiryCount={totalFailed}
                  message={pendingSubmission(onBackButtonClick)}
                />
              </Col>
              <Col span={12}>
                <ApprovalStatusSpanWithCustom
                  isSuccess={true}
                  inquiryCount={totalSuccess}
                  message={""}
                />
              </Col>
            </Row>
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* <Image
            src={isSuccess ? successIcon : failedIcon}
            alt="Success icon"
            preview={false}
            style={{ paddingRight: "20px" }}
          /> */}
          <div style={{ display: "flex", flex: 4, alignItems: "center" }}>
            {((isBulk && isAllSuccess) ||
              (!isBulk && isSuccess && !isUpdateJpjFailed)) &&
              successStatus(isBulk, vehicleNo, totalVehicle, isPayment)}
            {((isBulk && isAllFailed) ||
              (!isBulk && (!isSuccess || isUpdateJpjFailed))) &&
              failedStatus(
                isBulk,
                vehicleNo,
                totalVehicle,
                onBackButtonClick,
                isUpdateJpjFailed
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export const PaymentApprovalSuccessCard = ({
  isBulk, //indicator if bulk or single txn
  totalVehicle,
  vehicleNo,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <Card
        style={{
          height: "50%",
          textAlign: "left",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          // marginBottom: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CheckCircleOutlined
            style={{
              color: "#40a05f",
              fontSize: 70,
              marginRight: 20,
            }}
          />
          <div
            style={{
              color: "#40a05f",
              font: "normal normal bold 16px/24px Open Sans",
            }}
          >
            Approval of {isBulk ? totalVehicle + " vehicles" : vehicleNo} is
            successful.
          </div>
        </div>
      </Card>
    </div>
  );
};

export const TransactionAmountCard = (
  dataSource,
  totalVehicle,
  currentVehicle,
  isBulk
) => {
  return (
    <div style={{ width: "100%" }}>
      <Card
        className="custom-transaction-card"
        bodyStyle={{ padding: 0, textAlign: "justify" }}
        style={{
          height: "50%",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          // marginBottom: 16,
        }}
        bordered={false}
      >
        {/* First Row */}
        <Row justify="center" style={{ paddingTop: "24px" }}>
          <Col>
            <h3 style={{ textAlign: "center" }}>Ownership transfer for</h3>
            <p style={{ textAlign: "center", fontSize: "1.4em" }}>
              <b>
                {totalVehicle === 1
                  ? currentVehicle?.toUpperCase()
                  : totalVehicle + " vehicles"}
              </b>
            </p>
            <br />
          </Col>
        </Row>

        {/* Second Row */}
        <Row justify="space-between">
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <span>&nbsp;Item Description</span>
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <span>Amount (RM)</span>
          </Col>
        </Row>
        {/* Third Row */}
        <Row style={{ backgroundColor: "#f0f0f0", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <b>Processing Fee</b>
            <br />
            {isBulk ? (
              dataSource.bulkProcessingFee &&
              dataSource.bulkProcessingFee.length > 0 ? (
                <>
                  {dataSource.bulkProcessingFee.map((fee, index) => (
                    <span key={index}>
                      RM{fee.processingAmount.toFixed(2)} x {fee.count} vehicles
                      <br />
                    </span>
                  ))}
                </>
              ) : null
            ) : null}
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.processingAmount
                ? dataSource.processingAmount.toFixed(2)
                : "-"}
            </b>
            <br />
          </Col>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <b>MYEG Service Charge</b>
            <br />
            {isBulk ? <span>RM2.75 x {totalVehicle} vehicles</span> : null}
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.eserviceAmount
                ? dataSource.eserviceAmount.toFixed(2)
                : "-"}
            </b>
          </Col>
        </Row>

        {/* Fourth Row */}
        <Row style={{ backgroundColor: "#a9a9a9", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            Subtotal
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.subTotalAmount
                ? dataSource.subTotalAmount.toFixed(2)
                : "-"}
            </b>
          </Col>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            SST @ 8%
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.sstAmount ? dataSource.sstAmount.toFixed(2) : "-"}
            </b>
          </Col>
          {dataSource.fisAmount ? (
            <>
              <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
                Ownership Claimed Fee
              </Col>
              <Col
                span={12}
                style={{ paddingRight: "20%", textAlign: "right" }}
              >
                <b>
                  {dataSource.fisAmount ? dataSource.fisAmount.toFixed(2) : "-"}
                </b>
              </Col>
            </>
          ) : (
            <></>
          )}
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            JPJ Ownership Transfer Fee
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.jpjAmount ? dataSource.jpjAmount.toFixed(2) : "-"}
            </b>
          </Col>
        </Row>

        {/* Last Row */}
        <Row style={{ backgroundColor: "#4b0082", color: "white", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            Total amount
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <span style={{ fontSize: "large" }}>
              RM{" "}
              {dataSource.totalAmount ? dataSource.totalAmount.toFixed(2) : "-"}
            </span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

<style>
  {`
      .custom-transaction-card {
        text-align: justify !important;
      }
      .ant-card .custom-transaction-card {
        text-align: justify !important;
      }
  `}
</style>;
