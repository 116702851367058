import { EditOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { message, Row, Table, Button, Col, Modal, Card, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  searchI2iTransactions,
  searchOutTransactions,
  searchM2mTransactions,
  searchInTransactions,
  InBulkTransferInquiryWithJPJ,
  checkBulkTransactionDetails,
  bulkProcessPaymentIN,
  OUTBulkTransferInquiryWithJPJ,
  getPrepaidBalance,
  m2mProceedBulk,
  checkOutBulkTransactionDetails,
  bulkProcessPaymentOUT,
} from "../../../services/api";
import {
  IN_TRANSACTION_STATUSES,
  IN_TRANSACTION_STATUSES_COLORS,
  IN_TRANSACTION_STATUSES_LABELS,
  OUT_TRANSACTION_STATUSES,
  OUT_TRANSACTION_STATUSES_COLORS,
  OUT_TRANSACTION_STATUSES_LABELS,
  OUT_TRANSACTION_TYPES,
  OUT_TRANSACTION_TYPES_IDS,
} from "../../../constants";
import { SessionContext } from "../../../App";
//Component
import { VehicleUpdateTableComponent } from "../../component/Layout/TableComponent";
import { BackButton, LinkButton } from "../../component/UI/Button";
import { TableBlueShadowCheckbox } from "../../component/UI/Checkbox";
import { PaginationShowingSpan } from "../../component/UI/Span";
import { VehicleSearchForm } from "../../component/Layout/FormComponent";
import { CustomPaginationComponent } from "../../component/Layout/PaginationComponent";
import { ReactComponent as ArrowElbowIcon } from "../../../assets/icon/Icon-arrow-elbow.svg";
import {
  ApprovalInModal,
  ApprovalStatusModal,
  TransactionAmountModal,
} from "../../component/Layout/ModalComponent";
import { highlightText } from "../../component/Function/HighlightText";
import {
  onApprovalSetAdmin,
  ModuleType,
  validateRolePermission,
} from "../../Utils";
import { async } from "q";
import { getUserRole, getPermissions } from "../../../services/local";

export const REPORT_TYPE_LABELS = {
  ALL: "All",
  IN: "In",
  APT: "APT",
  OUT: "Out",
  I2I: "i2i",
  M2M: "m2m",
};

export const TAB_TYPE = {
  JPJ_SUBMISSION: "1",
  PAYMENT: "2",
  M2M: "3",
};

export const STATUS_TYPE = {
  JPJ_SUBMISSION: "Pending_Submission",
  PAYMENT: "Pending_Payment",
  M2M: "Pending_M2M",
};

export const TAB_TYPE_MESSAGE = {
  1: "JPJ submission",
  2: "Payment",
  3: "M2M",
};

export const NOTIFICAION_APPROVAL_TYPE = {
  BULK_APPROVAL: "APPROVAL",
  BULK_IN_PAYMENT: "IN_PAYMENT",
  BULK_OUT_PAYMENT: "OUT_PAYMENT",
  BULK_I2I_PAYMENT: "I2I_PAYMENT",
  BULK_OUT_SUBMISSION: "OUT_SUBMISSION",
  BULK_I2I_SUBMISSION: "I2I_SUBMISSION",
};

export const SEARCH = "searchall";

export default function CustomPaginationTable({
  pendingType,
  tabType,
  setTabType,
  setJpjSubmissionCount,
  setPaymentCount,
  setM2mCount,
}) {
  const history = useHistory();
  const [searchForm] = useForm();
  const { company, branch } = useContext(SessionContext);

  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCount, setDataSourceCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [isEmptySearchTerm, setIsEmptySearchTerm] = useState("");
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isApprovalStatusModalVisible, setIsApprovalStatusModalVisible] =
    useState(false);

  const [editVehicleForm] = useForm();
  const [vehicleData, setVehicleData] = useState([]);
  const [isEditVehicle, setIsEditVehicle] = useState(false);

  const [isSelectedModalVisible, setIsSelectedModalVisible] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  const selectedDataSourceKey = selectedDataSource.map((obj) => obj.id);
  const selectedDataSourceKeyToString = selectedDataSource.map((obj) =>
    obj.id.toString()
  );

  const [sortBy, setSortBy] = useState("1D");
  //Amount
  const [isAmountModalVisible, setIsAmountModalVisible] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState({});
  const flattenSelectedObjects = (selectedRowKeys) => {
    return Object.values(selectedRowKeys).flatMap((pageObjects) => pageObjects);
  };
  const selectedDataSourceAmount = flattenSelectedObjects(selectedRowKeys)
    .reduce(
      (total, item) =>
        item.paymentStatus !== "S" && item.status !== 7
          ? total + (item.transactionAmountResponse?.totalAmount || 0)
          : total,
      0
    )
    .toFixed(2);

  //Approval modal
  const [isApprovalModalVisible, setIsApprovalModalVisible] = useState(false);
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isApprovalRejected, setIsApprovalRejected] = useState(false);
  const [bulkDetails, setBulkDetails] = useState([]);
  const [approvalModuleType, setApprovalModuleType] = useState(
    NOTIFICAION_APPROVAL_TYPE.BULK_APPROVAL
  );
  const [moduleType, setModuleType] = useState("");
  const [transactionType, setTransactionType] = useState("");

  const [balanceAmount, setBalanceAmount] = useState(0);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState("");

  //role permission
	const [permissions, setPermissions] = useState([]);
	useEffect(async () => {
		if (company.id) {
		  await getPermissions().then(async (permissions) => {
			setPermissions(permissions);
		  });
		}
	  }, [company]);

  const isOutM2m =
    pendingType === REPORT_TYPE_LABELS.OUT && tabType === TAB_TYPE.M2M;

  //bulk
  const [bulkTransferId, setBulkTransferId] = useState(null);
  const [isM2mBulkCompleted, setIsM2mBulkCompleted] = useState(false);
  const intervalId = useRef(null);

  const offsetHeader = isLoading ? 0 : 230;

  //Pagination - START
  //TODO - To refactor to component
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [sortOrder, setSortOrder] = useState("descend");
  const [sortedData, setSortedData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const maxSelectSize = 50;
  const maxPageSize = 30;
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSourceCount);
  const totalPages = Math.ceil(dataSourceCount / 30);

  const handleDeselectThisPage = () => {
    setSelectedRowKeys((prev) => ({
      ...prev,
      [currentPage]: [],
    }));
    setSelectAll((prev) => ({
      ...prev,
      [currentPage]: false,
    }));
  };

  const handleClearAll = () => {
    setSelectedRowKeys({});
    setSelectAll({});
    setSelectedDataSource([]);
  };

  const handleModalClearAll = async () => {
    await setSelectedRowKeys({});
    await setSelectAll({});
    await setSelectedDataSource([]);
    await onReset();
    await setIsSelectedModalVisible(false);
    await setIsApprovalStatusModalVisible(false);
    await getData();
  };

  const getCurrentPageTotal = () => {
    const currentSelectedKeys = selectedRowKeys[currentPage] || [];
    return currentSelectedKeys.length;
  };

  const getTotalSelectedKeys = () => {
    return Object.values(selectedRowKeys).flat().length;
  };

  const handleSelectAllChange = (page, event) => {
    const checked = event.target.checked;
    const currentSelectedObjects = selectedRowKeys[page] || [];
    const remainingQuota =
      maxSelectSize - getTotalSelectedKeys() + currentSelectedObjects.length;

    // Filter dataSource to exclude items with status === 10
    const filteredDataSource = dataSource.filter((item) => item.status !== 10);

    const newSelectedObjects = checked
      ? filteredDataSource.slice(0, remainingQuota)
      : [];

    setSelectAll((prev) => ({ ...prev, [page]: checked }));
    setSelectedRowKeys((prev) => ({
      ...prev,
      [page]: newSelectedObjects,
    }));
  };

  const handleRowSelectionChange = (page, key, record) => {
    setSelectedRowKeys((prev) => {
      const newSelectedObjects = prev[page] ? [...prev[page]] : [];
      const index = newSelectedObjects.findIndex((item) => item.key === key);

      if (index > -1) {
        newSelectedObjects.splice(index, 1);
      } else {
        const totalSelectedKeys = getTotalSelectedKeys();
        if (totalSelectedKeys >= maxSelectSize) {
          message.warning(
            "You can only select up to " + maxSelectSize + " items."
          );
          return prev;
        }
        newSelectedObjects.push(record);
      }

      return { ...prev, [page]: newSelectedObjects };
    });

    setSelectAll((prev) => ({
      ...prev,
      [page]: selectedRowKeys[page]?.length === dataSourceCount - 1,
    }));
  };

  const handleSort = (order) => {
    const sorted = [...dataSource].sort((a, b) => {
      return order === "ascend"
        ? moment(a.transactionDate).diff(moment(b.transactionDate))
        : moment(b.transactionDate).diff(moment(a.transactionDate));
    });
    setSortedData(sorted);
  };

  /* custom pagination */
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };

  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    handlePageChange(page);
    setIsEditing(false);
  };

  const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={(event) => {
                console.log("blur",event)
                handleInputBlur()
              }}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      getData(page);
    }
  };

  //COLUMN
  const TITLE_COLUMN = (isViewColumn) => ({
    title: (
      <TableBlueShadowCheckbox
        checked={selectAll[currentPage] || false}
        onChange={(e) => handleSelectAllChange(currentPage, e)}
        disabled={false}
      />
    ),
    width: "60px",
    dataIndex: "selectAll",
    key: "selectAll",
    render: (_, record) => (
      <TableBlueShadowCheckbox
        checked={
          selectedRowKeys[currentPage]?.some(
            (item) => item.key === record.key
          ) || false
        }
        onChange={() => {
          handleRowSelectionChange(currentPage, record.key, record);
        }}
        disabled={record.status === 10}
      />
    ),
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
  });

  const CREATED_DATE_COLUMN = (isViewColumn) => ({
    title: "Created Date",
    dataIndex: "createdDate",
    render: (transactionDate) => {
      let formattedDate = new Date(transactionDate);
      return (
        <div className="date-container">
          {highlightText(
            moment(formattedDate).format("DD-MMM-YYYY"),
            searchTerm
          )}
          <br />
          {moment(formattedDate).format("hh:mm a")}
        </div>
      );
    },
    sorter: true,
    sortOrder: sortOrder,
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
  });

  const SELLER_COLUMN = (isViewColumn) => ({
    title: "Seller",
    render: (rowData) => {
      return (
        <>
          {highlightText(
            rowData.sellerName && rowData.sellerName !== ""
              ? rowData.sellerName
              : "-",
            searchTerm
          )}{" "}
          <br />
          <span>
            (
            {highlightText(
              rowData.sellerNRIC && rowData.sellerNRIC !== ""
                ? rowData.sellerNRIC
                : "-",
              searchTerm
            )}
            )
          </span>
        </>
      );
    },
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    // dataIndex: 'sellerName',
    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
  });

  const BUYER_COLUMN = (isViewColumn) => ({
    title: "Buyer",
    render: (rowData) => {
      return (
        <>
          {highlightText(
            rowData.buyerName && rowData.buyerName !== ""
              ? rowData.buyerName
              : "-",
            searchTerm
          )}{" "}
          <br />
          <span>
            (
            {highlightText(
              rowData.buyerNRIC && rowData.buyerNRIC !== ""
                ? rowData.buyerNRIC
                : "-",
              searchTerm
            )}
            )
          </span>
        </>
      );
    },
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    // dataIndex: 'sellerName',
    // sorter: (a, b) => a.sellerName.localeCompare(b.sellerName),
  });

  const VEHICLE_NUMBER_COLUMN = (isViewColumn) => ({
    title: "Vehicle Number",
    dataIndex: "carRegistrationNo",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (text) => highlightText(text, searchTerm),
  });

  const CHASIS_NUMBER_COLUMN = (isViewColumn) => ({
    title: "Chasis Number",
    width: 200,
    dataIndex: "chassisNo",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (text) => highlightText(text, searchTerm),
  });

  const ENGINE_NUMBER_COLUMN = (isViewColumn) => ({
    title: "Engine Number",
    width: 200,
    dataIndex: "engineNo",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (text) => highlightText(text, searchTerm),
  });

  //Cater all module status
  const STATUS_COLUMN = (isViewColumn) => ({
    title: "Status",
    // dataIndex: "status",
    width: 200,
    render: (rowData) => {
      return (
        <>
          {pendingType === REPORT_TYPE_LABELS.IN &&
            tabType === TAB_TYPE.JPJ_SUBMISSION &&
            rowData.failCode === "" && (
              <Tag color={IN_TRANSACTION_STATUSES_COLORS["0"]}>
                Pending for JPJ submission
              </Tag>
            )}
          {pendingType === REPORT_TYPE_LABELS.IN &&
            (tabType !== TAB_TYPE.JPJ_SUBMISSION || rowData.failCode !== "") &&
            rowData.paymentStatus !== "S" && (
              <Tag color={IN_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                {IN_TRANSACTION_STATUSES_LABELS[rowData.status]}
              </Tag>
            )}

          {pendingType === REPORT_TYPE_LABELS.IN &&
            tabType !== TAB_TYPE.JPJ_SUBMISSION &&
            rowData.paymentStatus === "S" && (
              <Tag color={IN_TRANSACTION_STATUSES_COLORS[2]}>
                {IN_TRANSACTION_STATUSES_LABELS[2]}
              </Tag>
            )}

          {(pendingType === REPORT_TYPE_LABELS.OUT ||
            pendingType === REPORT_TYPE_LABELS.I2I) &&
            tabType !== TAB_TYPE.M2M && (
              <Tag color={OUT_TRANSACTION_STATUSES_COLORS[rowData.status]}>
                {OUT_TRANSACTION_STATUSES_LABELS[rowData.status]}
              </Tag>
            )}

          {pendingType === REPORT_TYPE_LABELS.OUT &&
            tabType === TAB_TYPE.M2M &&
            (rowData.sellerInquirySuccess !== null ? (
              <Tag color={"blue"}>{"Pending Seller Approval"}</Tag>
            ) : (
              <Tag color={"red"}>{"Failed"}</Tag>
            ))}
        </>
      );
    },
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
  });

  const JPJ_RESPONSE_COLUMN = (isViewColumn) => ({
    title: "JPJ Response message",
    dataIndex: "failCode",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
  });

  const DAY_REMAINING_COLUMN = (isViewColumn) => ({
    title: "Day Remaining",
    dataIndex: "dayRemaining",
    render: (dayRemaining, rowData) => {
      if (rowData.status === 4) {
        return "";
      }

      if (dayRemaining > 1) {
        return `${dayRemaining} days`;
      } else if (dayRemaining > 0) {
        return `${dayRemaining} day`;
      } else if (rowData.isM2mTransaction) {
        return "N/A";
      } else {
        return "Expired";
      }
    },
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
  });

  const AMOUNT_COLUMN = (isViewColumn) => ({
    title: "Amount (RM)",
    // dataIndex: "amount",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (rowData) => {
      const onAmountButtonClick = () => {
        setIsAmountModalVisible(true);
        setSelectedAmount(rowData.transactionAmountResponse);
      };
      const totalAmount =
        "RM" +
        Number(rowData.transactionAmountResponse?.totalAmount).toFixed(2);
      return isViewColumn
        ? totalAmount
        : LinkButton(totalAmount, onAmountButtonClick);
    },
  });

  const IN_ACTION_COLUMN = (isViewColumn) => ({
    title: "Action",
    fixed: "right",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (rowData) => {
      let url = "";
      let showEditButton = false;

      if (rowData.status === IN_TRANSACTION_STATUSES.FAILED)
        showEditButton = true;

      if (rowData.status === IN_TRANSACTION_STATUSES.PENDING_APPROVAL) {
        url += "/transfer/in/payment";
      } else if (
        rowData.status === IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS
      ) {
        url += "/transfer/in/printslip";
      } else {
        url += "/transfer/in/create";
      }
      return (
        <>
          <Button
            style={{
              border: "1px solid #2B61C4",
              color: "#2B61C4",
              borderRadius: "4px",
              marginBottom: 10,
              marginRight: 10,
            }}
            onClick={() => {
              history.push(`${url}/${rowData.id}`, {
                isM2M: rowData.isM2mTransaction ?? false,
                isReport: false,
                oldIsReport: true, // to avoid conflict with what's already implemented, keep the old isReport
                fromPendingPage: true,
                jpjErrorMsg: rowData.failCode,
                tabType: tabType,
              });
            }}
          >
            <>
              <EditOutlined />
              <span className="img-btn-text">REVIEW</span>
            </>
          </Button>
          {showEditButton && (
            <Button
              style={{
                border: "1px solid #2B61C4",
                color: "#2B61C4",
                borderRadius: "4px",
                marginBottom: 10,
                marginRight: 10,
                tabType: tabType,
              }}
              onClick={() => {
                history.push(`/transfer/in/vehicleinfo/${rowData.id}`);
              }}
            >
              <>
                <EditOutlined />
                <span className="img-btn-text">EDIT</span>
              </>
            </Button>
          )}
        </>
      );
    },
  });

  const OUT_ACTION_COLUMN = (isViewColumn) => ({
    title: "Action",
    fixed: "right",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (rowData) => {
      let url = "";
      let serviceId = "";

      let allowedStatusToEdit = [];

      Object.values(OUT_TRANSACTION_STATUSES).filter((value) => {
        // Exclude values 1, 3, 4, 6, 7, 8
        if (
          ![
            OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS,
            OUT_TRANSACTION_STATUSES.PENDING_PAYMENT,
            OUT_TRANSACTION_STATUSES.SUCCESS,
            OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
            OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
            OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL,
          ].includes(value)
        ) {
          allowedStatusToEdit.push(value);
        }
      });

      if (
        (rowData.transactionTypeLabel &&
          rowData.transactionTypeLabel ==
            OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2I].label) ||
        rowData.transactionTypeLabel ==
          OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2C].label
      ) {
        allowedStatusToEdit.push(
          // OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
          OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
        );
      }

      let showEditButton = false;

      if (
        rowData.transactionTypeLabel !=
        OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label
      ) {
        if (allowedStatusToEdit.includes(rowData.status)) showEditButton = true;
      }

      switch (rowData.transactionTypeLabel) {
        case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.I2I].label:
          url += `/transfer/i2i`;
          serviceId = "i2i";
          break;
        case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label:
          url += `/transfer/out`;
          serviceId = "m2m";
          break;
        default:
          url += `/transfer/out`;
          serviceId = "out";
      }
      switch (rowData.status) {
        /**
         * 11/3/2022 daniel.kwok
         * These are the status that allowed for re-submittion of transfer
         */
        case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
          /**
           * 11/3/2022 daniel.kwok
           * Disable user from re-submitting a record
           * if dayRemaining <= 0
           */
          if (rowData.dayRemaining > 0) {
            // const state = {
            //     step: STEPS.JPJ_SUBMISSION.label,
            //     subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
            // }
            // const cached = encodeURIComponent(JSON.stringify(state))
            if (pendingType) url += `/create/${rowData.id}`;
            else url += `/payment/${rowData.id}`;
          } else {
            url += `/payment/${rowData.id}`;
          }
          break;
        case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
          url += `/payment/${rowData.id}`;
          break;
        case OUT_TRANSACTION_STATUSES.SUCCESS:
          url += `/printslip/${rowData.id}`;
          break;
        // case OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED:
        //     url += `/printslip/${rowData.id}`;
        //     break
        default:
          url += `/payment/${rowData.id}`;
          break;
      }

      return (
        rowData.dayRemaining > 0 && (
          <div>
            <Button
              // disabled={isDisableButton}
              style={{
                border: "1px solid #2B61C4",
                color: "#2B61C4",
                borderRadius: "4px",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={async () => {
                history.push(url, {
                  isReport: pendingType ? false : true,
                  oldIsReport: true,
                  fromPendingPage: pendingType ? true : false,
                  jpjErrorMsg: rowData.failCode,
                  tabType: tabType,
                });
              }}
            >
              <EditOutlined />
              <span className="img-btn-text">REVIEW</span>
            </Button>

            {showEditButton == true || rowData.failCode !== "" ? (
              <Button
                style={{
                  border: "1px solid #2B61C4",
                  color: "#2B61C4",
                  borderRadius: "4px",
                  marginBottom: 10,
                  marginRight: 10,
                }}
                onClick={() => {
                  history.push(`/transfer/out/vehicleinfo/${rowData.id}`, {
                    tabType: tabType,
                  });
                }}
              >
                <>
                  <EditOutlined />
                  <span className="img-btn-text">EDIT</span>
                </>
              </Button>
            ) : null}
          </div>
        )
      );
    },
  });

  const OUT_M2M_ACTION_COLUMN = (isViewColumn) => ({
    title: "Action",
    fixed: "right",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (rowData) => {
      let url = "";
      switch (rowData.status) {
        /**
         * 11/3/2022 daniel.kwok
         * These are the status that allowed for re-submittion of transfer
         */
        case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
          /**
           * 11/3/2022 daniel.kwok
           * Disable user from re-submitting a record
           * if dayRemaining <= 0
           */
          if (rowData.dayRemaining > 0) {
            // const state = {
            //     step: STEPS.JPJ_SUBMISSION.label,
            //     subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
            // }
            // const cached = encodeURIComponent(JSON.stringify(state))
            if (pendingType) url += `/create/${rowData.id}`;
            else url += `/payment/${rowData.id}`;
          } else {
            url += `/payment/${rowData.id}`;
          }
          break;
        case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
          url += `/payment/${rowData.id}`;
          break;
        case OUT_TRANSACTION_STATUSES.SUCCESS:
          url += `/printslip/${rowData.id}`;
          break;
        // case OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED:
        //     url += `/printslip/${rowData.id}`;
        //     break
        default:
          url += `/payment/${rowData.id}`;
          break;
      }
      return (
        <>
          <Button
            // disabled={isDisableButton && rowData.sellerInquirySuccess !== null}
            style={{
              border: "1px solid #2B61C4",
              color: "#2B61C4",
              borderRadius: "4px",
              marginBottom: 10,
              marginRight: 10,
            }}
            onClick={async () => {
              history.push("/transfer/m2m", {
                rowData: rowData,
                type: REPORT_TYPE_LABELS.M2M,
                // isJpjSubmitted: rowData.failCode !== "",
                isBulk: true,
                isJpjSubmitted: true,
                sellerInquirySuccess: rowData.sellerInquirySuccess != null,
                tabType: tabType,
              });
            }}
          >
            <EditOutlined />
            <span className="img-btn-text">REVIEW</span>
          </Button>
          {rowData.sellerInquirySuccess === null && (
            <Button
              disabled={
                isDisableButton && rowData.sellerInquirySuccess !== null
              }
              style={{
                border: "1px solid #2B61C4",
                color: "#2B61C4",
                borderRadius: "4px",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={() => {
                history.push("/transfer/m2m", {
                  isBulk: true,
                  rowData: rowData,
                  type: REPORT_TYPE_LABELS.OUT,
                  isVehicleEdit: true,
                  tabType: tabType,
                });
              }}
            >
              <EditOutlined />
              <span className="img-btn-text">EDIT</span>
            </Button>
          )}
        </>
      );
    },
  });

  const I2I_ACTION_COLUMN = (isViewColumn) => ({
    title: "Action",
    fixed: "right",
    onCell: (rowData) => ({
      style: {
        backgroundColor:
          !isViewColumn &&
          selectedRowKeys[currentPage]?.some((item) => item.key === rowData.id)
            ? "#f0f4fc"
            : "",
      },
    }),
    render: (rowData) => {
      let url = "";
      let serviceId = "";

      let allowedStatusToEdit = [];

      Object.values(OUT_TRANSACTION_STATUSES).filter((value) => {
        // Exclude values 1, 3, 4, 6, 7, 8
        if (
          ![
            OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS,
            OUT_TRANSACTION_STATUSES.PENDING_PAYMENT,
            OUT_TRANSACTION_STATUSES.SUCCESS,
            OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED,
            OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED,
            OUT_TRANSACTION_STATUSES.PENDING_FOR_MYEG_OFFICER_APPROVAL,
          ].includes(value)
        ) {
          allowedStatusToEdit.push(value);
        }
      });

      if (
        (rowData.transactionTypeLabel &&
          rowData.transactionTypeLabel ==
            OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2I].label) ||
        rowData.transactionTypeLabel ==
          OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.D2C].label
      ) {
        allowedStatusToEdit.push(
          // OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED,
          OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED
        );
      }

      let showEditButton = false;

      if (
        rowData.transactionTypeLabel !=
        OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.M2M].label
      ) {
        if (allowedStatusToEdit.includes(rowData.status)) showEditButton = true;
      }

      switch (rowData.transactionTypeLabel) {
        case OUT_TRANSACTION_TYPES[OUT_TRANSACTION_TYPES_IDS.I2I].label:
          url += `/transfer/i2i`;
          break;
        default:
          url += `/transfer/i2i`;
      }
      switch (rowData.status) {
        /**
         * 11/3/2022 daniel.kwok
         * These are the status that allowed for re-submittion of transfer
         */
        case OUT_TRANSACTION_STATUSES.PENDING_FOR_DEALER_APPROVAL:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_AND_SELLER_MYSIKAP:
        case OUT_TRANSACTION_STATUSES.BUYER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_FAILED:
        case OUT_TRANSACTION_STATUSES.PENDING_BUYER_INFO:
        case OUT_TRANSACTION_STATUSES.SELLER_INQUIRY_SUCCESS:
          /**
           * 11/3/2022 daniel.kwok
           * Disable user from re-submitting a record
           * if dayRemaining <= 0
           */
          if (rowData.dayRemaining > 0) {
            // const state = {
            //     step: STEPS.JPJ_SUBMISSION.label,
            //     subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW
            // }
            // const cached = encodeURIComponent(JSON.stringify(state))
            if (pendingType) url += `/create/${rowData.id}`;
            else url += `/payment/${rowData.id}`;
          } else {
            url += `/payment/${rowData.id}`;
          }
          break;
        case OUT_TRANSACTION_STATUSES.PENDING_PAYMENT:
          url += `/payment/${rowData.id}`;
          break;
        case OUT_TRANSACTION_STATUSES.SUCCESS:
          url += `/printslip/${rowData.id}`;
          break;
        // case OUT_TRANSACTION_STATUSES.UPDATE_TO_JPJ_FAILED:
        //     url += `/printslip/${rowData.id}`;
        //     break
        default:
          url += `/payment/${rowData.id}`;
          break;
      }

      return (
        rowData.dayRemaining > 0 && (
          <div>
            <Button
              // disabled={isDisableButton}
              style={{
                border: "1px solid #2B61C4",
                color: "#2B61C4",
                borderRadius: "4px",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={async () => {
                history.push(url, {
                  isReport: pendingType ? false : true,
                  oldIsReport: true,
                  fromPendingPage: pendingType ? true : false,
                  jpjErrorMsg: rowData.failCode,
                  tabType: tabType,
                });
              }}
            >
              <EditOutlined />
              <span className="img-btn-text">REVIEW</span>
            </Button>

            {showEditButton == true || rowData.failCode !== "" ? (
              <Button
                style={{
                  border: "1px solid #2B61C4",
                  color: "#2B61C4",
                  borderRadius: "4px",
                  marginBottom: 10,
                  marginRight: 10,
                }}
                onClick={() => {
                  history.push(`/transfer/i2i/vehicleinfo/${rowData.id}`, {
                    tabType: tabType,
                  });
                }}
              >
                <>
                  <EditOutlined />
                  <span className="img-btn-text">EDIT</span>
                </>
              </Button>
            ) : null}
          </div>
        )
      );
    },
  });

  //Pagination - END
  const IN_JPJ_SUBMISSION_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    IN_ACTION_COLUMN(false),
  ];

  const IN_JPJ_SUBMISSION_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
  ];

  const IN_PAYMENT_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    AMOUNT_COLUMN(false),
    IN_ACTION_COLUMN(false),
  ];

  const IN_PAYMENT_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
    AMOUNT_COLUMN(true),
  ];

  const OUT_JPJ_SUBMISSION_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    BUYER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    DAY_REMAINING_COLUMN(false),
    OUT_ACTION_COLUMN(false),
  ];

  const OUT_JPJ_SUBMISSION_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    BUYER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
  ];

  const OUT_PAYMENT_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    BUYER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    DAY_REMAINING_COLUMN(false),
    AMOUNT_COLUMN(false),
    OUT_ACTION_COLUMN(false),
  ];

  const OUT_PAYMENT_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    BUYER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
    AMOUNT_COLUMN(true),
  ];

  const OUT_M2M_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    BUYER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    OUT_M2M_ACTION_COLUMN(false),
  ];

  const I2I_JPJ_SUBMISSION_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    BUYER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    DAY_REMAINING_COLUMN(false),
    I2I_ACTION_COLUMN(false),
  ];

  const I2I_JPJ_SUBMISSION_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    BUYER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
  ];

  const I2I_PAYMENT_COLUMNS = [
    TITLE_COLUMN(false),
    CREATED_DATE_COLUMN(false),
    SELLER_COLUMN(false),
    BUYER_COLUMN(false),
    VEHICLE_NUMBER_COLUMN(false),
    CHASIS_NUMBER_COLUMN(false),
    ENGINE_NUMBER_COLUMN(false),
    STATUS_COLUMN(false),
    JPJ_RESPONSE_COLUMN(false),
    DAY_REMAINING_COLUMN(false),
    AMOUNT_COLUMN(false),
    I2I_ACTION_COLUMN(false),
  ];

  const I2I_PAYMENT_VIEW_COLUMNS = [
    SELLER_COLUMN(true),
    BUYER_COLUMN(true),
    VEHICLE_NUMBER_COLUMN(true),
    CHASIS_NUMBER_COLUMN(true),
    ENGINE_NUMBER_COLUMN(true),
    STATUS_COLUMN(true),
    JPJ_RESPONSE_COLUMN(true),
    AMOUNT_COLUMN(true),
  ];

  const REPORT_TYPES = {
    [REPORT_TYPE_LABELS.IN]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          let searchParam = "";
          if (searchTerm !== "") {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchInTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setJpjSubmissionCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: IN_JPJ_SUBMISSION_COLUMNS,
        viewColumns: IN_JPJ_SUBMISSION_VIEW_COLUMNS,
        bulkSubmit: async () => {
          inBulkTransferInquiry(selectedDataSourceKey, onReset, company.id);
        },
        bulkDetailCheck: async () => {
          return await checkBulkTransactionDetails(bulkTransferId);
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let search = "";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchInTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setPaymentCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: IN_PAYMENT_COLUMNS,
        viewColumns: IN_PAYMENT_VIEW_COLUMNS,
        bulkSubmit: async (bulkTransferId) => {
          inBulkProcessPayment(
            selectedDataSourceKeyToString,
            onReset,
            company.coRegNo,
            bulkTransferId,
            false
          );
        },
        bulkDetailCheck: async () => {
          return await checkBulkTransactionDetails(bulkTransferId);
        },
      },
      [TAB_TYPE.M2M]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let search = "";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchInTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.M2M
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setM2mCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: IN_PAYMENT_COLUMNS,
        viewColumns: IN_PAYMENT_VIEW_COLUMNS,
        bulkSubmit: async (bulkTransferId) => {
          inBulkProcessPayment(
            selectedDataSourceKeyToString,
            onReset,
            company.coRegNo,
            bulkTransferId,
            true
          );
        },
        bulkDetailCheck: async () => {
          return await checkBulkTransactionDetails(bulkTransferId);
        },
      },
    },
    [REPORT_TYPE_LABELS.OUT]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchOutTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setJpjSubmissionCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: OUT_JPJ_SUBMISSION_COLUMNS,
        viewColumns: OUT_JPJ_SUBMISSION_VIEW_COLUMNS,
        bulkSubmit: async () => {
          outBulkTransferInquiry(
            selectedDataSourceKey,
            onReset,
            company.id,
            true
          );
        },
        bulkDetailCheck: async () => {
          return await checkOutBulkTransactionDetails(bulkTransferId);
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let search = "";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchOutTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setPaymentCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: OUT_PAYMENT_COLUMNS,
        viewColumns: OUT_PAYMENT_VIEW_COLUMNS,
        bulkSubmit: async (bulkTransferId) => {
          outBulkProcessPayment(
            selectedDataSourceKeyToString,
            onReset,
            company.coRegNo,
            bulkTransferId,
            true
          );
        },
        bulkDetailCheck: async () => {
          return await checkOutBulkTransactionDetails(bulkTransferId);
        },
      },
      [TAB_TYPE.M2M]: {
        api: async (currentPage, isSearch, sort, searchValue) => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let search = "";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchM2mTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            searchParam,
            sort ?? sortBy,
            STATUS_TYPE.M2M
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setM2mCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: OUT_M2M_COLUMNS,
        viewColumns: OUT_JPJ_SUBMISSION_VIEW_COLUMNS,
        bulkSubmit: async (bulkTransferId) => {
          proceedM2mBulk(
            selectedDataSourceKey,
            onReset,
            company.id,
            bulkTransferId
          );
        },
        bulkDetailCheck: async () => {
          return await checkBulkTransactionDetails(bulkTransferId);
        },
      },
    },
    [REPORT_TYPE_LABELS.I2I]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchI2iTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setJpjSubmissionCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: I2I_JPJ_SUBMISSION_COLUMNS,
        viewColumns: I2I_JPJ_SUBMISSION_VIEW_COLUMNS,
        bulkSubmit: async () => {
          outBulkTransferInquiry(
            selectedDataSourceKey,
            onReset,
            company.id,
            false
          );
        },
        bulkDetailCheck: async () => {
          return await checkOutBulkTransactionDetails(bulkTransferId);
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage,
          isSearch,
          sort,
          searchValue,
          isEmptySearch
        ) => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let search = "";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          searchI2iTransactions(
            branch.id,
            company.coRegNo,
            maxPageSize,
            currentPage,
            SEARCH,
            isEmptySearch ? "" : searchParam,
            sort ?? sortBy,
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              const dataWithKey = res.data.map((item, index) => {
                return {
                  key: item.id,
                  ...item,
                };
              });
              if (isSearch && res.count === 0) {
                setIsEmptySearch(true);
                setIsEmptySearchTerm(searchTerm);
              } else {
                setDataSource(dataWithKey);
                setDataSourceCount(res.count);
                setPaymentCount(res.count);
              }
              // setIsLoading(false);
            })
            .catch((err) => {
              err && message.error(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        columns: I2I_PAYMENT_COLUMNS,
        viewColumns: I2I_PAYMENT_VIEW_COLUMNS,
        bulkSubmit: async (bulkTransferId) => {
          outBulkProcessPayment(
            selectedDataSourceKeyToString,
            onReset,
            company.coRegNo,
            bulkTransferId,
            false
          );
        },
        bulkDetailCheck: async () => {
          return await checkOutBulkTransactionDetails(bulkTransferId);
        },
      },
    },
  };

  const report = REPORT_TYPES[pendingType][tabType];

  const handleSearch = async (isEmptySearch) => {
    setIsLoading(true);
    const { searchTerm } = searchForm.getFieldsValue();
    setCurrentPage(1);

    // setIsLoading(true);
    let trimmedSearchQuery = ""; // Assign empty string if searchTerm is undefined
    if (typeof searchTerm !== "undefined" || searchTerm !== undefined) {
      // trimmedSearchQuery = searchTerm.replace(/\s/g, "");
      trimmedSearchQuery = searchTerm.trim().toUpperCase();
    }

    // setSearchTerm(trimmedSearchQuery);
    setSearchTerm(trimmedSearchQuery);
    await report.api(1, true, sortBy, trimmedSearchQuery, isEmptySearch);
    // setIsLoading(false);
  };

  const onBackButtonClick = () => {
    // history.push("/transfer/in/create");
    history.push("/");
    // setIsEditVehicle(false);
  };

  const onSubmit = async () => {
    if (bulkTransferId && bulkTransferId !== 0) {
      setIsApprovalStatusModalVisible(true);
      return;
    }
    // const selectedDataSource = getSelectedDataSource();
    // setVehicleSelectedData(selectedDataSource);
    // setIsVehicleSearched(true);
    if (tabType === TAB_TYPE.JPJ_SUBMISSION) {
      await report.bulkSubmit();
    } else {
      onPaymentSubmit();
    }
  };

  const onReset = () => {
    setBulkTransferId(null);
    setIsM2mBulkCompleted(false);
  };

  const onPaymentSubmit = () => {
    let module;
    let transactionType;
    switch (pendingType) {
      case REPORT_TYPE_LABELS.OUT:
        module = ModuleType.OUT;
        transactionType = OUT_TRANSACTION_TYPES_IDS.D2I;
        if (tabType === TAB_TYPE.M2M) {
          setApprovalModuleType(NOTIFICAION_APPROVAL_TYPE.BULK_APPROVAL);
        } else {
          setApprovalModuleType(NOTIFICAION_APPROVAL_TYPE.BULK_OUT_PAYMENT);
        }
        break;
      case REPORT_TYPE_LABELS.I2I:
        module = ModuleType.I2I;
        transactionType = OUT_TRANSACTION_TYPES_IDS.I2I;
        setApprovalModuleType(NOTIFICAION_APPROVAL_TYPE.BULK_I2I_PAYMENT);
        break;
      default:
        setApprovalModuleType(NOTIFICAION_APPROVAL_TYPE.BULK_IN_PAYMENT);
        module = ModuleType.IN;
        transactionType = "";
        break;
    }
    setModuleType(module);
    setTransactionType(transactionType);
    // setIsModalVisible(false);
    onApprovalSetAdmin(
      setIsApprovalModalVisible,
      transactionType,
      module,
      company,
      setIsAdminUser,
      true,
      loggedInUserRole,
      permissions
    );
  };

  const processApproval = () => {
    inBulkProcessPayment(
      selectedDataSourceKeyToString,
      onReset,
      company.coRegNo
    );
  };

  const onSelectedVehicle = () => {
    setSelectedDataSource(
      flattenSelectedObjects(selectedRowKeys).map((item, index) => {
        return {
          ...item,
          no: index + 1,
        };
      })
    );
    setIsSelectedModalVisible(true);
  };

  const submitButton = (isSelected) => {
    return (
      <Button
        type="primary"
        style={{ border: "5px" }}
        className="rectangle-button to-the-right"
        loading={isLoading}
        disabled={getTotalSelectedKeys() === 0 || isDisableButton}
        onClick={isSelected ? onSubmit : onSelectedVehicle}
      >
        {getTotalSelectedKeys() === 0
          ? "Select transaction"
          : tabType === TAB_TYPE.JPJ_SUBMISSION
          ? "Submit to JPJ"
          : isOutM2m
          ? "Seller approval & JPJ Submission"
          : "Confirm & Pay RM " + selectedDataSourceAmount}
      </Button>
    );
  };

  //Modal
  const selectedVehicleTableModal = () => (
    <Modal
      className="custom-modal"
      width="80vw"
      centered={true}
      open={isSelectedModalVisible}
      onCancel={() => {
        setIsSelectedModalVisible(false);
        if (isM2mBulkCompleted) {
          handleModalClearAll();
        } else {
          onReset();
          getData(currentPage);
        }
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={
        <>
          {tabType !== TAB_TYPE.JPJ_SUBMISSION && !isOutM2m && (
            <>
              Approval is required for this payment to
              complete.&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )}
          {isOutM2m && (
            <>
              Approval is required for this Seller approval & JPJ Submission to
              complete.&nbsp;&nbsp;&nbsp;&nbsp;
            </>
          )}
          {submitButton(true)}
        </>
      }
    >
      <h3>
        <b>
          Selected {selectedDataSource.length} transactions to{" "}
          {tabType === TAB_TYPE.PAYMENT ? "pay" : "submit"}
        </b>
      </h3>
      <br />
      {/* {VehicleSelectedTableComponent(selectedDataSource, branchs)} */}
      <Table
        bordered={true}
        dataSource={selectedDataSource}
        columns={report.viewColumns}
        pagination={false}
        scroll={{
          x: report.columns.length * 100,
          y: 400,
        }}
      />
    </Modal>
  );

  const getData = (currentPage, sort) => {
    setIsLoading(true);
    report.api(currentPage, false, sort);
  };

  useEffect(() => {
    getUserRole().then((res) => {
      setLoggedInUserRole(res);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(branch).length > 0 && Object.keys(company).length) {
      setIsLoading(true);
      setCurrentPage(1);
      handleClearAll();
      getData(currentPage);
    }

    if (loggedInUserRole) {
      setIsDisableButton(false);
      let module;
      switch (pendingType) {
        case REPORT_TYPE_LABELS.OUT:
          module = ModuleType.OUT;
          if (tabType === TAB_TYPE.M2M) {
            module = ModuleType.OUTM2M;
          }
          break;
        case REPORT_TYPE_LABELS.I2I:
          module = ModuleType.I2I;
          break;
        default:
          module = ModuleType.IN;
          break;
      }
      if (tabType !== TAB_TYPE.JPJ_SUBMISSION) {
        setIsDisableButton(
          validateRolePermission(loggedInUserRole, module, true, permissions)
        );
      }
    }
  }, [branch, company, tabType, loggedInUserRole]);

  const inBulkTransferInquiry = async (
    transferIdList,
    onResetData,
    companyId
  ) => {
    const saveBuyerPayload = {
      transferIdList: bulkTransferId == null ? transferIdList : [],
      uId: "",
      bulkTransferId: bulkTransferId ?? null,
      companyId: companyId,
      moduleType: "IN_SUBMISSION",
    };

    const m2mProceedRes = await InBulkTransferInquiryWithJPJ(saveBuyerPayload);
    if (m2mProceedRes.inquiryStatus !== "00") {
      message.error("Processing Failed. Please Try Again.");
      return false;
    } else {
      message.info("Transfer in Progress.");
      await onResetData();
      setBulkTransferId(m2mProceedRes.bulkTransferId);
      setIsApprovalStatusModalVisible(true);
      return true;
    }
  };

  const inBulkProcessPayment = async (
    transferIdList,
    onResetData,
    dealerCompanyROC,
    bulkTrfId,
    isM2mTransaction
  ) => {
    const payload = {
      transferIdList: bulkTrfId == null ? transferIdList : [],
      uId: "",
      bulkTransferId: bulkTrfId ?? null,
      dealerCompanyROC: dealerCompanyROC,
      moduleType: NOTIFICAION_APPROVAL_TYPE.BULK_IN_PAYMENT,
      isM2mTransaction: isM2mTransaction,
    };

    let m2mProceedRes = await bulkProcessPaymentIN(payload);
    if (m2mProceedRes.status !== "00") {
      message.error("Processing Failed. Please Try Again.");
      m2mProceedRes.message && message.error(m2mProceedRes.message);
      if (m2mProceedRes.message === "Insufficient balance to process payment") {
        setIsM2mBulkCompleted(true);
        const bulkDetails = {
          inquiryFailed: m2mProceedRes.totalVehicle
            ? m2mProceedRes.totalVehicle
            : getTotalSelectedKeys(),
          inquirySuccess: 0,
          totalVehicle: m2mProceedRes.totalVehicle
            ? m2mProceedRes.totalVehicle
            : getTotalSelectedKeys(),
        };
        setBulkDetails(bulkDetails);
        setIsApprovalStatusModalVisible(true);
      }
      return false;
    } else {
      message.info("Transfer in Progress.");
      await onResetData();
      setBulkTransferId(m2mProceedRes.bulkTransferId);
      setIsApprovalStatusModalVisible(true);
      return true;
    }
  };

  const outBulkTransferInquiry = async (
    transferIdList,
    onResetData,
    companyId,
    isOutTxn
  ) => {
    const saveBuyerPayload = {
      transferIdList: bulkTransferId == null ? transferIdList : [],
      uId: "",
      bulkTransferId: bulkTransferId ?? null,
      companyId: companyId,
      moduleType: isOutTxn
        ? NOTIFICAION_APPROVAL_TYPE.BULK_OUT_SUBMISSION
        : NOTIFICAION_APPROVAL_TYPE.BULK_I2I_SUBMISSION,
      isOutTxn: isOutTxn,
    };

    const m2mProceedRes = await OUTBulkTransferInquiryWithJPJ(saveBuyerPayload);
    if (m2mProceedRes.statusCode !== "00") {
      message.error("Processing Failed. Please Try Again.");
      return false;
    } else {
      message.info("Transfer in Progress.");
      await onResetData();
      setBulkTransferId(m2mProceedRes.bulkTransferId);
      setIsApprovalStatusModalVisible(true);
      return true;
    }
  };

  const outBulkProcessPayment = async (
    transferIdList,
    onResetData,
    dealerCompanyROC,
    bulkTrfId,
    isOutTxn
  ) => {
    const payload = {
      transferIdList: bulkTrfId == null ? transferIdList : [],
      uId: "",
      bulkTransferId: bulkTrfId ?? null,
      dealerCompanyROC: dealerCompanyROC,
      moduleType: isOutTxn
        ? NOTIFICAION_APPROVAL_TYPE.BULK_OUT_PAYMENT
        : NOTIFICAION_APPROVAL_TYPE.BULK_I2I_PAYMENT,
      isOutTxn: isOutTxn,
    };

    const m2mProceedRes = await bulkProcessPaymentOUT(payload);
    if (m2mProceedRes.status !== "00") {
      message.error("Processing Failed. Please Try Again.");
      m2mProceedRes.message && message.error(m2mProceedRes.message);
      if (m2mProceedRes.message === "Insufficient balance to process payment") {
        setIsM2mBulkCompleted(true);
        const bulkDetails = {
          inquiryFailed: m2mProceedRes.totalVehicle
            ? m2mProceedRes.totalVehicle
            : getTotalSelectedKeys(),
          inquirySuccess: 0,
          totalVehicle: m2mProceedRes.totalVehicle
            ? m2mProceedRes.totalVehicle
            : getTotalSelectedKeys(),
        };
        setBulkDetails(bulkDetails);
        setIsApprovalStatusModalVisible(true);
      }
      return false;
    } else {
      message.info("Transfer in Progress.");
      await onResetData();
      setBulkTransferId(m2mProceedRes.bulkTransferId);
      setIsApprovalStatusModalVisible(true);
      return true;
    }
  };

  const proceedM2mBulk = async (
    transferIdList,
    onResetData,
    companyId,
    bulkTrfId
  ) => {
    const saveBuyerPayload = {
      transferIdList: bulkTrfId == null ? transferIdList : [],
      uId: "",
      bulkTransferId: bulkTrfId ?? null,
      companyId: companyId,
      moduleType:
        tabType === TAB_TYPE.M2M
          ? NOTIFICAION_APPROVAL_TYPE.BULK_APPROVAL
          : NOTIFICAION_APPROVAL_TYPE.BULK_IN_PAYMENT,
    };

    const m2mProceedRes = await m2mProceedBulk(saveBuyerPayload);
    if (m2mProceedRes.inquiryStatus !== "00") {
      message.error("Processing Failed. Please Try Again.");
      return false;
    } else {
      message.info("Transfer in Progress.");
      await onResetData();
      setBulkTransferId(m2mProceedRes.bulkTransferId);
      setIsApprovalStatusModalVisible(true);
      return true;
    }
  };

  /* checkBulkTransaction */
  useEffect(() => {
    if (bulkTransferId && isApprovalStatusModalVisible && !isM2mBulkCompleted) {
      intervalId.current = setInterval(() => {
        report.bulkDetailCheck().then(async (res) => {
          if (res.status) {
            setBulkDetails(res);
          } else {
            message.error(res.message);
            clearInterval(intervalId.current);
            // setBulkTransferId(0);
          }

          if (res.remainingVehicle === 0) {
            const res = await getPrepaidBalance(company.coRegNo);
            res.balanceAmount && setBalanceAmount(res.balanceAmount);
            message.success("Process completed.");
            clearInterval(intervalId.current);
            setIsM2mBulkCompleted(true);
            // getData(currentPage);
          }
        });
      }, 3000);
    }

    return () => clearInterval(intervalId.current);
  }, [bulkTransferId, isApprovalStatusModalVisible]);

  useEffect(() => {
    if (
      tabType === TAB_TYPE.PAYMENT ||
      (pendingType === REPORT_TYPE_LABELS.IN && tabType === TAB_TYPE.M2M)
    ) {
      if (dataSource[0] && dataSource[0].transactionAmountResponse === null) {
        getData(currentPage);
      }
    }
  }, [dataSource]);

  return (
    <>
      <>
        <div>
          {isEditVehicle ? (
            <>
              {/* Edit vehicle */}
              {VehicleUpdateTableComponent(vehicleData, editVehicleForm)}
            </>
          ) : (
            <>
              <div className="sticky-card">
                {/* Pagination */}
                <Card>
                  <Row justify="space-around" align="middle">
                    <Col span={3} style={{ textAlign: "left" }}>
                      {CustomPaginationComponent(
                        setCurrentPage,
                        currentPage,
                        totalPages,
                        setSelectAll
                      )}
                      <CustomPagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </Col>
                    <Col span={13} style={{ textAlign: "left" }}>
                      <Row>
                        {PaginationShowingSpan(
                          startItem,
                          endItem,
                          dataSourceCount
                        )}
                      </Row>
                    </Col>
                    <Col span={4}></Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                      {VehicleSearchForm(
                        searchForm,
                        searchTerm,
                        setSearchTerm,
                        handleSearch,
                        setIsEmptySearch,
                        isEmptySearch
                      )}
                    </Col>
                  </Row>
                </Card>

                {/* Selected & Submit to JPJ  */}
                <div
                  style={{
                    padding: 16,
                    backgroundColor:
                      getTotalSelectedKeys() !== 0 ? "#fffca4" : "white",
                  }}
                >
                  <Row justify="space-around" align="middle">
                    {getTotalSelectedKeys() !== 0 ? (
                      <>
                        <Col
                          span={getTotalSelectedKeys() === 0 ? 24 : 12}
                          style={{
                            textAlign: "left",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          &nbsp;
                          <ArrowElbowIcon />{" "}
                          {getTotalSelectedKeys() === maxSelectSize
                            ? "Selected the maximum of "
                            : "Selected "}
                          <Button
                            type="link"
                            style={{ padding: 2 }}
                            onClick={onSelectedVehicle}
                          >
                            <span className="custom-link-button-underline">
                              {getTotalSelectedKeys()} transactions
                            </span>
                          </Button>{" "}
                          for {TAB_TYPE_MESSAGE[tabType]}.
                          <Button
                            type="link"
                            onClick={handleDeselectThisPage}
                            style={{ padding: 2, marginLeft: 5 }}
                          >
                            <b>Deselect This Page</b>
                          </Button>
                          <Button
                            type="link"
                            style={{ padding: 2, marginLeft: 5 }}
                            onClick={handleClearAll}
                          >
                            <b>Clear All</b>
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col
                      span={getTotalSelectedKeys() === 0 ? 24 : 12}
                      style={{ textAlign: "right" }}
                    >
                      <>
                        {getTotalSelectedKeys() === 0 && (
                          <>
                            Select up to a maximum {maxSelectSize} vehicles for
                            JPJ submission.&nbsp;&nbsp;&nbsp;&nbsp;
                          </>
                        )}
                        {submitButton(false)}
                      </>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* Inventory Listing */}
              <Table
                className="custom-table"
                // rowClassName={rowClassName}
                bordered={true}
                dataSource={dataSource}
                columns={report.columns}
                loading={isLoading}
                pagination={false}
                sticky={{
                  offsetHeader: offsetHeader, // Adjust based on the height of other sticky elements
                  offsetScroll: 0,
                  getContainer: () => window,
                }}
                scroll={{
                  x: report.columns.length * 100,
                  // y: 800,
                }}
                onChange={(pagination, filters, sorter) => {
                  const sortMap = {
                    "createdDate-ascend": "1A",
                    "createdDate-descend": "1D",
                  };
                  const sortKey = sorter.field + "-" + sorter.order;
                  const newSortBy = sortMap[sortKey] || null;
                  if (newSortBy) setSortBy(newSortBy);
                  setSortOrder(sorter.order);
                  getData(currentPage, newSortBy);
                  // handleSort(sorter.order);
                }}
                onRow={(record, rowIndex) => ({
                  onClick: () => {},
                })}
              />

              {/* Modal pop out */}
              {selectedVehicleTableModal()}

              <ApprovalInModal
                isIncorrectOtp={isIncorrectOtp}
                isModalVisible={isApprovalModalVisible}
                transferIdList={selectedDataSourceKey}
                isAdminUser={isAdminUser}
                setIsModalVisible={setIsApprovalModalVisible}
                setIsApprovalRejected={setIsApprovalRejected}
                processApproval={report.bulkSubmit}
                setIsIncorrectOtp={setIsIncorrectOtp}
                approvalModuleType={approvalModuleType}
                setBulkTransferId={setBulkTransferId}
                moduleType={moduleType}
                transactionType={transactionType}
                isM2mTransaction={tabType === TAB_TYPE.M2M}
                bulkTransferId={""}
                transferId={""}
              />
              <ApprovalStatusModal
                isModalVisible={isApprovalStatusModalVisible}
                setIsModalVisible={setIsApprovalStatusModalVisible}
                bulkDetails={bulkDetails}
                isM2mBulkCompleted={isM2mBulkCompleted}
                vehicleDataList={selectedDataSourceKey}
                handleClearAll={handleModalClearAll}
                isPayment={
                  tabType === TAB_TYPE.PAYMENT || tabType === TAB_TYPE.M2M
                }
                isOutM2m={
                  pendingType === REPORT_TYPE_LABELS.OUT &&
                  tabType === TAB_TYPE.M2M
                }
                setTab={setTabType}
                selectedDataSourceAmount={selectedDataSourceAmount}
                balanceAmount={balanceAmount}
              />
              <TransactionAmountModal
                isModalVisible={isAmountModalVisible}
                setIsModalVisible={setIsAmountModalVisible}
                dataSource={selectedAmount}
              />
            </>
          )}
        </div>
      </>
      {/* Footer */}
      {isEditVehicle ? (
        <div className="section-bottom-button">
          {/* {BackButton(onBackButtonClick)} */}
          {/* {UpdateButton(onUpdateVehicle)} */}
        </div>
      ) : (
        <div className="section-bottom-button">
          {BackButton(onBackButtonClick)}
        </div>
      )}
      {/* To refactor to component and assign to unique classname to avoid confusion */}
      <style>
        {`
              // custom-modal

                .custom-table-selected-row {
                  background-color: #f0f4fc !important; /* Light blue background color */
                }

                .custom-link-button-underline {
                  text-decoration: underline;
                }
                .custom-link-button-underline:hover {
                  text-decoration: underline;
                }
                .ant-table-sticky-holder {
                  z-index: 996;
                }
                /* Make the card sticky */
                .sticky-card {
                  position: sticky;
                  top: 50px;
                  z-index: 999;
                }
                .custom-table .ant-table {
                  border-radius: 0 !important;
                }
                
                .custom-table .ant-table-thead > tr > th {
                  border-radius: 0 !important;
                }
                
                .custom-table .ant-table-tbody > tr > td {
                  border-radius: 0 !important;
                }
                .custom-table .ant-table-thead > tr > th {
                  background: #f0ecec;
                }
                .site-page-header {
                  padding-left: 0px;
                  padding-bottom: 15px;
                }
                .search-header {
                  font: italic normal normal 16px/22px Open Sans;
                  color: white;
                  margin-bottom: 7px;
                }
                .add-new-user-button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 127px !important;
                }
                .add-new-user-button > span {
                  font-size: 16px;
                }
                .export-report {
                  padding-left: 30px;
                  padding-right: 30px;
                  box-shadow: none;
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                  // height: 45px !important;
                }
                .ant-select-single .ant-select-selector {
                  align-items: center;
                  color: #00000080;
                  font-weight: 600;
                }
                .ant-select-arrow {
                  margin: 0px;
                }
                .section-card-left {
                  text-align: left !important;
                  color: black !important;
                }
                .ant-card-left {
                  text-align: left !important;
                }
                .ant-pagination {
                  display: none;
                }
                // .ant-table-selection {
                //   text-align: center;
                // }
                .red-asterisk {
                  color: red;
                  font-weight: bold;
                }
                .ant-form-item-label
                  > label.ant-form-item-required:not(
                    .ant-form-item-required-mark-optional
                  )::before {
                  display: none;
                }
                // .ant-modal-content {
                //     width: max-content;
                // }
                .ant-card {
                  border-radius: 0px !important;
                }
            `}
      </style>
    </>
  );
}
