import { Steps } from "antd"
import { PageHeader } from '@ant-design/pro-components';
import React, { useContext, useEffect, useState } from "react"
import JPJVerification from "./jpjVerification"
import SelectCompany from "./selectCompany"
import SellerApproval from "./sellerApproval"
import VehicleInformation from "./vehicleInformation"
import "./index.less";
import { CompanyResponse, VehicleResponse } from "./types"
import { getAuthorizedNric } from "../../services/local"
import { SessionContext } from "../../App"
import { useLocation } from 'react-router-dom'
export type PropsFromChildren = {
    companyProps?: CompanyResponse
    vehicleProps?: VehicleResponse
    transferId?: number
    bulkTransferId?: number
}

// Member to Member (M2M) is unpolished because the future was disabled halfway during development.
// The basic flow works, but it haven't been tested thoroughly by QA.
export default function Member2MemberPageV2() {
    const { user } = useContext(SessionContext)
    const [current, setCurrent] = useState(0)
    const [company, setCompany] = useState({} as CompanyResponse)
    const [vehicle, setVehicle] = useState({} as VehicleResponse)
    const [transferId, setTransferId] = useState(0)
    const [bulkTransferId, setBulkTransferId] = useState(0)
    const [isApprover, setIsApprover] = useState(false)
    const { state: locationState } = useLocation<Record<string, any>>()
    const [pendingType, setPendingType] = useState("OUT")
    const [isBulk, setIsBulk] = useState(false)
    const [isReport, setIsReport] = useState(false)
    const [isVehicleEdit, setIsVehicleEdit] = useState(false)
    const [isFromAdminPage, setIsFromAdminPage] = useState(false)
    const [isJpjSubmitted, setIsJpjSubmitted] = useState(false)
    const [sellerInquirySuccess, setSellerInquirySuccess] = useState(false)

    useEffect(()=>{
        if(locationState?.rowData) { //cater pending list m2m
            setCurrent(1)
            setPendingType(locationState?.type)
            setIsBulk(locationState?.isBulk) //indicate if is bulk transfer
            setIsReport(locationState?.isReport) //indicate if is for report view
            setIsVehicleEdit(locationState?.isVehicleEdit)   //indicate if is to process edit vehicle
            setIsFromAdminPage(locationState?.fromAdminPage) //indicate if is from admin search page
            setIsJpjSubmitted(locationState?.isJpjSubmitted) //indicate if is done jpj inquiry save-m2m-proceed and failed
            setSellerInquirySuccess(locationState?.sellerInquirySuccess) //indicate if is inquiry success and pending approval
            const vehicleResponse: VehicleResponse= {
                companyId: locationState?.rowData?.m2mCompanyId,
                inventory: {
                    id: locationState?.rowData?.id,
                    vehicleNo: locationState?.rowData?.carRegistrationNo,
                    chassisNo: locationState?.rowData?.chassisNo,
                    engineNo: locationState?.rowData?.engineNo,
                    manufacturerYear: locationState?.rowData?.manufactureYear,
                    thumbInUser: "",
                    transactionDate: locationState?.rowData?.createdDate,
                    transactionStatus: locationState?.rowData?.status,
                    transactionType: "0",
                    isWhiteListVehicle: false, //30.3.2022 daniel.kwok hardcoded defaults
                    lkmoption: 1, //30.3.2022 daniel.kwok hardcoded defaults
                    branchId: "",
                },
                message: "00",
            }
            setVehicle(vehicleResponse);
            setTransferId(locationState?.rowData?.id)
        }
    }, [locationState?.rowData])

    const nextStep = (data?: PropsFromChildren) => {
        if(data) {
            if(data.companyProps) {
                setCompany(data.companyProps)
            }
            if(data.vehicleProps) {
                setVehicle(data.vehicleProps)
            }
            if(data.transferId) {
                setTransferId(data.transferId)
            }
            if(data.bulkTransferId) {
                setBulkTransferId(data.bulkTransferId)
            }
        }
        // M2M doesn't use history.push to navigate between steps, instead it follow the method shown by antd docs.
        // Reference: https://ant.design/components/steps/#components-steps-demo-simple
        // By using this way, it is shorter to write, but user can't navigate the step back and forth
        // using browser's back button. User will need to restart M2M flow from the start if using browser's back.
        setCurrent(current + 1);
    };

    const prevStep = () => {
        setCurrent(current - 1);
    };

    useEffect(() => {
        if(user.nric === getAuthorizedNric()) {
            setIsApprover(true)
        }
    }, [user.nric])
    

    const STEPS_COMPONENTS = [
        {
            title: "Select Company",
            component: <SelectCompany next={nextStep} />,
        },
        {
            title: "Vehicle Information",
            component: <VehicleInformation company={company} vehicle={vehicle} transferId={transferId} 
                        pendingType={pendingType} isBulk={isBulk} isReport={isReport} isVehicleEdit={isVehicleEdit} 
                        isFromAdminPage={isFromAdminPage} isJpjSubmitted={isJpjSubmitted} next={nextStep} prev={prevStep} />,
        },
        {
            title: "JPJ Verification",
            component: <JPJVerification company={company} vehicle={vehicle} transferId={transferId} isJpjSubmitted={isJpjSubmitted} 
            isReport={isReport} isFromAdminPage={isFromAdminPage} sellerInquirySuccess={sellerInquirySuccess} bulkTransferId={bulkTransferId} next={nextStep} />,
        },
        {
            title: 'Approved',
            component: <SellerApproval transferId={transferId} bulkTransferId={bulkTransferId} isApprover={isApprover} next={nextStep} />,
        },
    ]

    return (
        <div className="step-form-container">
            <PageHeader
                className="site-page-header"
                title={'Member to Member'}
            />
            <Steps
                className="steps-progress"
                labelPlacement='vertical'
                size="small"
                current={current}
            >
                {
                    STEPS_COMPONENTS.map(step => {
                        return <Steps.Step
                            key={step.title} title={<span style={{ color: 'white' }}>{step.title}</span>}
                        />
                    })
                }
            </Steps>

            <div className="steps-content">{STEPS_COMPONENTS[current].component}</div>
            <style>
                {`
                .ant-page-header .ant-page-header-heading-title {
                    color: white;
                    font-size: 25px;
                    padding: 10px;
                }
            `}
            </style>
        </div>
    )
}
