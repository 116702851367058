// @ts-nocheck
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Tooltip,
} from "antd";
import React from "react";
import { SEARCH_QUERY_TOOLTIP } from "../../constants";

/* 
Component for vehicleInformation
TODO - OUT & I2I module
*/
// ** import {vehicleRegistrationNumber} from '../../components/VehicleInformation'
export const vehicleRegistrationNumber = (
  styles,
  searchTerm,
  onChangeVehicleInput,
  vehicleLoading,
  handleCheckRegistrationNo,
  isUpdate
) => {
  return (
    <>
      <Col className="gutter-row" span={8}>
        <Form.Item
          label={<p style={styles.label}>Vehicle Registration Number</p>}
          name="vehicleRegistrationNo"
          rules={[
            {
              required: true,
              pattern: new RegExp("^[a-zA-Z0-9]{2,20}$"),
              message: (
                <ol>
                  <li>{"Must be between 2 - 20 characters"}</li>
                  <li>{"Allow for (a-z), (A-Z), (0-9)"}</li>
                </ol>
              ),
            },
          ]}
        >
          {/* Input Box conditional for new / update */}
          {isUpdate ? (
            <Input
              disabled
              placeholder="Enter vehicle registration number"
              style={{ width: "100%" }}
              className="textbox-form-style"
              onChange={(e) => {
                onChangeVehicleInput(e);
              }}
            />
          ) : (
            <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
              <Input
                placeholder="Enter vehicle registration number"
                style={{ width: "100%" }}
                className="textbox-form-style"
                value={searchTerm}
                onChange={(e) => {
                  onChangeVehicleInput(e);
                }}
              />
            </Tooltip>
          )}
        </Form.Item>
      </Col>
      {handleCheckRegistrationNo && (
        <Col
          className="gutter-row"
          span={8}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            loading={vehicleLoading}
            type="primary"
            style={{ width: "100%" }}
            onClick={handleCheckRegistrationNo}
          >
            Check Registration Number
          </Button>
        </Col>
      )}
    </>
  );
};

export const make = (styles, props, history, isUpdate) => {
  return (
    <>
      <Form.Item
        label={<p style={styles.label}>Make</p>}
        name="carMake"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Make is required`,
          },
        ]}
      >
        {isUpdate ? (
          <Input
            disabled={
              !props?.vehicle?.carMake ||
              history?.location?.state?.fromAdminPage == true
                ? false
                : true
            }
            style={{ width: "100%" }}
            className="textbox-form-style"
            placeholder="Enter vehicle make"
          />
        ) : (
          <Input
            style={{ width: "100%" }}
            className="textbox-form-style"
            placeholder="Enter vehicle make"
          />
        )}
      </Form.Item>
    </>
  );
};

export const model = (styles, props, history, isUpdate) => {
  return (
    <>
      <Form.Item
        label={<p style={styles.label}>Model</p>}
        name="carModel"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Model is required`,
          },
        ]}
      >
        {isUpdate ? (
          <Input
            disabled={
              !props?.vehicle?.carModel ||
              history?.location?.state?.fromAdminPage == true
                ? false
                : true
            }
            style={{ width: "100%" }}
            className="textbox-form-style"
            placeholder="Enter vehicle model"
          />
        ) : (
          <Input
            style={{ width: "100%" }}
            className="textbox-form-style"
            placeholder="Enter vehicle model"
          />
        )}
      </Form.Item>
    </>
  );
};

export const manufactureYear = (styles, props, history, disabledYear, isUpdate) => {
  return (
    <>
      <Form.Item
        className="custom-select"
        label={<p style={styles.label}>Manufacture Year</p>}
        name="manufactureYear"
        rules={[
          {
            required: true,
            message: `Manufacture Year is required`,
          },
        ]}
      >
        {isUpdate ? (
          <DatePicker.YearPicker
            disabled={
              !props?.vehicle?.manufactureYear ||
              history?.location?.state?.fromAdminPage == true
                ? false
                : true
            }
            disabledDate={disabledYear}
            style={{ width: "100%", height: 41 }}
            placeholder="Select year"
          />
        ) : (
          <DatePicker.YearPicker
            disabledDate={disabledYear}
            style={{ width: "100%", height: 41 }}
            placeholder="Select year"
          />
        )}
      </Form.Item>
    </>
  );
};

export const chassisNumber = (styles) => {
  return (
    <>
      <Form.Item
        label={<p style={styles.label}>Chassis Number</p>}
        name="chassisNo"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Chassis Number is required`,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          className="textbox-form-style"
          placeholder="Enter chassis number"
        />
      </Form.Item>
    </>
  );
};

export const engineNumber = (styles) => {
  return (
    <>
      <Form.Item
        label={<p style={styles.label}>Engine Number</p>}
        name="engineNo"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Engine Number is required`,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          className="textbox-form-style"
          placeholder="Enter engine number"
        />
      </Form.Item>
    </>
  );
};

export const vehicleType = (styles, vehicleLoading, vehicleTypes, history, isUpdate) => {
  return (
    <>
      <Form.Item
        label={<p style={styles.label}>Vehicle Type</p>}
        name="vehicleType"
        rules={[
          {
            required: true,
            message: `Vehicle Type is required`,
          },
        ]}
      >
        <Select
          disabled={
            isUpdate
              ? history?.location?.state?.fromAdminPage == true
                ? false
                : true
              : false
          }
          className="custom-select"
          showSearch
          placeholder="Select a vehicle type"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: "100%", height: 41 }}
          loading={vehicleLoading}
        >
          {vehicleTypes.map((vt) => {
            return (
              <Select.Option value={vt.id} key={vt.id}>
                {vt.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};
