import React, { useEffect, useState } from 'react'
import {
    Input,
    Button,
    Form,
    Card,
    Typography,
    message,
    Skeleton,
    Progress,
} from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as CryptoJS from 'crypto-js'
import './index.css'
import { logout, resetPassword, verifyResetPasswordLinkValidity } from '../../services/api'
import { getToken, removeUserRole } from '../../services/local'

const TOKEN_STATUSES = {
    VERIFYING_TOKEN: 'VERIFYING_TOKEN',
    VERIFIED: 'VERIFIED',
    EXPIRED: 'EXPIRED'
}

const ENCRIPTION_KEY = 'vj875^v&%9)7ShjL^+_)(^h@$qy7)7w6'

export default function ResetPasswordPage() {
    const history = useHistory()
    const params = useParams()
    const { token } = params

    const [isLoading, setIsLoading] = useState(false)
    const [tokenStatus, setTokenStatus] = useState(TOKEN_STATUSES.VERIFYING_TOKEN)
    const [username, setUsername] = useState(null)
    /**
     * newPassword & confirmNewPasswords needs to be diff at first
     */
    const [newPassword, setNewPassword] = useState(null)
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const passwordStrengthMap = {
		WEAK: {
			percent: 30,
			color: "red",
			message: "Weak",
			messageBody: "It will be easy for a computer to crack your password.",
		},
		NORMAL: {
			percent: 50,
			color: "orange",
			message: "Normal",
			messageBody: "It will take some time for a computer to crack your password.",
		},
		STRONG: {
			percent: 91,
			color: "green",
			message: "Strong",
			messageBody: "It will take a very long time for a computer to crack your password.",
		},
	};
	const [showPasswordStrength, setShowPasswordStrength] = useState(false);
	const [passwordStrength, setPasswordStrength] = useState(passwordStrengthMap["WEAK"]);

    /**
     * 14/3/2022 daniel.kwok
     * Using verifyResetPasswordLinkValidity to validate if
     * reset password link is valid
     * 
     * Could be invalid, e.g. too long ago, or multiple links were
     * requested in succession
     */
    useEffect(async () => {
        const { username, expiryDate } = getDecryptedToken(token)
        setUsername(username)


        const string = `username=${username}&expiryDate=${expiryDate}`

        const keyHex = CryptoJS.enc.Utf8.parse(ENCRIPTION_KEY);
        const encryptedString = CryptoJS.TripleDES
            .encrypt(string, keyHex, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            })
            .toString()

        const encodedEncryptedString = encodeURIComponent(encryptedString)
        
        verifyResetPasswordLinkValidity(encodedEncryptedString)
            .then(res => {
                if(res.status !== true) throw res.message
                setTokenStatus(TOKEN_STATUSES.VERIFIED)
            })
            .catch(err => {
                err && message.error(err)
                setTokenStatus(TOKEN_STATUSES.EXPIRED)
            })
    }, [])

    useEffect(async () => {
        const isLoggedIn = getToken()
        if (isLoggedIn) {
            await removeUserRole()
            logout()
           
            window.location.reload()
        }
    }, [])


    /**
     * Decrypt token to determine
     * user, so we know which user's password is being changed
     * date, so we know if this request is expired
     * @param {String} token 
     */
    function getDecryptedToken(token) {
        const decodedToken = decodeURIComponent(token)
        var keyHex = CryptoJS.enc.Utf8.parse(ENCRIPTION_KEY);

        const decrypted = CryptoJS.TripleDES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(decodedToken)
        }, keyHex, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });

        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8)

        if (!decryptedString) return {}

        const [usernamestring, datestring] = decryptedString.split('&')
        const username = usernamestring.split('=')[1]
        const expiryDate = datestring.split('=')[1]

        return { username, expiryDate }
    }

    function getEncryptParams() {
        const { username, expiryDate } = getDecryptedToken(token)
        /**
         * 15/3/2022 daniel.kwok
         * expiryDate is the date when user requests to reset password
         * This prevents user from re-setting their passwords repeatedly in the case where they've
         * requested multiple reset password links.
         * 
         * Once they've reset password via one of the link, the reset is automatically invalidated
         */

        const message = `username=${username}&password=${newPassword}&confirmPassword=${confirmNewPassword}&expiryDate=${expiryDate}`
        var keyHex = CryptoJS.enc.Utf8.parse(ENCRIPTION_KEY);

        var encrypted = CryptoJS.TripleDES
            .encrypt(message, keyHex, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            })
            .toString()

        return encrypted
    }

    const checkPasswordStrength = (password) => {
		// Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter and one number:
		const passwordNormalRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/;
		// Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
		const passwordStrongRegex =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~`])[A-Za-z\d!@#$%^&*()_+|~`]{8,12}$/;

		// re-render PasswordStrengthComponent everytime strength is changed
		if (password) {
			setShowPasswordStrength(true);

			// if password matches normal strength regex, change strength state to normal
			passwordNormalRegex.test(password) && setPasswordStrength(passwordStrengthMap["NORMAL"]);
			// if password matches strong strength regex, change stregth state to strong
			passwordStrongRegex.test(password) && setPasswordStrength(passwordStrengthMap["STRONG"]);
			// otherwise, change strength to weak
			!passwordNormalRegex.test(password) &&
				!passwordStrongRegex.test(password) &&
				setPasswordStrength(passwordStrengthMap["WEAK"]);
		} else {
			setShowPasswordStrength(false);
		}
	};

	const PasswordStrengthComponent = () => {
		return showPasswordStrength ? (
			<>
				<Progress percent={passwordStrength.percent} strokeColor={passwordStrength.color} showInfo={false} />
				<p>
					<span style={{ fontWeight: "bold", color: passwordStrength.color }}>
						{passwordStrength.message}.{" "}
					</span>
					{passwordStrength.messageBody}
				</p>
			</>
		) : (
			<></>
		);
	};

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography.Title
                style={{
                    color: 'white'
                }}
            >
                Twocar
            </Typography.Title>

            <Card className='styled-card'>
                <h2 >Reset your password</h2>

                {
                    tokenStatus === TOKEN_STATUSES.VERIFYING_TOKEN ? (
                        <Skeleton active />
                    ) : tokenStatus === TOKEN_STATUSES.EXPIRED ? (
                        <>
                            <p>Link invalid/expired. &nbsp;
                                <a
                                    id='back-link'
                                    onClick={() => history.push('/forgot-password')}
                                >
                                    Try again ?
                                </a>
                            </p>
                        </>
                    ) : (

                        <Form
                            layout={'vertical'}
                            style={{
                                textAlign: 'left'
                            }}
                            onFinish={values => {
                                setIsLoading(true)

                                const encryptedString = getEncryptParams()
                                const encodedString = encodeURIComponent(encryptedString)

                                resetPassword(encodedString)
                                    .then(res => {
                                        if (!res.status) throw (res.details && res.details[0]) || res.message || res.error

                                        message.success(`Successfully reset password`)
                                        window.location.href = '/login'
                                    })
                                    .catch(err => err && message.error(err.toString()))
                                    .finally(() => {
                                        setIsLoading(false)
                                    })
                            }}
                            initialValues={{

                            }}
                        >
                            <Form.Item
                                rules={[
							{
								required: true,
								message: "Please input your new password",
							},
							{
								max: 12,
								min: 8,
								message: "Password must have 8 - 12 characters",
							},
							{
								pattern: /^(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least one capital letter",
							},
							{
								pattern: /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least one alphanumerical character",
							},
							{
								pattern: /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
								message: "New password must have at least a number",
							},
						]}
                                name='new_password'
                                label={`New password`}>
                                <Input.Password
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                        checkPasswordStrength(e.target.value)
                                    }}
                                />
                            </Form.Item>
                            {PasswordStrengthComponent()}
                            {/* <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        pattern: new RegExp(newPassword),
                                        message: ['Must be the same as new password'],
                                    },
                                ]}
                                name='confirm_new_password'
                                label={`Confirm password`}>
                                <Input.Password
                                    value={confirmNewPassword}
                                    onChange={e => {
                                        setConfirmNewPassword(e.target.value)
                                    }}
                                />
                            </Form.Item> */}
                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['newPassword']}
                                hasFeedback
                                rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                () => ({
                                    validator(_, confirmNewPassword) {
                                    if (!confirmNewPassword || newPassword === confirmNewPassword) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Must be the same as new password'));
                                    },
                                }),
                                ]}
                            >
                                <Input.Password
                                    value={confirmNewPassword}
                                    onChange={e => {
                                        setConfirmNewPassword(e.target.value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ textAlign: 'center', }}
                            >
                                <Button
                                    loading={isLoading}
                                    type='primary'
                                    style={{ width: '100%' }}
                                    htmlType='submit'
                                >
                                    Change password
                                </Button>
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    // offset: 8,
                                    span: 24,
                                }}
                                style={{ textAlign: 'center' }}
                            >
                                <a
                                    id='back-link'
                                    onClick={() => window.location.pathname = '/login'}
                                >
                                    Back to login
                                </a>
                            </Form.Item>

                        </Form>
                    )
                }
            </Card>
        </div>
    )
}
