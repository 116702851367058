import { Checkbox } from "antd";
import { links } from "../../../../config";
import "./index.css"; // Import your custom CSS file

export const TermAndConditionCheckbox = () => {
  return (
    <Checkbox>
      <p style={{ textAlign: "left" }}>
        I agree to transfer the ownership of this vehicle and take full
        responsibility for this transfer according to the agreed&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href={links.termsAndCondition}
          style={{ textDecoration: "underline" }}
        >
          Terms & Conditions
        </a> .
      </p>
    </Checkbox>
  );
};

export const TableBlueShadowCheckbox = ({ checked, onChange, disabled }) => {
  return (
    <Checkbox
      className="blue-shadow-checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
