import React from "react";
import { Alert } from "antd";
import { ReactComponent as InfoIcon } from "../../../../assets/icon/Icon-info-24.svg";

export const InfoAlert = (message) => {
  return (
    <Alert
      // closable
      // onClose={() => setisLowBalance(false)}
      showIcon={false}
      style={{marginTop: 0, marginBottom: "20px"}}
      className={"default-alert"}
      message={
        <>
          <span style={{verticalAlign: "middle", marginRight: "5px"}}>
            <InfoIcon />
          </span>{" "}
          <span dangerouslySetInnerHTML={{ __html: message }}></span>
        </>
      }
      banner
    />
  );
};