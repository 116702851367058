
/**
 * This is a constant file meant to keep all router state names
 * AUTHORIZE_BRANCH_USER     //step
 * INSERT_IC          //subStep
 */
export const STEPS = {
    AUTHORIZE_BRANCH_USER: {
        label: 'AUTHORIZE_BRANCH_USER',
        subSteps: {
            INSERT_IC: 'INSERT_IC',
            INSERTING_IC: 'INSERTING_IC',
            THUMBPRINT_BEFORE: 'THUMBPRINT_BEFORE',
            THUMBPRINTING: 'THUMBPRINTING',
            THUMBPRINT_FAILED: 'THUMBPRINT_FAILED',
            THUMBPRINT_SUCCESS: 'THUMBPRINT_SUCCESS',
            SUMMARY: 'SUMMARY',
            FORM: 'FORM',
            TWOFA: 'TWOFA',
            ADD_STAFF: 'ADD_STAFF',
            OTHER_BRANCH_FORM: 'OTHER_BRANCH_FORM'
        }
    },
}