import {
	Breadcrumb,
	Button,
	Card,
	Col,
	Form,
	Image,
	message,
	Row,
	Select,
	Table,
} from "antd";
import { PageHeader } from '@ant-design/pro-components';
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../App";
import arrow_back from "../../assets/arrow_back.svg";
import { getInventoryListCount } from "../../services/api";
import {
    HQ_BRANCH,
} from '../../constants';

export default function InterBranchTransferPage() {
	const [generateReport] = useForm();
	const { company, branchs } = useContext(SessionContext);
	const history = useHistory();
	const [dataSource, setDataSource] = useState([]);
	const [initialDataSource, setInitialDataSource] = useState([]);
	const [loading, setLoading] = useState(false)
	let allBranches = [HQ_BRANCH, ...branchs];

	const columns = [
		{
			title: "No",
			dataIndex: "key",
		},
		{
			title: "Branch ID",
			dataIndex: "id",
			render: (id) => (
				<Button
					type="link"
					onClick={() =>
						history.push(`/interbranchtransfer/${company.id}/${id}`)
					}
					style={{ textDecoration: "underline" }}
				>
					{id}
				</Button>
			),
		},
		{
			title: "Branch Name",
			dataIndex: "branchName",
			width: 300,
		},
		{
			title: "State",
			dataIndex: "state",
			render: (state) => (
				<span style={{ textTransform: "uppercase" }}>{state}</span>
			),
		},
		{
			title: "No. Of Car(s) Available",
			dataIndex: "inventoryCount",
			render: (inventoryCount) => inventoryCount ?? "-",
		},
	];

	const handleFilterBranch = async () => {
		const { branchId } = generateReport.getFieldsValue();
		let filteredBranch =
			branchId && branchId > 0
				? await Promise.all(
						allBranches
							.filter((branch) => branch.id === branchId)
							.map(async (branch, index) => {
								try {
									const currentInventoryCount =
										await getInventoryListCount(
											branch.id,
											company.id
										);
									return {
										key: index + 1,
										state: branch.branchAddress?.state,
										inventoryCount:
											currentInventoryCount.count,
										...branch,
									};
								} catch (err) {
									err && message.error(err);
								}
							})
				  )
				: initialDataSource;

		if (branchId === 0) {
			filteredBranch = await Promise.all(
					allBranches
					.filter((branch) => branch.id === 0)
					.map(async (branch, index) => {
						setLoading(true)
						try {
							const currentInventoryCount = await getInventoryListCount(
								branch.id,
								company.id
							);
							return {
								key: index + 1,
								state: branch.branchAddress?.state,
								inventoryCount: currentInventoryCount.count,
								...branch,
							};
						} catch (err) {
							err && message.error(err);
						} finally {
							setLoading(false)
						}
					})
				);
		}

		setDataSource(filteredBranch);
	};

	// get all inventory count of all branches
	useEffect(async () => {
		let refinedBranches = []
		if (allBranches) {
			setLoading(true)
			refinedBranches = await Promise.all(
				allBranches.map(async (branch, index) => {
					try {
						const currentInventoryCount = await getInventoryListCount(
							branch.id,
							company.id
						);
						return {
							key: index + 1,
							state: branch.branchAddress?.state,
							inventoryCount: currentInventoryCount.count,
							...branch,
						};
					} catch (err) {
						err && message.error(err);
					} finally {
						// TODO
					}
				})
			);
			setLoading(false)
		}

		setDataSource(refinedBranches);
		setInitialDataSource(refinedBranches);
	}, [branchs, company.coRegNo]);

	return (
		<>
			<div className="page-content">
				<Breadcrumb separator=">" style={{ marginTop: "60px" }}>
					<Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
					<Breadcrumb.Item href="/interbranchtransfer">
						Vehicle Inter-Branch Transfer
					</Breadcrumb.Item>
				</Breadcrumb>
				<PageHeader
					className="site-page-header"
					style={{ paddingLeft: 0 }}
					title="Vehicle Inter-Branch Transfer"
				/>
				<p className="site-page-header" style={{ marginBottom: 20 }}>
					You can transfer the vehicles between different branches in
					your own company.
				</p>
				<Card
					style={{
						borderRadius: 8,
						textAlign: "left",
						marginBottom: 20,
					}}
				>
					<h2>Generate inter-branch transfer report</h2>
					<Form
						form={generateReport}
						layout="vertical"
						onFinish={handleFilterBranch}
						initialValues={{
							branchId: "0",
						}}
					>
						<Row gutter={[20, 0]} className="filter-row">
							<Col span={6}>
								<Form.Item
									label="Branch Name"
									name={"branchId"}
								>
									<Select>
										<Select.Option key={"0"} value={"0"}>
											-- All --
										</Select.Option>
										{allBranches && allBranches.map((branch) => {
											return (
												<Select.Option
													key={branch.id}
													value={branch.id}
												>
													{branch.branchName}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							</Col>
							{/* Upon clicking on "Generate Report", this Form's onFinish() function will be triggered. */}
							{/* NOTE: To achieve this behaviour, <Button> needs to be a child of <Form.Item>, and <Form.Item> a child of <Form> */}
							<Col span={6}>
								<Form.Item style={{ paddingTop : "25px" }}>
									<Button
										className="add-new-user-button buttons"
										type="primary"
										htmlType="submit"
									>
										Generate Report
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Card>
				<Table
					className="report-table"
					columns={columns}
					dataSource={dataSource}
					loading={loading}
				/>
				<Button
					className="back-button buttons"
					shape="round"
					onClick={() => {
						history.goBack();
					}}
				>
					<Image src={arrow_back} preview={false} />
					Back
				</Button>
			</div>
			<style>
				{`
				.ant-select-selector { 
					border: 1px solid #2B61C4 !important;
					box-shadow: 0px 2px 4px #00000033 !important;
					background: #FFFFFF 0% 0% no-repeat padding-box !important;
					border-radius: 4px !important;
					height: 48px !important;
					display: flex;
					align-items: center;
				}
				.ant-select-arrow {
					color: #2B61C4;
				}
				.add-new-user-button.buttons { 
					height: 45px;
				}
				.filter-row {
					display: flex;
					// align-items: flex-end;
				}
				td {
					color: #333333;
				}
			`}
			</style>
		</>
	);
}
