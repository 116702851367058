import React, { useContext, useEffect, useState } from 'react'
import {
    Row,
    Col,
    Button,
    Card,
    Image,
    Skeleton
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'
import { I2I_TRANSACTION_TYPES } from "../../constants"
import propTypes from "prop-types"
import iconPending from '../../assets/icon-pending-list-60.svg'
import arrow_back from '../../assets/arrow_back.svg'
import { REPORT_TYPE_LABELS } from '../ReportingPage/TransferReportPage'
import {
    getRolesByUsername
} from "../../services/api";
import { SessionContext } from '../../App';


function TransactionType(props) {

    const { user } = useContext(SessionContext);
    const history = useHistory()
    const [filterI2iTxnList, setFilterI2iTxnList] = useState([])

    useEffect(() => {

        if (user.nric) {
            getRolesByUsername(user.nric)
                .then(res => {
                    if (res.status == true) {

                        const hasRunnerRole = res.roles && res.roles.some(role => ["MYEG_OWNER_RUNNER", "MYEG_RUNNER_2", "MYEG_RUNNER_1"].includes(role.name));

                        // Filter the I2I_TRANSACTION_TYPES array
                        const filteredI2iTxnList = I2I_TRANSACTION_TYPES.filter(ot => {
                            // If hasRunnerRole is true and ot.id is 2, filter it out
                            return !(hasRunnerRole && ot.id === 2);
                        });

                        // Set the filtered value to the state variable
                        setFilterI2iTxnList(filteredI2iTxnList);
                    }
                })
                .catch(err => {

                })
                .finally(() => { })
        }

    }, [user])

    return (
        <>
            {
                filterI2iTxnList.length === 0 ? (
                    <Skeleton active />
                ) :
                    (<Card style={{ height: '50%', width: '100%', background: 'transparent', border: "0px" }} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline'
                            }}>
                                <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#FFFFFF', }}>Start a new ownership transfer</h2>

                                {/* <Button
                        size="large"
                        className="i2i-pending-button"
                        shape='round'
                        style={{
                            background: ' #FFFFFF 0% 0% no-repeat padding-box',

                            font: 'normal normal 600 16px Raleway',
                            color: '#2B61C4',
                        }}
                        onClick={() => history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.I2I}`)}
                    ><Image src={iconPending} preview={false} width={20} />i2i PENDING LIST</Button> */}

                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {
                                    filterI2iTxnList
                                        .map(ot => {
                                            return <Col key={ot.id} xs={12} md={8}>
                                                <Card className={!ot.disabled ? 'ant-card-hover' : ' '} key={ot.label} onClick={() => {
                                                    if (!ot.disabled) {
                                                        if (ot.overridingUrl) {
                                                            window.open(ot.overridingUrl, '_blank')?.focus();
                                                        } else {
                                                            props.onChange(ot.id)
                                                        }
                                                    }
                                                }}
                                                    style={styles.ownershipTypeCard}
                                                >
                                                    <div

                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            height: 150
                                                        }}
                                                    >
                                                        <div
                                                            style={{ flex: 3, }}
                                                        >
                                                            <img alt="" src={ot.image} style={{ maxHeight: 100, filter: ot.disabled ? `grayscale(100%)` : '' }} />

                                                        </div>

                                                        <div
                                                            style={{ flex: 3 }}
                                                        >
                                                            <h1
                                                                style={{
                                                                    font: 'normal normal 600 24px Raleway',
                                                                    textAlign: 'left',
                                                                    color: '#16467D'
                                                                }}
                                                            >{ot.label}</h1>

                                                        </div>
                                                    </div>

                                                </Card>
                                            </Col>
                                        })
                                }

                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 16,
                                }}
                            >
                                <hr style={{ width: '100%', borderTop: '1px solid #D8D8D8' }} />
                                <span
                                    style={{
                                        position: 'relative',
                                        top: '-25px',
                                        background: '#D8D8D8',
                                        padding: '4px 12px',
                                        borderRadius: '20px',
                                        fontWeight: 'bold',
                                        color: '#575757',
                                    }}
                                >
                                    OR
                                </span>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column', // Added to stack items vertically
                                }}
                            >
                                <h2 style={{ fontWeight: 'bold', marginTop: 24, marginBottom: 32, font: 'normal normal normal 24px/33px Open Sans', color: '#333333', }}>Continue pending transfers</h2>

                                <div style={{ width: '100%', maxWidth: 400 }}> {/* Adjust the max width as needed */}
                                    <Card
                                        className={'ant-card-hover'}
                                        onClick={() => {
                                            history.push(`/reporting/transferreport/${REPORT_TYPE_LABELS.I2I}`)
                                        }}
                                        style={styles.ownershipTypeCard}
                                    >
                                        <div

                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                height: 150
                                            }}
                                        >

                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <img alt='' src={iconPending} style={{ maxHeight: 100, filter: '' }} />

                                            </div>

                                            <div
                                                style={{ flex: 3 }}
                                            >
                                                <h1
                                                    style={{
                                                        font: 'normal normal 600 24px Raleway',
                                                        textAlign: 'left',
                                                        color: '#16467D'
                                                    }}
                                                >I2I Pending List</h1>

                                            </div>
                                        </div>
                                    </Card>
                                </div>

                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 32
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        history.push('/')
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                                {/* <Button
                        style={{
                            width: 200
                        }}
                        disabled={!transactionType}
                        onClick={() => {
                            props.onChange(transactionType)
                        }}
                        type='primary'
                    >
                        Continue
                    </Button> */}
                            </div>

                        </div>
                    </Card >)
            }
        </>

    )
}

TransactionType.propTypes = {
    transactionType: propTypes.number,
    onChange: propTypes.func.isRequired
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0
    },
    value: {
        fontWeight: 'bold',
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: 8
    },
    ownershipTypeCardDisabled: {
        background: '#DDDDDD 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '8px',
        borderColor: 'transparent',
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 10px rgba(9, 8, 8, 0.393)`,
        transition: `box-shadow .1s`,

    }
}

export default TransactionType
