import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// import logo from '../../assets/logo-myeg.svg'
import logo from "../../assets/logo/TWOCAR-Logo-Black.png";
import { Card } from "antd";

import { logout } from "../../services/api";
import { removeUserRole } from "../../services/local";

export default function NotFoundPage() {
    const history = useHistory();
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);

    useEffect(async () => {
        if (history.location.pathname == "/login") {
            await logout();
            await removeUserRole();
            window.location.pathname = "/login";
        }

        if (history.location.pathname != "/otp") setShowNotFoundPage(true);
    }, [history.location.pathname]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            {showNotFoundPage == true && (
                <Card>
                    <img src={logo} height={50} />
                    <h1>Oops! We can't seem to find this page.</h1>
                    <a
                        style={{
                            color: "black",
                            textDecoration: "underline",
                        }}
                        onClick={() => (window.location.pathname = "/")}
                    >
                        Go to home
                    </a>
                </Card>
            )}
        </div>

        // <></>
    );
}
