import { Input, Table } from "antd";
import { HQ_BRANCH } from "../../../../constants";
const { Column } = Table;

export const VehicleUpdateTableComponent = (
  vehicleDataList,
  editVehicleForm
) => {
  return (
    <Table
      className="vehicle-table"
      dataSource={vehicleDataList}
      pagination={false}
    >
      <Column title="Vehicle Number" dataIndex="vehicleNo" key="vehicleNo" />
      <>
        <Column
          title="Chassis Number"
          dataIndex="chassisNo"
          key="chassisNo"
          render={(text, record) => (
            <Input
              defaultValue={text}
              onChange={(e) =>
                editVehicleForm.setFieldsValue({
                  ...editVehicleForm,
                  chassisNo: e.target.value,
                })
              }
            />
          )}
        />
        <Column
          title="Engine Number"
          dataIndex="engineNo"
          key="engineNo"
          render={(text, record) => (
            <Input
              defaultValue={text}
              onChange={(e) =>
                editVehicleForm.setFieldsValue({
                  ...editVehicleForm,
                  engineNo: e.target.value,
                })
              }
            />
          )}
        />
      </>
    </Table>
  );
};

export const VehicleSelectedTableComponent = (
  selectedDataSource,
  branchs,
  isStickyHeader,
  isCustomPagination,
  isAllowBranch
) => {
  const branchColumnTitle =
    isStickyHeader || isCustomPagination ? "Location" : "Branch";
  const isScroll = !isStickyHeader && !isCustomPagination;
  //type
  return (
    <Table
      className="vehicle-table"
      bordered={true}
      dataSource={selectedDataSource}
      pagination={false}
      scroll={
        isScroll
          ? {
              y: 800,
            }
          : false
      }
      sticky={
        isStickyHeader ? { offsetHeader: 0, getContainer: () => window } : false
      }
    >
      <Column title="No" width={"5%"} dataIndex="no" key="no" />
      <Column title="Vehicle Number" dataIndex="vehicleNo" key="vehicleNo" />
      <Column title="Chassis Number" dataIndex="chassisNo" key="chassisNo" />
      <Column title="Engine Number" dataIndex="engineNo" key="engineNo" />
      {isAllowBranch && <Column
        title={branchColumnTitle}
        dataIndex="branchId"
        key="branch"
        render={(branchId) => {
          let allBranches = branchs ? [...branchs, HQ_BRANCH] : [HQ_BRANCH];
          return allBranches.find((b) => b.id == branchId)?.branchName || "-";
        }}
      />}
    </Table>
  );
};

const VehicleDetailsTableColumns = [
  {
    title: "No",
    key: "No",
    width: 50,
    dataIndex: "key",
  },
  {
    title: "Vehicle Details",
    key: "vehicle",
    width: 400,
    render: (rowData) => {
      return (
        <p>
          <b>{rowData.carRegistrationNo}</b>
          <br />
          {rowData.chassisNo}
          <br />
          {rowData.engineNo}
        </p>
      );
    },
  },
  {
    title: "JPJ Enquiry details",
    key: "inquiry",
    onCell: (rowData) => ({
      style: {
        backgroundColor: rowData.failCode ? "#ffefef" : "",
      },
    }),
    render: (rowData) => {
      return (
        <>
          {rowData.failCode ? (
            <p> {rowData.failCode}</p>
          ) : (
            <p>
              Not blacklisted
              <br />
              No ownership claim
            </p>
          )}
        </>
      );
    },
  },
];

export const VehicleDetailsTableComponent = (dataSource) => (
  <Table
    className="vehicle-table"
    dataSource={dataSource}
    columns={VehicleDetailsTableColumns}
    pagination={false}
  />
);
