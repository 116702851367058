import React, { useState } from 'react'
import {
    Input,
    Button,
    Form,
    Card,
    Typography,
    message,
} from 'antd'
import { useHistory } from 'react-router-dom'

import './index.css'
import { 
    forgotPassword,
    forgotPasswordNric,
} from '../../services/api'

const STATUSES = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
}

export default function ForgotPasswordEmailSentPage() {

    const history = useHistory()

    // const [username, setUsername] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(STATUSES.PENDING)
    const [nric, setNric] = useState('')
    const [emailAddress, setEmailAddress] = useState('')


    function onSubmitResetPassword() {
        setIsLoading(true)

        forgotPasswordNric(nric)
            .then(res => {

                if (!res.status) throw (res.error_description) || res.message || `Error. Try again `

                message.success(res.message)
                setStatus(STATUSES.SUCCESS)
                setEmailAddress(res.email)
            })
            .catch(err => {
                err && message.error(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
        /* forgotPassword(username)
            .then(res => {

                if (!res.status) throw (res.error_description) || res.message || `Error. Try again `

                message.success(res.message)
                setStatus(STATUSES.SUCCESS)
            })
            .catch(err => {
                err && message.error(err)
            })
            .finally(() => {
                setIsLoading(false)
            }) */
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <Typography.Title
                style={{
                    color: 'white'
                }}
            >
                Twocar
            </Typography.Title>
            <Card className='styled-card'>
                {
                    status === STATUSES.PENDING ? (
                        <Form
                            layout={'vertical'}
                        >

                            <h2 >Forgot password ?</h2>
                            <Form.Item
                                // label='Email'
                                // name='username'
                                label='Mykad number'
                                name='nric'
                                className='label-italic'
                                onKeyPress={e => {
                                    if (!/[0-9]/.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
        
                            >
                                <Input
                                    value={nric}
                                    placeholder={'Enter Mykad number'}
                                    maxLength={12}
                                    onChange={e => {
                                        // let _username = e.target.value.trim()
                                        setNric(e.target.value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    loading={isLoading}
                                    disabled={!nric}
                                    style={{
                                        width: '100%'
                                    }}
                                    onClick={onSubmitResetPassword}
                                    type="primary" htmlType="submit">
                                    Send reset password email
                                </Button>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    // offset: 8,
                                    span: 24,
                                }}
                                style={{ textAlign: 'center' }}
                            >
                                <a
                                    id='back-link'
                                    onClick={() => history.push('/login')}
                                >
                                    Back to login
                                </a>
                            </Form.Item>

                        </Form>
                    // A link had been sent to your email.
                    ) : status === STATUSES.SUCCESS ? (
                        <>
                            <p>
                                A link had been sent to {emailAddress}.
                                Click the link within 24 hours to reset your password.
                            </p>
                            <a
                                id='back-link'
                                onClick={() => history.push('/login')}
                            >
                                Back to login
                            </a>
                        </>
                    ) : null
                }

            </Card>
        </div>
    )
}
