import { Input, Radio } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SessionContext } from "../../../App";
import {
  searchInTransactions,
  searchI2iTransactions,
  searchOutTransactions,
  searchM2mTransactions,
} from "../../../services/api";
import "./index.less";
import CustomPaginationTable, {
  REPORT_TYPE_LABELS,
  TAB_TYPE,
  STATUS_TYPE,
  SEARCH,
} from "../../../components/CustomPagination/TransactionTable";
import { getPermissions } from "../../../services/local";

export const ROUTE_PENDING_TYPE = {
  IN: "In",
  OUT: "Out",
  I2I: "i2i",
};

export default function TransferReportBulkPageV2() {
  const location = useLocation();
  const history = useHistory();
  // const { pendingType } = useParams();
  const transferReportUrl = "/reporting/transferreport/";
  const pendingType =
    location.pathname === transferReportUrl + ROUTE_PENDING_TYPE.IN
      ? REPORT_TYPE_LABELS.IN
      : location.pathname === transferReportUrl + ROUTE_PENDING_TYPE.OUT
      ? REPORT_TYPE_LABELS.OUT
      : REPORT_TYPE_LABELS.I2I;

  const { company, branch, branchs, user } = useContext(SessionContext);
  const historyTabType = history.location.state?.tabType ?? "1";

  const [tabType, setTabType] = useState(historyTabType);
  const [jpjSUbmissionCount, setJpjSubmissionCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [m2mCount, setM2mCount] = useState(0);

  const [permissions, setPermissions] = useState([]);
  // const [reportType, setReportType] = useState(REPORT_TYPE_LABELS.IN);
  const ReceiptType = {
    vehicleDealerCopy: "1",
    confirmationSlip: "2",
    dealerCopy: "3",
  };

  const isI2I = pendingType === REPORT_TYPE_LABELS.I2I;
  const isOUT = pendingType === REPORT_TYPE_LABELS.OUT;
  const isOutTransaction = permissions.outTransaction;
  const isM2mTransaction = permissions.m2mTransaction;
  const isHideM2m = isI2I || (isOUT && isOutTransaction && !isM2mTransaction);
  const isHideOut = isOUT && !isOutTransaction && isM2mTransaction;

  const tabTypeCountMap = isHideM2m
    ? [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT]
    : isHideOut
    ? [TAB_TYPE.M2M]
    : [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT, TAB_TYPE.M2M];

  // isHideOut && setTabType(TAB_TYPE.M2M)
  // const tabTypeCountMap = !isI2I
  //   ? [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT, TAB_TYPE.M2M]
  //   : [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT];

  const jpjSubmitTabStyle = tabType === "1" ? "#f0f4fc" : "white";
  const paymentTabStyle = tabType === "2" ? "#f0f4fc" : "white";
  const m2mTabStyle = tabType === "3" ? "#f0f4fc" : "white";
  const CustomRadio = () => {
    return (
      <>
        <div
          style={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}
        >
          <Radio.Group
            className="full-width-radio-group"
            defaultValue={tabType}
            onChange={(e) => {
              setTabType(e.target.value);
            }}
          >
            {!isHideOut && (
              <>
                <Radio.Button
                  value="1"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "0px",
                    background: jpjSubmitTabStyle,
                  }}
                >
                  <b>JPJ submission</b>{" "}
                  <Input
                    style={{ background: jpjSubmitTabStyle }}
                    className="custom-input"
                    value={jpjSUbmissionCount}
                    readOnly
                  />
                  {!isHideOut && (
                    <div
                      className="pointer"
                      style={{ background: jpjSubmitTabStyle }}
                    ></div>
                  )}
                </Radio.Button>
                <Radio.Button
                  value="2"
                  style={{
                    background: paymentTabStyle,
                    borderTopRightRadius: isHideM2m ? "10px" : "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <b style={{ marginLeft: "20px" }}>Payment</b>{" "}
                  <Input
                    style={{ background: paymentTabStyle }}
                    className="custom-input"
                    value={paymentCount}
                    readOnly
                  />
                  {!isHideM2m && !isHideOut && (
                    <div
                      className="pointer"
                      style={{ background: paymentTabStyle }}
                    ></div>
                  )}
                </Radio.Button>
              </>
            )}
            {!isHideM2m && (
              <Radio.Button
                value="3"
                style={{
                  borderTopRightRadius: "10px",
                  background: m2mTabStyle,
                  borderBottomRightRadius: "0px",
                }}
              >
                <b style={{ marginLeft: "20px" }}>M2M</b>{" "}
                <Input
                  style={{ background: m2mTabStyle }}
                  className="custom-input"
                  value={m2mCount}
                  readOnly
                />
              </Radio.Button>
            )}
          </Radio.Group>
        </div>
      </>
    );
  };

  const REPORT_TYPES = {
    [REPORT_TYPE_LABELS.IN]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async () => {
          searchInTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              if (res && res.count) {
                setJpjSubmissionCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async () => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";

          searchInTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              if (res && res.count) {
                setPaymentCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
      [TAB_TYPE.M2M]: {
        api: async () => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          searchInTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.M2M
          )
            .then((res) => {
              if (res && res.count) {
                setM2mCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
    },
    [REPORT_TYPE_LABELS.OUT]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async () => {
          searchOutTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              if (res && res.count) {
                setJpjSubmissionCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async () => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";

          searchOutTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              if (res && res.count) {
                setPaymentCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
      [TAB_TYPE.M2M]: {
        api: async () => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          searchM2mTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.M2M
          )
            .then((res) => {
              if (res && res.count) {
                setM2mCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
    },
    [REPORT_TYPE_LABELS.I2I]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async () => {
          searchI2iTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.JPJ_SUBMISSION
          )
            .then((res) => {
              if (res && res.count) {
                setJpjSubmissionCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async () => {
          const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";

          searchI2iTransactions(
            branch.id,
            company.coRegNo,
            0,
            1,
            SEARCH,
            "",
            "1D",
            STATUS_TYPE.PAYMENT
          )
            .then((res) => {
              if (res && res.count) {
                setPaymentCount(res.count);
              }
            })
            .catch((err) => {})
            .finally(() => {});
        },
      },
    },
  };
  useEffect(() => {
    if (company.coRegNo && branch.id !== null) {
      tabTypeCountMap.map((tab) => {
        REPORT_TYPES[pendingType][tab].api();
      });
    }
  }, [company, branch]);

  useEffect(async () => {
    if (company.id) {
      await getPermissions().then(async (permissions) => {
        setPermissions(permissions);
        if (
          permissions &&
          pendingType === REPORT_TYPE_LABELS.OUT &&
          !permissions.outTransaction
        ) {
          setTabType(TAB_TYPE.M2M);
        }
      });
    }
  }, [company]);

  return (
    <>
      <div className="page-content">
        <>
          <PageHeader
            style={{ paddingLeft: "0px", marginTop: "40px" }}
            className="site-page-header"
            title={
              pendingType === REPORT_TYPE_LABELS.I2I
                ? "I2I Pending Transaction"
                : pendingType === REPORT_TYPE_LABELS.OUT
                ? "OUT Pending Transaction"
                : "IN Pending Transaction"
            }
          />
          <CustomRadio />
          <CustomPaginationTable
            pendingType={pendingType}
            tabType={tabType}
            setTabType={setTabType}
            setJpjSubmissionCount={setJpjSubmissionCount}
            setPaymentCount={setPaymentCount}
            setM2mCount={setM2mCount}
          />
          {/* <Card></Card>
          <Card></Card>
          <Table></Table> */}
        </>
      </div>

      <style>
        {`
            .full-width-radio-group {
              display: flex;
              width: 100%;
              // position: relative;
            }

            .pointer {
              width: 38px;
              height: 36px;
              border-top: 1px solid black;
              border-right: 1px solid black;
              background-color: white;
              position: absolute;
              right: -20px;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              z-index: 100;
          }

            .ant-radio-button-wrapper {
              flex: 1;
              text-align: left;
              position: relative;
              border: 1px solid black;
              border-right: none;
              border-radius: 0;
              padding: 10px 10px 10px 20px;
              height: fit-content;
              /* align-items: center; */
          }

          .custom-input {
            border-radius: 10px;
            height: 30px;
            width: 60px;
            margin-left: 10px;
        }

            
        `}
      </style>
    </>
  );
}
