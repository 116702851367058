import React, { useEffect, useState, useContext } from 'react'
import {
    Form,
    Input,
    Button,
    Image,
    Select,
    Space,
    message,
    Row,
    Col,
    Checkbox
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'

import thumbprint from '../../assets/thumbprint.jpg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import arrow_back from '../../assets/arrow_back.svg'
import {
    LoadingOutlined,
} from '@ant-design/icons'
import {
    STEPS,
} from './steps'
import {
    breakdownIc,
    parseAddress,
    removeTrailings,
    getState
} from '../../util'
import {
    getDistrictList,
    isAccountExist,
    getAccountDetails,
    getCityList,
    validateCityWithRequestParam,
} from '../../services/api'
import DeviceReader from '../../components/DeviceReader'
import propTypes from 'prop-types'
import {
    STATES,
    OUT_TRANSACTION_TYPES_IDS,
    STATES_FULLNAME,
    CUSTOMER_CATEGORY
} from '../../constants'
import { useForm } from 'antd/lib/form/Form'
import {
    getCompanyId,
} from '../../services/local'
import { decryptUrlVerify } from '../../components/Utils'
import { SessionContext } from '../../App'
import AuthorizeBuyerComponent from '../../components/AuthorizeBuyerComponent'

function AuthorizeBuyer(props) {

    const history = useHistory()
    const [form] = useForm()
    const [districtLoading, setDistrictLoading] = useState(false)
    const [districts, setDistricts] = useState([])
    const [correspondentDistricts, setCorrespondentDistricts] = useState([])
    const { formattedDob, isMale } = breakdownIc(props.buyer?.identificationNo)
    const [isAddressDifferent, setIsAddressDifferent] = useState(false)
    const [buyerCity, setBuyerCity] = useState("")
    const [cityList, setCityList] = useState([])
    const [cityLoading, setCityLoading] = useState(false)
    const [isShowCity, setIsShowCity] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const { user, } = useContext(SessionContext)
    const cityName = props.buyer.mykadCity ?? props.buyer.city

    useEffect(() => {

        if (props.buyer.isAddressDifferent)
            setIsAddressDifferent(props.buyer.isAddressDifferent)

    }, [props.buyer.isAddressDifferent])

    useEffect(() => {

        if (isAddressDifferent) {
            form.setFieldsValue({
                correspondentAddress1: props?.buyer?.correspondentAddress1 && props?.buyer?.correspondentAddress1,
                correspondentAddress2: props?.buyer?.correspondentAddress2 && props?.buyer?.correspondentAddress2,
                correspondentAddress3: props?.buyer?.correspondentAddress3 && props?.buyer?.correspondentAddress3,
                correspondentPostcode: props?.buyer?.correspondentPostcode && props?.buyer?.correspondentPostcode
            })
        } else
            form.setFieldsValue({
                correspondentAddress1: '',
                correspondentAddress2: '',
                correspondentAddress3: '',
                correspondentPostcode: ''
            })

    }, [isAddressDifferent, props.buyer])

    useEffect(() => {

        if (history.location.state.subStep)
            setSubStep(history.location.state.subStep)

    }, [history.location.state.subStep])

    useEffect(() => {

        if (props?.buyer?.state) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.state))?.code

            if (stateCode) {
                setDistrictLoading(true)
                getDistrictList(stateCode).then((res) => {
                    setDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                })
            }
        }

    }, [props.buyer.state])

    useEffect(() => {

        if (props?.buyer?.correspondentState) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.correspondentState))?.code

            if (stateCode) {
                form.setFieldsValue({
                    correspondentState: stateCode,
                })
                setDistrictLoading(true)
                getDistrictList(stateCode).then((res) => {
                    setCorrespondentDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                })
            }
        }

    }, [props.buyer.correspondentState])

    useEffect(() => {
        districts
            .filter(district => props?.buyer?.district && district?.districtName?.toUpperCase() === props?.buyer?.district?.toUpperCase())
            .map(district => {
                form.setFieldsValue({
                    district: district.districtCode,
                })
            })
    }, [districts, props.buyer.district])

    useEffect(() => {
        correspondentDistricts
            .filter(district => props?.buyer?.correspondentDistrict && district?.districtName?.toUpperCase() === props?.buyer?.correspondentDistrict?.toUpperCase())
            .map(district => {
                form.setFieldsValue({
                    correspondentDistrict: district.districtCode,
                })
            })
    }, [correspondentDistricts, props.buyer.correspondentDistrict])

    useEffect(() => {

        form.setFieldsValue({
            correspondentMobileNo: !props?.buyer?.correspondentMobileNo ? props?.buyer?.mobileNo : props?.buyer?.correspondentMobileNo,
            correspondentEmail: !props?.buyer?.correspondentEmail ? props?.buyer?.email : props?.buyer?.correspondentEmail,
        })

    }, [props])

    /* 
        Validate if city is mapped in DB,
        Else get city list
    */
    useEffect(() => {
        const getCityListing = () => {
            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.state))?.code
            if (stateCode) {
                setCityLoading(true)
                getCityList(stateCode).then((res) => {
                    setCityList(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setIsShowCity(true)
                    setCityLoading(false)
                })
            }
        }
        if (cityName) {
            validateCityWithRequestParam(cityName).then((res) => {
                if(!res.status) {
                    getCityListing()
                }
                else {
                    setBuyerCity(cityName)
                }
            }).catch((err) => {
                err && message.error(err?.toString())
                getCityListing()
            }).finally(() => {
                setIsButtonDisabled(false)
            })
        }
    }, [cityName])

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUBMPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY]: {
            component: (
                <AuthorizeBuyerComponent        
                    parseAddress={parseAddress}
                    removeTrailings={removeTrailings}
                    history={history}
                    props={props}
                    isShowCity={isShowCity}
                    cityLoading={cityLoading}
                    cityList={cityList}
                    isButtonDisabled={isButtonDisabled}
                    styles={styles}
                    formattedDob={formattedDob}
                    form={form}
                    districts={districts}
                    correspondentDistricts={correspondentDistricts}
                    buyerCity={buyerCity}
                    isAddressDifferent={isAddressDifferent}
                    setDistrictLoading={setDistrictLoading}
                    setCorrespondentDistricts={setCorrespondentDistricts}
                    isMale={isMale}
                />
            )
        },
    }

    const [step, setStep] = useState(STEPS.AUTHORIZE_BUYER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <div
                style={{
                    height: '50%',
                    width: '100%',
                    border: '0px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >

                    {
                        subStep !== STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'white',
                                    padding: 24,
                                    boxShadow: '0px 3px 6px #00000029',
                                    textAlign: 'center',
                                    borderRadius: 8
                                }}
                            >
                                {
                                    currentStep && currentStep.component
                                }
                                <DeviceReader
                                    forKey='buyer'
                                    nextStep={STEPS.AUTHORIZE_BUYER.label}
                                    nextSubStep={STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY}
                                    onIdentityCardSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERTING_IC)
                                    }}
                                    onWaitingThumbprint={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_BEFORE)
                                    }}
                                    onThumbprintDetected={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUBMPRINTING)
                                    }}
                                    onThumbprintFailed={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED)
                                    }}
                                    onThumbprintSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_SUCCESS)
                                    }}
                                    onTryAgain={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                                    }}
                                    onFinish={async data => {

                                        let errorMessages = [];

                                        const decryptedResponse = await decryptUrlVerify(data,
                                            data.encryptString,
                                            data.forkey,
                                            getCompanyId(),
                                            "DERMALOG",
                                            user.nric.replace(/[\s*]/g, ""))
                                        if(decryptedResponse === false) {
                                            errorMessages.push("Failed to authorize session with token. Please try again.")
                                        }

                                        if (!data.name) {
                                            errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        // if (!data.district) {
                                        //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                        // }

                                        if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                            errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (errorMessages.length > 0) {
                                            for (let errorMessage of errorMessages) {
                                                message.error(errorMessage);
                                            }
                                            props.onChange({})

                                            history.push(history.location.pathname, {
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })
                                            return;
                                        }

                                        const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                        let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                        if (!state) state = getState(data.identificationNo)

                                        let mobileNo
                                        let _email

                                        isAccountExist(data.identificationNo.replace(/[\s*]/g, ""))
                                            .then(res => {

                                                if (!res.status) {
                                                    return {
                                                        status: false
                                                    }
                                                } else {
                                                    return getAccountDetails(data.identificationNo.replace(/[\s*]/g, ""))
                                                }

                                            })
                                            .then(res => {

                                                if (res.status == false) {
                                                    mobileNo = ''
                                                    _email = ''
                                                } else {

                                                    const { email, mobileno } = res

                                                    mobileNo = mobileno
                                                    _email = email

                                                }

                                            })
                                            .catch(err => {
                                                err && message.error(err)
                                            }).finally(() => {

                                                props.onChange({
                                                    ...props.buyer,
                                                    ...data,
                                                    state: state?.code,
                                                    mobileNo: mobileNo,
                                                    email: _email,
                                                    address1: data.address1.replace(/\0.*$/, ""),
                                                    address2: data.address2.replace(/\0.*$/, ""),
                                                    address3: data.address3.replace(/\0.*$/, ""),
                                                    name: data.name.replace(/\*/g, ''),
                                                    district: data.district.replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                                    city: data.district.replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                                    citizenship: data.citizenship
                                                })
                                                setStep(STEPS.AUTHORIZE_BUYER.label)
                                                setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY)
                                            })

                                    }}
                                    onDataChanged={data => { }}
                                    onError={data => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 20,
                                    }}
                                >
                                    {/* <Button
                                        shape='round'
                                        onClick={() => {
                                            history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })
                                        }}
                                    >
                                        Prev step
                                    </Button> */}
                                </div>
                            </div>
                        ) : <div> {
                            currentStep && currentStep.component
                        }</div>
                    }
                </div>
            </div>
        </>
    )
}

AuthorizeBuyer.propTypes = {
    buyer: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    transactionType: propTypes.number.isRequired
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    formLabel: {
        // font: 'italic normal normal 16px Open Sans',
        font: 'normal normal 16px Open Sans',
        color: '#333333',
        margin: 0
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    }
}

export default AuthorizeBuyer