import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, Row, Button, message, Pagination } from "antd";
import "./index.less";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import propTypes from "prop-types";
import {
  getOutTransferAmountByTransferId,
  getOutTransferByTransferId,
  getOutBulkTransactionDetails,
  checkOutBulkTransactionDetails,
  getPrepaidBalance,
} from "../../services/api";
import {
  BUTTON_RETURN_PENDING_TRANSACTION,
  ALERT_AUTHORITY_MESSAGE,
} from "../../constants";
import { SessionContext } from "../../App";
import {
  useShortcut,
  onApprovalSubmit,
  ModuleType,
} from "../../components/Utils";
import {
  getCompanyId,
  getUserRole,
  getPermissions,
} from "../../services/local";
import { validateRolePermission } from "../../components/Utils";
import {
  VerificationCard,
  CompanyCardComponent,
  TransactionAmountCard,
} from "../../components/component/Layout/CardComponent";
import { VehicleDetailsTableComponent } from "../../components/component/Layout/TableComponent";
import {
  ApprovalButton,
  TextButton,
} from "../../components/component/UI/Button";
import { InfoAlert } from "../../components/component/UI/Alert";
import {
  outBulkProcessPayment,
  onPaymentSubmit,
  updateToJPJ,
} from "./function";
import {
  ApprovalInModal,
  ApprovalStatusModal,
} from "../../components/component/Layout/ModalComponent";

const TRANSFER_PAYMENT_STATUSES = {
  PENDING: "P", //TODO daniel.k change to 'P' instead of empty string. Set as such to work around BE bug at time
  REFUNDED: "R", //Refunded, should proceed payment again
  SUCCESS: "S", //skip payment again
};

function Payment(props) {
  const { isReport } = props;

  const history = useHistory();
  const params = useParams();
  const { company, user } = useContext(SessionContext);
  const { state: locationState } = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const transferId = Number(
    history.location.state.isBulk ? 0 : params.transferId
  );
  const [bulkTransferId, setBulkTransferId] = useState(0);
  const propsBulkTransferId = Number(
    history.location.state.isBulk ? params.transferId : 0
  );
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false);
  const [isApprovalRejected, setIsApprovalRejected] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [permissions, setPermissions] = useState([]);

  //payment
  //Approval
  const [bulkDetails, setBulkDetails] = useState([]);
  const [isM2mBulkCompleted, setIsM2mBulkCompleted] = useState(false);
  const [isBulkProcess, setIsBulkProcess] = useState(false);

  const [balanceAmount, setBalanceAmount] = useState(0);

  const [tabType, setTabType] = useState("");
  const intervalId = useRef(null);

  const [isApprovalStatusModalVisible, setIsApprovalStatusModalVisible] =
    useState(false);

  const [isJPJLoading, setIsJPJLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(true);
  const [updateJpjFailed, setUpdateJpjFailed] = useState(false);
  const [isDisable, setIsDisabled] = useState(false);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailed, setTotalFailed] = useState(0);
  const [currentVehicle, setCurrentVehicle] = useState("");
  const [sellerCompanyData, setSellerCompanyData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [buyerData, setBuyerData] = useState({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [transactionAmount, setTransactionAmount] = useState({
    id: 0,
    name: "",
    jpjAmount: 0,
    eserviceAmount: 0,
    fisAmount: 0,
    fisEserviceAmount: 0,
    sstAmount: 0,
    totalAmount: 0,
    processingAmount: 0,
    subTotalAmount: 0,
  });
  const selectedVehicleDetails = vehicleDetails
    ? vehicleDetails
        .filter((res) => res.status !== 6 && res.status !== 1)
        .map((obj) => obj.id)
    : [];

  useEffect(() => {
    getUserRole().then((res) => {
      setLoggedInUserRole(res);
    });
    getPermissions().then(async (permissions) => {
      setPermissions(permissions);
    });
  }, []);
  {
    useShortcut(setIsModalVisible);
  }

  const isUntilJpjSubmission = validateRolePermission(
    loggedInUserRole,
    "OUT",
    true,
    permissions
  );

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };

  const dataSource = vehicleDetails ? vehicleDetails : [];
  const isBulk = propsBulkTransferId && propsBulkTransferId !== 0;
  const isSingleVehicle =
    (transferId && transferId !== 0) || selectedVehicleDetails.length === 1;

  /* Pagination */
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [isFooterEditing, setIsFooterEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPageSize = 10;
  const totalPages = Math.ceil(dataSource.length / maxPageSize);
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const paginatedData = dataSource.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, dataSource.length)
  );
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };
  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsHeaderEditing(false);
    setIsFooterEditing(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  /* Pagination */

  /* getInBulkTransactionDetails */
  useEffect(() => {
    if (propsBulkTransferId !== 0) {
      getBulkTransactionDetail();
    }
  }, [propsBulkTransferId]);

  const getBulkTransactionDetail = () => {
    
    // history.push(`/transfer/out/printslip/${1205}`, {
    //   isBulk: true,
    // });
    setIsJPJLoading(true);
    getOutBulkTransactionDetails(propsBulkTransferId, "BULKOUT").then((res) => {
      try {
        const sellerCompany = {
          id: 0,
          coName: res.seller.name,
          coRegNo: res.seller.identificationNo,
          email: res.seller.email ?? "",
          contactNo: res.seller.phoneNo ?? "",
          ucdCode: "",
        };
        setSellerCompanyData(sellerCompany);

        const buyer = {
          id: 0,
          name: res.buyer.name,
          identificationNo: res.buyer.identificationNo,
          email: res.buyer.email,
          phoneNo: res.buyer.phoneNo,
          ucdCode: "",
        };
        setBuyerData(buyer);

        const dataWithKey = res.vehicleDetailList.map((item, index) => {
          return {
            key: index + 1,
            ...item,
          };
        });
        setVehicleDetails(dataWithKey);
        setTransactionAmount(res.transactionAmount);
        setTotalSuccess(res.totalSuccess);
        setCurrentVehicle(res.vehicleDetailList[0]?.carRegistrationNo); //cater when bulk has only single vehicle
        const totalFail = res.vehicleDetailList.length - res.totalSuccess;
        setTotalFailed(totalFail ?? 0);
      } catch (err) {
      } finally {
      }
    });

    setIsJPJLoading(false);
  };

  useEffect(() => {
    if (transferId !== 0) {
      getTransactionDetail();
    }
  }, [transferId]);

  const getTransactionDetail = () => {
    getOutTransferByTransferId(transferId, "OUT", getCompanyId()).then(
      (res) => {
        try {
          if (res.status === 4) {
            message.warning("Redirect to printslip");
            history.push(`/transfer/out/printslip/${transferId}`, {
              isBulk: false,
            });
          }
          if (res.status === 6) setVerificationSuccess(false);
          if (res.status === 7) setUpdateJpjFailed(true);
          setCurrentVehicle(res.carRegistrationNo);
          const sellerCompany = {
            id: 0,
            coName: res.sellerInfo.name,
            coRegNo: res.sellerInfo.identificationNo,
            email: res.sellerInfo.email,
            contactNo: res.sellerInfo.phoneNo,
            ucdCode: "",
          };
          setSellerCompanyData(sellerCompany);

          const buyer = {
            id: 0,
            name: res.buyerInfo.name,
            identificationNo: res.buyerInfo.identificationNo,
            email: res.buyerInfo.email,
            phoneNo: res.buyerInfo.phoneNo,
            ucdCode: "",
          };

          setBuyerData(buyer);

          const failCode = res.failCode === "" ? "-" : res.failCode;

          const vehicle = {
            carRegistrationNo: res.carRegistrationNo,
            chassisNo: res.chassisNo,
            engineNo: res.engineNo,
            vehicleTypeNewId: res.vehicleTypeNewId,
            failCode: res.status === 6 || res.status === 7 ? failCode : "",
            branchId: res.vehicleBranchId,
          };
          const dataWithKey = {
            key: 1,
            ...vehicle,
          };
          setVehicleDetails([dataWithKey]);
          setTotalSuccess(1);

          getOutTransferAmountByTransferId(transferId)
            .then((res) => {
              if (res.id === undefined) throw res.message;

              const amount = {
                id: res.id,
                name: res.name,
                jpjAmount: res.jpjAmount,
                eserviceAmount: res.eserviceAmount,
                fisAmount: res.fisAmount,
                fisEserviceAmount: res.fisEserviceAmount,
                sstAmount: res.sstAmount,
                totalAmount: res.totalAmount,
                processingAmount: res.processingAmount,
                subTotalAmount: res.processingAmount + res.eserviceAmount,
              };
              setTransactionAmount(amount);
            })
            .catch((err) => err && message.error(err))
            .finally(() => {});
        } catch (err) {
        } finally {
          setIsJPJLoading(false);
        }
      }
    );
  };

  const LoadingCard = () => {
    return (
      <Card>
        <LoadingOutlined />
      </Card>
    );
  };

  const onResetData = () => {};

  const onBackButton = () => {
    props.isFromAdminPage
      ? history.push("/adminSearchVehicleInfo")
      : props.isReport
      ? history.push("/reporting/transferreport/")
      : history.push(`/reporting/transferreport/Out`, { tabType: "2" });
  };

  /* Pagination */
  const CustomPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    isEditing,
    setIsEditing,
  }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const showingVehicleCount = () => {
    return (
      <span>
        Showing{" "}
        <b>
          {startItem} - {endItem}
        </b>{" "}
        of <b>{dataSource.length} vehicles</b>.&nbsp;&nbsp;&nbsp;&nbsp;All JPJ
        verification are saved in{" "}
        <Button style={{ padding: 0 }} type="link" onClick={onBackButton}>
          <span style={{ textDecoration: "underline" }}>
            pending submission
          </span>
          .
        </Button>
      </span>
    );
  };

  const PaginationWrapperHeader = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isHeaderEditing}
            setIsEditing={setIsHeaderEditing}
          />
        </Row>
      </>
    );
  };

  const PaginationWrapperFooter = () => {
    return (
      <>
        <Row className="custom-wrap-row">
          {showingVehicleCount()}
          <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isEditing={isFooterEditing}
            setIsEditing={setIsFooterEditing}
          />
        </Row>
      </>
    );
  };

  const VehicleDetailPaginationTable = () => {
    return (
      <Card
        style={{
          flex: 3,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <>
          <Pagination
            current={currentPage}
            pageSize={1}
            total={totalPages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            showQuickJumper={false}
            showSizeChanger={false}
            style={{ marginBottom: "16px" }}
          />
          {PaginationWrapperHeader()}
          {VehicleDetailsTableComponent(paginatedData)}
          {PaginationWrapperFooter()}
        </>
      </Card>
    );
  };
  /* Pagination */

  const processPayment = () => {
    processApproval(null);
  };

  const processApproval = async (bulkTrfId) => {
    const transferIdToSubmit =
      isBulk && isSingleVehicle ? selectedVehicleDetails[0] : transferId;
    setIsDisabled(true);
    let status = false;
    // if (propsBulkTransferId && propsBulkTransferId !== 0) {
    if (
      // (bulkTrfId && bulkTrfId !== 0) ||
      // (selectedVehicleDetails && selectedVehicleDetails.length !== 0 && !isSingleVehicle)
      isBulk && !isSingleVehicle
    ) {
      setBulkTransferId(bulkTrfId);
      status = await outBulkProcessPayment(
        selectedVehicleDetails,
        onResetData,
        company.coRegNo,
        // propsBulkTransferId,
        bulkTrfId,
        true,
        setBulkDetails,
        setIsM2mBulkCompleted,
        setIsApprovalStatusModalVisible,
        vehicleDetails.length,
        setBulkTransferId
      );
    } else {
      if (!updateJpjFailed) {
        status = await onPaymentSubmit(company, transferIdToSubmit, user);
      } else {
        status = await updateToJPJ(user, transferIdToSubmit);
      }
    }
    if (isSingleVehicle && status) {
      history.push(`/transfer/out/printslip/${transferIdToSubmit}`, {
        isBulk: false,
      });
    } else if (isBulk && status) {
      setIsBulkProcess(true);
      setIsDisabled(false);
    } else if (!isBulk && !status) {
      setIsDisabled(false);
      getTransactionDetail();
    }
    else {
      setIsDisabled(false);
    }
  };

  const approvalButton = () => {
    if (isBulk && isBulkProcess) {
      setIsApprovalStatusModalVisible(true);
    } else if (!isBulk && updateJpjFailed) {
      processApproval();
    } else {
      onApprovalSubmit(
        setIsModalVisible,
        setIsDisabled,
        "",
        processPayment,
        company,
        ModuleType.OUT,
        permissions
      );
    }
  };

  /* checkBulkTransaction */
  useEffect(() => {
    if (bulkTransferId && isApprovalStatusModalVisible && !isM2mBulkCompleted) {
      intervalId.current = setInterval(() => {
        checkOutBulkTransactionDetails(bulkTransferId).then(async (res) => {
          if (res.status) {
            setBulkDetails(res);
          } else {
            message.error(res.message);
            clearInterval(intervalId.current);
            // setBulkTransferId(0);
          }

          if (res.remainingVehicle === 0) {
            const res = await getPrepaidBalance(company.coRegNo);
            res.balanceAmount && setBalanceAmount(res.balanceAmount);
            message.success("Process completed.");
            clearInterval(intervalId.current);
            setIsM2mBulkCompleted(true);
            // getData(currentPage);
          }
        });
      }, 3000);
    }

    return () => clearInterval(intervalId.current);
  }, [bulkTransferId, isApprovalStatusModalVisible]);

  const handleModalClearAll = () => {
    if (bulkDetails.totalVehicle === bulkDetails.paymentSuccess) {
      // history.push(`/transfer/out/printslip/${propsBulkTransferId}`, {
      history.push(`/transfer/out/printslip/${bulkTransferId}`, {
        isBulk: isBulk,
      });
    } else {
      setIsApprovalStatusModalVisible(false);
      onBackButton();
    }
  };

  return (
    <>
      {isJPJLoading ? (
        <LoadingCard />
      ) : (
        <>
          {isUntilJpjSubmission && InfoAlert(ALERT_AUTHORITY_MESSAGE)}
          <VerificationCard
            isSuccess={verificationSuccess}
            totalSuccess={totalSuccess}
            totalFailed={totalFailed}
            isBulk={propsBulkTransferId !== 0}
            isPayment={true}
            vehicleNo={currentVehicle}
            onBackButtonClick={onBackButton}
            isUpdateJpjFailed={updateJpjFailed}
          />
          <br />
          {VehicleDetailPaginationTable()}
          <br />
          {CompanyCardComponent(sellerCompanyData, buyerData, false)}
          <br />
          {transactionAmount.totalAmount !== 0 &&
            TransactionAmountCard(
              transactionAmount ? transactionAmount : [],
              totalSuccess,
              currentVehicle,
              isBulk
            )}
          <br />
          {isUntilJpjSubmission && InfoAlert(ALERT_AUTHORITY_MESSAGE)}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {TextButton(BUTTON_RETURN_PENDING_TRANSACTION, onBackButton)}
            {verificationSuccess ? (
              isUntilJpjSubmission ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!isBulk && updateJpjFailed ? (
                    <></>
                  ) : (
                    <span style={{ marginRight: "20px" }}>
                      Payment for <strong>{totalSuccess} vehicle.</strong>
                    </span>
                  )}

                  {!isBulk && updateJpjFailed
                    ? ApprovalButton(approvalButton, "Update JPJ", isDisable)
                    : selectedVehicleDetails.length !== 0 &&
                      ApprovalButton(
                        approvalButton,
                        "Pay RM " +
                          Number(transactionAmount.totalAmount).toFixed(2),
                        isDisable
                      )}
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        </>
      )}

      <ApprovalInModal
        isIncorrectOtp={isIncorrectOtp}
        isModalVisible={isModalVisible}
        // transferIdList={[]}
        transferIdList={isSingleVehicle ? [] : selectedVehicleDetails}
        isAdminUser={false}
        setIsModalVisible={setIsModalVisible}
        setIsApprovalRejected={setIsApprovalRejected}
        processApproval={processApproval}
        setIsIncorrectOtp={setIsIncorrectOtp}
        approvalModuleType={isBulk && !isSingleVehicle ? "OUT_PAYMENT" : "OUT"}
        setBulkTransferId={setBulkTransferId}
        moduleType={ModuleType.OUT}
        transactionType={2}
        isM2mTransaction={false}
        // bulkTransferId={propsBulkTransferId}
        bulkTransferId={null}
        transferId={
          isBulk && isSingleVehicle ? selectedVehicleDetails[0] : transferId
        }
      />
      <ApprovalStatusModal
        isModalVisible={isApprovalStatusModalVisible}
        setIsModalVisible={setIsApprovalStatusModalVisible}
        bulkDetails={bulkDetails}
        isM2mBulkCompleted={isM2mBulkCompleted}
        vehicleDataList={vehicleDetails}
        handleClearAll={handleModalClearAll}
        isPayment={true}
        isOutM2m={false}
        setTab={setTabType}
        selectedDataSourceAmount={transactionAmount.totalAmount}
        balanceAmount={balanceAmount}
      />

      <style>
        {`
              .ant-pagination {
                display: none;
              }
              .custom-pagination {
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
              .custom-wrap-row {
                display: flex;
                justify-content: space-between;
              }
            `}
      </style>
    </>
  );
}

Payment.propTypes = {
  seller: propTypes.object,
  dealer: propTypes.object,
  vehicle: propTypes.object,
  approverNric: propTypes.string,
};

const styles = {
  label: {
    font: "italic normal normal 16px Open Sans",
    color: "#333333",
    marginBottom: 8,
  },
  value: {
    font: "normal normal 600 16px Open Sans",
    color: "#333333",
  },
};

export default Payment;
