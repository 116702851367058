import React, { useEffect, useContext, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Image,
    Space,
    Select,
    Card,
    Row,
    Col,
    message,
    Typography,
    Checkbox
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'

import thumbprint from '../../assets/thumbprint.jpg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import arrow_back from '../../assets/arrow_back.svg'
import {
    LoadingOutlined,
} from '@ant-design/icons'
import {
    STEPS,
} from './steps'
import {
    breakdownIc,
    parseAddress,
    removeTrailings,
    getState
} from '../../util'
import {
    getCompanyDetails,
    getDistrictList,
    isAccountExist,
    getAccountDetails,
    getAdminUsersForCompanyForOut,
    getAdminUsersForCompany
} from '../../services/api'
import DeviceReader from '../../components/DeviceReader'
import propTypes from 'prop-types'
import {
    STATES,
    OUT_TRANSACTION_TYPES_IDS,
    STATES_FULLNAME,
    CUSTOMER_CATEGORY
} from '../../constants'
import { useForm } from 'antd/lib/form/Form'
import {
    getCompanyId,
} from '../../services/local'
import { decryptUrlVerify } from '../../components/Utils'
import { SessionContext } from '../../App'

function AuthorizeSeller(props) {

    const { Text } = Typography
    const history = useHistory()
    const [companyRegNum, setCompanyRegNum] = useState("")
    const [company, setCompany] = useState()
    const [adminList, setAdminList] = useState([])
    const [districtLoading, setDistrictLoading] = useState(false)
    const [districts, setDistricts] = useState([])
    const [correspondentDistricts, setCorrespondentDistricts] = useState([])
    const [correspondentForm] = useForm()

    const { formattedDob, isMale } = breakdownIc(props.seller.identificationNo)
    const [isAddressDifferent, setIsAddressDifferent] = useState(false)
    const { user, } = useContext(SessionContext)

    useEffect(() => {

        if (isAddressDifferent) {
            correspondentForm.setFieldsValue({
                correspondentAddress1: props?.seller?.correspondentAddress1 && props?.seller?.correspondentAddress1,
                correspondentAddress2: props?.seller?.correspondentAddress2 && props?.seller?.correspondentAddress2,
                correspondentAddress3: props?.seller?.correspondentAddress3 && props?.seller?.correspondentAddress3,
                correspondentPostcode: props?.seller?.correspondentPostcode && props?.seller?.correspondentPostcode
            })
        } else
            correspondentForm.setFieldsValue({
                correspondentAddress1: '',
                correspondentAddress2: '',
                correspondentAddress3: '',
                correspondentPostcode: ''
            })

    }, [isAddressDifferent, props])

    useEffect(() => {

        if (props.seller.isAddressDifferent)
            setIsAddressDifferent(props.seller.isAddressDifferent)

    }, [props.seller.isAddressDifferent])

    useEffect(() => {
        if (history.location.state.subStep) setSubStep(history.location.state.subStep)
    }, [history.location.state.subStep])

    useEffect(() => {

        if (props.seller.state) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props.seller.state))?.code

            if (stateCode) {

                /* setDistrictLoading(true)

                getDistrictList(stateCode).then((res) => {
                    setDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                }) */

            }

        }

    }, [props.seller.state])

    useEffect(() => {

        if (props.seller.correspondentState) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props.seller.correspondentState))?.code

            if (stateCode) {

                correspondentForm.setFieldsValue({
                    correspondentState: stateCode,
                })

                setDistrictLoading(true)

                getDistrictList(stateCode).then((res) => {
                    setCorrespondentDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                })

            }

        }

    }, [props.seller.correspondentState])

    useEffect(() => {

        correspondentDistricts && correspondentDistricts
            .filter(district => {

                if (props.seller?.correspondentDistrict) {
                    return district?.districtName?.toUpperCase() === props.seller?.correspondentDistrict?.toUpperCase()
                }

            })
            .map(district => {
                correspondentForm.setFieldsValue({
                    correspondentDistrict: district.districtCode,
                })
            })
    }, [correspondentDistricts, props.seller.correspondentDistrict])

    useEffect(() => {

        districts && districts
            .filter(district => {

                if (props.seller?.district) {
                    return district?.districtName?.toUpperCase() === props.seller?.district?.toUpperCase()
                }

            })
            .map(district => {
                correspondentForm.setFieldsValue({
                    district: district.districtCode,
                })
            })
    }, [districts, props.seller.district])

    useEffect(() => {

        correspondentForm.setFieldsValue({
            correspondentMobileNo: !props?.seller?.correspondentMobileNo ? props?.seller?.mobileNo : props?.seller?.correspondentMobileNo,
            correspondentEmail: !props?.seller?.correspondentEmail ? props?.seller?.email : props?.seller?.correspondentEmail,
        })

    }, [props])

    const searchCompany = async () => {
        if (!companyRegNum) {
            message.error("Please key in company registration number")

            return
        }

        const companyRes = await getCompanyDetails(companyRegNum)
        if (companyRes.status !== '00') {
            message.error("Company not found")
            setCompany(null)
        } else {
            setCompany(companyRes)

            let admin

            if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

                admin = await getAdminUsersForCompanyForOut(companyRegNum)

            } else {

                admin = await getAdminUsersForCompany(companyRegNum)

            }

            if (admin.status === true) {
                setAdminList(admin.users)
            }
        }
    }

    const companyDetails = (company) => {
        const { companyDetails } = company
        const address = companyDetails.companyAddress
        const formattedAddress = parseAddress(address)

        return (
            <Card className='section-card'>
                <Text className='section-header'>Buyer's company details</Text>
                <Row>
                    <Col span={8}>
                        <Text className='italic'>Company Name</Text>
                        <div className='company-details'>{companyDetails.coName}</div>
                    </Col>
                    <Col span={4}>
                        <Text className='italic'>Company ROC</Text>
                        <div className='company-details'>{companyDetails.coRegNo}</div>
                    </Col>
                    <Col span={12}>
                        <Text className='italic'>Company Address</Text>
                        <div className='company-details'>{formattedAddress}</div>
                    </Col>
                </Row>
            </Card>
        )
    }

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_SELLER.subSteps.S_ROC_SEARCH_PAGE]: {
            component: (
                <div>
                    <Card className='section-card'>
                        <Text className='section-header'>Select Seller Company</Text>
                        <Row>
                            <Col span={8}>
                                <Text className='italic'>Company registration number</Text>
                                <Input
                                    className='input-field'
                                    placeholder='Registration number'
                                    value={companyRegNum}
                                    onChange={(e) => setCompanyRegNum(e.target.value)}

                                />
                                <Button type='primary' onClick={searchCompany} block>Search</Button>
                            </Col>
                        </Row>
                    </Card>
                    {company
                        ? companyDetails(company)
                        : null
                    }
                    <div className='section-bottom-button'>
                        <Button className='rounded-button-primary' onClick={history.goBack}>BACK</Button>
                        {company && <Button className='rectangle-button to-the-right' onClick={() => {

                            if (adminList == null || adminList.length == 0) {
                                message.error("There's no admin in this company.")

                            } else {
                                setStep(STEPS.AUTHORIZE_SELLER.label)
                                setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)
                            }

                        }}>Continue</Button>}
                    </div>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify owner's identity
                    </h1>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify owner's identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify owner's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUBMPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify owner's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify owner's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify Authorised Person Identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY]: {
            component: (
                <>
                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                        <Col span={12}>
                            <div style={{
                                padding: 24,
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                borderRadius: 4,
                                width: '100%',
                                marginBottom: 24,
                                height: '89.5%',
                                display: 'flex',
                                flexDirection: 'column'

                            }}>
                                <h2 style={{ fontWeight: 'bold', marginTop: 20 }}>Owner's Identity Card Details</h2>
                                <div style={{ display: 'flex' }}>
                                    <img alt='' src={`data:image/png;base64, ${props.seller.photo}`} height='200px' style={{ marginRight: '10%' }} />
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: 1 }}>
                                        <Space direction="vertical">
                                            <p style={styles.label}>Name</p>
                                            <p style={styles.value}>{props.seller.name}</p>
                                            <p style={styles.label}>NRIC</p>
                                            <p style={styles.value}>{props.seller.identificationNo}</p>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{formattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                            <p style={styles.label}>Address</p>
                                            <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label, district: "" })}</p>
                                        </Space>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 32
                                }}
                            >

                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {

                                        props.onChange({})

                                        history.push(history.location.pathname, {
                                            step: STEPS.TRANSACTION_TYPE.label,
                                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                        })
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Form
                                    id='correrspondentFormAnchor'
                                    form={correspondentForm}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'
                                    }}
                                    layout={'vertical'}
                                    initialValues={{
                                        correspondentMobileNo: props?.seller?.mobileNo,
                                        correspondentEmail: props?.seller?.email,
                                        customerCategory: props?.seller?.sellerType
                                    }}
                                    onFinish={values => {

                                        if (values.correspondentAddress1) {
                                            values.correspondentAddress1 = removeTrailings(values.correspondentAddress1)
                                        }

                                        if (values.correspondentAddress2) {
                                            values.correspondentAddress2 = removeTrailings(values.correspondentAddress2)
                                        }

                                        if (values.correspondentAddress3) {
                                            values.correspondentAddress3 = removeTrailings(values.correspondentAddress3)
                                        }

                                        if (values.district) {
                                            districts
                                                .filter(district => district.districtCode === values.district)
                                                .map(district => {
                                                    values.district = district.districtName
                                                })
                                        }

                                        if (values.correspondentDistrict) {

                                            correspondentDistricts
                                                .filter(district => district.districtCode === values.correspondentDistrict)
                                                .map(district => {
                                                    values.correspondentDistrict = district.districtName
                                                })
                                        }

                                        const seller = {
                                            address1: props.seller?.address1,
                                            address2: props.seller?.address2,
                                            address3: props.seller?.address3,
                                            correspondentAddress1: values.correspondentAddress1 ?? '',
                                            correspondentAddress2: values.correspondentAddress2 ?? '',
                                            correspondentAddress3: values.correspondentAddress3 ?? '',
                                            correspondentPostcode: values.correspondentPostcode ?? '',
                                            correspondentState: values.correspondentState ?? '',
                                            correspondentDistrict: values.correspondentDistrict ?? '',
                                            correspondentEmail: values.correspondentEmail,
                                            correspondentMobileNo: values.correspondentMobileNo,
                                            dateOfBirth: props.seller?.dateOfBirth,
                                            district: values.district,
                                            city: props.seller?.city,
                                            email: props.seller?.email,
                                            gender: props.seller?.gender,
                                            identificationNo: props.seller?.identificationNo.replace(/[\s*]/g, ""),
                                            name: props.seller?.name,
                                            phoneNo: props.seller?.phoneNo,
                                            mobileNo: props.seller?.mobileNo,
                                            postcode: props.seller?.postcode,
                                            repIc: props.seller?.repIc,
                                            repIc2: props.seller?.repIc2,
                                            repName: props.seller?.repName,
                                            sellerType: props.seller?.sellerType ?? values.customerCategory,
                                            state: props.seller?.state,
                                            dob: props.seller?.dob,
                                            photo: props.seller?.photo,
                                            sellerType: values.customerCategory,
                                            isAddressDifferent: isAddressDifferent,
                                            citizenship: props.seller?.citizenship
                                        }

                                        props.onChange({
                                            ...seller,
                                            ...company
                                        })
                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.VEHICLE_INFORMATION.label,
                                            subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                                        })
                                    }}
                                >
                                    <div style={{
                                        padding: '24px',
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "4px",
                                        width: '100%',
                                        minHeight: '800px', // Set your desired minimum height here
                                        height: 'auto', // Allow the div to expand as needed
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '100%',
                                        alignItems: 'flex-start'
                                    }}
                                    >
                                        <h2 style={{ fontWeight: 'bold', marginTop: 20 }}>Additional information</h2>
                                        {

                                            <Form.Item
                                                name={'customerCategory'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please choose Customer Category from the dropdown',
                                                    },
                                                ]}
                                                label={<p style={styles.formLabel}>Customer Category</p>}
                                            >
                                                <Select
                                                    className='custom-select'
                                                    showSearch
                                                    style={{ width: 200, height: 40 }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onChange={(customerCategoryCode) => {
                                                        // Your onChange logic here
                                                    }}
                                                // value={props?.seller?.sellerType} // Set the selected value based on sellerType
                                                >
                                                    {
                                                        Object.values(CUSTOMER_CATEGORY).map(category => (
                                                            <Select.Option
                                                                key={category.code}
                                                                value={category.code}
                                                                style={{
                                                                    color: category.code === 0 ? 'red' : category.code === 1 ? 'blue' : 'inherit', // Use 'inherit' here
                                                                    backgroundColor: 'transparent'
                                                                }}
                                                            >
                                                                {category.label}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        }

                                        <Form.Item
                                            name='correspondentMobileNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: new RegExp('^\\d{9,11}$'),
                                                    message: 'Accepts only numbers (length must be between 9 - 11)',
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>Mobile Number</p>}>
                                            <Input className='textbox-form-style'
                                                style={{ width: 500 }}

                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name='correspondentEmail'
                                            rules={[
                                                {
                                                    required: false,
                                                    pattern: new RegExp('^(?=.{6,50}$)[a-zA-Z0-9~!$%^.&*_\\-=+}|{\\‘?#\/`]*@[a-zA-Z0-9_\\-\\.]*$'),
                                                    message: <li>
                                                        <ol>{'Must be between 6 - 50 characters'}</ol>
                                                        <ol>{'Must contain @'}</ol>
                                                        <ol>{'Allow for (a-z), (A-Z), (0-9) ~!$%^.&*_-=+}|{\‘?#/`'}</ol>
                                                    </li>
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>Email</p>}>
                                            <Input
                                                className='textbox-form-style'
                                                style={{ width: 500 }}
                                            />
                                        </Form.Item>

                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.seller, state: Object.values(STATES).find(s => s.code === props.seller?.state)?.label })}</p> */}

                                        {/* <Form.Item
                                            name='district'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please choose district from the dropdown`
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>District</p>}
                                        >
                                            <Select
                                                className='custom-select'
                                                showSearch
                                                style={{ width: 200, height: 40 }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                loading={districtLoading}
                                            >
                                                {
                                                    districts.map(d => {
                                                        return (
                                                            <Select.Option
                                                                key={d.districtCode}
                                                                value={d.districtCode}
                                                            >
                                                                {d.districtName}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item> */}

                                        {/* <Form.Item
                                            name="isAddressDifferent"
                                            valuePropName="checked"
                                            initialValue={isAddressDifferent}
                                        >
                                            <Checkbox
                                                onChange={(e) => setIsAddressDifferent(e.target.checked)}
                                            >
                                                <p style={{ color: '#333333', font: 'normal normal 600 14px Open Sans' }}>
                                                    Current address is different than MyKad/MyPR
                                                </p>
                                            </Checkbox>
                                        </Form.Item> */}

                                        {
                                            isAddressDifferent === true && (
                                                <>
                                                    <Form.Item
                                                        style={{ marginBottom: 8 }}
                                                        rules={[
                                                            {
                                                                required: isAddressDifferent,
                                                                message: `Current address is required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress1'
                                                        label={<p style={styles.formLabel}>Current Address</p>}>
                                                        <Input
                                                            className='textbox-form-style' style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{ marginBottom: 8 }}
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: `Required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress2'
                                                    >
                                                        <Input
                                                            className='textbox-form-style' style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item

                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: `Required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress3'
                                                    >
                                                        <Input
                                                            className='textbox-form-style' style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 10,
                                                            maxWidth: 500,
                                                        }}
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    pattern: new RegExp('^\\d{5,5}$'),
                                                                    message: `Please enter a valid postcode`
                                                                },
                                                            ]}
                                                            name='correspondentPostcode'
                                                            label={<p style={styles.formLabel}>Postcode</p>}
                                                        >
                                                            <Input className='textbox-form-style' />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name={'correspondentState'}
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    message: 'Please choose state from the dropdown',
                                                                },
                                                            ]}
                                                            label={<p style={styles.formLabel}>State</p>}
                                                        >
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={(stateCode) => {
                                                                    correspondentForm.setFieldsValue({
                                                                        correspondentDistrict: null
                                                                    })
                                                                    setDistrictLoading(true)
                                                                    getDistrictList(stateCode).then((res) => {
                                                                        setCorrespondentDistricts(res)
                                                                    }).catch((err) => {
                                                                        err && message.error(err?.toString())
                                                                    }).finally(() => {
                                                                        setDistrictLoading(false)
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                    Object.values(STATES)
                                                                        .map(state => {
                                                                            return <Select.Option
                                                                                key={state.code}
                                                                                value={state.code}
                                                                            >
                                                                                {state.label}
                                                                            </Select.Option>
                                                                        })
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                       {/*  <Form.Item
                                                            name='correspondentDistrict'
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    message: `Please choose district from the dropdown`
                                                                },
                                                            ]}
                                                            label={<p style={styles.formLabel}>District</p>}
                                                        >
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                loading={districtLoading}
                                                            >
                                                                {
                                                                    correspondentDistricts.map(d => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={d.districtCode}
                                                                                value={d.districtCode}
                                                                            >
                                                                                {d.districtName}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item> */}
                                                    </div>
                                                </>)}

                                        {/* <div>
                                            <p style={styles.formLabel}>Country</p>
                                            <p style={styles.value}>Malaysia</p>
                                        </div> */}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: 32
                                        }}
                                    >
                                        <Button
                                            size='large'
                                            style={{
                                                width: 200,
                                                background: " #2B61C4 0% 0% no-repeat padding-box",
                                                boxShadow: "0px 4px 0px #1E4489",
                                                borderRadius: "8px",
                                                borderColor: 'transparent'
                                            }}
                                            htmlType='submit'
                                            type='primary'
                                            onClick={() => {
                                                correspondentForm
                                                    .validateFields(["correspondentMobileNo", "correspondentEmail"])
                                                    .catch((err) => {
                                                        window.document
                                                            .getElementById("correrspondentFormAnchor")
                                                            .scrollIntoView();
                                                        throw err;
                                                    });
                                            }}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </>
            )
        },
    }

    const [step, setStep] = useState(STEPS.AUTHORIZE_SELLER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <div
                style={{
                    height: '50%',
                    width: '100%',
                    border: '0px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >

                    {
                        subStep !== STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY && subStep !== STEPS.AUTHORIZE_SELLER.subSteps.S_ROC_SEARCH_PAGE ? (

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'white',
                                    padding: '24px',
                                    boxShadow: '0px 3px 6px #00000029',
                                    textAlign: 'center'

                                }}
                            >
                                {
                                    currentStep && currentStep.component
                                }

                                <DeviceReader
                                    forKey='seller'
                                    nextStep={STEPS.AUTHORIZE_SELLER.label}
                                    nextSubStep={STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY}
                                    onIdentityCardSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERTING_IC)
                                    }}
                                    onWaitingThumbprint={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_BEFORE)
                                    }}
                                    onThumbprintDetected={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUBMPRINTING)
                                    }}
                                    onThumbprintFailed={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_FAILED)
                                    }}
                                    onThumbprintSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.THUMBPRINT_SUCCESS)
                                    }}
                                    onTryAgain={() => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)
                                    }}
                                    onFinish={async data => {

                                        let errorMessages = [];

                                        const decryptedResponse = await decryptUrlVerify(data,
                                            data.encryptString,
                                            data.forkey,
                                            getCompanyId(),
                                            "DERMALOG",
                                            user.nric.replace(/[\s*]/g, ""))
                                        if(decryptedResponse === false) {
                                            errorMessages.push("Failed to authorize session with token. Please try again.")
                                        }

                                        if (!data.name) {
                                            errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        // if (!data.district) {
                                        //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                        // }

                                        if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                            errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (errorMessages.length > 0) {
                                            for (let errorMessage of errorMessages) {
                                                message.error(errorMessage);
                                            }
                                            props.onChange({})

                                            history.push(history.location.pathname, {
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })
                                            return;
                                        }
                                        data.identificationNo = data.identificationNo.replace(/[\s*]/g, "");

                                        const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                        let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                        if (!state) state = getState(data.identificationNo)

                                        let mobileNo
                                        let _email

                                        isAccountExist(data.identificationNo.replace(/[\s*]/g, ""))
                                            .then(res => {

                                                if (!res.status) {
                                                    return {
                                                        status: false
                                                    }
                                                } else {
                                                    return getAccountDetails(data.identificationNo.replace(/[\s*]/g, ""))
                                                }

                                            })
                                            .then(res => {

                                                if (res.status == false) {
                                                    mobileNo = ''
                                                    _email = ''
                                                } else {

                                                    const { email, mobileno } = res

                                                    mobileNo = mobileno
                                                    _email = email

                                                }

                                            })
                                            .catch(err => {
                                                err && message.error(err)
                                            }).finally(() => {

                                                let cardTypeCode = Object.values(CUSTOMER_CATEGORY).find(category => category.label.toLowerCase() === data.cardType?.toLowerCase())?.code;

                                                props.onChange({
                                                    ...props.seller,
                                                    ...data,
                                                    state: state?.code,
                                                    mobileNo: mobileNo,
                                                    email: _email,
                                                    address1: data.address1.replace(/\0.*$/, ""),
                                                    address2: data.address2.replace(/\0.*$/, ""),
                                                    address3: data.address3.replace(/\0.*$/, ""),
                                                    name: data.name.replace(/\*/g, ''),
                                                    district: data.district.replace(/[^a-zA-Z\s]/g, ''),
                                                    city: data.district.replace(/[^a-zA-Z\s]/g, ''),
                                                    sellerType: cardTypeCode,
                                                    citizenship: data.citizenship
                                                })
                                                setStep(STEPS.AUTHORIZE_SELLER.label)
                                                setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY)
                                            })


                                    }}
                                    onDataChanged={data => { }}
                                    onError={data => {
                                        setStep(STEPS.AUTHORIZE_SELLER.label)
                                        setSubStep(STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC)
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 20,
                                    }}
                                >
                                    {/* <Button
                                        shape='round'
                                        onClick={() => {
                                            history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.VEHICLE_INFORMATION.label,
                                                subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                                            })
                                        }}
                                    >
                                        Prev step
                                    </Button> */}
                                </div>
                            </div>
                        ) : <div>{
                            currentStep && currentStep.component
                        }</div>
                    }
                </div>
            </div>
        </>
    )
}

AuthorizeSeller.propTypes = {
    seller: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    formLabel: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
        margin: 0
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    }
}

export default AuthorizeSeller